import { HTMLAttributes, PropsWithChildren, useRef, useState } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { COLORS } from 'tailwind.config';
import { ErrorMessage } from './ErrorMessage';

const convertSeparatorValue = (value: string, separator: string) => {
  if (value) {
    const parts = value?.toString()?.split('.');
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts?.join('.');
  }

  if (Number.isNaN(value)) {
    return '';
  }

  return value;
};

function getObjectValue(obj: any, path: string) {
  return path
    .split('.')
    .reduce(
      (acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined),
      obj
    );
}

type Props = PropsWithChildren<{
  useForm: UseFormReturn<any>;
  name: string;
  isErrorFiled: boolean;
  label?: string;
  helperText?: string;
  showHelperText?: boolean;
  asNumeric?: boolean;
  thousandSeparator?: string;
  readonly?: boolean;
  type?: 'input' | 'date' | 'email';
  placeholder?: string;
  key?: string;
  maxLength?: number;
}> &
  HTMLAttributes<HTMLDivElement>;

export const TextField = ({
  className,
  useForm,
  name,
  isErrorFiled,
  label,
  helperText,
  showHelperText = false,
  asNumeric = false,
  thousandSeparator = ',',
  readonly = false,
  type = 'input',
  placeholder,
  key = '',
  maxLength,
  onBlur,
}: Props) => {
  const formValue = useForm.watch(name);
  const [focus, setFocus] = useState(false);
  const [viewFormValue, setViewFormValue] = useState(
    asNumeric ? convertSeparatorValue(formValue, thousandSeparator) : formValue
  );
  const viewInputRef = useRef<HTMLInputElement>(null);
  const formInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={className}>
      {label && (
        <p className="text-grayscale-700 text-meta pb-[20px]">{label}</p>
      )}

      {/* 表示用 */}
      {!focus && (
        <input
          type={type}
          className={`${className} py-[9px] px-[12px] border rounded w-full border-grayscale-500 text-ui`}
          defaultValue={viewFormValue}
          value={viewFormValue}
          placeholder={placeholder}
          disabled={readonly}
          key={key}
          onFocus={() => {
            setFocus(true);
            setTimeout(() => {
              formInputRef.current?.focus();
            }, 100);
          }}
          style={{ borderColor: isErrorFiled ? COLORS.MAIN_RED : '' }}
          ref={viewInputRef}
          maxLength={maxLength}
        />
      )}

      {/* 実態 */}
      {focus && (
        <input
          type={type}
          className={`${className} py-[9px] px-[12px] border rounded w-full border-grayscale-500 text-ui`}
          {...useForm.register(name)}
          defaultValue={formValue}
          style={{ borderColor: isErrorFiled ? COLORS.MAIN_RED : '' }}
          key={key}
          onBlur={(e) => {
            if (asNumeric) {
              // 全角から半角数字への変換
              const cleanInputValue = e.target.value
                .replace(/[０-９]/g, (s) => {
                  return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
                })
                .replaceAll(',', '');

              if (Number.isNaN(parseFloat(cleanInputValue))) {
                useForm.setValue(name, '');
                setViewFormValue('');
                return;
              }

              useForm.setValue(name, parseFloat(cleanInputValue));
              setViewFormValue(
                convertSeparatorValue(cleanInputValue, thousandSeparator)
              );
            } else {
              useForm.setValue(name, e.target.value);
              setViewFormValue(e.target.value);
            }

            setFocus(false);
            onBlur?.(e);
          }}
          ref={formInputRef}
          maxLength={maxLength}
        />
      )}

      {isErrorFiled && (
        <ErrorMessage
          message={(
            getObjectValue(useForm.formState.errors, name) as FieldError
          )?.message?.toString()}
        />
      )}

      {showHelperText && helperText && (
        <p className="text-grayscale-700 text-meta mt-[4px]">{helperText}</p>
      )}
    </div>
  );
};
