import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type UserType = 'corporationAdmin' | 'outsideManager' | null;

export const accessTokenStorageAtom = atomWithStorage<string | null>(
  'accessToken',
  null
);
export const userIdStorageAtom = atomWithStorage<string | null>('userId', null);
export const userTypeStorageAtom = atomWithStorage<UserType>('userType', null);

export const userTypeAtom = atom(
  (get) => {
    return get(userTypeStorageAtom);
  },
  (get, set, newValue: UserType) => {
    set(userTypeStorageAtom, newValue);
  }
);

export const hasUserTypeCorporationAdminAtom = atom(
  (get) => get(userTypeAtom) === 'corporationAdmin'
);
export const hasUserOutsideManagerAtom = atom(
  (get) => get(userTypeAtom) === 'outsideManager'
);

export const loginAtom = atom(
  null,
  (get, set, newValue: { userTypeValue: UserType; userId: string }) => {
    set(userIdStorageAtom, newValue.userId);
    set(userTypeStorageAtom, newValue.userTypeValue);
  }
);

export const logoutAtom = atom(null, (get, set) => {
  set(userIdStorageAtom, null);
});

export const userIdAtom = userIdStorageAtom;
