import { components } from 'infra/api/schema';

// eslint-disable-next-line consistent-return
export const getQualificationLabel = (
  qualification: components['schemas']['QualificationEnum']
): string => {
  try {
    return {
      cooks: '料理人',
      specialCooks: '専門調理師・調理技能士',
      nutritionist: '栄養士・管理栄養士',
      confectioneryTechnician: '菓子製造技能士',
      breadManufacturingTechnician: 'パン製造技能士',
      confectioneryHygienist: '製菓衛生師',
      fuguChef: 'ふぐ調理師',
      foodSanitationManager: '食品衛生責任者・食品衛生管理者',
      fireSafetyManager: '防火管理者',
      restaurantServiceCertifiedSkilledWorker: 'レストランサービス技能士',
      foodCoordinator: 'フードコーディネーター',
      wineSommelier: 'ワインソムリエ',
      bartender: 'バーテンダー',
      sakeTaster: 'きき酒師',
      sakeProviderMaister: '日本酒提供者マイスター',
      vegetableSommelier: '野菜ソムリエ',
    }[qualification];
  } catch (error) {
    throw new Error('`finalEducation`が不正な値です');
  }
};
