import { FC, useEffect, useState } from 'react';
import { TableRow, Avatar, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { OnsiteUserListResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import {
  DeleteOrSuspendModal,
  state as DeleteOrSuspendModalState,
} from 'context/users/onsite_users/_infra/components/Modal/DeleteOrSuspendModal';
import { BluePersonIcon } from 'context/users/onsite_users/Staff/_components/BluePersonIcon';
import { COLORS } from 'tailwind.config';
import { WealthLabel } from 'context/wealth/WealthLabel/WealthLabel';

type RowProps = {
  onsiteUser: OnsiteUserListResponse;
  index: number;
  isStripedBackgroundColor: boolean;
  isChecked: boolean;
  onChangeCheckbox: (staffId: string, isUnselect: boolean) => void;
};

export const StaffRow: FC<RowProps> = ({
  onsiteUser,
  index,
  isStripedBackgroundColor,
  isChecked,
  onChangeCheckbox,
}) => {
  const navigate = useNavigate();
  const onClick = () => navigate(`/staffs_manage/staffs/${onsiteUser.id}`);
  const deleteOrSuspendModalState = DeleteOrSuspendModalState.local();
  const stripedBackgroundColor =
    index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const [fadeOutClass, setFadeOutClass] = useState('');

  useEffect(() => {
    if (deleteOrSuspendModalState.isDeletedCompleted) {
      setFadeOutClass('opacity-0 transition-opacity duration-500');
      setTimeout(() => setShouldDisplay(false), 500);
    }
  }, [deleteOrSuspendModalState.isDeletedCompleted]);

  if (!shouldDisplay) return <span />;

  return (
    <TableRow
      className={fadeOutClass}
      style={{
        backgroundColor: isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell
        className="base-hover w-[60px] !text-center !p-[0px]"
        component="th"
        scope="row"
        onClick={() => {
          onChangeCheckbox(onsiteUser.id, isChecked);
        }}
      >
        <Checkbox checked={isChecked} aria-label="選択状態" />
      </BaseTableBodyCell>
      <BaseTableBodyCell
        className="base-hover w-[185px]"
        onClick={onClick}
        component="th"
        scope="row"
      >
        <div className="flex gap-[10px] items-center">
          <Avatar
            sx={{ width: 32, height: 32 }}
            alt="Remy Sharp"
            src={onsiteUser.avatar ?? ''}
          />
          <span
            className={`${
              deleteOrSuspendModalState.isSuspendExecuteCompleted
                ? 'opacity-suspend'
                : ''
            }`}
          >
            <span className="text-ui">
              {onsiteUser.lastName} {onsiteUser.firstName}
            </span>
            {onsiteUser.isEvaluator ? (
              <div className="flex items-center gap-[6px] rounded-full bg-soft-blue px-[6px] py-[4px] w-[54px] text-micro mt-[8px] h-[16px]">
                <BluePersonIcon />
                <span className="font-bold text-main-blue">評価者</span>
              </div>
            ) : (
              ''
            )}

            {onsiteUser.subjectToEvaluationThisTerm ? (
              ''
            ) : (
              <div className="block w-[70px] text-micro mt-[8px]">
                <div className="text-micro font-bold text-grayscale-700 rounded-full bg-grayscale-500 px-[5px] py-[4px] leading-none whitespace-nowrap">
                  今期評価対象外
                </div>
              </div>
            )}
          </span>
        </div>
      </BaseTableBodyCell>

      <BaseTableBodyCell
        onClick={onClick}
        className={`base-hover w-[135px] ${
          deleteOrSuspendModalState.isSuspendExecuteCompleted
            ? 'opacity-suspend'
            : ''
        }`}
      >
        <span className="text-ui">
          <WealthLabel label={onsiteUser.wealthProfileResult} />
        </span>
      </BaseTableBodyCell>

      <BaseTableBodyCell
        onClick={onClick}
        className={`base-hover w-[52px] ${
          deleteOrSuspendModalState.isSuspendExecuteCompleted
            ? 'opacity-suspend'
            : ''
        }`}
      >
        <span className="text-ui">{onsiteUser.occupation.name}</span>
      </BaseTableBodyCell>

      <BaseTableBodyCell
        onClick={onClick}
        className={`base-hover w-[80px] ${
          deleteOrSuspendModalState.isSuspendExecuteCompleted
            ? 'opacity-suspend'
            : ''
        }`}
      >
        <span className="text-ui">{onsiteUser.grade.position.name}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell
        onClick={onClick}
        className={`base-hover w-[52px] ${
          deleteOrSuspendModalState.isSuspendExecuteCompleted
            ? 'opacity-suspend'
            : ''
        }`}
      >
        {onsiteUser.grade.name}
      </BaseTableBodyCell>
      <BaseTableBodyCell
        onClick={onClick}
        className={`base-hover w-[130px] ${
          deleteOrSuspendModalState.isSuspendExecuteCompleted
            ? 'opacity-suspend'
            : ''
        }`}
      >
        <span className="text-ui">{onsiteUser.shop.name}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell
        onClick={onClick}
        className={`base-hover w-[112px] ${
          deleteOrSuspendModalState.isSuspendExecuteCompleted
            ? 'opacity-suspend'
            : ''
        }`}
      >
        <div className="flex items-center">
          <div className="w-[48px] bg-grayscale-500 rounded-full h-[4px]">
            <div
              className="bg-main-blue h-[4px] rounded-full"
              style={{ width: `${onsiteUser.evaluationAchievementPercent}%` }}
            />
          </div>
          <span className="ml-[5px] text-meta w-[35px]">
            {onsiteUser.evaluationAchievementPercent}%
          </span>
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell onClick={onClick}>
        <div className="flex gap-[6px] items-center base-hover w-[132px]">
          <Avatar
            sx={{ width: 32, height: 32 }}
            alt="Remy Sharp"
            src={onsiteUser.evaluator?.avatar || ''}
          />
          <span
            className={`${
              deleteOrSuspendModalState.isSuspendExecuteCompleted
                ? 'opacity-suspend'
                : ''
            }`}
          >
            <span className="text-ui">
              {onsiteUser.evaluator?.lastName} {onsiteUser.evaluator?.firstName}
            </span>
          </span>
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="w-[54px]">
        <MoreMenu
          render={[
            {
              label: <span>編集</span>,
              onClick: () => {
                navigate(`/staffs_manage/staffs/${onsiteUser.id}/edit`);
              },
            },
            !deleteOrSuspendModalState.isSuspendExecuteCompleted
              ? {
                  label: <span>一時停止</span>,
                  onClick: () => {
                    deleteOrSuspendModalState.showModal('suspend');
                  },
                }
              : null,
            {
              label: <span>削除</span>,
              onClick: () => {
                deleteOrSuspendModalState.showModal('delete');
              },
            },
          ]}
        />
      </BaseTableBodyCell>

      <DeleteOrSuspendModal
        _state={deleteOrSuspendModalState}
        onsiteUser={onsiteUser}
      />
    </TableRow>
  );
};
