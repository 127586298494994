import { ReactNode, useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { UseFormReturn } from 'react-hook-form';
import { COLORS } from 'tailwind.config';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  menuItems: { label: ReactNode; value: string | number }[] | undefined;
  useForm: UseFormReturn<any>;
  name: string;
  isErrorFiled: boolean;
  className?: string;
  order?: 'labelAsc';
};

export const Select = ({
  menuItems,
  useForm,
  name,
  isErrorFiled,
  className = 'w-[320px]',
  order,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const updateValue = (value: string | number) => {
    useForm.setValue(name, adjustValue(value));
  };

  const adjustValue = (value: any) => {
    if (typeof value === 'number') {
      return Number(value);
    }

    return String(value);
  };

  function sortMenuItems(a: any, b: any) {
    if (order === 'labelAsc') {
      return a?.label?.toString()?.localeCompare(b?.label?.toString()!);
    }

    return true;
  }

  const initValue =
    menuItems?.filter(
      (item) => adjustValue(item.value) === adjustValue(useForm.watch(name))
    )[0]?.label || '選択';

  return (
    <div>
      <div className={`relative ${className}`}>
        <button
          type="button"
          className="
            relative w-full py-[8px] pl-[12px] pr-[20px]
            border-1 border-grayscale-500 rounded text-base text-left
            select-hover
          "
          onClick={() => {
            toggleDropdown();
          }}
          style={{ borderColor: isErrorFiled ? COLORS.MAIN_RED : '' }}
        >
          {initValue || '選択'}
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
            <ArrowDownIcon />
          </div>
        </button>
        {isOpen && (
          <div
            className="
              absolute left-0 top-[41px] w-full overflow-y-auto max-h-[300px] z-10 py-[6px]
              flex flex-col border-1 border-grayscale-500 rounded text-base hidden-scrollbar
              shadow-main bg-grayscale-100
            "
          >
            {menuItems?.sort(sortMenuItems).map((item) => (
              <button
                type="button"
                key={item.value}
                className="py-[10px] px-[20px] text-left pulldown-hover bg-selectbox"
                onMouseDown={() => {
                  updateValue(item.value);
                  toggleDropdown();
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>

      {isErrorFiled && (
        <ErrorMessage
          message={useForm.formState.errors[name]?.message?.toString()}
        />
      )}

      {/* 表示されているときのみ有効: 別に独立したchecknoxのon offで処理が走ってしまうため */}
      {isOpen && (
        <input
          type="text"
          className="absolute w-0 h-0 opacity-0"
          ref={(element) => {
            element?.focus();
          }}
          onBlur={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};
