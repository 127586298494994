import { Avatar } from '@mui/material';
import { RightArrowIcon } from 'context/users/onsite_users/_infra/components/StaffDetails/StaffTabs/MVPTab/MVPLogItem/RightArrowIcon';
import { BaseChip } from 'infra/components/UI/Helps/BaseChip';
import { colorToRgba } from 'context/mvps/types/mvpCategory';
import { AvatarAndName } from 'infra/components/UI/Profiles/AvatarAndName';
import { AccessTimeIcon } from 'infra/components/UI/Icons/AccessTimeIcon';
import { formatDistance } from 'infra/utils/date-fns';
import { useState, CSSProperties } from 'react';
import { TwemojiIconFromEmoji } from 'infra/components/UI/Emoji/TwemojiIconFromEmoji';
import { getStamp } from 'context/mvps/types/stamp';
import { MvpCoinAssignment } from 'context/mvps/api/get.mvpCoinAssignment';
import { COLORS } from 'tailwind.config';

export const MVPLogItem = (props: {
  color: string;
  MVPCategoryName: string;
  from: { avatar: string; name: string; icon: MvpCoinAssignment['stamp'] };
  to: {
    avatar: string;
    name: string;
    icon?: MvpCoinAssignment['reactionType'];
  };
  content: string;
  createdAt: Date;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <button
      type="button"
      aria-label="もらったMVPコインの詳細"
      className="overflow-hidden rounded shadow-main"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div
        className="flex items-center px-[20px] py-[10px]"
        style={{ backgroundColor: colorToRgba(props.color, 0.1) }}
      >
        <AvatarAndName imagePath={props.from.avatar} name={props.from.name} />
      </div>
      <div className="px-[20px] py-[14px]">
        <div className="flex justify-between mb-[16px]">
          <div className="flex items-center">
            <div className="mr-[14px]">
              <RightArrowIcon />
            </div>
            <AvatarAndName imagePath={props.to.avatar} name={props.to.name} />
          </div>
          <div className="flex -space-x-[6px]">
            <Avatar
              sx={{
                width: 26,
                height: 26,
                backgroundColor: COLORS.GRAY_400,
              }}
            >
              <TwemojiIconFromEmoji
                emoji={getStamp(props.from.icon)}
                size="18px"
              />
            </Avatar>
            {props.to.icon && (
              <Avatar
                sx={{
                  width: 26,
                  height: 26,
                  backgroundColor: COLORS.GRAY_400,
                }}
              >
                <TwemojiIconFromEmoji
                  emoji={getStamp(props.to.icon)}
                  size="18px"
                />
              </Avatar>
            )}
          </div>
        </div>
        <div className="mb-[30px]">
          <p
            className="text-grayscale-700 whitespace-pre-wrap text-left text-meta"
            style={
              {
                display: '-webkit-box',
                overflow: 'hidden',
                '-webkit-line-clamp': isExpanded ? undefined : '2',
                '-webkit-box-orient': 'vertical',
              } as CSSProperties
            }
          >
            {props.content}
          </p>
        </div>
        <div className="flex justify-between">
          <BaseChip
            color16={props.color}
            label={`#${props.MVPCategoryName}`}
            size="small"
          />
          <div className="flex items-center justify-center gap-[10px]">
            <AccessTimeIcon />
            <p className="text-grayscale-600 text-label">
              {formatDistance(props.createdAt)}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};
