import { UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  useForm: UseFormReturn<any>;
  name: string;
  onClick?: () => void;
  className?: string;
  id?: string;
};

export const Checkbox = (props: Props) => {
  const defaultClassName = `
    absolute whitespace-nowrap w-[1px] h-[1px] border-0 p-0 -m-[1px] cursor-pointer appearance-none
    before:content-[''] before:w-[18px] before:h-[18px] before:rounded before:border-1 before:border-grayscale-600 before:block
    before:absolute before:top-[50%] before:-translate-y-[16px] before:left-0 before:transform
    checked:before:bg-main-blue checked:before:border-main-blue
    after:content-[''] after:absolute after:border-b-2 after:border-l-2 after:opacity-0 after:h-[6px] after:w-[10px] after:transform after:border-grayscale-100
    after:-rotate-45 after:-top-[10px] after:left-[4px] after:block
    checked:after:opacity-100
  `;

  return (
    <div>
      <div className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          {...props.useForm.register(props.name)}
          onClick={props.onClick}
          className={`${defaultClassName} ${props.className}`}
          id={props.id}
        />
      </div>

      {!!props.useForm.formState.errors[props.name]?.message && (
        <ErrorMessage
          message={props.useForm.formState.errors[
            props.name
          ]?.message?.toString()}
        />
      )}
    </div>
  );
};
