import { createSvgIcon } from '@mui/material/utils';

const d =
  'M15.572,2a2.335,2.335,0,0,1,2.294,2.114h1.3a1.818,1.818,0,0,1,1.79,1.7l.006.152V9.134a3.393,3.393,0,0,1-3.145,3.429l-.216.006a5.957,5.957,0,0,1-4.85' +
  '4,4.176v1.636h1.8a3.379,3.379,0,0,1,3.329,3.24l.005.195v.528a.785.785,0,0,1-.665.785l-.1.007H6.848a.778.778,0,0,1-.762-.685l-.007-.108v-.528a3.393,3.393,0' +
  ',0,1,3.145-3.429l.189-.005h1.8V16.745A5.957,5.957,0,0,1,6.357,12.57H6.33A3.386,3.386,0,0,1,3,9.134V5.963a1.823,1.823,0,0,1,1.8-1.85h1.3A2.335,2.335,0,0,1,' +
  '8.387,2ZM14.546,19.967H9.413A1.808,1.808,0,0,0,7.664,21.4l-.028.156h8.688A1.82,1.82,0,0,0,14.7,19.974ZM15.572,3.585H8.387a.781.781,0,0,0-.77.793v6.34a4.42' +
  '9,4.429,0,0,0,4.362,4.493,4.429,4.429,0,0,0,4.362-4.493V4.378A.781.781,0,0,0,15.572,3.585Zm3.6,2.114H17.88v5.266A1.828,1.828,0,0,0,19.417,9.3l.007-.163V5.' +
  '963a.263.263,0,0,0-.2-.257ZM6.078,5.7H4.791a.26.26,0,0,0-.257.264V9.134a1.833,1.833,0,0,0,1.544,1.831Z';

export const TotalPointIcon = createSvgIcon(
  <svg
    id="グループ_602"
    data-name="グループ 602"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 42.083 42.084"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="1"
        x2="0.5"
        y2="-0.324"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#048cf2" />
        <stop offset="1" stopColor="#2deaa8" />
      </linearGradient>
    </defs>
    <ellipse
      id="楕円形_58"
      data-name="楕円形 58"
      cx="21.042"
      cy="21.042"
      rx="21.042"
      ry="21.042"
      transform="translate(0 0)"
      fill="url(#linear-gradient)"
    />
    <g id="trophy" transform="translate(12.296 10.152)">
      <g id="_-Product-Icons" data-name="-Product-Icons">
        <g id="ic_fluent_trophy_24_regular">
          <path
            id="_-Color"
            data-name="-Color"
            d={d}
            transform="translate(-2.996 -2)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>,
  'TotalPointIcon'
);
