export const CircleFlagIcon = () => (
  <svg
    id="実践目標アイコン_大_"
    data-name="実践目標アイコン（大）"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <rect
      id="長方形_1308"
      data-name="長方形 1308"
      width="13"
      height="13"
      rx="6.5"
      fill="#fff"
    />
    <g
      id="グループ_2459"
      data-name="グループ 2459"
      transform="translate(136 -2221)"
    >
      <g id="グループ_2629" data-name="グループ 2629">
        <circle
          id="楕円形_205"
          data-name="楕円形 205"
          cx="4.5"
          cy="4.5"
          r="4.5"
          transform="translate(-134 2223)"
          fill="#fff"
        />
        <g id="flag" transform="translate(-132 2225)">
          <g id="_-Product-Icons" data-name="🔍-Product-Icons">
            <g id="ic_fluent_flag_24_filled">
              <path
                id="_-Color"
                data-name="🎨-Color"
                d="M3,3.234A.231.231,0,0,1,3.225,3h4.95a.238.238,0,0,1,.18.379L7.106,5.129,8.355,6.882a.238.238,0,0,1-.18.379H3.45v1.5A.233.233,0,0,1,3.255,9L3.225,9A.229.229,0,0,1,3,8.793l0-.032Z"
                transform="translate(-3 -2.998)"
                fill="#1a222c"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
