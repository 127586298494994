import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import {
  ShopForm,
  ShopFormSchema,
} from 'context/corporation/shop/components/ShopForm';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useNavigate } from 'react-router-dom';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { PropsWithChildren } from 'react';
import { ResponseBrandOfResponsePaginatedCorporation } from 'context/corporation/brand/api/types';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';

import { createShop } from 'context/corporation/shop/api/createShop';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';

const DEDFAULT_BUSINESS_HOUR_DATETIME = {
  startedDatetime: '11:00',
  endedDatetime: '23:00',
};

export const ShopCreate = () => {
  const [{ data: corporation }] = useFetchCorporation();

  if (corporation?.brands === undefined) {
    return (
      <div>
        <PageTitle
          title="店舗登録"
          breadcrumbs={[
            ['ホーム', '/'],
            ['店舗一覧', '/shops'],
          ]}
        />
        <LoadingBackdrop isShow />
      </div>
    );
  }

  if (corporation?.brands.length === 0) {
    return (
      <div>
        <PageTitle
          title="店舗登録"
          breadcrumbs={[
            ['ホーム', '/'],
            ['店舗一覧', '/shops'],
          ]}
        />
        <div className="mt-[40px]">
          <WarningMessageBox
            message="業態未登録エラー"
            detailsMessage="業態が未登録です。業態を1つ以上登録してください"
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PageTitle
        title="店舗登録"
        breadcrumbs={[
          ['ホーム', '/'],
          ['店舗一覧', '/shops'],
        ]}
      />
      <ShopCreateLoaded brands={corporation?.brands!} />
    </div>
  );
};
export const ShopCreateLoaded = (
  props: PropsWithChildren<{
    brands: ResponseBrandOfResponsePaginatedCorporation[];
  }>
) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFromInstace = useForm({
    resolver: zodResolver(ShopFormSchema),
    defaultValues: {
      brandId: props.brands[0].id,
      name: '',
      zipCode: '',
      phoneNumber: '',
      businessHours: [
        { dayOfWeek: 0, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
        { dayOfWeek: 1, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
        { dayOfWeek: 2, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
        { dayOfWeek: 3, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
        { dayOfWeek: 4, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
        { dayOfWeek: 5, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
        { dayOfWeek: 6, ...DEDFAULT_BUSINESS_HOUR_DATETIME },
      ],
      coinAvailabilityStartedDatetime:
        DEDFAULT_BUSINESS_HOUR_DATETIME.startedDatetime,
      coinAvailabilityEndedDatetime:
        DEDFAULT_BUSINESS_HOUR_DATETIME.endedDatetime,
    } as z.infer<typeof ShopFormSchema>,
  });

  const onSubmit = useFromInstace.handleSubmit((value) => {
    startLoading('create_shop');

    createShop(value)
      .then(() => {
        displayMessage({ text: '店舗を登録しました', isInterruption: false });
        navigate('/shops');
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFromInstace.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('create_shop');
      });
  });

  return (
    <div className="flex items-center mt-[50px] rounded bg-grayscale-100">
      <ShopForm
        useForm={useFromInstace}
        brands={props.brands}
        onSubmit={onSubmit}
        actionLabel="登録"
      />
    </div>
  );
};
