import { Avatar, Dialog, IconButton, TextField } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { SmallModal } from 'infra/components/UI/Overlays/SmallModal';
import { TwemojiIconFromEmoji } from 'infra/components/UI/Emoji/TwemojiIconFromEmoji';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

export const CommentModal = (props: {
  currentComment: string | null;
  onClose: () => void;
  onSubmit: (comment: string) => void;
}) => {
  const [comment, setComment] = useState(props.currentComment || '');
  const [, displayMessage] = useAtom(displayMessageAtom);

  const onSubmit = () => {
    if (comment.length > 1000) {
      displayMessage({
        text: 'コメントは1000文字以内で入力してください',
        isInterruption: true,
      });
      return;
    }
    props.onSubmit(comment);
    props.onClose();
  };

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <SmallModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-[116px] text-[18px] text-bold"
            onClick={onSubmit}
          >
            投稿
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div>
          <div className="flex mb-[30px] gap-[20px] relative left-[40px]">
            <IconButton onClick={() => setComment(`${comment}😄`)}>
              <TwemojiIconFromEmoji emoji="😄" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}👌`)}>
              <TwemojiIconFromEmoji emoji="👌" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}❤️`)}>
              <TwemojiIconFromEmoji emoji="❤️" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}👏`)}>
              <TwemojiIconFromEmoji emoji="👏" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}🙏`)}>
              <TwemojiIconFromEmoji emoji="🙏" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}😉`)}>
              <TwemojiIconFromEmoji emoji="😉" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}👍`)}>
              <TwemojiIconFromEmoji emoji="👍" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}✌️`)}>
              <TwemojiIconFromEmoji emoji="✌️" size="30px" />
            </IconButton>
            <IconButton onClick={() => setComment(`${comment}🙌`)}>
              <TwemojiIconFromEmoji emoji="🙌" size="30px" />
            </IconButton>
          </div>
          <div className="flex gap-[10px]">
            <Avatar src="" />
            <TextField
              size="small"
              label=""
              fullWidth
              placeholder="コメントを入力してください😀"
              multiline
              rows={6}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              variant="outlined"
            />
          </div>
        </div>
      </SmallModal>
    </Dialog>
  );
};
