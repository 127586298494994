import { CalendarMonthIcon } from 'infra/components/UI/Icons/CalendarMonthIcon';
import { Avatar } from '@mui/material';
import { CommitmentSheet } from 'context/objective/components/evaluate/CommitmentSheet';
import { OnsiteUserAffiliation } from 'context/objective/components/ObjectiveRecordList/OnsiteUserAffiliation';
import { formatDate } from 'infra/utils/date-fns';
import { EvaluationProgressBar } from 'infra/components/UI/Steps/EvaluationProgressBar';
import { ObjectiveRecord } from 'context/objective/components/evaluate/evaluateMiddle';

export const EvaluationUserView = ({
  objectiveRecord,
}: {
  objectiveRecord: ObjectiveRecord;
}) => {
  const user = objectiveRecord.onsiteUser!;
  const fullName = `${user.lastName} ${user?.firstName}`;

  const practicalThinkings = objectiveRecord.basicThinkings.flatMap(
    (b) => b.practicalThinkings
  );
  const progress =
    practicalThinkings.length === 0
      ? 0
      : (practicalThinkings.filter((p) => p.hasAchieved).length /
          practicalThinkings.length) *
        100;

  return (
    <div className="fixed w-[360px] h-full flex flex-col items-center gap-[20px] py-[30px] px-[20px] bg-grayscale-100">
      <Avatar style={{ width: 96, height: 96 }} src={user.avatar || ''} />

      <div className="text-center">
        <p className="text-base font-bold mb-[10px]">{fullName} さん</p>
        <OnsiteUserAffiliation
          align="center"
          shopName={user.shop.name}
          occupationName={user.occupation.name}
          gradeName={user.grade.name}
          positionName={user.grade.position.name}
          wealthProfileResult={user.wealthResultProfile}
        />
      </div>

      <div className="flex items-center gap-[12px]">
        <CalendarMonthIcon />
        <p className="text-ui">
          {formatDate(new Date(objectiveRecord.evaluationPeriod.startedAt))}~
          {formatDate(new Date(objectiveRecord.evaluationPeriod.endedAt))}
        </p>
      </div>

      <CommitmentSheet
        basicThinkings={objectiveRecord.basicThinkings}
        currentGrade={objectiveRecord.currentGrade}
        nextGrade={objectiveRecord.nextGrade}
      />

      <div className="flex justify-center items-center gap-[20px]">
        <div
          className="
            w-[40px] h-[40px] flex items-center justify-center
            rounded text-label border-1 border-main-blue text-main-blue font-bold text-center leading-tight loose-short-text
          "
        >
          目標
          <br />
          進捗
        </div>

        <EvaluationProgressBar value={progress} />
      </div>
    </div>
  );
};
