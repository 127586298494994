import {
  ResponseObjectiveRecord,
  useFetchObjectiveRecord,
} from 'context/objective/api/useFetchObjectiveRecords';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { ErrorMessageView } from 'pages/ErrorMessageView';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { useState } from 'react';
import TransparentCheck from 'context/objective/components/evaluate/svg/transparent_check_mark.svg';
import { useAtom } from 'jotai';
import {
  resetScore,
  ValidatedScore,
} from 'context/objective/store/evaluationByOutsideManager';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';

import { postMiddleEvaluation } from 'context/objective/api/post.middleEvaluation';

import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { EvaluationUserView } from './EvaluationByOutsideManager/EvaluationUserView';
import { EvaluationLayout } from './EvaluationByOutsideManager/Layout/Index';
import { InputScoreForm } from './EvaluationByOutsideManager/InputScoreForm';

export type ObjectiveRecord = ResponseObjectiveRecord;

export const EvaluateMiddle = () => {
  const params = useParams();
  const { objectiveRecordId } = params;
  const [{ data: objectiveRecord, loading }] =
    useFetchObjectiveRecord(objectiveRecordId);
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const navigate = useNavigate();
  const [displayMode, setDisplayMode] = useState<'start' | 'input' | 'end'>(
    'start'
  );

  const [, reset] = useAtom(resetScore);

  const displayNonFieldApiError = useDisplayNonFieldApiError();

  if (objectiveRecordId === undefined) {
    return (
      <ErrorMessageView errorMessage="不正なIDが指定されています。再度お試しください" />
    );
  }

  if (loading) {
    return <LoadingBackdrop isShow />;
  }

  if (objectiveRecord === undefined) {
    return (
      <ErrorMessageView errorMessage="不正なIDが指定されています。再度お試しください" />
    );
  }

  if (objectiveRecord.isCompletedMiddleEvaluationByBoss) {
    return (
      <ErrorMessageView errorMessage="すでに中間上司評価の入力が完了しています" />
    );
  }

  const user = objectiveRecord.onsiteUser!;
  const fullName = `${user.lastName} ${user?.firstName}`;

  const onFixEvaluation = (scoreList: ValidatedScore[]) => {
    startLoading('fix_evaluation');

    postMiddleEvaluation(objectiveRecordId, scoreList)
      .then(() => {
        setDisplayMode('end');
      })
      .catch((error) => {
        displayNonFieldApiError(error);
      })
      .finally(() => {
        stopLoading('fix_evaluation');
      });
  };

  return (
    <EvaluationLayout
      evaluationType="middle"
      displayProggress={displayMode === 'input'}
      displayFixButton={displayMode === 'input'}
      onFixEvaluation={onFixEvaluation}
      onBack={() => {
        if (displayMode === 'start') navigate(-1);
        if (displayMode === 'input') setDisplayMode('start');
        if (displayMode === 'end') navigate(-1);
      }}
    >
      <div className="flex relative h-full">
        <EvaluationUserView objectiveRecord={objectiveRecord} />

        {/* 開始時 */}
        {displayMode === 'start' && (
          <div
            className="ml-[370px] p-[60px] bg-grayscale-100"
            style={{ width: 'calc(100% - 360px)' }}
          >
            <h3 className="text-page-heading pb-[40px]">マネジャー中間評価</h3>
            <p className="text-base leading-readable mb-[40px]">
              {fullName}
              さんの目標設定期間が終了しました。
              <br />
              中間評価を入力してください。
            </p>
            <BaseButton
              className="w-btn-confirm rounded-full"
              onClick={() => {
                reset();
                setDisplayMode('input');
              }}
            >
              中間評価をはじめる
            </BaseButton>
          </div>
        )}

        {/* 入力時 */}
        {displayMode === 'input' && (
          <div
            className="p-[60px] pb-[160px] ml-[370px] bg-grayscale-100"
            style={{ width: 'calc(100% - 360px)' }}
          >
            <InputScoreForm
              evaluationType="middle"
              user={objectiveRecord.onsiteUser}
              objectiveRecord={objectiveRecord}
            />
          </div>
        )}

        {/* 完了時 */}
        {displayMode === 'end' && (
          <div
            className="ml-[370px] p-[60px] bg-grayscale-100"
            style={{ width: 'calc(100% - 360px)' }}
          >
            <div className="flex items-center pb-[30px] gap-[40px]">
              <img src={TransparentCheck} alt="チェックマーク" />
              <p className="text-page-heading">中間評価を確定しました！</p>
            </div>

            <p className="text-base leading-readable pb-[60px]">
              {fullName}
              さんに中間評価が通知されます。
            </p>
            <BaseButton
              className="w-btn-confirm rounded-full"
              onClick={() => navigate('/')}
            >
              閉じる
            </BaseButton>
          </div>
        )}
      </div>
    </EvaluationLayout>
  );
};
