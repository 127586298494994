import { createSvgIcon } from '@mui/material/utils';

const d =
  'M14.317,11A1.687,1.687,0,0,1,16,12.688v.689a2.063,2.063,0,0,1-.384,1.2C14.46,16.2,12.566,17,10,17s-4.458-.8-5.615-2.427A2.063,2.063,0,0,1,4,13.378v-' +
  '.69A1.687,1.687,0,0,1,5.69,11ZM10,2a3.75,3.75,0,1,1-3.75,3.75A3.75,3.75,0,0,1,10,2Z';

export const OnePeopleIcon = createSvgIcon(
  <svg
    id="_-Product-Icons"
    data-name="-Product-Icons"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 12 14.998"
  >
    <g id="ic_fluent_person_24_filled">
      <path
        id="_-Color"
        data-name="-Color"
        d={d}
        transform="translate(-4.004 -2.005)"
        fill="#048cf2"
      />
    </g>
  </svg>,
  'OnePeople'
);
