import { FC } from 'react';
import { TableRow } from '@mui/material';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { COLORS } from 'tailwind.config';
import { RedPersonIcon } from 'context/users/onsite_users/Staff/StaffImport/RedPersonIcon';
import { BluePersonIcon } from 'context/users/onsite_users/Staff/StaffImport/BluePersonIcon';
import { EMPLOYMENT_STATUS_LIST } from 'context/users/onsite_users/_infra/state/const/employmentStatus';

type RowProps = {
  onsiteUser: any;
  index: number;
  isStripedBackgroundColor: boolean;
};

export const StaffImportRow: FC<RowProps> = ({
  onsiteUser,
  index,
  isStripedBackgroundColor,
}) => {
  const stripedBackgroundColor =
    index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;

  const mapSexLabel = (genderCode: '0' | '1') => {
    const genderMap = {
      '0': '男性',
      '1': '女性',
    };

    return genderMap[genderCode] || '-';
  };

  const f = (str: string) => {
    return str || '-';
  };

  const getAuthorityAttributes = (authority: string) => {
    let style = '';
    let IconComponent = null;

    switch (authority) {
      case 'マネージャ評価者':
        style = 'bg-soft-red text-main-red';
        IconComponent = RedPersonIcon;
        break;
      case 'マネージャ':
        style = 'bg-soft-blue text-main-blue';
        IconComponent = BluePersonIcon;
        break;
      case 'スタッフ':
        style = 'hidden';
        break;
      default:
        style = 'hidden';
        break;
    }

    return { style, IconComponent };
  };

  const { style, IconComponent } = getAuthorityAttributes(onsiteUser.authority);

  return (
    <TableRow
      style={{
        backgroundColor: isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        <div
          className={`inline-block gap-[4px] text-label rounded p-[5px] font-bold ${style}`}
        >
          <div className="flex items-center gap-[6px]">
            {IconComponent && <IconComponent />}
            {onsiteUser.authority}
          </div>
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.sei)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.mei)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.seiZenkaku)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.meiZenkaku)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.email)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.gradeName)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.evaluator)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.shopName)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {mapSexLabel(onsiteUser.seibetu)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.phoneNumber)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.joinedAt)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.occupationName)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.subOccupationName)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.employmentStatus)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.salary)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.allowance1Name)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.alowance1Amount)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.allowance2Name)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.alowance2Amount)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.allowance3Name)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.alowance3Amount)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.allowance4Name)}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="!px-[30px] !py-[15px] !text-ui">
        {f(onsiteUser.alowance4Amount)}
      </BaseTableBodyCell>
    </TableRow>
  );
};
