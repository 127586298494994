import { Avatar } from '@mui/material';
import { PropsWithChildren } from 'react';

export const OnsiteUserName = (
  props: PropsWithChildren<{
    image?: string;
    fullName?: string;
    isWithdrawal?: boolean;
  }> = {
    image: '/apple-touch-icon.png',
    fullName: 'スタッフ名',
    isWithdrawal: false,
  }
) => (
  <div className="flex items-center pb-[14px] gap-[10px]">
    <Avatar
      sx={{ width: 32, height: 32 }}
      alt="スタッフの画像"
      src={props.image}
    />

    <p className="text-ui font-bold pl-[10x]">{props.fullName}</p>
    <div className="flex-grow" />
    {props.isWithdrawal && (
      <p className="text-meta bg-grayscale-600 text-grayscale-100 rounded px-[6px] py-[2px]">
        退職者
      </p>
    )}
  </div>
);
