export const CheckIcon = () => (
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 42 42"
    xmlSpace="preserve"
  >
    <circle style={{ fill: '#1FC8C2' }} cx="21" cy="21" r="21" />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M18.4,28c-0.5,0-1-0.2-1.4-0.6l-5.4-5.5c-0.8-0.8-0.8-2.1,0-2.8s2.1-0.8,2.8,0l4,4.1l9.2-8.6
      c0.8-0.7,2.1-0.6,2.8,0.2c0.7,0.8,0.7,2-0.1,2.7l-10.6,10C19.4,27.8,18.9,28,18.4,28z"
    />
  </svg>
);
