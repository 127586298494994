import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { PropsWithChildren, useEffect } from 'react';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { Link, useNavigate } from 'react-router-dom';
import { ResponseBrandOfResponsePaginatedCorporation } from 'context/corporation/brand/api/types';

import { BrandFilterArea } from 'context/corporation/brand/components/BrandFilterArea';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { getBrandName } from 'context/corporation/brand/components/BrandForm';
import ReplaceImg from 'context/corporation/brand/assets/replace_img01.png';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { COLORS } from 'tailwind.config';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';

import { useAtom } from 'jotai';
import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { fetchCorporationBrands } from 'context/corporation/brand/api/fetchCorporationBrand';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';

export const BrandListContext = () => {
  const pagerState = useFilterAreaContext();
  const isEmpty = pagerState?.pager.items.length === 0;

  useEffect(() => {
    pagerState.doitPaging(1);
  }, [pagerState.searchParams]);

  return (
    <div>
      <PageTitle title="業態一覧" breadcrumbs={[['ホーム', '/']]} />

      {isEmpty && (
        <div className="mt-[40px]">
          <WarningMessageBox
            message="業態 未登録"
            detailsMessage="業態が登録されていません。業態を1つ以上登録してください"
          />
          <p className="my-[20px] text-base">
            まだ登録されたデータはありません。
          </p>
          <Link to="/brands/create">
            <BaseButton
              className="w-btn-small p-button-small tracking-short-text text-base bg-transparent border border-main-blue text-main-blue"
              startIcon={<AddIcon color="text-main-blue" />}
            >
              登録
            </BaseButton>
          </Link>
        </div>
      )}

      {!isEmpty && (
        <>
          <BrandFilterArea />
          <Contents isLoading={pagerState?.pager?.isLoading}>
            <div className="mt-[20px] flex justify-between items-center">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="bg-grayscale-500">
                  <TableRow>
                    <TableHeaderCell orderValue="name" pagerState={pagerState}>
                      ブランド
                    </TableHeaderCell>
                    <TableHeaderCell
                      orderValue="business_type"
                      pagerState={pagerState}
                    >
                      業態
                    </TableHeaderCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagerState?.pager.items.map(
                    (
                      brand: ResponseBrandOfResponsePaginatedCorporation,
                      index: number
                    ) => {
                      return (
                        <Row
                          key={brand.id}
                          brand={brand}
                          index={index}
                          isStripedBackgroundColor
                        />
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </div>
          </Contents>
          <div className="pt-[40px]">
            <Pagination pagerState={pagerState} />
          </div>
        </>
      )}
    </div>
  );
};

const Row = ({
  brand,
  index,
  isStripedBackgroundColor = false,
}: PropsWithChildren<{
  brand: ResponseBrandOfResponsePaginatedCorporation;
  index: number;
  isStripedBackgroundColor?: boolean;
}>) => {
  const navigate = useNavigate();

  const onClick = () => navigate(`/brands/${brand.id}`);
  const stripedBackgroundColor =
    index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;

  return (
    <TableRow
      style={{
        backgroundColor: isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell
        className="base-hover"
        onClick={onClick}
        component="th"
        scope="row"
      >
        <div className="flex gap-[20px] items-center">
          {brand.brandLogo &&
          !brand.brandLogo.includes('brands-logs_default.jpg') ? (
            <img
              src={brand.brandLogo}
              alt="ブランドロゴ"
              className="max-w-[60px]"
            />
          ) : (
            <img src={ReplaceImg} alt="代替画像" className="max-w-[60px]" />
          )}
          {brand.name}
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell onClick={onClick}>
        <div className="base-hover">{getBrandName(brand.businessType)}</div>
      </BaseTableBodyCell>
      <BaseTableBodyCell>
        <div className="flex justify-end">
          <MoreMenu
            render={[
              {
                label: '編集',
                onClick: () => {
                  onClick();
                },
              },
            ]}
          />{' '}
        </div>
      </BaseTableBodyCell>
    </TableRow>
  );
};

export const BrandList = () => {
  const pagerState = usePagerState<ResponseBrandOfResponsePaginatedCorporation>(
    (limit: number, offset: number, searchParams: URLSearchParams) => {
      return fetchCorporationBrands(limit, offset, searchParams);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState.pager.isLoading}>
        <BrandListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
