import { useAtom } from 'jotai';
import { useLogin } from 'context/auth/api/login';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { loginAtom, logoutAtom } from 'context/auth/store/auth';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import axios from 'axios';

export const Debug = () => {
  const [, login] = useAtom(loginAtom);
  const [, logout] = useAtom(logoutAtom);
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);

  const [, displayMessage] = useAtom(displayMessageAtom);

  const [{ data, loading, error }, fetchData] = useLogin();
  useNonFieldApiErrorCallback(error);

  const load = () => {
    startLoading('part-page');

    setTimeout(() => {
      stopLoading('part-page');

      displayMessage({
        text: '読み込みが完了しました',
        isInterruption: Math.random() > 0.5,
      });
    }, 3000);
  };

  if (loading) {
    return <LoadingBackdrop isShow />;
  }

  return (
    <div>
      <div className="flex items-center gap-[20px]">
        <BaseButton
          className="text-base"
          onClick={() =>
            displayMessage({
              text: '読み込みが完了しました',
              isInterruption: true,
            })
          }
        >
          割り込み表示
        </BaseButton>
        <BaseButton
          className="text-meta"
          onClick={() =>
            displayMessage({
              text: '読み込みが完了しました',
              isInterruption: false,
            })
          }
        >
          割り込まない表示
        </BaseButton>
        <BaseButton className="text-meta" onClick={load}>
          読み込み
        </BaseButton>
        <BaseButton
          className="text-meta"
          onClick={() => {
            // eslint-disable-next-line no-alert
            const userId = window.prompt('ユーザーIDを設定してください');
            if (userId === null || userId === '') return;
            login({
              userId,
              userTypeValue: 'corporationAdmin',
            });
          }}
        >
          ユーザーIDをセット
        </BaseButton>
        <BaseButton className="text-meta" onClick={() => logout()}>
          ログアウト
        </BaseButton>
        {/* <BaseButton onClick={() => fetchData()}>
        API発火
      </BaseButton> */}
        取得データ↓
        <code>{JSON.stringify(data)}</code>
      </div>
      <div className="flex items-center gap-[20px]">
        <form
          action="https://api.solutions.canayell.atma.work/tenant/my/corporation/"
          method="GET"
        >
          <input type="submit" />
        </form>
        <form
          action="https://api.solutions.canayell.atma.work/tenant/my/contracts/billing_url/"
          method="POST"
        >
          <input type="submit" />
        </form>

        <button
          type="button"
          onClick={async () => {
            const response = await axios.get(
              'https://api.solutions.canayell.atma.work/tenant/my/corporation/'
            );
            console.log(response);
          }}
        >
          jsでリクエスト！（GET）
        </button>

        <button
          type="button"
          onClick={async () => {
            const response = await axios.post(
              'https://api.solutions.canayell.atma.work/tenant/my/contracts/billing_url/'
            );
            console.log(response);
          }}
        >
          jsでリクエスト！（POST）
        </button>
      </div>
    </div>
  );
};
