import { useState } from 'react';
import { COLORS } from 'tailwind.config';

type IconProps = {
  size?: number;
};

export const CircleDotMenuIcon = ({ size = 24, ...props }: IconProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const switchCircleColorByHoverState = () =>
    isHovered ? COLORS.MAIN_BLUE : COLORS.GRAY_600;

  return (
    <svg
      id="コンポーネント_70_2"
      data-name="コンポーネント 70 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <circle
        id="楕円形_17"
        data-name="楕円形 17"
        cx="16"
        cy="16"
        r="16"
        fill="#fff"
      />
      <g
        id="グループ_329"
        data-name="グループ 329"
        transform="translate(24 15) rotate(90)"
      >
        <path
          id="パス_3029"
          data-name="パス 3029"
          d="M1,0A1,1,0,1,1,0,1,1,1,0,0,1,1,0Z"
          transform="translate(0 7)"
          fill="none"
          stroke={switchCircleColorByHoverState()}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          id="楕円形_15"
          data-name="楕円形 15"
          cx="1"
          cy="1"
          r="1"
          fill="none"
          stroke={switchCircleColorByHoverState()}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="楕円形_16"
          data-name="楕円形 16"
          cx="1"
          cy="1"
          r="1"
          transform="translate(0 14)"
          fill="none"
          stroke={switchCircleColorByHoverState()}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
