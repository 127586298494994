import { TextField } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { useNavigate } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { useAtom } from 'jotai';
import { useLogin } from 'context/auth/api/login';
import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { loginAtom } from 'context/auth/store/auth';

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export const Login = () => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, login] = useAtom(loginAtom);

  const navigate = useNavigate();

  const { handleSubmit, control } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [{ error }, fetchData] = useLogin();
  useNonFieldApiErrorCallback(error);

  const onLogin = handleSubmit(async (submittedData) => {
    startLoading('login');
    await fetchData(submittedData)
      .then((value) => {
        const userTypeValue = () => {
          if (value.data.user.isCorporationAdmin) return 'corporationAdmin';
          if (value.data.user.isOutsideManager) return 'outsideManager';

          return null;
        };

        login({
          userId: value.data.user.id,
          userTypeValue: userTypeValue(),
        });

        navigate('/');
      })
      .catch(() => {
        return null;
      })
      .finally(() => {
        stopLoading('login');
      });
  });

  return (
    <div className="relative min-h-[100vh]">
      <div className="flex justify-center items-center min-h-[100vh]">
        <div className="flex flex-col gap-[60px] w-[360px]">
          <h2 className="text-section-heading font-bold text-grayscale-800">
            ログイン
          </h2>
          <div>
            <p className="text-grayscale-700 text-base pb-[20px]">
              メールアドレス
            </p>

            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message}
                  fullWidth
                  label=""
                  variant="outlined"
                  sx={{ paddingBottom: '30px' }}
                />
              )}
            />

            <p className="text-grayscale-700 text-base pb-[20px]">パスワード</p>

            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message}
                  fullWidth
                  type="password"
                  label=""
                  variant="outlined"
                />
              )}
            />

            {/* <Typography variant="body2" color="primary">
              <Link to="/guest/password-reset">パスワードをお忘れの方はこちら</Link>
            </Typography> */}
          </div>

          <div className="text-center">
            <BaseButton
              className="text-btn-large w-btn-medium"
              onClick={onLogin}
            >
              ログイン
            </BaseButton>
          </div>
        </div>
      </div>
      <p className="text-grayscale-600 text-base text-center absolute w-full bottom-[40px]">
        2023 © CANaYELL
      </p>
    </div>
  );
};
