import { MvpRanking } from 'context/corporation/rankings/mvp_ranking';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { CorporationRankingState } from 'context/corporation/rankings/state';

export const CorporationRankings = () => {
  const state = CorporationRankingState();

  const tabItemStyles = (isActive: boolean) =>
    `px-[20px] pt-[4px] pb-[10px] text-center text-base font-bold text-grayscale-600 base-hover ${
      isActive ? 'border-b-3 border-main-blue text-grayscale-800' : ''
    }`;

  return (
    <div>
      <PageTitle title="全社ランキング" breadcrumbs={[['ホーム', '/']]} />
      <div className="mt-[50px] p-main-section bg-grayscale-100 rounded">
        <ul className="flex items-center">
          <li
            className={tabItemStyles(state.activeTab === 'mvp')}
            onClick={() => state.setActiveTab('mvp')}
          >
            MVPランキング
          </li>
          {/* <li className={tabItemStyles(state.activeTab === 'score')}>
            評価スコアランキング
          </li> */}
        </ul>
        <MvpRanking />
      </div>
    </div>
  );
};
