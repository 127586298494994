import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';

export const BaseSelectField = forwardRef<
  HTMLDivElement,
  SelectProps & {
    menuItems?: ReactNode[];
    formHelperText?: ReactNode;
    clickablePlaceholder?: boolean;
    allLabel?: string;
  }
>((props, ref) => {
  const {
    sx,
    menuItems,
    formHelperText,
    error,
    clickablePlaceholder,
    allLabel,
    ...selectProps
  } = props;

  return (
    <FormControl
      size="small"
      sx={{ width: 320, ...sx }}
      variant="outlined"
      error={error}
      ref={ref}
    >
      <Select
        {...selectProps}
        sx={{ paddingTop: '2px', paddingBottom: '2px' }}
        displayEmpty
      >
        <MenuItem
          sx={{ fontSize: '13px' }}
          value=""
          disabled={!clickablePlaceholder}
        >
          選択してください
        </MenuItem>
        {allLabel && <MenuItem value={allLabel}>{allLabel}</MenuItem>}
        {menuItems}
      </Select>
      <FormHelperText>{formHelperText}</FormHelperText>
    </FormControl>
  );
});
