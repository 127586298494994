import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/mvps/mvp_category/';

export type Request = SnakeObjToCamelObj<
  NonNullable<
    paths['/tenant/my/mvps/mvp_category/{id}/']['patch']['requestBody']
  >['content']['application/json']
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/mvps/mvp_category/{id}/']['patch']['responses'][200]['content']['application/json']
>;

/**
 * 契約情報の更新
 */
export const patchMvpCategory = async (
  request: Request
): Promise<SuccessResponse> => {
  return apiClient.patch(`${URL}${request.id}/`, {
    name: request.name,
    icon: request.icon,
    color: request.color,
    description: request.description,
    customIconImage: request.customIconImage,
  });
};
