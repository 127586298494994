import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useDisplayNonFieldApiError } from './useDisplayNonFieldApiError';

/**
 * nonFieldなAPIエラーが発生した際に検知して表示するカスタムフック
 */
export const useNonFieldApiErrorCallback = <T, D>(
  error: AxiosError<T, D> | null
) => {
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  useEffect(() => {
    if (error !== null) {
      displayNonFieldApiError(error);
    }
  }, [error]);
};
