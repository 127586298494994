import { apiClient, MY_TENANT_URL } from 'infra/api/config';

import { RequestOccupation, ResponseOccupation } from './types';

export const bulkOccupation = async (occupation: RequestOccupation) => {
  const response = await apiClient.post<ResponseOccupation>(
    MY_TENANT_URL('corporation/occupation/bulk/'),
    occupation
  );
  return response;
};
