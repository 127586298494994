import { CheckBoxOff } from './CheckBoxOff';
import { CheckBoxOn } from './CheckBoxOn';

export const PracticalThinkingCheckbox = (props: {
  isChecked: boolean;
  description: string;
  isRecentGoal: boolean;
}) => {
  return (
    <div className="flex gap-[10px] items-center ml-[20px] pb-[12px]">
      {props.isChecked ? <CheckBoxOn /> : <CheckBoxOff />}
      <p
        className={`text-base ${
          props.isRecentGoal ? 'font-bold' : 'font-normal'
        }`}
      >
        {props.description}
      </p>
    </div>
  );
};
