import { PropsWithChildren } from 'react';

export const ErrorMessage = (
  props: PropsWithChildren<{
    message?: string;
  }>
) => {
  return (
    <>
      {props.message && (
        <p className="text-meta text-main-red mt-[6px]">{props.message}</p>
      )}
    </>
  );
};
