import { BoxProps } from '@mui/material';
import { Tooltip } from 'infra/components/UI/Helps/Tooltip';

export const BaseFormField = (
  props: BoxProps & {
    label: string;
    tooltip?: string;
  }
) => {
  return (
    <div className="mb-[28px]">
      <div className="mb-[15px] flex items-center">
        <p className="text-base font-bold text-grayscale-800">{props.label}</p>
        {props.tooltip && <Tooltip tooltip={{ text: props.tooltip }} />}
      </div>
      {props.children}
    </div>
  );
};
