export const StoreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.001"
      height="20"
      viewBox="0 0 20.001 20"
    >
      <g
        id="コンポーネント_45_2"
        data-name="コンポーネント 45 – 2"
        opacity="0.5"
      >
        <path
          id="building-shop.1"
          d="M18,2a.75.75,0,0,1,.474.169l.076.071,3.272,3.528.031.039A.729.729,0,0,1,22,6.248V8.167a3.819,3.819,0,0,1-1,2.581v10.5a.75.75,0,0,1-.648.743l-.1.007H3.751a.75.75,0,0,1-.743-.648L3,21.25v-10.5a3.818,3.818,0,0,1-.993-2.353L2,8.167V6.291a.728.728,0,0,1,.1-.408l.065-.1L2.2,5.742,5.45,2.24A.75.75,0,0,1,5.9,2.007L6,2Zm-2.918,8.442-.012.018a3.833,3.833,0,0,1-6.154-.016A3.837,3.837,0,0,1,4.5,11.761V20.5H6V13.751a.75.75,0,0,1,.648-.743L6.75,13h4.5a.75.75,0,0,1,.743.648l.007.1V20.5h7.5V11.762a3.837,3.837,0,0,1-4.416-1.319ZM10.495,14.5h-3v6h3Zm6.76-1.5a.75.75,0,0,1,.743.648l.007.1v3.5a.75.75,0,0,1-.648.743l-.1.007h-3.5a.75.75,0,0,1-.743-.648l-.007-.1v-3.5a.75.75,0,0,1,.648-.743l.1-.007ZM16.5,14.5h-2v2h2ZM8.166,7H3.5V8.167l.006.171.028.231.033.156.049.172.054.148.041.094q.047.1.1.2l.1.161.054.075.129.156.142.144.1.085.042.034a2.322,2.322,0,0,0,1.112.482l.181.019.16.005A2.333,2.333,0,0,0,8.161,8.326l.005-.16Zm6.165,0H9.666V8.167a2.333,2.333,0,0,0,2.016,2.311l.157.016L12,10.5a2.333,2.333,0,0,0,2.327-2.173l.005-.16ZM20.5,7H15.833V8.167a2.333,2.333,0,0,0,2.016,2.311l.157.016.16.005a2.323,2.323,0,0,0,1.485-.534l.09-.078.116-.114L20,9.6a2.342,2.342,0,0,0,.15-.216l.1-.187.063-.138.058-.155.03-.1.038-.152.028-.158.018-.167.006-.17ZM9.062,3.5H6.327l-1.858,2H8.446Zm4.307,0H10.63l-.616,2h3.97Zm4.3,0H14.938l.616,2H19.53Z"
          transform="translate(-2 -2)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
