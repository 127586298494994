import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/user/corporation_admin/';
export type Request = SnakeObjToCamelObj<
  paths['/tenant/my/user/corporation_admin/']['post']['requestBody']['content']['application/json']
> & {
  dateOfBirth?: string;
};
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/corporation_admin/']['post']['responses'][201]['content']['application/json']
>;

export const postCorporationAdmin = (request: Request) => {
  return apiClient.post(URL, {
    email: request.email,
    firstName: request.firstName,
    lastName: request.lastName,
    departmentName: request.departmentName,
    password: request.password,
    dateOfBirth: request.dateOfBirth,
  });
};
