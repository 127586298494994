/**
 * 退職者一覧のテーブル行の管理関連のステート
 */

import { useNavigate } from 'react-router-dom';
import { OnsiteUserListResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';

export namespace Types {
  export type OnsiteUserListResponseExtended = OnsiteUserListResponse & {
    unSubscribedAt?: string;
  };

  export type RowProps = {
    onsiteUser: OnsiteUserListResponseExtended;
  };
}

export namespace evetns {
  export const navigateToStaffDetail = (
    onsiteUser: Types.OnsiteUserListResponseExtended
  ) => {
    const navigate = useNavigate();

    return () => {
      navigate(`/staffs_manage/staffs/${onsiteUser.id}`);
    };
  };

  export const navigateToStaffEdit = (
    onsiteUser: Types.OnsiteUserListResponseExtended
  ) => {
    const navigate = useNavigate();

    return () => {
      navigate(`/staffs_manage/staffs/${onsiteUser.id}/edit`);
    };
  };
}

export default () => {};
