import useAxios from 'axios-hooks';
import { useAtom } from 'jotai';

import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';

export const URL = '/tenant/my/evaluation_periods/corporation/mbos/';
export type MboListResponse = SnakeObjToCamelObj<
  paths[typeof URL]['get']['responses'][200]['content']['application/json']
>;

/**
 * MBO一覧の取得
 * @param useCache
 * @returns
 */
export const getMbos = (shopIds?: string[], useCache = false) => {
  const response = useAxios<MboListResponse>(
    {
      url: URL,
      params: {
        limit: 9999,
        shop_id__in: shopIds?.join(','),
      },
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};
