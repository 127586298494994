import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { AxiosResponse } from 'axios';
import { GradeOfResponsePaginatedCorporation } from 'context/corporation/grade/api/types';

export const URL = '/tenant/my/corporation/grade/{id}/';

type Request = SnakeObjToCamelObj<
  NonNullable<
    paths[typeof URL]['patch']['requestBody']
  >['content']['application/json']
>;

export const updateGrade = async (
  id: number,
  grade: Omit<
    Request,
    | 'id'
    | 'selectableBasicThinkingMasters'
    | 'createdAt'
    | 'updatedAt'
    | 'position'
  >
) => {
  const response = await apiClient.patch<
    any,
    AxiosResponse<GradeOfResponsePaginatedCorporation>
  >(URL.replace('{id}', id.toString()), grade);
  return response;
};
