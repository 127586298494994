import { Avatar } from '@mui/material';

export const AvatarAndName = (props: {
  imagePath: string;
  name: string;
  size?: number;
}) => (
  <div className="flex items-center">
    <Avatar
      src={props.imagePath}
      sx={{ width: props.size ?? 24, height: props.size ?? 24 }}
    />
    <p className="text-note font-bold ml-[8px]">{props.name}</p>
    <p className="text-label ml-[4px]">さん</p>
  </div>
);
