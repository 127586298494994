export const BusinessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.003"
      height="19.5"
      viewBox="0 0 16.003 19.5"
    >
      <g
        id="コンポーネント_47_4"
        data-name="コンポーネント 47 – 4"
        opacity="0.5"
      >
        <g id="building.1" transform="translate(-4 -2)">
          <path
            id="パス_3016"
            data-name="パス 3016"
            d="M8.5,5.5a1,1,0,1,0,1,1A1,1,0,0,0,8.5,5.5Z"
            fill="#fff"
          />
          <path
            id="パス_3017"
            data-name="パス 3017"
            d="M7.5,13.5a1,1,0,1,1,1,1A1,1,0,0,1,7.5,13.5Z"
            fill="#fff"
          />
          <path
            id="パス_3018"
            data-name="パス 3018"
            d="M8.5,9a1,1,0,1,0,1,1A1,1,0,0,0,8.5,9Z"
            fill="#fff"
          />
          <path
            id="パス_3019"
            data-name="パス 3019"
            d="M11,6.5a1,1,0,1,1,1,1A1,1,0,0,1,11,6.5Z"
            fill="#fff"
          />
          <path
            id="パス_3020"
            data-name="パス 3020"
            d="M12,12.5a1,1,0,1,0,1,1A1,1,0,0,0,12,12.5Z"
            fill="#fff"
          />
          <path
            id="パス_3021"
            data-name="パス 3021"
            d="M14.5,13.5a1,1,0,1,1,1,1A1,1,0,0,1,14.5,13.5Z"
            fill="#fff"
          />
          <path
            id="パス_3022"
            data-name="パス 3022"
            d="M12,9a1,1,0,1,0,1,1A1,1,0,0,0,12,9Z"
            fill="#fff"
          />
          <path
            id="パス_3023"
            data-name="パス 3023"
            d="M6.25,2A2.25,2.25,0,0,0,4,4.25v16.5a.75.75,0,0,0,.75.75h14.5a.75.75,0,0,0,.75-.75v-9a2.25,2.25,0,0,0-2.25-2.25H16.5V4.25A2.25,2.25,0,0,0,14.25,2ZM5.5,4.25a.75.75,0,0,1,.75-.75h8a.75.75,0,0,1,.75.75v6a.75.75,0,0,0,.75.75h2a.75.75,0,0,1,.75.75V20h-2V17.25a.75.75,0,0,0-.75-.75H8.25a.75.75,0,0,0-.75.75V20h-2ZM15,18v2H12.75V18Zm-3.75,0v2H9V18Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
