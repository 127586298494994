/**
 * 企業情報 担当者情報関連ルーティング
 */
import { CorporationAdminList } from 'context/users/corporation_admin/components/CorporationAdminList';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const CorporationAdminListRoute: RouteType = {
  path: '/operation-setting/corporation-admins',
  element: <CorporationAdminList />,
  do_it: () => (
    <Route
      path={CorporationAdminListRoute.path}
      element={CorporationAdminListRoute.element}
    />
  ),
};

export const routes = () => <>{CorporationAdminListRoute.do_it()}</>;
