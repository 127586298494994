import { z } from 'zod';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { Dialog } from '@mui/material';
import {
  FieldPath,
  FieldValues,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import {
  MonthWithMbo,
  Shop,
} from 'context/corporation/mbo/components/ShopMboDetail/Index';
import { format } from 'date-fns';
import { postMbo } from 'context/corporation/mbo/api/post.mbo';

import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { patchMbo } from 'context/corporation/mbo/api/patch.mbo';
import { COLORS } from 'tailwind.config';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { numberSchema } from 'infra/config/custom.scheme';
import { ShopTitle } from './ShopTitle';

const NumericTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: {
  name: TName;
  useForm: UseFormReturn<TFieldValues>;
  readonly?: boolean;
  label: string;
}) => {
  return (
    <div className="w-[180px]">
      <p className="text-ui mb-[8px]">{props.label}</p>
      <TextField
        useForm={props.useForm}
        name={props.name}
        isErrorFiled={!!props.useForm.formState.errors[props.name]}
        readonly={props.readonly || false}
        asNumeric
        maxLength={12}
      />
    </div>
  );
};

export const EditShopMboModal = (
  props: PropsWithChildren<{
    render: (openModal: () => void) => ReactNode;
    monthWithMbo: MonthWithMbo;
    shop: Shop;
    readonly: boolean;
    onSaved: () => void;
  }>
) => {
  const [active, setActive] = useState(false);
  const { mbo } = props.monthWithMbo;

  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm<mboFormData>({
    resolver: zodResolver(mboScheme),
    defaultValues: {
      predictedValueSales: mbo?.predictedValueSales ?? null,
      predictedValueProfit: mbo?.predictedValueProfit ?? null,
      predictedValueEs: mbo?.predictedValueEs ?? null,
      predictedValueCs: mbo?.predictedValueCs ?? null,
      actualValueSales: mbo?.actualValueSales ?? null,
      actualValueProfit: mbo?.actualValueProfit ?? null,
      actualValueEs: mbo?.actualValueEs ?? null,
      actualValueCs: mbo?.actualValueCs ?? null,
    },
  });

  const openHandler = () => {
    setActive(true);
  };
  const closeHandler = () => {
    setActive(false);
  };

  const onSubmit = useFormInstance.handleSubmit((data) => {
    startLoading('save_mbo');

    let process: Promise<any>;
    if (!mbo?.id) {
      process = postMbo(
        data,
        props.shop.id,
        format(props.monthWithMbo.month, 'yyyyMM')
      );
    } else {
      process = patchMbo(mbo.id, data);
    }

    process
      .then(() => {
        displayMessage({ text: 'MBOを保存しました', isInterruption: false });
        props.onSaved();
        closeHandler();
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('save_mbo');
      });
  });

  return (
    <>
      {props.render(openHandler)}
      <Dialog open={active} onClose={closeHandler} maxWidth={false}>
        <BaseModal
          activeButton={
            props.readonly ? (
              <div />
            ) : (
              <BaseButton
                type="submit"
                className="w-btn-small p-button-small text-btn-confirm"
                onClick={onSubmit}
              >
                保存
              </BaseButton>
            )
          }
          closeHandler={closeHandler}
        >
          <div className="pb-[40px]">
            <h3 className="text-section-heading font-bold mb-[30px]">
              店舗MBO管理
            </h3>
            <ShopTitle
              borderColor={COLORS.MAIN_BLUE}
              shopName={props.shop.name}
              brandName={props.shop.brand.name}
              boxProps={{ marginBottom: 4 }}
            />
            <p className="font-bold mb-[6px]">
              {format(props.monthWithMbo.month, 'yyyy年MM月')}
            </p>
            <p className="text-base text-grayscale-700 mb-[30px]">
              データがない項目は空欄で入力してください。
            </p>

            <form>
              <div className="flex gap-[30px]">
                <NumericTextField
                  readonly={props.readonly}
                  label="売上（目標）"
                  name="predictedValueSales"
                  useForm={useFormInstance}
                />
                <NumericTextField
                  readonly={props.readonly}
                  label="売上（実績）"
                  name="actualValueSales"
                  useForm={useFormInstance}
                />
              </div>
              <div className="flex gap-[30px] mt-[30px]">
                <NumericTextField
                  readonly={props.readonly}
                  label="利益（目標）"
                  name="predictedValueProfit"
                  useForm={useFormInstance}
                />
                <NumericTextField
                  readonly={props.readonly}
                  label="利益（実績）"
                  name="actualValueProfit"
                  useForm={useFormInstance}
                />
              </div>
              <div className="flex gap-[30px] mt-[30px]">
                <NumericTextField
                  readonly={props.readonly}
                  label="ES（目標）"
                  name="predictedValueEs"
                  useForm={useFormInstance}
                />
                <NumericTextField
                  readonly={props.readonly}
                  label="ES（実績）"
                  name="actualValueEs"
                  useForm={useFormInstance}
                />
              </div>
              <div className="flex gap-[30px] mt-[30px]">
                <NumericTextField
                  readonly={props.readonly}
                  label="CS（目標）"
                  name="predictedValueCs"
                  useForm={useFormInstance}
                />
                <NumericTextField
                  readonly={props.readonly}
                  label="CS（実績）"
                  name="actualValueCs"
                  useForm={useFormInstance}
                />
              </div>
            </form>
          </div>
        </BaseModal>
      </Dialog>
    </>
  );
};

const mboScheme = z.object({
  predictedValueSales: numberSchema,
  predictedValueProfit: numberSchema,
  predictedValueEs: numberSchema,
  predictedValueCs: numberSchema,
  actualValueSales: numberSchema,
  actualValueProfit: numberSchema,
  actualValueEs: numberSchema,
  actualValueCs: numberSchema,
});

type mboFormData = z.infer<typeof mboScheme>;
