import { MenuItem } from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import {
  getEvaluationPeriodName,
  evaluationPeriodSettingState,
} from 'context/evaluation_period/components/EvaluationPeriodSetting/state';
import { EditEvaluationPeriodModal } from 'context/evaluation_period/components/EvaluationPeriodSetting/_components/EditEvaluationPeriodModal';
import { TimeIcon } from 'infra/components/UI/Icons/TimeIcon';
import { useFetchEvaluationPeriodGroups } from 'context/evaluation_period/api/useFetchEvaluationPeriodGroups';
import { BaseSelectField } from 'infra/components/UI/Forms/BaseSelectField';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { format } from 'date-fns/esm';
import * as from from './form';
import {
  EvaluationPeriodList1Year,
  EvaluationPeriodList3Month,
  EvaluationPeriodList6Month,
} from './_components/EvaluationPeriodList';

export const EvaluationPeriodSetting = () => {
  const [{ data: evaluationPeriodGroups, loading }, refetch] =
    useFetchEvaluationPeriodGroups();
  const state = evaluationPeriodSettingState(evaluationPeriodGroups);

  if (loading) {
    return null;
  }

  return (
    <div>
      <PageTitle title="評価スケジュール設定" breadcrumbs={[['ホーム', '/']]} />

      <div className="p-main-section mt-[40px] bg-grayscale-100 rounded">
        <div className="flex items-center justify-end">
          <EditEvaluationPeriodModal
            key="1"
            selectedEvaluationPeriodSetting={
              state.selectedEvaluationPeriodSetting
            }
            render={(openModal, formAtom) => (
              <BaseButton
                className="w-btn-small p-button-small tracking-short-text text-base"
                startIcon={<AddIcon />}
                onClick={() => {
                  formAtom.reset({
                    evaluationCycle: 6,
                  });
                  openModal();
                }}
              >
                登録
              </BaseButton>
            )}
            onSave={() => refetch()}
          />
          <TimeIcon className="mr-[10px] ml-[30px]" />
          <p className="text-meta mr-5">評価期間</p>

          {state.selectedEvaluationPeriodId !== null &&
            evaluationPeriodGroups && (
              <BaseSelectField
                size="small"
                value={state.selectedEvaluationPeriodId}
                sx={{ width: 200 }}
                onChange={(event) => {
                  state.setSelectedEvaluationPeriodId(
                    event.target.value as string
                  );
                }}
                menuItems={[...evaluationPeriodGroups.results]
                  .reverse()
                  .map((evaluationPeriod) => (
                    <MenuItem
                      key={evaluationPeriod.id}
                      value={evaluationPeriod.id.toString()}
                    >
                      <p className="text-ui text-grayscale-800">
                        {getEvaluationPeriodName(
                          evaluationPeriod.evaluationPeriods
                        )}
                      </p>
                    </MenuItem>
                  ))}
              />
            )}
        </div>

        <Divider className="my-[40px]" />

        {!!evaluationPeriodGroups?.results &&
          evaluationPeriodGroups?.results.length > 0 &&
          Object.keys(state.selectedEvaluationPeriodSetting).length !== 0 &&
          !!state.selectedEvaluationPeriodSetting && (
            <>
              <div className="flex justify-between items-center">
                <div className="grid grid-flow-col auto-cols-max gap-24">
                  <div>
                    <p className="mb-[20px] font-bold text-base">
                      評価サイクル
                    </p>
                    <p className="text-ui text-grayscale-700">{`${state.selectedEvaluationPeriodSetting?.evaluationCycle}ヶ月`}</p>
                  </div>
                  <div>
                    <p className="mb-[20px] font-bold text-base">開始日</p>
                    <p className="text-ui text-grayscale-700">
                      {state.selectedEvaluationPeriodSetting
                        ?.evaluationPeriods?.[0]?.startedAt &&
                        format(
                          new Date(
                            state.selectedEvaluationPeriodSetting
                              ?.evaluationPeriods?.[0]?.startedAt
                          ),
                          'M月'
                        )}
                    </p>
                  </div>
                </div>

                <EditEvaluationPeriodModal
                  key="2"
                  selectedEvaluationPeriodSetting={
                    state.selectedEvaluationPeriodSetting
                  }
                  render={(openModal, formAtom) => (
                    <BaseButton
                      className="w-btn-small p-button-small tracking-short-text"
                      onClick={() => {
                        formAtom.reset(
                          from.Basic.defaultValues(
                            state.selectedEvaluationPeriodSetting
                          )
                        );
                        openModal();
                      }}
                    >
                      編集
                    </BaseButton>
                  )}
                  onSave={() => refetch()}
                />
              </div>
              <Divider className="my-[40px]" />
              <div>
                {state.selectedEvaluationPeriodSetting.evaluationCycle ===
                  3 && <EvaluationPeriodList3Month />}
                {state.selectedEvaluationPeriodSetting.evaluationCycle ===
                  6 && <EvaluationPeriodList6Month />}
                {state.selectedEvaluationPeriodSetting.evaluationCycle ===
                  12 && <EvaluationPeriodList1Year />}
              </div>
            </>
          )}

        {state.completedselectedEvaluationPeriodSetting === 'empty' && (
          <>
            <WarningMessageBox
              message="評価スケジュールを設定してください"
              detailsMessage="評価サイクル、開始月、年間の詳細な評価スケジュールを設定します"
            />
            <p className="my-[30px] text-base">
              まだ登録されたデータはありません。
            </p>
            <EditEvaluationPeriodModal
              selectedEvaluationPeriodSetting={
                state.selectedEvaluationPeriodSetting
              }
              key="3"
              render={(openModal, formAtom) => (
                <BaseButton
                  className="w-btn-small p-button-small tracking-short-text text-base bg-transparent border border-main-blue text-main-blue"
                  startIcon={<AddIcon color="text-main-blue" />}
                  onClick={() => {
                    formAtom.reset({
                      evaluationCycle: 6,
                    });
                    openModal();
                  }}
                >
                  登録
                </BaseButton>
              )}
              onSave={() => refetch()}
            />
          </>
        )}
      </div>
    </div>
  );
};
