import { ReactNode, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';
import { OnsiteUserDetailResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import {
  DeleteOrSuspendModal,
  state as DeleteOrSuspendModalState,
} from 'context/users/onsite_users/_infra/components/Modal/DeleteOrSuspendModal';
import { useAtom } from 'jotai';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'tailwind.config';
import { GoalTab } from './GoalTab';
import { ProfileTab } from './ProfileTab';
import { MVPTab } from './MVPTab';
import { GoalDetailsTab } from './GoalDetailsTab';
import * as StaffTabState from './state';
import { OnsiteUserStateAtom, UserDetail } from '../../../state/state.get';

const ActiveUserEditIcon = ({ deleteOrSuspendModalState }: any) => {
  const navigate = useNavigate();
  const [onsiteUserState, _] = useAtom(OnsiteUserStateAtom);

  return (
    <MoreMenu
      render={[
        {
          label: <span>編集</span>,
          onClick: () => {
            navigate(
              `/staffs_manage/staffs/${onsiteUserState.onsiteUser?.id}/edit`
            );
          },
        },
        {
          label: <span>一時停止</span>,
          onClick: () => {
            deleteOrSuspendModalState.showModal('suspend');
          },
        },
        {
          label: <span>削除</span>,
          onClick: () => {
            deleteOrSuspendModalState.showModal('delete');
          },
        },
      ]}
    />
  );
};

const SuspendedUserEditIcon = ({ deleteOrSuspendModalState }: any) => {
  const navigate = useNavigate();
  const [onsiteUserState, _] = useAtom(OnsiteUserStateAtom);

  return (
    <MoreMenu
      render={[
        {
          label: <span>編集</span>,
          onClick: () => {
            navigate(
              `/staffs_manage/staffs/${onsiteUserState.onsiteUser?.id}/edit`
            );
          },
        },
        {
          label: <span>削除</span>,
          onClick: () => {
            deleteOrSuspendModalState.showModal('delete');
          },
        },
      ]}
    />
  );
};

const UnsubscribedUserStateEditIcon = () => {
  const navigate = useNavigate();
  const [onsiteUserState, _] = useAtom(OnsiteUserStateAtom);

  return (
    <MoreMenu
      render={[
        {
          label: <span>編集</span>,
          onClick: () => {
            navigate(
              `/staffs_manage/staffs/${onsiteUserState.onsiteUser?.id}/edit`
            );
          },
        },
      ]}
    />
  );
};

export const StaffTabs = (props: {
  staffId?: string;
  onsiteUser: OnsiteUserDetailResponse;
  selectedObjectiveRecordId?: number;
  objectiveRecords: ResponseObjectiveRecord[];
  onSelectObjectiveRecord: (id?: number) => void;
}) => {
  const [onsiteUserState, setOnsiteUserState] = useAtom(OnsiteUserStateAtom);

  useEffect(() => {
    setOnsiteUserState(new UserDetail(props.onsiteUser));
  }, [props.onsiteUser]);

  const staffTabState = StaffTabState.state();
  const deleteOrSuspendModalState = DeleteOrSuspendModalState.local();

  StaffTabState.reactive.NavigateToStaffsWhenDeleteCompleted();
  const selectedObjectiveRecord = props.objectiveRecords.find(
    (o) => o.id === props.selectedObjectiveRecordId
  );

  return (
    <div className="bg-grayscale-100 relative min-w-[790px] w-full overflow-x-auto">
      <div
        className="border-b border-grayscale-500"
        style={{
          borderStyle: staffTabState.activeTab === 1 ? 'none' : 'inherit',
        }}
      >
        <div className="flex items-center justify-between ml-[30px]">
          <Tabs
            value={staffTabState.activeTab}
            onChange={(event, value) => {
              staffTabState.setActiveTab(value);
            }}
            sx={{
              '.MuiTabs-indicator': {
                height: '4px',
              },
              '.Mui-selected': {
                color: COLORS.MAIN_BLUE,
              },
            }}
            TabIndicatorProps={{
              style: {
                background: COLORS.MAIN_BLUE,
              },
            }}
          >
            {['マンダラ', 'MVP', 'プロフィール'].map((value, index) => (
              <Tab
                key={value}
                label={value}
                sx={{
                  fontWeight:
                    staffTabState.activeTab === index ? 'bold' : 'normal',
                  paddingY: 2,
                }}
                {...staffTabState.generateTabProp(index)}
              />
            ))}
          </Tabs>
        </div>

        <div className="absolute right-5 top-2">
          {onsiteUserState.appUserState.isCurrentState() === 'active' && (
            <ActiveUserEditIcon
              deleteOrSuspendModalState={deleteOrSuspendModalState}
            />
          )}
          {onsiteUserState.appUserState.isCurrentState() === 'suspended' && (
            <SuspendedUserEditIcon
              deleteOrSuspendModalState={deleteOrSuspendModalState}
            />
          )}
          {onsiteUserState.appUserState.isCurrentState() === 'withdrawal' && (
            <UnsubscribedUserStateEditIcon />
          )}
        </div>
      </div>

      <TabPanel index={0} value={staffTabState.activeTab}>
        {selectedObjectiveRecord === undefined ? (
          <GoalTab
            objectiveRecordList={props.objectiveRecords}
            onSelectObjectiveRecord={props.onSelectObjectiveRecord}
          />
        ) : (
          <GoalDetailsTab
            objectiveRecord={selectedObjectiveRecord}
            handleBack={() => props.onSelectObjectiveRecord()}
          />
        )}
      </TabPanel>
      <TabPanel index={1} value={staffTabState.activeTab}>
        <MVPTab onsiteUser={props.onsiteUser} />
      </TabPanel>
      <TabPanel index={2} value={staffTabState.activeTab}>
        <ProfileTab onsiteUser={props.onsiteUser} />
      </TabPanel>

      <DeleteOrSuspendModal
        _state={deleteOrSuspendModalState}
        onsiteUser={props.onsiteUser}
      />
    </div>
  );
};

const TabPanel = (props: {
  children?: ReactNode;
  index: number;
  value: number;
}) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ borderStyle: 'hidden' }}
      id={`staff-tabpanel-${index}`}
      aria-labelledby={`staff-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
