import { apiClient } from 'infra/api/config';

/**
 * 最終上司評価の登録
 */
export const postFinalEvaluation = async (
  objectiveRecordId: string,
  scoreList: { score: number }[]
) => {
  return apiClient.post(
    `/tenant/my/objective/${objectiveRecordId}/evaluation_results/final_evaluation_by_boss/`,
    {
      evaluation_results: scoreList,
      objective_record: {
        comment_by_final_evaluation_by_boss: '',
      },
    }
  );
};
