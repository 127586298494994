import { apiClient, MY_TENANT_URL } from 'infra/api/config';

import {
  ResponseBrand,
  RequestBrand,
} from 'context/corporation/brand/api/types';

export const createBrand = async (brand: RequestBrand) => {
  const response = await apiClient.post<ResponseBrand>(
    MY_TENANT_URL('corporation/brand/'),
    brand
  );
  return response;
};
