import React, { useState } from 'react';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { ResponsePosition } from 'context/corporation/position/api/types';
import { getEmploymentStatusLabel } from 'context/users/onsite_users/_infra/state/const/employmentStatus';
import { COLORS } from 'tailwind.config';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { PositionEditFormDialog } from './EditForm/PositionEditFormDialog';

type Props = {
  positions: ResponsePosition[];
  onSaved: () => void;
};

export const PositionList = (props: Props) => {
  const modalState = useState(false);
  const isEmpty = !props.positions.length;

  return (
    <div>
      <div className="flex items-center justify-between border-b-2 border-grayscale-500 pb-[20px] mb-[30px]">
        <p className="font-bold">職位・等級</p>
        <BaseButton
          className="!w-[100px] p-button-small tracking-short-text text-ui"
          onClick={() => {
            modalState[1](true);
          }}
        >
          {isEmpty ? '登録' : '編集'}
        </BaseButton>
      </div>
      <div className="min-h-[340px]">
        {isEmpty && (
          <div>
            <WarningMessageBox
              message="職位・等級を設定してください"
              detailsMessage="職位名称と等級、雇用形態を設定します。"
            />
            <p className="my-[20px] text-base">
              まだ登録されたデータはありません。
            </p>
            <BaseButton
              className="w-btn-small p-button-small tracking-short-text text-base bg-transparent border border-main-blue text-main-blue"
              startIcon={<AddIcon color="text-main-blue" />}
              onClick={() => {
                modalState[1](true);
              }}
            >
              登録
            </BaseButton>
          </div>
        )}

        {!isEmpty && (
          <table className="min-w-[650px] text-left w-full">
            <thead className="bg-grayscale-500">
              <tr>
                <th className="pl-[40px] pr-[20px] py-[14px] text-grayscale-700 text-base font-normal w-[260px]">
                  雇用形態
                </th>
                <th className="pl-[40px] pr-[20px] py-[14px] text-grayscale-700 text-base font-normal w-[260px]">
                  職位
                </th>
                <th className="pl-[40px] pr-[20px] py-[14px] text-grayscale-700 text-base font-normal w-[100%-520px]">
                  等級
                </th>
              </tr>
            </thead>
            <tbody>
              {props.positions.map((position, index) => {
                const stripedBackgroundColor =
                  index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;
                return (
                  <tr
                    key={position.id}
                    style={{
                      backgroundColor: stripedBackgroundColor,
                    }}
                  >
                    <td className="pl-[40px] pr-[20px] py-[28px] text-ui">
                      {getEmploymentStatusLabel(position.employmentStatus)}
                    </td>
                    <td className="pl-[40px] pr-[20px] py-[28px] text-ui">
                      {position.name}
                    </td>
                    <td className="pl-[40px] pr-[20px] py-[28px] text-ui">
                      {position.grades.map((grade) => (
                        <div key={grade.id}>{grade.name}</div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <PositionEditFormDialog
        modalState={modalState}
        onSaved={props.onSaved}
        positions={props.positions}
      />
    </div>
  );
};
