export const PeriodCircleActiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="楕円形_384"
      data-name="楕円形 384"
      fill="#fff"
      stroke="#048cf2"
      strokeWidth="2"
    >
      <circle cx="8" cy="8" r="8" stroke="none" />
      <circle cx="8" cy="8" r="7" fill="none" />
    </g>
  </svg>
);
