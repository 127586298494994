import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { importStaffCsv } from 'context/users/onsite_users/_infra/api/importStaffCsv.create';
import { StaffImportRow } from 'context/users/onsite_users/Staff/StaffImport/StaffImportRow';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

import { DragDropFile } from 'context/users/onsite_users/Staff/StaffImport/DragDropFile';
import { CheckIcon } from 'context/users/onsite_users/Staff/StaffImport/CheckIcon';
import { exportStaffCsv } from '../../_infra/api/exportStaffCsv';

type StatusManager = {
  err: string[][] | string[];
  previewData: [];
  outsideManagerListWithTemporaryPassword: [
    {
      name: string;
      email: string;
      temporaryPassword: string;
    },
  ];
};

const ErrorMessage = (props: { statusManager: StatusManager }) => {
  if (!props.statusManager?.err) {
    return <span />;
  }
  const isRowErr =
    Array.isArray(props.statusManager?.err?.[0]) &&
    typeof props.statusManager?.err?.[0]?.[0] === 'number';

  // CSVの行レベルのエラー表示エリア
  if (isRowErr) {
    return (
      <div className="mb-[30px]">
        <div>
          {(props.statusManager?.err as string[][]).map((rowMessage) => {
            return (
              <div
                className="mb-[10px] p-[20px] bg-soft-red"
                key={`${rowMessage}-rowMessage`}
              >
                {rowMessage.map((message, messageIndex) => {
                  return (
                    <p
                      className="text-meta text-warning-red"
                      key={`${message}-message`}
                    >
                      {messageIndex === 0 ? (
                        <span className="font-bold mb-[4px] inline-block">
                          CSVファイルの{message}行目：
                        </span>
                      ) : (
                        message
                      )}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // 行に関係ないエラーの表示エリア
  return (
    <div className="mb-[10px]">
      <p className="text-meta text-warning-red">
        {(props.statusManager?.err as string[]).map((message) => {
          return <p>{message}</p>;
        })}
      </p>
    </div>
  );
};

const OutsideManagerPassword = (props: { statusManager: StatusManager }) => {
  const outsideManagerListWithTemporaryPassword =
    props.statusManager?.outsideManagerListWithTemporaryPassword;

  if (!outsideManagerListWithTemporaryPassword?.length) {
    return <span />;
  }

  return (
    <div className="bg-soft-blue p-[30px] mb-[40px] rounded">
      <div className="flex items-center gap-[20px] mb-[20px]">
        <div className="w-[30px] h-[30px]">
          <CheckIcon />
        </div>
        <p className="font-bold">マネージャ評価者の作成が完了しました。</p>
      </div>
      {outsideManagerListWithTemporaryPassword.map((outsideManager: any) => {
        return (
          <div className="mb-[10px] p-[20px] rounded bg-grayscale-100">
            <dl className="flex items-center gap-[6px] text-meta mb-[4px] font-medium">
              <dt className="min-w-[120px]">ユーザ名:</dt>
              <dd className="">{outsideManager.name}</dd>
            </dl>
            <dl className="flex items-center gap-[6px] text-meta mb-[4px] font-medium">
              <dt className="min-w-[120px]">メールアドレス:</dt>
              <dd className="">{outsideManager.email}</dd>
            </dl>
            <dl className="flex items-center gap-[6px] text-meta mb-[4px] font-medium">
              <dt className="min-w-[120px]">パスワード: </dt>
              <dd className="">{outsideManager.temporaryPassword}</dd>
            </dl>
          </div>
        );
      })}
    </div>
  );
};

const HowToUse = () => {
  return (
    <>
      <p className="font-bold pb-[16px] mb-[30px] border-b-2 border-solid border-grayscale-500">
        1. CSVファイルの用意
      </p>

      <div className="text-base text-grayscale-700 mb-[60px]">
        <p
          className="text-main-blue cursor-pointer inline-block"
          onClick={() => {
            exportStaffCsv();
          }}
        >
          CSVファイルのサンプル
        </p>
        をダウンロードして、必要情報を追加・更新してください。
      </div>
    </>
  );
};

const UploadPreviewCSVFile = (props: {
  setStatusManager: (value: any) => void;
  useFile: any;
}) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const [useFile, setUseFile] = props.useFile;

  const previewOnSubmitHandler = (file: File): any => {
    if (file === null) {
      displayMessage({
        text: '2. CSVファイルのアップロードが完了していません。先にCSVファイルをアップロードしてください。',
        isInterruption: true,
      });
      return null;
    }

    startLoading('import-mandalat-master-csv');
    const response2 = importStaffCsv(file, true)
      .then((response) => {
        displayMessage({
          text: '記入頂いた内容を元に「3. インポートするスタッフ一覧」に内容を表示しました。内容をご確認の上一括登録ボタンを選択してください。',
          isInterruption: true,
        });
        props.setStatusManager(response.data);
        return response;
      })
      .catch((error) => {
        props.setStatusManager(error.response.data);
      })
      .finally(() => {
        stopLoading('import-mandalat-master-csv');
      });

    return response2;
  };

  return (
    <>
      <p className="font-bold pb-[16px] mb-[30px] border-b-2 border-solid border-grayscale-500">
        2. CSVファイルのアップロード
      </p>

      <DragDropFile
        height={180}
        width="100%"
        file={useFile}
        handleFile={async (file) => {
          setUseFile(file);
          previewOnSubmitHandler(file);
        }}
      />
    </>
  );
};

const PreviewImportedStaffList = (props: { statusManager: any }) => {
  return (
    <>
      <p className="font-bold pb-[16px] mb-[30px] mt-[60px] border-b-2 border-solid border-grayscale-500">
        3. インポートするスタッフ一覧
      </p>

      <div className="mt-[20px] whitespace-nowrap overflow-x-auto base-scrollbar">
        <Table className="m-w-[650px]">
          <TableHead className="bg-grayscale-500">
            <TableRow>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  権限
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  姓
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  名
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  せい
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  めい
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  メールアドレス
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  等級
                </p>
              </th>

              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  評価者
                </p>
              </th>
              <th className="px-[30px] pr-[14px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  所属店舗
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  性別
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  電話番号
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  入社日
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  職種
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  サブ職種
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  雇用形態
                </p>
              </th>

              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  給与
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当1_名称
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当1_金額
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当2_名称
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当2_金額
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当3_名称
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当3_金額
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当4_名称
                </p>
              </th>
              <th className="px-[30px] py-[15px]">
                <p className="text-base text-grayscale-700 font-normal text-left">
                  手当4_金額
                </p>
              </th>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.statusManager?.previewData?.map(
              (user: any, index: number) => {
                return (
                  <StaffImportRow
                    key={user.email}
                    onsiteUser={user}
                    index={index}
                    isStripedBackgroundColor
                  />
                );
              }
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const Submit = (props: { useFile: any; setStatusManager: any }) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const [useFileValue, _] = props.useFile;

  const uploadOnSubmitHandler = (file: File): void => {
    if (file === null) {
      displayMessage({
        text: 'CSVファイルがアップロードされていません。CSVファイルをアップロードしてください。',
        isInterruption: true,
      });
      return;
    }

    startLoading('import-staff-prevoew-master-csv');
    importStaffCsv(file, false)
      .then((response) => {
        displayMessage({
          text: 'スタッフの一括登録が完了しました。外部評価者のパスワードは上部に掲載されます。画面を遷移または操作を行うと消えてしまうため、ユーザ自身でパスワードを控えてください。',
          isInterruption: true,
          onClose: () => {
            setTimeout(() => {
              document.querySelector('#main')?.scrollTo(0, 0);
            }, 300);
          },
        });
        props.setStatusManager(response?.data);
        stopLoading('import-staff-prevoew-master-csv');
      })
      .catch((error: any) => {
        props.setStatusManager(error.response?.data);
      })
      .finally(() => {
        stopLoading('import-staff-prevoew-master-csv');
      });
  };

  return (
    <div className="pt-[40px] mt-[60px] gap-[60px] flex justify-center border-t-1 border-solid border-grayscale-500">
      <button type="button" onClick={() => {}}>
        <p className="text-grayscale-700 font-normal">キャンセル</p>
      </button>

      <BaseButton
        className="w-btn-medium text-btn-confirm p-button-small"
        onClick={() => {
          uploadOnSubmitHandler(useFileValue);
        }}
      >
        一括登録
      </BaseButton>
    </div>
  );
};

export const StaffImport = () => {
  const [statusManager, setStatusManager] = useState({} as StatusManager);
  const useFile = useState<File | null>(null);

  return (
    <div>
      <PageTitle
        title="スタッフ一括登録"
        breadcrumbs={[
          ['ホーム', '/'],
          ['スタッフ一覧', '/staffs_manage/staffs/'],
        ]}
      />

      <div className="flex items-center mt-[50px] rounded bg-grayscale-100">
        <div className="w-full p-main-section">
          <ErrorMessage statusManager={statusManager} />
          <OutsideManagerPassword statusManager={statusManager} />
          <HowToUse />
          <UploadPreviewCSVFile
            setStatusManager={setStatusManager}
            useFile={useFile}
          />
          <PreviewImportedStaffList statusManager={statusManager} />
          <Submit useFile={useFile} setStatusManager={setStatusManager} />
        </div>
      </div>
    </div>
  );
};
