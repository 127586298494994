/*
  # HACK
  このinputは、カスタムセレクトボックスのドロップダウンを制御するためのハックです。
  ユーザーがドロップダウンの外側をクリックすると、onBlurイベントによりドロップダウンが閉じます。
*/

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const HackCloseDropDown = ({ isOpen, setIsOpen }: Props) => {
  // 独立したradioなどで呼ばれるため
  if (!isOpen) return null;

  return (
    <input
      type="text"
      className="absolute w-0 h-0 opacity-0"
      ref={(element) => {
        // ドロップダウンが開いているときのみフォーカスを当てる
        if (isOpen && element) {
          element.focus();
        }
      }}
      onBlur={() => {
        setIsOpen(false);
      }}
    />
  );
};
