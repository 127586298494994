export const BossCommentBackGround = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="644.012"
      height="145.252"
      viewBox="0 0 644.012 145.252"
    >
      <g
        id="グループ_3755"
        data-name="グループ 3755"
        transform="translate(17536.65 10287)"
      >
        <g
          id="グループ_3751"
          data-name="グループ 3751"
          transform="translate(2569.627 -8255.963) rotate(-25)"
        >
          <path
            id="パス_3574"
            data-name="パス 3574"
            d="M704.439,1105.065l1.128-.12.118.84-.616-.1Z"
            transform="translate(-18027.494 -11373.527)"
            fill="#fab7b6"
          />
          <path
            id="パス_3575"
            data-name="パス 3575"
            d="M582.194,1055.882l.394-.423.73.592.111.814-.082.192Z"
            transform="translate(-17918.775 -11329.423)"
            fill="#fab7b6"
          />
          <path
            id="パス_3576"
            data-name="パス 3576"
            d="M668.756,1157.658l1.8-.044.2.669-.4.66-.489.466Z"
            transform="translate(-17995.76 -11420.472)"
            fill="#fab7b6"
          />
          <path
            id="パス_3577"
            data-name="パス 3577"
            d="M265.251,1075.245a58.455,58.455,0,0,0,9.424-2.142c2.092-.67,3.483-1.261,6.5-2.044,2.94-.762,3.009-.56,3.8-1.034,2.588-1.555,2.474-4.521,4.958-5.741.921-.453,1.924-.86,1.924-.86,1.071-.435,1.314-.462,2.026-.818a13.372,13.372,0,0,0,1.728-1.05c.822-.568,1.059-.957,1.6-1.23l2.96-1.5a1.859,1.859,0,0,1,1.395,1.515,1.273,1.273,0,0,1-.154.644l2.707-1.01,4.818-.615,5.115.1a1.255,1.255,0,0,1,.127,1.112,1.03,1.03,0,0,1-.273.359l2.383.035a1.654,1.654,0,0,1,.042,1.313,1.864,1.864,0,0,1-1.082.852,8.126,8.126,0,0,1-2.7.424c-.6.054-1.077.106-1.379.141q-.424.178-.868.351c-.918.356-1.809.656-2.665.91l3.814-.792,2.86-.24a1.712,1.712,0,0,1,.476,1.138,1.675,1.675,0,0,1-.111.544c-.257.637-1.764,1.151-3.78,1.276a7.592,7.592,0,0,1-3.8,1.034q-1.033.6-2.095,1.261-.925.573-1.805,1.144l1.547-.324,4.156-.4,1.787.026a.72.72,0,0,1,.451.067c.4.221.361.808.357.853a1.26,1.26,0,0,1-.256.6c-.641.818-2.688.635-2.784.625a7.034,7.034,0,0,1-3.235.558,8.1,8.1,0,0,1-3.023,1.47,5.5,5.5,0,0,1-1.558,1.128,5.572,5.572,0,0,1-1.652.5,11.915,11.915,0,0,1-4.947,2.329c-2.489.5-3.783-.147-6.392.087-1.843.165-3.114.661-6.445,2.186-2.952,1.351-6.7,3.134-11.035,5.369l-2.16-4.454Z"
            transform="translate(-17636.902 -11332.42)"
            fill="#fa9172"
          />
          <path
            id="パス_3578"
            data-name="パス 3578"
            d="M588.21,1081.618a6.305,6.305,0,0,0,2.145-.534,6.2,6.2,0,0,0,1.473-.927,8.825,8.825,0,0,0,4.623-1.284,5.548,5.548,0,0,0,1.5.143,5.7,5.7,0,0,0,2.767-.868l-1.887,1.1-2.39-.063a23.194,23.194,0,0,0-2.752,1.326c-.23.259-1.85.007-1.85.007l-1.871,1.412-2.222.149.149-.351Z"
            transform="translate(-17923.713 -11349.646)"
            fill="#fa9172"
            opacity="0.5"
          />
          <path
            id="パス_3579"
            data-name="パス 3579"
            d="M605.673,1106.9a16.814,16.814,0,0,1-3.721,1.419l-1.592.643-.4.66.979-.541,2.375-.793,1.771-.71,3.436-1.031,3-.494-3.43.307Z"
            transform="translate(-17934.576 -11374.514)"
            fill="#fa9172"
            opacity="0.5"
          />
          <g
            id="グループ_3713"
            data-name="グループ 3713"
            transform="translate(-17315.191 -10262.641)"
          >
            <path
              id="パス_3580"
              data-name="パス 3580"
              d="M775.615,1173.963l-.107-.026a10.377,10.377,0,0,1,2.135-4.191c-.12-.809-.21-1.632-.267-2.448a31.794,31.794,0,0,1-.049-3.541,4.1,4.1,0,0,1-.528-4.145l.1.043a4,4,0,0,0,.526,4.052l.012.016v.02a31.7,31.7,0,0,0,.048,3.548c.057.82.148,1.649.27,2.463l0,.024-.016.019a10.267,10.267,0,0,0-2.128,4.166Z"
              transform="translate(-775.507 -1159.613)"
              fill="#fa9172"
            />
          </g>
          <path
            id="パス_3581"
            data-name="パス 3581"
            d="M636.081,1190.064a64.257,64.257,0,0,0,3.64-7.932c1.727-4.563,2.851-7.621,2.042-9.883a18.086,18.086,0,0,1-1.231-4.157,6.128,6.128,0,0,0-.229-1.154,6.815,6.815,0,0,0-.977-1.933c-1.342-2.035-.936-2.287-2.1-3.807a13.451,13.451,0,0,1-1.411-1.757,6.456,6.456,0,0,1-.764-2.434l-1.079-1.974a2.332,2.332,0,0,1,1.694-.6c1.076.106,1.749,1.169,2.271,1.992a6.739,6.739,0,0,1,.7,1.464l4.142,4.291a9.565,9.565,0,0,0,2.113-3.492,7.015,7.015,0,0,1,.725-1.706,6.846,6.846,0,0,1,1.048-1.337c.136-.646.3-1.325.5-2.032.29-1.015.613-1.948.947-2.792a23.036,23.036,0,0,0,.744-4.289c.106-1.414.129-3.538,1.076-4.507a1.157,1.157,0,0,1,.911-.451.973.973,0,0,1,.629.494l.245,2.457-.2,6.4.341-.8.668-4.674q.082-1.852.164-3.7a1.014,1.014,0,0,1,.315-.459,1.215,1.215,0,0,1,1.336-.041,1.322,1.322,0,0,1,.527,1.512q.072.677.144,1.355a.828.828,0,0,1,.911-.451.813.813,0,0,1,.646.736l.3,5.779c.069-.132.479-.9.835-.836.243.045.355.461.425.763a26.528,26.528,0,0,1,.217,5.979c0,.458.007.667.008.828.005,1.388-.54,4.538-4.648,12l-1.526,3.026-.606,1.99.219,3.153-1.227,2.324-3.4,13.921a2.245,2.245,0,0,1-.605.93c-2.5,2.232-10.838-1.752-10.585-3.872A.855.855,0,0,1,636.081,1190.064Z"
            transform="translate(-17964.824 -11405.718)"
            fill="#fa9172"
          />
          <path
            id="パス_3582"
            data-name="パス 3582"
            d="M778.761,1143.591l.1,1.286a.963.963,0,0,0,.327.782.981.981,0,0,0,1.119.047l.214-1.915a.889.889,0,0,0-.778-.849A.9.9,0,0,0,778.761,1143.591Z"
            transform="translate(-18093.592 -11407.389)"
            fill="#fab7b6"
          />
          <path
            id="パス_3583"
            data-name="パス 3583"
            d="M655.1,1165.14l-.237-1.568-.044,1.6Z"
            transform="translate(-17983.361 -11425.782)"
            fill="#fa9172"
            opacity="0.3"
          />
          <path
            id="パス_3584"
            data-name="パス 3584"
            d="M625.348,1171.9l-.144-1.355.053,1.425Z"
            transform="translate(-17957.027 -11432)"
            fill="#fa9172"
            opacity="0.3"
          />
          <path
            id="パス_3585"
            data-name="パス 3585"
            d="M674.117,1118.764l-.563-1.717.2,1.927.262-.2Z"
            transform="translate(-18000.025 -11384.315)"
            fill="#fa9172"
            opacity="0.3"
          />
          <path
            id="パス_3586"
            data-name="パス 3586"
            d="M600.857,1156.393l-1.525.57-1.984.718-.69.777.6-1.4,1.558-1.128-.963,1.137Z"
            transform="translate(-17931.641 -11418.967)"
            fill="#fa9172"
            opacity="0.3"
          />
          <path
            id="パス_3587"
            data-name="パス 3587"
            d="M696.279,1064.562l-1.294-.06-.018-.106,1.5.007a.173.173,0,0,1-.043.1A.192.192,0,0,1,696.279,1064.562Z"
            transform="translate(-18019.07 -11337.388)"
            fill="#fab7b6"
          />
          <g
            id="グループ_3714"
            data-name="グループ 3714"
            transform="translate(-17366.643 -10260.785)"
            opacity="0.2"
          >
            <path
              id="パス_3588"
              data-name="パス 3588"
              d="M310.983,1189.884l-.473-.983c2.9-1.346,5.7-2.727,8.32-4.1l.429-.226a25.042,25.042,0,0,1,7.647-2.942,21.986,21.986,0,0,1,2.472-.316l.1-.007.092.025a10.467,10.467,0,0,0,4.95.131,10.337,10.337,0,0,0,4.637-2.345l.139-.123.187-.015a4.437,4.437,0,0,0,1.429-.355,4.386,4.386,0,0,0,1.347-.913l.072-.072,2.162-.96.456.991-1.978.878a5.491,5.491,0,0,1-1.611,1.07,5.56,5.56,0,0,1-1.6.427,11.46,11.46,0,0,1-5,2.479,11.6,11.6,0,0,1-5.391-.12,20.812,20.812,0,0,0-2.252.293,24.137,24.137,0,0,0-7.33,2.832l-.43.227C316.713,1187.142,313.9,1188.531,310.983,1189.884Z"
              transform="translate(-310.51 -1176.68)"
              fill="#fa9172"
            />
          </g>
          <path
            id="パス_3589"
            data-name="パス 3589"
            d="M808.842,1136.421l.177,1.84,1.14.7a.168.168,0,0,0,.1.042.2.2,0,0,0,.182-.147l-.189-2.095a.821.821,0,0,0-.689-.636A.87.87,0,0,0,808.842,1136.421Z"
            transform="translate(-18120.346 -11401.317)"
            fill="#fab7b6"
          />
          <path
            id="パス_3590"
            data-name="パス 3590"
            d="M826.436,1163.576l.185,1.821.724.554.361.148a1.878,1.878,0,0,0,.127-.162c.005-.019-.146-2.2-.146-2.2a.707.707,0,0,0-.383-.51A.722.722,0,0,0,826.436,1163.576Z"
            transform="translate(-18135.992 -11425.436)"
            fill="#fab7b6"
          />
          <path
            id="パス_3591"
            data-name="パス 3591"
            d="M843.8,1217.515l.269,1.45a1.517,1.517,0,0,0,.383.51,1.549,1.549,0,0,0,.537.3l-.238-2.419a.611.611,0,0,0-.434-.178A.6.6,0,0,0,843.8,1217.515Z"
            transform="translate(-18151.438 -11473.563)"
            fill="#fab7b6"
          />
          <path
            id="パス_3592"
            data-name="パス 3592"
            d="M633.123,1265.228l.476,1.138a4.579,4.579,0,0,1,.067.97c0,.12-.013.234-.026.343l-.4-.326-1.269-2.243a.83.83,0,0,1,.434-.175A.909.909,0,0,1,633.123,1265.228Z"
            transform="translate(-17963.047 -11516.121)"
            fill="#fab7b6"
          />
          <path
            id="パス_3593"
            data-name="パス 3593"
            d="M777.935,1235.636l.028-3.169.828-3.077.826-2.788-.027-.36.39-.492.3,1.969-1.254,5.066-.725,1.706-.137.886Z"
            transform="translate(-18092.857 -11481.2)"
            fill="#fa9172"
            opacity="0.5"
          />
          <path
            id="パス_3594"
            data-name="パス 3594"
            d="M812.33,1168.116l.1,2.141-.056,4.593-.749,5.6-.765,4.329,1.212-4.992,1.084-3.679-.168-2.425-.026-3.506L812.6,1168Z"
            transform="translate(-18122.143 -11429.726)"
            fill="#fa9172"
            opacity="0.5"
          />
          <path
            id="パス_3595"
            data-name="パス 3595"
            d="M833.406,1223.409l.157,2.833-.824,4.04-.27,3.581.845-2.834.646-4.8-.177-3Z"
            transform="translate(-18141.359 -11478.955)"
            fill="#fa9172"
            opacity="0.5"
          />
          <path
            id="パス_3596"
            data-name="パス 3596"
            d="M510.493,1084.749l-.9,1.12-2.149,1.066-4.549,3.719-.535,1.258-.394,2.533-1.168,2.466,2.411-3.134.138-2.3,2.14-1.793,2.366-2.289,2.1-1.378Z"
            transform="translate(-17846.385 -11355.528)"
            fill="#fa9172"
            opacity="0.5"
          />
          <path
            id="パス_3597"
            data-name="パス 3597"
            d="M713.186,1078.273l1.746.016.125.752-1.546-.51Z"
            transform="translate(-18035.273 -11349.756)"
            fill="#fab7b6"
          />
          <path
            id="パス_3623"
            data-name="パス 3623"
            d="M590.309,1541.318c-6.786-.036-13.6.036-20.383,0a13.661,13.661,0,0,1,1.115-4.838c1.386-3.138,2.772-3.535,4.2-6.849,1.113-2.576.448-2.856,1.66-5.545,1.372-3.044,3.567-5.665,4.426-5.327.8.313.02,3,1.107,3.479.82.362,1.623-1.01,2.766-.761,1.4.3,1.124,2.557,2.656,3.479,2.1,1.263,5.192-1.411,5.975-.544.673.746-1.81,2.5-2.545,6.305-.511,2.644.288,3.778-.332,7.719A21.921,21.921,0,0,1,590.309,1541.318Z"
            transform="translate(-17907.865 -11742.328)"
            fill="#2deaa8"
          />
          <path
            id="パス_3626"
            data-name="パス 3626"
            d="M0,1226.727v-21.961a19.26,19.26,0,0,1,2.987-1.957c3.371-1.784,5.249-1.478,9.073-2.5,4.657-1.245,4.6-2.428,9.516-3.805a34.331,34.331,0,0,1,6.971-1.2c2.923-1.785,3.885-1.837,4.2-1.522.433.426-.458,1.377,0,2.5.506,1.24,1.925.9,3.1,2.392a5.217,5.217,0,0,1,1,2.935c.117,1.565-.463,2.025-.553,3.262-.212,2.9,2.731,4.113,2.213,5.436-.592,1.509-4.461.094-9.958,2.066-.85.305-.415.216-4.647,2.174-4.513,2.088-4.819,2.1-6.528,2.935-4.989,2.432-5.308,3.742-9.958,6.2A42.49,42.49,0,0,1,0,1226.727Z"
            transform="translate(-17401 -11452.579)"
            fill="#2deaa8"
          />
          <ellipse
            id="楕円形_393"
            data-name="楕円形 393"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17392.148 -10242.921)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_394"
            data-name="楕円形 394"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17399.563 -10247.052)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_395"
            data-name="楕円形 395"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17398.012 -10234.55)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_396"
            data-name="楕円形 396"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17385.398 -10250.422)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_397"
            data-name="楕円形 397"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17385.398 -10242.377)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_398"
            data-name="楕円形 398"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17377.984 -10248.465)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_399"
            data-name="楕円形 399"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17333.727 -10205.413)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_400"
            data-name="楕円形 400"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17331.291 -10213.784)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_401"
            data-name="楕円形 401"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17321.002 -10211.176)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_402"
            data-name="楕円形 402"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17325.316 -10206.175)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_403"
            data-name="楕円形 403"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17325.539 -10217.48)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_404"
            data-name="楕円形 404"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17373.67 -10254.227)"
            fill="#fff"
          />
          <ellipse
            id="楕円形_405"
            data-name="楕円形 405"
            cx="1.494"
            cy="1.468"
            rx="1.494"
            ry="1.468"
            transform="translate(-17370.129 -10245.529)"
            fill="#fff"
          />
        </g>
        <g
          id="グループ_3749"
          data-name="グループ 3749"
          transform="translate(-0.905 10)"
        >
          <path
            id="パス_3598"
            data-name="パス 3598"
            d="M1898.243,1074.757a75.423,75.423,0,0,1-6.372-8.841,97.036,97.036,0,0,1-6.071-11.845c-.633-1.417-.366-.892-1.987-4.633-2.358-5.443-2.714-6.071-3.079-7.709a19.13,19.13,0,0,1-.351-6.47,18.12,18.12,0,0,1,1.386-5.007l1.128-2.791a2.589,2.589,0,0,0-1.377-.379,3.964,3.964,0,0,0-2.449,1.383,7.712,7.712,0,0,0-1.188,1.311c-1.175,1.666-1.182,3.216-1.694,5.541a26.813,26.813,0,0,1-1.188,3.92,3.818,3.818,0,0,0-2.009-1.614c-1.347-.4-1.881.488-4.371.734-1.82.181-2.65-.183-3.52.5a2.806,2.806,0,0,0-.917,1.462,2.6,2.6,0,0,0,.19,1.657,3.882,3.882,0,0,0-2.112.3,1.966,1.966,0,0,0-.44.389,1.8,1.8,0,0,0-.315.67,2.677,2.677,0,0,0,.037,1.924,2.215,2.215,0,0,0,.388.569q-.007.137-.015.274a1.56,1.56,0,0,0-.579.382,1.894,1.894,0,0,0-.367,1.628,2.356,2.356,0,0,0,.9,1.42,3.1,3.1,0,0,0-.315.67,1.982,1.982,0,0,0-.073,1.369,1.553,1.553,0,0,0,.257.425,3.756,3.756,0,0,0,1.349.894c.747.431.613.69,1.334,1.167a4.284,4.284,0,0,0,1.643.634,18.436,18.436,0,0,0,4.3,3.106,18.677,18.677,0,0,0,5.074,1.773c.194.7.506,1.78.953,3.07a49.763,49.763,0,0,0,2.984,6.881,66.293,66.293,0,0,1,4.634,12.595,1.834,1.834,0,0,0,.264.288c2.9,2.554,14.83-3.855,14.212-7.089A1.418,1.418,0,0,0,1898.243,1074.757Z"
            transform="translate(-19056.018 -11304.127)"
            fill="#fab887"
          />
          <path
            id="パス_3599"
            data-name="パス 3599"
            d="M2048.708,1029.44c-.356.742-.625,1.273-.838,1.678-.144.274-.363.682-.191.951a.446.446,0,0,0,.176.153l1.745-2.113a.43.43,0,0,0,.015-.274C2049.537,1029.582,2049.171,1029.405,2048.708,1029.44Z"
            transform="translate(-19222.051 -11306.229)"
            fill="#fad5cb"
          />
          <path
            id="パス_3600"
            data-name="パス 3600"
            d="M1951.016,1182.16a3.634,3.634,0,0,1,.469-.937,3.7,3.7,0,0,1,.741-.785l.418.022a.77.77,0,0,1,.212,1.247,3.932,3.932,0,0,1-.572.245A4.013,4.013,0,0,1,1951.016,1182.16Z"
            transform="translate(-19136.141 -11440.814)"
            fill="#fad5cb"
          />
          <path
            id="パス_3601"
            data-name="パス 3601"
            d="M1889.624,1163.772l1.313,1.578a4.77,4.77,0,0,0,4.92,2.04,2.837,2.837,0,0,1-.572.245,3.657,3.657,0,0,1-2.5-.267,5.2,5.2,0,0,1-1.217-.75c-1.4-1.082-2.442-2.638-2.222-2.86C1889.377,1163.725,1889.45,1163.708,1889.624,1163.772Z"
            transform="translate(-19081.266 -11425.922)"
            fill="#fab887"
            opacity="0.5"
          />
          <path
            id="パス_3602"
            data-name="パス 3602"
            d="M1867.292,1199.583l2.566,1.643,1.87,1.607,2.4.124,2.064-.03.279.014-1.276.346-3.615-.324-4.63-2.938.326-.168Z"
            transform="translate(-19061.377 -11457.878)"
            fill="#fab887"
            opacity="0.5"
          />
          <path
            id="パス_3603"
            data-name="パス 3603"
            d="M1869.495,1233.648l3.185,2.087,3.246.443-.293.259-2.765-.555-1.66-.772Z"
            transform="translate(-19063.639 -11488.239)"
            fill="#fab887"
            opacity="0.5"
          />
          <path
            id="パス_3604"
            data-name="パス 3604"
            d="M1972.64,1134.752l-.418,2.587-.646,4.222,1.947,5.455-2.763-5.085-.048-4.017,1.545-3.665Z"
            transform="translate(-19153.656 -11399.646)"
            fill="#fab887"
            opacity="0.5"
          />
          <path
            id="パス_3605"
            data-name="パス 3605"
            d="M1910.879,1193.743l.917-.174,1.064-.272.205,1.383q0,1.3,0,2.608l-1.342.35-.55,1.063-.345,1.217-.99.223-.719.375-.843.094.653.858-.623,1.2-.887.915-.543-.3-.667-.584,1.026-.391.895-1.878.939-.472.439-.069.959-1.654-.084-.4.161-.4.624-.33.484-2.083.315-.67Z"
            transform="translate(-19096.289 -11452.274)"
            fill="#fab887"
            opacity="0.5"
          />
          <path
            id="パス_3606"
            data-name="パス 3606"
            d="M1946.916,1214.328l-1.547.194a1.758,1.758,0,0,1,.037-.685,1.908,1.908,0,0,1,.755-1.059l1.115.058a.81.81,0,0,1,.374.843A.827.827,0,0,1,1946.916,1214.328Z"
            transform="translate(-19131.102 -11469.64)"
            fill="#fad5cb"
          />
          <path
            id="パス_3607"
            data-name="パス 3607"
            d="M1929.007,1242.251c0-.767.331-1.358.778-1.47a.758.758,0,0,1,.418.022l.836.043a1.4,1.4,0,0,1-.484,1.21,1.261,1.261,0,0,1-.711.238Z"
            transform="translate(-19116.566 -11494.58)"
            fill="#fad5cb"
          />
          <path
            id="パス_3608"
            data-name="パス 3608"
            d="M1912.7,1265.663a5.93,5.93,0,0,1,.609-.929.959.959,0,0,1,.293-.259,1,1,0,0,1,.565-.108.458.458,0,0,1,.132.144.806.806,0,0,1-.33.944.976.976,0,0,1-.286.123A2.516,2.516,0,0,1,1912.7,1265.663Z"
            transform="translate(-19102.066 -11515.614)"
            fill="#fad5cb"
          />
          <path
            id="パス_3620"
            data-name="パス 3620"
            d="M1923.318,1136.663l3.561.185,3.175-1.425-1.1,2.905-.395.632-1.977-1.034-1.529.2Z"
            transform="translate(-19111.508 -11400.693)"
            fill="#fab887"
            opacity="0.5"
          />
          <path
            id="パス_3622"
            data-name="パス 3622"
            d="M2069.009,1431.111h-21.85l-13.259-28.3a.676.676,0,0,1,.317-.892l13.632-6.761a.7.7,0,0,1,.917.276Z"
            transform="translate(-19209.797 -11632.121)"
            fill="#048cf2"
          />
          <path
            id="パス_3627"
            data-name="パス 3627"
            d="M2027.736,1380.884l-1.219-2.559a.5.5,0,0,1,.233-.665l12.171-6.08a.519.519,0,0,1,.683.205l1.419,2.51Z"
            transform="translate(-19203.244 -11611.127)"
            fill="#f2f2f2"
          />
          <g
            id="グループ_3716"
            data-name="グループ 3716"
            transform="translate(-17173.996 -10231.037)"
          >
            <path
              id="パス_3628"
              data-name="パス 3628"
              d="M.014,0H.235L.318,12.1H.1Z"
              transform="translate(0 0.099) rotate(-25.298)"
              fill="#158cf2"
            />
          </g>
          <ellipse
            id="楕円形_406"
            data-name="楕円形 406"
            cx="0.941"
            cy="0.924"
            rx="0.941"
            ry="0.924"
            transform="translate(-17171.516 -10229.439)"
            fill="#158cf2"
          />
          <ellipse
            id="楕円形_407"
            data-name="楕円形 407"
            cx="0.941"
            cy="0.924"
            rx="0.941"
            ry="0.924"
            transform="translate(-17169.525 -10225.526)"
            fill="#158cf2"
          />
          <path
            id="パス_3639"
            data-name="パス 3639"
            d="M2027.741,1370.255l.48.967,11.348-5.67-.616-1.061Z"
            transform="translate(-19204.375 -11604.858)"
            fill="#fab887"
            opacity="0.3"
          />
          <path
            id="パス_3640"
            data-name="パス 3640"
            d="M2046.392,1387.8l.565,1-13.286,6.59-.529-1.111Z"
            transform="translate(-19209.18 -11625.637)"
            fill="#f2f2f2"
          />
        </g>
        <g
          id="グループ_3748"
          data-name="グループ 3748"
          transform="matrix(0.985, 0.174, -0.174, 0.985, -1873.65, 2841.304)"
        >
          <path
            id="パス_3609"
            data-name="パス 3609"
            d="M2507.451,1177.156a.66.66,0,0,0,.28.324c1.843.977,10.53-9.136,9.039-11.084a.8.8,0,0,0-.229-.2l-10.645-5.512s-5.462-3.642-6.453-4.266-3.684-2.17-3.684-2.17l-3-3.9a29.105,29.105,0,0,0-2.086-3.511c-.457-.4-1.55-1.272-1.55-1.272l-.432-1.423-.534-1.672s-1.448-1.747-1.88-2.445-.963-1.647-.963-1.647-1.5.3-1.806,1-1.2,1.414-.56,3.153a6.511,6.511,0,0,0,1.552,2.537l-1.6-1.4-8.485-4.914-2.21-1.447-3.48-1.671-2.286-1.272-3.887-1.222-.787-.124a1,1,0,0,0-.584.375c-.275.407-.053.952.127,1.4a3.754,3.754,0,0,0,.929,1.332l3.973,1.985,4.752,3.02,4.4,3.193-5.051-3.222-1.3-.794-3-1.572-2.744-1.671-5.208-1.571a1.632,1.632,0,0,0-1.168.749,1.6,1.6,0,0,0,.509,1.971l4.6,2.245,5.09,3.379,5.2,3.432-.952-.167-4.385-2.6-3.478-2.172-4.6-1.949a1.8,1.8,0,0,0-1.295.8,1.762,1.762,0,0,0-.076,1.622l4.536,2.846,5.672,3.9,3.257,1.709-4.026-1.542a7.635,7.635,0,0,0-1.766-1.1,7.807,7.807,0,0,0-3.074-.673,1.143,1.143,0,0,0-1.092.574,1.111,1.111,0,0,0,.254,1.348l2.693,1.547,7.7,4.635,2.31,1.7,2.238.651s3.78,2.865,6.475,3.429a44.615,44.615,0,0,0,7.115.563l9.932,7.24Z"
            transform="translate(-19585.898 -11398.551)"
            fill="#fa9172"
          />
          <path
            id="パス_3610"
            data-name="パス 3610"
            d="M2517.851,1165.33c-.885-1.957-3.319-2.827-5.311-4.24-.664-.435-1-1.3-1.77-1.522a9.137,9.137,0,0,1-1.66-1.2,12.922,12.922,0,0,1-5.11-6.05l-6.112-3.54-2.21-1.447-3.481-1.671-2.286-1.272-.393-.123c-1.1.753-3.273.089-4.419.732-.061.038-.121.078-.179.119a3.841,3.841,0,0,0,.79,1.03l3.973,1.985,4.752,3.02,4.4,3.193-5.051-3.222-1.3-.794-3-1.571-2.744-1.671-2.837-.856a5.472,5.472,0,0,0-.771,3.109l2.339,1.142,5.09,3.379,5.2,3.432-.952-.167-4.385-2.6-3.478-2.172-3.442-1.459a27.569,27.569,0,0,1,1.82,3.777,8.07,8.07,0,0,0,.775,1.3,1.512,1.512,0,0,1,.062.141l5.024,3.456,3.257,1.709-4.026-1.543a7.623,7.623,0,0,0-1.766-1.1,7.735,7.735,0,0,0-2.354-.63c.005.084.013.168.025.25.162,1.115-.921,1.18-1.687,1.519a1.188,1.188,0,0,0,.1.109l2.693,1.546,7.7,4.635,2.31,1.7,2.238.652s3.78,2.865,6.475,3.429a44.621,44.621,0,0,0,7.115.563l4.317,3.147C2518.748,1172.209,2519.13,1168.473,2517.851,1165.33Z"
            transform="translate(-19609.367 -11408.576)"
            fill="#fa9172"
            opacity="0.5"
          />
          <path
            id="パス_3611"
            data-name="パス 3611"
            d="M2496.22,1270.565c-.012-.082-.019-.166-.025-.25-.271-.029-.515-.04-.72-.043a1.142,1.142,0,0,0-1.092.574,1.1,1.1,0,0,0,.15,1.239C2495.3,1271.745,2496.383,1271.679,2496.22,1270.565Z"
            transform="translate(-19619.273 -11520.878)"
            fill="#fa9172"
          />
          <path
            id="パス_3612"
            data-name="パス 3612"
            d="M2705.065,1185.658l.711.3,1.013-.688-.154-1.01-1.163-1.946-.46,1.048-.736.724Z"
            transform="translate(-19806.055 -11442.485)"
            fill="#fab7b6"
          />
          <path
            id="パス_3613"
            data-name="パス 3613"
            d="M2700.313,1237.232l.89,3.269,1.162,4.811,2.075,3.572-3.276-3.9-.553-4.828-1.223-3.726Z"
            transform="translate(-19801.707 -11490.715)"
            fill="#fa9172"
            opacity="0.5"
          />
          <g
            id="グループ_3715"
            data-name="グループ 3715"
            transform="translate(-17125.9 -10265.082)"
          >
            <path
              id="パス_3614"
              data-name="パス 3614"
              d="M2524.988,1197.286a51.951,51.951,0,0,0-4.6-8.759c-3.354-5.34-5.209-7.361-5.616-8.359l-1.525-3.743a58.589,58.589,0,0,1-6.86-4.54,38.467,38.467,0,0,1-4.269-4.691,7.921,7.921,0,0,1-2.541-1.9c-.711-1.023-3.481-4.566-3.481-4.566l-4.675-5.689a1.734,1.734,0,0,1-.153-2.546,1.755,1.755,0,0,1,2.032-.075l4.929,4.865,2.66,3.442.288.176-.178-.8-2.033-3.543-6.938-11.6a1.512,1.512,0,0,1,.076-1.622,1.756,1.756,0,0,1,2.49.324l9.58,13.025-10.012-14.448-1.169-2.146a1.442,1.442,0,0,1,.355-2.022,1.5,1.5,0,0,1,2.058.349l3.709,4.042s1.474,2.171,1.855,2.745,1.474,1.447,1.905,2.146a38.344,38.344,0,0,0,3.3,4.491,18.4,18.4,0,0,0,2.972,2.595l-1.779-2.919a27.78,27.78,0,0,1-2.464-2.795c-.991-1.348-2.719-4.142-2.719-4.142l-2.379-2.71a6.967,6.967,0,0,1-1.061-1.612l-.625-1.318a1.222,1.222,0,0,1,.381-1.6,1.268,1.268,0,0,1,1.524.124l3.15,2.67s1.6,2.121,2.49,3.219,1.575,1.7,2.363,2.545,1.017,1.772,2.211,2.894a37.338,37.338,0,0,1,4.37,4.217,21.3,21.3,0,0,0,3.4,3.293l1.5.424,2.082-5.741a4.235,4.235,0,0,1-.128-2.121,3.872,3.872,0,0,1,2.463-3,2.917,2.917,0,0,1,.991-.1,9.354,9.354,0,0,0-.024,3.918,6.466,6.466,0,0,1,.331,2.62,7.218,7.218,0,0,1-.71,1.872c-.414,1.08-.369,1.833-.176,5.715a14.636,14.636,0,0,0-.786,3.494,33.967,33.967,0,0,0,.129,6.464l6.836,11.354,7.039,8.234a3.238,3.238,0,0,1,.381,1.3c.3,4.494-9.806,9.8-12.317,7.74A1.97,1.97,0,0,1,2524.988,1197.286Z"
              transform="translate(-2490.282 -1137.148)"
              fill="#fb9d82"
            />
            <path
              id="パス_3615"
              data-name="パス 3615"
              d="M2557.408,1141.248a.824.824,0,0,0,.33-.275,1.269,1.269,0,0,0-.127-1.4l-1.067-1.173a1.157,1.157,0,0,0-.966-.2,1.081,1.081,0,0,0-.761,1.023,1.121,1.121,0,0,0,.3.749l.966.923A1.254,1.254,0,0,0,2557.408,1141.248Z"
              transform="translate(-2547.232 -1138.055)"
              fill="#fab7b6"
            />
            <path
              id="パス_3616"
              data-name="パス 3616"
              d="M2509.72,1143.6l1.042,1.472a1.238,1.238,0,0,1,.051.849,1.136,1.136,0,0,1-.985.822,1.057,1.057,0,0,1-.717-.3l-1.406-1.716a1.181,1.181,0,0,1,.313-1.328A1.258,1.258,0,0,1,2509.72,1143.6Z"
              transform="translate(-2505.259 -1142.502)"
              fill="#fab7b6"
            />
            <path
              id="パス_3617"
              data-name="パス 3617"
              d="M2499.3,1191.294a1.073,1.073,0,0,0,.889.374.96.96,0,0,0,.7-.419.95.95,0,0,0-.139-1.1l-.915-1.522a1.157,1.157,0,0,0-1.651-.074,1.083,1.083,0,0,0-.05,1.323Z"
              transform="translate(-2496.642 -1182.683)"
              fill="#fab7b6"
            />
            <path
              id="パス_3618"
              data-name="パス 3618"
              d="M2486.691,1271.509l1.423,1.322a.8.8,0,0,0,.61.05.833.833,0,0,0,.482-.624,1.022,1.022,0,0,0-.178-.8l-.991-1.347a1.139,1.139,0,0,0-1.27-.224.939.939,0,0,0-.076,1.622Z"
              transform="translate(-2486.264 -1255.361)"
              fill="#fab7b6"
            />
            <path
              id="パス_3619"
              data-name="パス 3619"
              d="M2795.737,1215.955l.543-.062a.4.4,0,0,1,.438.462l-.559,2.92Z"
              transform="translate(-2761.494 -1207.329)"
              fill="#fab7b6"
            />
          </g>
          <path
            id="パス_3624"
            data-name="パス 3624"
            d="M2754.381,1527.3l-22,.009-9.38-17.349,12.061-6.958Z"
            transform="translate(-19822.707 -11728.309)"
            fill="#1fc8c2"
          />
          <path
            id="パス_3625"
            data-name="パス 3625"
            d="M2865.424,1395.372v23.374L2834,1391.676l7.414-9.676Z"
            transform="translate(-19921.424 -11620.463)"
            fill="#1fc8c2"
          />
          <path
            id="パス_3629"
            data-name="パス 3629"
            d="M2730.6,1495l-11.221,6.965a.839.839,0,0,1-1.145-.254.807.807,0,0,1,.259-1.125l11.221-6.965a.838.838,0,0,1,1.145.254A.807.807,0,0,1,2730.6,1495Z"
            transform="translate(-19818.355 -11719.84)"
            fill="#1fc8c2"
          />
          <path
            id="パス_3630"
            data-name="パス 3630"
            d="M.822.026l12.218.1a.84.84,0,0,1,.828.831h0a.806.806,0,0,1-.814.817L.836,1.675A.84.84,0,0,1,.008.844.806.806,0,0,1,.822.026Z"
            transform="translate(-17089.293 -10229.064) rotate(-52.222)"
            fill="#1fc8c2"
          />
          <g
            id="グループ_3717"
            data-name="グループ 3717"
            transform="translate(-17081.182 -10237.577)"
            opacity="0.3"
          >
            <path
              id="パス_3631"
              data-name="パス 3631"
              d="M2915.594,1407.435l-25.182-16.29.669-1L2915.594,1406Z"
              transform="translate(-2890.411 -1390.146)"
              fill="#1fc8c2"
            />
          </g>
          <g
            id="グループ_3718"
            data-name="グループ 3718"
            transform="translate(-17083.752 -10234.319)"
            opacity="0.3"
          >
            <path
              id="パス_3632"
              data-name="パス 3632"
              d="M2894.932,1441.713l-27.753-20.645.735-.953,27.018,20.1Z"
              transform="translate(-2867.18 -1420.115)"
              fill="#1fc8c2"
            />
          </g>
          <g
            id="グループ_3719"
            data-name="グループ 3719"
            transform="translate(-17086.016 -10231.56)"
            opacity="0.3"
          >
            <path
              id="パス_3633"
              data-name="パス 3633"
              d="M2876.741,1474.2l-30.016-27.835.836-.87,29.181,27.06Z"
              transform="translate(-2846.725 -1445.5)"
              fill="#1fc8c2"
            />
          </g>
          <g
            id="グループ_3720"
            data-name="グループ 3720"
            transform="translate(-17090.078 -10224.077)"
            opacity="0.3"
          >
            <path
              id="パス_3634"
              data-name="パス 3634"
              d="M2826.1,1537.393l-16.1-22.377.994-.69,16.592,23.067Z"
              transform="translate(-2810.009 -1514.326)"
              fill="#1fc8c2"
            />
          </g>
          <g
            id="グループ_3721"
            data-name="グループ 3721"
            transform="translate(-17093.975 -10221.651)"
            opacity="0.3"
          >
            <path
              id="パス_3635"
              data-name="パス 3635"
              d="M2787.225,1557.278l-12.421-20.019,1.039-.622,12.807,20.641Z"
              transform="translate(-2774.804 -1536.637)"
              fill="#1fc8c2"
            />
          </g>
          <g
            id="グループ_3722"
            data-name="グループ 3722"
            transform="translate(-17097.752 -10219.232)"
            opacity="0.3"
          >
            <path
              id="パス_3636"
              data-name="パス 3636"
              d="M2750.356,1577.119l-9.7-17.663,1.071-.568,10,18.222Z"
              transform="translate(-2740.659 -1558.889)"
              fill="#1fc8c2"
            />
          </g>
          <g
            id="グループ_3723"
            data-name="グループ 3723"
            transform="translate(-17126.719 -10280.872)"
            opacity="0.3"
          >
            <path
              id="パス_3651"
              data-name="パス 3651"
              d="M2481.349,1000.412a.276.276,0,0,1-.267-.2l-2.208-7.958a.272.272,0,0,1,.194-.334.278.278,0,0,1,.34.191l2.208,7.958a.271.271,0,0,1-.194.334A.287.287,0,0,1,2481.349,1000.412Z"
              transform="translate(-2478.864 -991.911)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3724"
            data-name="グループ 3724"
            transform="translate(-17111.336 -10270.155)"
            opacity="0.3"
          >
            <path
              id="パス_3652"
              data-name="パス 3652"
              d="M2618.176,1096.791a.279.279,0,0,1-.124-.029.27.27,0,0,1-.123-.365l2.948-5.761a.278.278,0,0,1,.371-.121.269.269,0,0,1,.123.365l-2.948,5.761A.278.278,0,0,1,2618.176,1096.791Z"
              transform="translate(-2617.899 -1090.486)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3725"
            data-name="グループ 3725"
            transform="translate(-17113.533 -10274.361)"
            opacity="0.3"
          >
            <path
              id="パス_3653"
              data-name="パス 3653"
              d="M2598.559,1058.788a.275.275,0,0,1-.276-.262l-.252-6.443a.277.277,0,0,1,.553-.021l.252,6.443a.274.274,0,0,1-.266.282Z"
              transform="translate(-2598.031 -1051.802)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3726"
            data-name="グループ 3726"
            transform="translate(-17109.176 -10268.109)"
            opacity="0.3"
          >
            <path
              id="パス_3654"
              data-name="パス 3654"
              d="M2637.694,1113.881a.278.278,0,0,1-.217-.1.269.269,0,0,1,.045-.382l5.188-4.035a.28.28,0,0,1,.389.044.269.269,0,0,1-.045.382l-5.188,4.035A.279.279,0,0,1,2637.694,1113.881Z"
              transform="translate(-2637.417 -1109.302)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3727"
            data-name="グループ 3727"
            transform="translate(-17096.516 -10266.471)"
            opacity="0.3"
          >
            <path
              id="パス_3655"
              data-name="パス 3655"
              d="M2752.117,1129.251a.279.279,0,0,1-.146-.041.269.269,0,0,1-.089-.374l2.728-4.324a.279.279,0,0,1,.381-.088.269.269,0,0,1,.089.374l-2.728,4.324A.278.278,0,0,1,2752.117,1129.251Z"
              transform="translate(-2751.841 -1124.384)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3728"
            data-name="グループ 3728"
            transform="translate(-17094.854 -10264.771)"
            opacity="0.3"
          >
            <path
              id="パス_3656"
              data-name="パス 3656"
              d="M2767.134,1143.489a.278.278,0,0,1-.228-.118.269.269,0,0,1,.071-.378l4.348-2.935a.28.28,0,0,1,.385.07.269.269,0,0,1-.071.378l-4.348,2.935A.278.278,0,0,1,2767.134,1143.489Z"
              transform="translate(-2766.857 -1140.011)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3729"
            data-name="グループ 3729"
            transform="translate(-17136.117 -10284.273)"
            opacity="0.3"
          >
            <path
              id="パス_3657"
              data-name="パス 3657"
              d="M2399.934,971.937a.277.277,0,0,1-.245-.146l-5.738-10.773a.269.269,0,0,1,.117-.367.279.279,0,0,1,.373.115l5.738,10.773a.269.269,0,0,1-.117.367A.281.281,0,0,1,2399.934,971.937Z"
              transform="translate(-2393.918 -960.621)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3730"
            data-name="グループ 3730"
            transform="translate(-17145.211 -10281.722)"
            opacity="0.3"
          >
            <path
              id="パス_3658"
              data-name="パス 3658"
              d="M2321.426,994.625a.277.277,0,0,1-.2-.087l-9.414-9.981a.269.269,0,0,1,.015-.384.28.28,0,0,1,.391.015l9.414,9.981a.269.269,0,0,1-.015.384A.279.279,0,0,1,2321.426,994.625Z"
              transform="translate(-2311.735 -984.101)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3731"
            data-name="グループ 3731"
            transform="translate(-17144.594 -10269.219)"
            opacity="0.3"
          >
            <path
              id="パス_3659"
              data-name="パス 3659"
              d="M2328.069,1103.692a.279.279,0,0,1-.1-.019l-10.482-4.042a.27.27,0,0,1-.156-.352.278.278,0,0,1,.359-.154l10.482,4.041a.27.27,0,0,1,.156.352A.276.276,0,0,1,2328.069,1103.692Z"
              transform="translate(-2317.31 -1099.107)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3732"
            data-name="グループ 3732"
            transform="translate(-17141.215 -10259.032)"
            opacity="0.3"
          >
            <path
              id="パス_3660"
              data-name="パス 3660"
              d="M2348.13,1193.5a.272.272,0,1,1-.005-.544l8.037-.153a.272.272,0,1,1,.011.543l-8.037.153Z"
              transform="translate(-2347.854 -1192.803)"
              fill="#996953"
            />
          </g>
        </g>
        <g
          id="グループ_3750"
          data-name="グループ 3750"
          transform="translate(-9.913 10)"
        >
          <g
            id="Layer_2"
            data-name="Layer 2"
            transform="translate(-17272.086 -10278.806)"
          >
            <g
              id="グループ_3711"
              data-name="グループ 3711"
              transform="translate(8.661 2.082)"
            >
              <path
                id="パス_3548"
                data-name="パス 3548"
                d="M1289.136,1091.925c-5.237-7.723-10.465-15.94-15.6-24.661q-3.114-5.289-5.985-10.477l2-7.257a3.577,3.577,0,0,0,.128-1.128,5.876,5.876,0,0,0-1.213-2.752c-.753-1.318-.244-1.175-1.2-3.337s-1.473-2.313-1.447-3.322a2,2,0,0,0-.338-1.441,1.2,1.2,0,0,0-.669-.4,1.467,1.467,0,0,0-1.118.36c-1.116.9-.932,3.124-.409,4.555.068.186.133.334.176.428a12.459,12.459,0,0,1,.678,3.71,7.538,7.538,0,0,1-.845-.826,17.645,17.645,0,0,1-1.707-2.722,17.381,17.381,0,0,0-1.036-1.5l-7.524-10.116a2.125,2.125,0,0,0-1.746-.966,1.465,1.465,0,0,0-.871.345c-.574.547-.512,1.809.313,3.026l-1.663-2.408a1.568,1.568,0,1,0-2.786,1.434l.745,1.611a1.718,1.718,0,0,0-1.367-.453,1.854,1.854,0,0,0-1.2,2.216l5.856,11.191-.275.357c-.728-1.066-1.471-2.239-2.2-3.52-.811-1.42-1.5-2.783-2.089-4.063a1.188,1.188,0,0,0-.9-.969,1.342,1.342,0,0,0-1.248.66,1.567,1.567,0,0,0-.112,1.371l1.039,2.738,2.236,4.833,1.677,2.237a31.338,31.338,0,0,0,9.752,12.052,18.343,18.343,0,0,1,3.256,6.087c.576,1.811,2.771,6.859,9,19.623l7.059,17.34h21.559Z"
                transform="translate(-1243.368 -1030.074)"
                fill="#fa9172"
              />
              <path
                id="パス_3549"
                data-name="パス 3549"
                d="M1437.62,1095.447a1.167,1.167,0,0,1,.293.713l.256,1.077a2.829,2.829,0,0,0-.068-1.234,1.14,1.14,0,0,0-.5-.8.816.816,0,0,0-.451-.07A1.172,1.172,0,0,1,1437.62,1095.447Z"
                transform="translate(-1415.712 -1088.056)"
                fill="#fab7b6"
              />
              <g
                id="グループ_3696"
                data-name="グループ 3696"
                transform="translate(5.441 4.227)"
              >
                <path
                  id="パス_3550"
                  data-name="パス 3550"
                  d="M0,0,2.2.02V.129L0,.112Z"
                  transform="matrix(0.865, -0.501, 0.501, 0.865, 0, 1.087)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3697"
                data-name="グループ 3697"
                transform="translate(8.11 8.374)"
              >
                <path
                  id="パス_3551"
                  data-name="パス 3551"
                  d="M0,0,2.291.021V.13L0,.112Z"
                  transform="matrix(0.85, -0.527, 0.527, 0.85, 0, 1.189)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3698"
                data-name="グループ 3698"
                transform="translate(10.471 12.453)"
              >
                <path
                  id="パス_3552"
                  data-name="パス 3552"
                  d="M0,0,2.81.028V.137L0,.113Z"
                  transform="translate(0 1.644) rotate(-36.373)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3699"
                data-name="グループ 3699"
                transform="translate(8.815 3.448)"
              >
                <path
                  id="パス_3553"
                  data-name="パス 3553"
                  d="M0,0,2.564.024V.134L0,.113Z"
                  transform="matrix(0.835, -0.55, 0.55, 0.835, 0, 1.389)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3700"
                data-name="グループ 3700"
                transform="translate(11.613 7.421)"
              >
                <path
                  id="パス_3554"
                  data-name="パス 3554"
                  d="M0,0,2.345.019V.128L0,.112Z"
                  transform="translate(0 1.002) rotate(-25.765)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3701"
                data-name="グループ 3701"
                transform="translate(13.84 11.393)"
              >
                <path
                  id="パス_3555"
                  data-name="パス 3555"
                  d="M0,0,2.744.016V.124L0,.111Z"
                  transform="translate(0 0.79) rotate(-17.056)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3702"
                data-name="グループ 3702"
                transform="translate(3.254 7.624)"
              >
                <path
                  id="パス_3556"
                  data-name="パス 3556"
                  d="M0,0,2.259.021V.13L0,.112Z"
                  transform="translate(0 1.209) rotate(-32.878)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3703"
                data-name="グループ 3703"
                transform="translate(5.495 11.444)"
              >
                <path
                  id="パス_3557"
                  data-name="パス 3557"
                  d="M0,0,2.042.019V.128L0,.112Z"
                  transform="translate(0 1.066) rotate(-31.983)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3704"
                data-name="グループ 3704"
                transform="translate(7.356 15.144)"
              >
                <path
                  id="パス_3558"
                  data-name="パス 3558"
                  d="M0,0,2.611.025V.134L0,.113Z"
                  transform="matrix(0.832, -0.555, 0.555, 0.832, 0, 1.43)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3705"
                data-name="グループ 3705"
                transform="translate(1.405 12.437)"
              >
                <path
                  id="パス_3559"
                  data-name="パス 3559"
                  d="M0,0,1.814.013V.122L0,.111Z"
                  transform="matrix(0.923, -0.384, 0.384, 0.923, 0, 0.684)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3706"
                data-name="グループ 3706"
                transform="translate(2.945 15.518)"
              >
                <path
                  id="パス_3560"
                  data-name="パス 3560"
                  d="M0,0,2.13.018V.127L0,.112Z"
                  transform="translate(0 0.98) rotate(-27.866)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3707"
                data-name="グループ 3707"
                transform="translate(5.063 18.239)"
              >
                <path
                  id="パス_3561"
                  data-name="パス 3561"
                  d="M0,0l2.292.02v.11L0,.113Z"
                  transform="translate(0 1.67) rotate(-47.369)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3708"
                data-name="グループ 3708"
                transform="translate(20.897 16.436)"
              >
                <path
                  id="パス_3562"
                  data-name="パス 3562"
                  d="M0,0H.111L.125,2.6H.016Z"
                  transform="matrix(0.312, -0.95, 0.95, 0.312, 0, 0.105)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3709"
                data-name="グループ 3709"
                transform="translate(20.388 12.496)"
              >
                <path
                  id="パス_3563"
                  data-name="パス 3563"
                  d="M0,0,1,0V.112l-1,0Z"
                  transform="translate(0 0.177) rotate(-10.345)"
                  fill="#fb9d82"
                />
              </g>
              <g
                id="グループ_3710"
                data-name="グループ 3710"
                transform="translate(15.634 28.565)"
              >
                <path
                  id="パス_3564"
                  data-name="パス 3564"
                  d="M1384.75,1297.185l-.085-.069.616-.732,1.988-1.791.015,0,2.245-.476,2.839-1.29.047.1-2.856,1.3-2.236.474-1.96,1.765Z"
                  transform="translate(-1384.665 -1292.825)"
                  fill="#fb9d82"
                />
              </g>
            </g>
            <g
              id="グループ_3712"
              data-name="グループ 3712"
              transform="translate(0 0)"
            >
              <path
                id="パス_3565"
                data-name="パス 3565"
                d="M1174.763,1043.173c-.154.849-.384,2.1-.668,3.62-.815,4.349-1.316,7.02-1.919,9.732-.493,2.22-1.245,5.322-2.359,9.057l-4.726,23.364q10.18,0,20.343,0a169.83,169.83,0,0,1-.686-30.35c.323-4.818.835-9.385,1.475-13.679a42.164,42.164,0,0,1,2.9-3.633c1.813-2.031,2.344-2.221,2.955-3.491.473-.984.132-.823,1.079-4.157a10.226,10.226,0,0,0,.66-2.914c.014-1.456-.47-1.594-.269-2.712.233-1.3,1.021-1.883.676-2.671a1.165,1.165,0,0,0-.684-.64c-1.023-.259-2.215,1.677-2.327,1.86a5.822,5.822,0,0,0-.838,3.028l-.725,1.875q-.073-2.748-.145-5.5.086-2.977.173-5.953.2-2.277.409-4.555a1.959,1.959,0,0,0-.63-2.154,1.417,1.417,0,0,0-.858-.24,2.125,2.125,0,0,0-1.666,1.9l-.466,5.24q-.2,2.693-.41,5.385l-.243-1.131q.434-5.154.869-10.308a2.158,2.158,0,0,0,.2-1.571,1.88,1.88,0,0,0-.919-1.211,1.508,1.508,0,0,0-1.148-.125,1.626,1.626,0,0,0-.841.83,3.315,3.315,0,0,0-.431,1.828q-.651,4.3-1.3,8.592l-.548,3.187.631-5.909.974-5.027a1.3,1.3,0,0,0-2.023-1.437,1.911,1.911,0,0,0-.641,1.324c-.343,2-.614,1.1-1.449,4.891-.213.969-.345,1.71-.489,2.515-.225,1.261-.385,2.322-.494,3.092a11.436,11.436,0,0,1,.019-2.481c.108-.907.338-1.885.517-3.578.083-.78.106-1.257-.22-1.616a1.307,1.307,0,0,0-1.321-.353c-.7.248-.909,1.254-1.027,1.8-.163.756-.518,1.472-.707,2.631a14.942,14.942,0,0,0-.177,1.655c-.134,2.569-.451,5.127-.671,7.69-.447,5.228-.577,4.921-.56,6.422A27.118,27.118,0,0,0,1174.763,1043.173Z"
                transform="translate(-1165.091 -1011.146)"
                fill="#fb9d82"
              />
              <path
                id="パス_3566"
                data-name="パス 3566"
                d="M1368.1,1029.841q-.016.366-.032.732a.376.376,0,0,0,.017.133.756.756,0,0,0,.912.552c.784-.145.732-.44.732-.44l.215-.833a.388.388,0,0,0-.108-.379,1.206,1.206,0,0,0-1.062-.332,1.118,1.118,0,0,0-.556.3A.392.392,0,0,0,1368.1,1029.841Z"
                transform="translate(-1345.612 -1027.26)"
                fill="#fab7b6"
              />
              <path
                id="パス_3567"
                data-name="パス 3567"
                d="M1336.371,1012.446l.147.17,1.61.128.188-.157a.4.4,0,0,0,.118-.168,1.121,1.121,0,0,0,0-.821,1.157,1.157,0,0,0-1.28-.653,1.174,1.174,0,0,0-.754.63,1.223,1.223,0,0,0-.119.655A.385.385,0,0,0,1336.371,1012.446Z"
                transform="translate(-1317.331 -1010.919)"
                fill="#fab7b6"
              />
              <path
                id="パス_3568"
                data-name="パス 3568"
                d="M1307.559,1029.552l.717.057a.4.4,0,0,0,.314-.112l.35-.34a.394.394,0,0,0,.118-.253c.021-.268,0-.624.027-.876a.372.372,0,0,0-.035-.206c-.143-.29-.368-.338-.728-.381a1.107,1.107,0,0,0-1.129.661.373.373,0,0,0-.026.084l-.139.738a.389.389,0,0,0,.111.351l.169.165A.4.4,0,0,0,1307.559,1029.552Z"
                transform="translate(-1291.318 -1025.637)"
                fill="#fab7b6"
              />
              <path
                id="パス_3569"
                data-name="パス 3569"
                d="M1267.795,1065.818l.454.063a.616.616,0,0,0,.484-.141l.107-.091a.592.592,0,0,0,.2-.343c.03-.161.052-.349.083-.506a.584.584,0,0,0-.323-.641,1.509,1.509,0,0,0-.376-.107.947.947,0,0,0-1,.468.512.512,0,0,0-.048.138l-.094.445a.592.592,0,0,0,.161.54A.614.614,0,0,0,1267.795,1065.818Z"
                transform="translate(-1255.964 -1058.262)"
                fill="#fab7b6"
              />
              <path
                id="パス_3570"
                data-name="パス 3570"
                d="M1424.23,1138.8l-.324.455a2.161,2.161,0,0,0,.023,1.073,2.1,2.1,0,0,0,.176.428l.65-1.5Z"
                transform="translate(-1395.218 -1124.9)"
                fill="#fab7b6"
              />
            </g>
            <path
              id="パス_3571"
              data-name="パス 3571"
              d="M1525,1647.566l20.47-4.566,6.839,9.078H1526.3Z"
              transform="translate(-1485.177 -1574.283)"
              fill="#fc506e"
            />
            <path
              id="パス_3572"
              data-name="パス 3572"
              d="M1535.266,1616.811l-22.065,6.844a.924.924,0,0,1-1.156-.591l-.944-2.939a.9.9,0,0,1,.6-1.135l22.065-6.844a.924.924,0,0,1,1.156.591l.944,2.939A.9.9,0,0,1,1535.266,1616.811Z"
              transform="translate(-1472.777 -1546.745)"
              fill="#fc506e"
            />
            <path
              id="パス_3573"
              data-name="パス 3573"
              d="M1284.228,1050.232l-1.145,4.193-1.048,5.457-.126.4-.226-1.584.52-5.459-.251-1.179.471.6-.534,6.427,1.667-7.3Z"
              transform="translate(-1268.782 -1045.958)"
              fill="#fb9d82"
              opacity="0.3"
            />
          </g>
          <path
            id="パス_3621"
            data-name="パス 3621"
            d="M1147.385,1684.164l.843-5.164h22.561v5.164Z"
            transform="translate(-18421.43 -11885.174)"
            fill="#fc506e"
          />
          <path
            id="パス_3637"
            data-name="パス 3637"
            d="M1149.775,1701.576h22.961v1.225h-23.161Z"
            transform="translate(-18423.377 -11905.296)"
            fill="#fc506e"
          />
          <path
            id="パス_3638"
            data-name="パス 3638"
            d="M1534,1664.414l20.691-6.414.553.761-21.023,6.414Z"
            transform="translate(-18765.266 -11866.457)"
            fill="#fc506e"
          />
          <rect
            id="長方形_2020"
            data-name="長方形 2020"
            width="24.121"
            height="4.892"
            rx="2.446"
            transform="translate(-17274.088 -10208.457)"
            fill="#fc506e"
          />
          <path
            id="パス_3641"
            data-name="パス 3641"
            d="M1351.847,1037.328l-.663,11.822-.763-1.727.713-10.094.463-.631-.964,10.507.279.66Z"
            transform="translate(-18602 -11312.7)"
            fill="#fb9d82"
            opacity="0.3"
          />
          <path
            id="パス_3642"
            data-name="パス 3642"
            d="M1314.574,1026.62l-1.713,13.174-.587-1.109,1.948-10.97.036.4-1.649,10.519.181.206Z"
            transform="translate(-18568.074 -11303.719)"
            fill="#fb9d82"
            opacity="0.3"
          />
          <path
            id="パス_3643"
            data-name="パス 3643"
            d="M1296.6,1100.624h1.211l-.531.457Z"
            transform="translate(-18554.133 -11369.677)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3644"
            data-name="パス 3644"
            d="M1264.755,1116.377h1.211l-.531.275Z"
            transform="translate(-18525.813 -11383.718)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3645"
            data-name="パス 3645"
            d="M1333.925,1092.489l.926-.109-.076.239-.492-.057-.418.164Z"
            transform="translate(-18587.277 -11362.329)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3646"
            data-name="パス 3646"
            d="M1338.553,1047.163l.926-.109-.076.238-.492-.057-.418.164Z"
            transform="translate(-18591.393 -11321.932)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3647"
            data-name="パス 3647"
            d="M1309.1,1052.94l.926-.109-.076.238-.492-.057-.418.164Z"
            transform="translate(-18565.197 -11327.08)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3648"
            data-name="パス 3648"
            d="M1267.056,1085.73l.926-.109-.076.238-.492-.057-.418.164Z"
            transform="translate(-18527.807 -11356.306)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3649"
            data-name="パス 3649"
            d="M1370.681,1054.528l.926-.109-.076.239-.492-.057-.418.164Z"
            transform="translate(-18619.965 -11328.496)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <path
            id="パス_3650"
            data-name="パス 3650"
            d="M1369.558,1103.9l.5.2h-.5Z"
            transform="translate(-18619.02 -11372.599)"
            fill="#fb9d82"
            opacity="0.5"
          />
          <g
            id="グループ_3733"
            data-name="グループ 3733"
            transform="translate(-17270.08 -10282.448)"
            opacity="0.3"
          >
            <path
              id="パス_3661"
              data-name="パス 3661"
              d="M1189.631,984.329a.278.278,0,0,1-.2-.085l-6.15-6.376a.268.268,0,0,1,.01-.384.28.28,0,0,1,.391.01l6.15,6.376a.268.268,0,0,1-.01.384A.279.279,0,0,1,1189.631,984.329Z"
              transform="translate(-1183.205 -977.41)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3734"
            data-name="グループ 3734"
            transform="translate(-17268.01 -10287.051)"
            opacity="0.3"
          >
            <path
              id="パス_3662"
              data-name="パス 3662"
              d="M1207.589,944.829a.278.278,0,0,1-.24-.136l-5.394-9.2a.269.269,0,0,1,.1-.371.279.279,0,0,1,.378.1l5.394,9.2a.269.269,0,0,1-.1.371A.28.28,0,0,1,1207.589,944.829Z"
              transform="translate(-1201.918 -935.082)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3735"
            data-name="グループ 3735"
            transform="translate(-17243.574 -10278.53)"
            opacity="0.3"
          >
            <path
              id="パス_3663"
              data-name="パス 3663"
              d="M1423.044,1017.34a.277.277,0,0,1-.247-.148.27.27,0,0,1,.121-.366l6.687-3.342a.279.279,0,0,1,.372.119.27.27,0,0,1-.121.366l-6.687,3.342A.278.278,0,0,1,1423.044,1017.34Z"
              transform="translate(-1422.767 -1013.454)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3736"
            data-name="グループ 3736"
            transform="translate(-17244.924 -10284.806)"
            opacity="0.3"
          >
            <path
              id="パス_3664"
              data-name="パス 3664"
              d="M1410.85,965.008a.278.278,0,0,1-.175-.061.269.269,0,0,1-.039-.383l7.268-8.733a.28.28,0,0,1,.389-.038.269.269,0,0,1,.039.383l-7.268,8.733A.278.278,0,0,1,1410.85,965.008Z"
              transform="translate(-1410.573 -955.732)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3737"
            data-name="グループ 3737"
            transform="translate(-17258.838 -10291.232)"
            opacity="0.3"
          >
            <path
              id="パス_3665"
              data-name="パス 3665"
              d="M1286.623,906.057a.276.276,0,0,1-.272-.226l-1.533-8.9a.272.272,0,0,1,.226-.313.276.276,0,0,1,.319.223l1.534,8.9a.272.272,0,0,1-.226.313A.3.3,0,0,1,1286.623,906.057Z"
              transform="translate(-1284.813 -896.615)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3738"
            data-name="グループ 3738"
            transform="translate(-17255.766 -10297)"
            opacity="0.3"
          >
            <path
              id="パス_3666"
              data-name="パス 3666"
              d="M1312.858,858.092a.274.274,0,0,1-.277-.272V843.835a.277.277,0,0,1,.553,0V857.82A.274.274,0,0,1,1312.858,858.092Z"
              transform="translate(-1312.581 -843.563)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3739"
            data-name="グループ 3739"
            transform="translate(-17249.338 -10293.155)"
            opacity="0.3"
          >
            <path
              id="パス_3667"
              data-name="パス 3667"
              d="M1370.945,891.174l-.031,0a.273.273,0,0,1-.244-.3l1.325-11.7a.277.277,0,0,1,.55.06l-1.325,11.7A.275.275,0,0,1,1370.945,891.174Z"
              transform="translate(-1370.668 -878.931)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_3740"
            data-name="グループ 3740"
            transform="translate(-17247.797 -10286.593)"
            opacity="0.3"
          >
            <path
              id="パス_3668"
              data-name="パス 3668"
              d="M1384.881,945.471a.279.279,0,0,1-.11-.022.27.27,0,0,1-.144-.358l2.487-5.632a.278.278,0,0,1,.364-.141.27.27,0,0,1,.144.358l-2.487,5.632A.277.277,0,0,1,1384.881,945.471Z"
              transform="translate(-1384.604 -939.295)"
              fill="#996953"
            />
          </g>
        </g>
        <g
          id="グループ_3754"
          data-name="グループ 3754"
          transform="translate(-11964.301 9114.525) rotate(45)"
        >
          <path
            id="パス_3071"
            data-name="パス 3071"
            d="M2531.932,542.121a104.639,104.639,0,0,1-29.448-.617l-5.331-6.346a4.019,4.019,0,0,0-.943-.824c-1.063-.665-2.022-.54-3.25-.661-1.664-.165-1.217-.524-3.773-1.042-2.572-.521-3.014-.154-3.913-.814a2.255,2.255,0,0,0-1.517-.609,1.3,1.3,0,0,0-.778.342,1.561,1.561,0,0,0-.371,1.216c.116,1.552,2.25,2.795,3.873,3.234.211.057.386.093.5.114a14.033,14.033,0,0,1,3.785,1.739,8.3,8.3,0,0,1-1.275.227,19.7,19.7,0,0,1-3.531-.205,19.4,19.4,0,0,0-2-.027l-13.858.282a2.312,2.312,0,0,0-1.964.936,1.559,1.559,0,0,0-.23.988c.138.853,1.32,1.594,2.937,1.63l-3.219-.047a1.827,1.827,0,0,0-1.9,1.038,1.874,1.874,0,0,0,1.464,2.333l1.925.356a1.851,1.851,0,0,0-1.263.924,2.04,2.04,0,0,0,1.26,2.459l13.792,1.873.153.469c-1.42-.028-2.947-.11-4.564-.269-1.792-.177-3.458-.427-4.984-.713a1.311,1.311,0,0,0-1.441.189,1.438,1.438,0,0,0-.179,1.521,1.76,1.76,0,0,0,1.172.964l3.129.807,5.774,1.069,3.072-.074a35.1,35.1,0,0,0,11.557.23,34.572,34.572,0,0,0,5.444-1.263,20.392,20.392,0,0,1,2.7.015,20.775,20.775,0,0,1,4.844.941c2.777.863,10.983,3.285,22.108,6.65a28.081,28.081,0,0,0,.243-19.033Z"
            transform="translate(-19765.047 -10622.106)"
            fill="#f9b887"
          />
          <path
            id="パス_3072"
            data-name="パス 3072"
            d="M2610.429,530.634a1.309,1.309,0,0,1,.828.191l1.135.452a3.182,3.182,0,0,0-1.16-.718,1.27,1.27,0,0,0-1.032-.064.869.869,0,0,0-.344.354A1.279,1.279,0,0,1,2610.429,530.634Z"
            transform="translate(-19891.924 -10621.406)"
            fill="#dfa87f"
          />
          <path
            id="パス_3221"
            data-name="パス 3221"
            d="M0,0H2.391v.12H0Z"
            transform="translate(-17293.639 -10077.506) rotate(-85.071)"
            fill="#f9b887"
          />
          <rect
            id="長方形_1201"
            data-name="長方形 1201"
            width="2.488"
            height="0.121"
            transform="translate(-17288.123 -10077.189) rotate(-86.749)"
            fill="#f9b887"
          />
          <rect
            id="長方形_1202"
            data-name="長方形 1202"
            width="0.121"
            height="3.055"
            transform="translate(-17282.615 -10079.475) rotate(-1.386)"
            fill="#f9b887"
          />
          <rect
            id="長方形_1203"
            data-name="長方形 1203"
            width="2.785"
            height="0.121"
            transform="translate(-17291.965 -10080.794) rotate(-88.379)"
            fill="#f9b887"
          />
          <path
            id="パス_3222"
            data-name="パス 3222"
            d="M0,0,2.542,0v.12L0,.12Z"
            transform="translate(-17286.973 -10081.009) rotate(-80.775)"
            fill="#f9b887"
          />
          <path
            id="パス_3223"
            data-name="パス 3223"
            d="M0,0,2.971.005v.12L0,.12Z"
            transform="translate(-17282.178 -10080.609) rotate(-72.08)"
            fill="#f9b887"
          />
          <rect
            id="長方形_1206"
            data-name="長方形 1206"
            width="2.454"
            height="0.121"
            transform="translate(-17291.811 -10073.352) rotate(-87.875)"
            fill="#f9b887"
          />
          <rect
            id="長方形_1207"
            data-name="長方形 1207"
            width="2.217"
            height="0.121"
            transform="matrix(0.053, -0.999, 0.999, 0.053, -17287.082, -10073.017)"
            fill="#f9b887"
          />
          <rect
            id="長方形_1208"
            data-name="長方形 1208"
            width="2.836"
            height="0.121"
            transform="translate(-17282.238 -10072.102) rotate(-88.711)"
            fill="#f9b887"
          />
          <path
            id="パス_3224"
            data-name="パス 3224"
            d="M0,0,1.966,0v.12L0,.12Z"
            transform="translate(-17289.078 -10069.012) rotate(-77.589)"
            fill="#f9b887"
          />
          <path
            id="パス_3225"
            data-name="パス 3225"
            d="M0,0,2.31,0v.12L0,.12Z"
            transform="matrix(0.124, -0.992, 0.992, 0.124, -17285.059, -10068.245)"
            fill="#f9b887"
          />
          <path
            id="パス_3226"
            data-name="パス 3226"
            d="M0,0H.12l0,2.5H0Z"
            transform="translate(-17281.184 -10070.411) rotate(-12.408)"
            fill="#f9b887"
          />
          <path
            id="パス_3227"
            data-name="パス 3227"
            d="M0,0,2.827.008v.12L0,.12Z"
            transform="translate(-17273.906 -10084.206) rotate(-36.824)"
            fill="#f9b887"
          />
          <path
            id="パス_3228"
            data-name="パス 3228"
            d="M0,0,1.087,0v.12L0,.12Z"
            transform="translate(-17277.654 -10086.098) rotate(-65.338)"
            fill="#f9b887"
          />
          <path
            id="パス_3073"
            data-name="パス 3073"
            d="M2769.163,642.939l-.279-1.007-.384-2.888.974-2.3.6-3.326.118.021-.605,3.345-.964,2.278.378,2.848.278,1Z"
            transform="translate(-20031.488 -10712.102)"
            fill="#f9b887"
          />
          <path
            id="パス_3074"
            data-name="パス 3074"
            d="M2497.058,618.051l2.35-.1c.678.04,1.389.064,2.131.068.993,0,1.935-.028,2.818-.087.8.056,1.663.088,2.575.083.785,0,1.531-.034,2.233-.083l.555.1-.969.408c-.886-.094-1.853-.158-2.89-.174-.884-.013-1.717.011-2.491.06l-.684-.015q-2.4-.051-4.77-.066l-2.085-.007c-.193-.029-.4-.066-.4-.1A6.177,6.177,0,0,1,2497.058,618.051Z"
            transform="translate(-19791.264 -10698.473)"
            fill="#f9b887"
            opacity="0.5"
          />
          <path
            id="パス_3075"
            data-name="パス 3075"
            d="M2491.029,649.673l13.647.941-14.128-.47Z"
            transform="translate(-19786.965 -10726.415)"
            fill="#f9b887"
            opacity="0.5"
          />
          <path
            id="パス_3076"
            data-name="パス 3076"
            d="M2432.422,704.761c.822.482,2.035,1.2,3.5,2.065,4.2,2.494,6.779,4.027,9.362,5.678,2.114,1.352,5.045,3.3,8.495,5.863a3.323,3.323,0,0,0,1.2.306c4.532.257,10.6-9.5,7.877-13.992a5.094,5.094,0,0,0-2.435-1.965,58.235,58.235,0,0,1-21.847-8.892,46.212,46.212,0,0,1-2.668-4.326c-1.418-2.614-1.414-3.225-2.5-4.331-.841-.857-.8-.449-3.9-2.687a11.491,11.491,0,0,0-2.771-1.787c-1.5-.573-1.83-.136-2.913-.768-1.255-.733-1.565-1.757-2.513-1.709a1.281,1.281,0,0,0-.924.447c-.656.936.9,2.886,1.045,3.07a6.523,6.523,0,0,0,2.821,2.01l1.668,1.454-5.752-1.962-6.106-2.46-4.567-2.162a2.2,2.2,0,0,0-2.472-.188,1.512,1.512,0,0,0-.575.776,2.317,2.317,0,0,0,1.341,2.417l5.256,2.482,5.428,2.481-1.265-.187-10.357-4.835a2.427,2.427,0,0,0-1.553-.8,2.088,2.088,0,0,0-1.6.465,1.6,1.6,0,0,0-.566,1.114,1.766,1.766,0,0,0,.542,1.169,3.722,3.722,0,0,0,1.732,1.138l8.413,4.616,3.1,1.778-5.886-2.906-4.842-2.916a1.537,1.537,0,0,0-1.87.049,1.45,1.45,0,0,0-.387,1.448,2.123,2.123,0,0,0,1.129,1.157c1.944,1.115.9,1.042,4.521,3.344.924.587,1.642,1.006,2.422,1.461,1.221.712,2.261,1.281,3.018,1.687a12.925,12.925,0,0,1-2.565-.971c-.9-.457-1.826-1.066-3.513-1.9-.777-.383-1.263-.59-1.759-.4a1.4,1.4,0,0,0-.867,1.2c-.01.806.955,1.4,1.479,1.732,4.007,2.526,2.435,2.33,6.405,4.5a51.735,51.735,0,0,0,5,2.4,56.972,56.972,0,0,0,6.868,2.293C2427.714,703.694,2429.866,704.247,2432.422,704.761Z"
            transform="translate(-19710.059 -10751.468)"
            fill="#f9b887"
          />
          <path
            id="パス_3077"
            data-name="パス 3077"
            d="M2430.233,682.59l.865.364a.184.184,0,0,0,.052.014.837.837,0,0,0,.974-.707c.147-.85-.179-.91-.179-.91l-.892-.613a.187.187,0,0,0-.167-.025,1.318,1.318,0,0,0-.9,1.013,1.228,1.228,0,0,0,.159.786A.187.187,0,0,0,2430.233,682.59Z"
            transform="translate(-19733.672 -10753.736)"
            fill="#dfa87f"
          />
          <path
            id="パス_3078"
            data-name="パス 3078"
            d="M2403.51,704.89l.592-.214a.251.251,0,0,0,.145-.133c.128-.285.255-.619.383-.907a.25.25,0,0,0-.015-.236c-.163-.256-.326-.461-.489-.724a.252.252,0,0,0-.1-.1,1.163,1.163,0,0,0-.558-.12,1.185,1.185,0,0,0-.8,2.051,1.386,1.386,0,0,0,.7.387A.258.258,0,0,0,2403.51,704.89Z"
            transform="translate(-19709.322 -10772.892)"
            fill="#dfa87f"
          />
          <path
            id="パス_3079"
            data-name="パス 3079"
            d="M2409.817,737.808l.309-.659a.525.525,0,0,0,.021-.4l-.045-.127a.517.517,0,0,0-.181-.247c-.229-.169-.525-.445-.761-.627a.529.529,0,0,0-.411-.1,1.173,1.173,0,0,0-.847.6,1.188,1.188,0,0,0,.249,1.388.518.518,0,0,0,.1.073l.659.392a.533.533,0,0,0,.494.027l.157-.072A.529.529,0,0,0,2409.817,737.808Z"
            transform="translate(-19714.148 -10802.109)"
            fill="#dfa87f"
          />
          <path
            id="パス_3080"
            data-name="パス 3080"
            d="M2429.72,790.7l.284-.523a.467.467,0,0,0-.008-.462l-.309-.521a.479.479,0,0,0-.173-.17c-.146-.084-.276-.144-.421-.226a.465.465,0,0,0-.383-.039,1.175,1.175,0,0,0-.553.571,1.011,1.011,0,0,0,.122,1.207.449.449,0,0,0,.09.072l.53.332a.475.475,0,0,0,.444.032l.153-.068A.47.47,0,0,0,2429.72,790.7Z"
            transform="translate(-19731.951 -10848.854)"
            fill="#dfa87f"
          />
          <path
            id="パス_3081"
            data-name="パス 3081"
            d="M2551.6,676.349l.287.413a2.455,2.455,0,0,0,1.121.389,2.32,2.32,0,0,0,.356,0l-1.155-1.249c-.352-.042-.616.078-.656.239A.263.263,0,0,0,2551.6,676.349Z"
            transform="translate(-19840.625 -10749.502)"
            fill="#dfa87f"
          />
          <path
            id="パス_3084"
            data-name="パス 3084"
            d="M2739.75,841.782l-6.762,13.715a.853.853,0,0,1-1.138.387l-2.881-1.4a.843.843,0,0,1-.39-1.131l6.762-13.715a.853.853,0,0,1,1.138-.387l2.881,1.4A.843.843,0,0,1,2739.75,841.782Z"
            transform="translate(-19996.291 -10893.25)"
            fill="#ed71ad"
          />
          <path
            id="パス_3229"
            data-name="パス 3229"
            d="M2.44,0,14.526.011a2.452,2.452,0,0,1,2.442,2.456h0A2.443,2.443,0,0,1,14.53,4.92L2.444,4.911A2.455,2.455,0,0,1,2.44,0Z"
            transform="matrix(0.128, -0.992, 0.992, 0.128, -17252.197, -10064.211)"
            fill="#ed71ad"
          />
          <path
            id="パス_3230"
            data-name="パス 3230"
            d="M0,0H1.315l0,3.983H0Z"
            transform="translate(-17252.037 -10065.133) rotate(-79.853)"
            fill="#ed71ad"
          />
          <path
            id="パス_3231"
            data-name="パス 3231"
            d="M0,0H1.315l0,4.3H0Z"
            transform="translate(-17251.646 -10068.155) rotate(-82.176)"
            fill="#ed71ad"
          />
          <path
            id="パス_3232"
            data-name="パス 3232"
            d="M0,0H1.315l0,4.539H0Z"
            transform="translate(-17251.18 -10072.159) rotate(-81.245)"
            fill="#ed71ad"
          />
          <path
            id="パス_3233"
            data-name="パス 3233"
            d="M0,0H1.315l0,4.356H0Z"
            transform="matrix(0.144, -0.99, 0.99, 0.144, -17250.641, -10076.258)"
            fill="#ed71ad"
          />
          <path
            id="パス_3234"
            data-name="パス 3234"
            d="M0,0H1.315l0,4.552H0Z"
            transform="matrix(0.095, -0.995, 0.995, 0.095, -17250.26, -10078.934)"
            fill="#ed71ad"
          />
          <path
            id="パス_3346"
            data-name="パス 3346"
            d="M2.44,0,14.526.011a2.452,2.452,0,0,1,2.442,2.456h0A2.443,2.443,0,0,1,14.53,4.92L2.444,4.911A2.455,2.455,0,0,1,2.44,0Z"
            transform="matrix(0.128, -0.992, 0.992, 0.128, -17252.197, -10064.211)"
            fill="#ed71ad"
          />
          <path
            id="パス_3141"
            data-name="パス 3141"
            d="M2799.445,864.906v26.321L2757.737,868.1a3.558,3.558,0,0,1-1.458-4.724l5.812-11.457a3.6,3.6,0,0,1,4.682-1.647Z"
            transform="translate(-20020.398 -10902.758)"
            fill="#ed71ad"
          />
          <path
            id="パス_3142"
            data-name="パス 3142"
            d="M2921.353,609.46v22l-25.463-4.321a1.7,1.7,0,0,1-1.407-1.892l2.118-16.277a1.706,1.706,0,0,1,1.841-1.474Z"
            transform="translate(-20142.307 -10689.271)"
            fill="#ed71ad"
          />
          <path
            id="パス_3237"
            data-name="パス 3237"
            d="M0,0,1.317,0l.01,3.978-1.317,0Z"
            transform="translate(-17267.637 -10039.965) rotate(-61.113)"
            fill="#ed71ad"
          />
          <path
            id="パス_3238"
            data-name="パス 3238"
            d="M0,0,1.317,0l.01,4.3L.01,4.3Z"
            transform="translate(-17266.289 -10042.703) rotate(-63.435)"
            fill="#ed71ad"
          />
          <path
            id="パス_3239"
            data-name="パス 3239"
            d="M0,0,1.317,0l.011,4.534-1.317,0Z"
            transform="translate(-17264.553 -10046.345) rotate(-62.503)"
            fill="#ed71ad"
          />
          <path
            id="パス_3240"
            data-name="パス 3240"
            d="M0,0,1.317,0l.011,4.351-1.317,0Z"
            transform="translate(-17262.719 -10050.054) rotate(-62.962)"
            fill="#ed71ad"
          />
          <path
            id="パス_3241"
            data-name="パス 3241"
            d="M0,0,1.316,0l.01,4.547-1.316,0Z"
            transform="translate(-17261.492 -10052.466) rotate(-65.799)"
            fill="#ed71ad"
          />
        </g>
        <g
          id="グループ_3753"
          data-name="グループ 3753"
          transform="translate(2573.013 -15642.614) rotate(-45)"
        >
          <g
            id="グループ_1970"
            data-name="グループ 1970"
            transform="translate(-17970.676 -10267.655)"
          >
            <path
              id="パス_3061"
              data-name="パス 3061"
              d="M107.418,608.574a104.531,104.531,0,0,0,28.564,7.148L142.812,611a4.033,4.033,0,0,1,1.129-.547c1.2-.362,2.093.011,3.311.217,1.649.279,1.313-.186,3.916-.013,2.619.174,2.948.644,3.99.244a2.26,2.26,0,0,1,1.625-.189,1.3,1.3,0,0,1,.659.534,1.56,1.56,0,0,1,.034,1.27c-.525,1.466-2.914,2.1-4.6,2.1-.219,0-.4-.011-.51-.02a14.065,14.065,0,0,0-4.113.682,8.286,8.286,0,0,0,1.169.554,19.675,19.675,0,0,0,3.46.73,19.384,19.384,0,0,1,1.937.5l13.289,3.915a2.3,2.3,0,0,1,1.645,1.419,1.558,1.558,0,0,1-.041,1.014c-.36.787-1.7,1.19-3.267.8l3.117.8a1.82,1.82,0,0,1,1.556,1.5,1.881,1.881,0,0,1-2.033,1.865l-1.951-.162a1.842,1.842,0,0,1,.972,1.223,2.047,2.047,0,0,1-1.87,2.04l-13.8-1.82-.272.412c1.377.347,2.871.669,4.473.94,1.776.3,3.449.5,5,.623a1.308,1.308,0,0,1,1.339.561,1.439,1.439,0,0,1-.231,1.514,1.767,1.767,0,0,1-1.387.621l-3.232-.044-5.853-.488-2.943-.879a35.07,35.07,0,0,1-11.206-2.816,34.467,34.467,0,0,1-4.914-2.65,20.372,20.372,0,0,0-2.61-.7,20.793,20.793,0,0,0-4.922-.366c-2.908.1-11.466.281-23.09.6a28.116,28.116,0,0,1,4.83-18.419Z"
              transform="translate(-102.478 -608.574)"
              fill="#fa9172"
            />
            <path
              id="パス_3062"
              data-name="パス 3062"
              d="M540.255,625.761a1.311,1.311,0,0,0-.85-.034l-1.215.138a3.2,3.2,0,0,1,1.31-.388,1.27,1.27,0,0,1,1.013.21.866.866,0,0,1,.238.432A1.274,1.274,0,0,0,540.255,625.761Z"
              transform="translate(-485.787 -623.444)"
              fill="#fab7b6"
            />
            <g
              id="グループ_1955"
              data-name="グループ 1955"
              transform="translate(62.53 15.93)"
            >
              <path
                id="パス_3209"
                data-name="パス 3209"
                d="M0,0,2.391,0v.12L0,.12Z"
                transform="translate(0 2.352) rotate(-79.594)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1956"
              data-name="グループ 1956"
              transform="translate(57.129 14.701)"
            >
              <path
                id="パス_3210"
                data-name="パス 3210"
                d="M0,0,2.488,0v.12L0,.12Z"
                transform="translate(0 2.433) rotate(-77.931)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1957"
              data-name="グループ 1957"
              transform="translate(51.542 13.472)"
            >
              <path
                id="パス_3211"
                data-name="パス 3211"
                d="M0,0,3.056.005v.12L0,.12Z"
                transform="matrix(0.288, -0.958, 0.958, 0.288, 0, 2.925)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1958"
              data-name="グループ 1958"
              transform="translate(61.793 11.959)"
            >
              <path
                id="パス_3212"
                data-name="パス 3212"
                d="M0,0,2.785,0v.12L0,.12Z"
                transform="translate(0 2.705) rotate(-76.271)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1959"
              data-name="グループ 1959"
              transform="translate(57.033 10.632)"
            >
              <path
                id="パス_3213"
                data-name="パス 3213"
                d="M0,0,2.542,0v.12L0,.12Z"
                transform="matrix(0.107, -0.994, 0.994, 0.107, 0, 2.527)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1960"
              data-name="グループ 1960"
              transform="translate(52.166 9.331)"
            >
              <rect
                id="長方形_1191"
                data-name="長方形 1191"
                width="0.121"
                height="2.97"
                transform="translate(0 0.005) rotate(-2.598)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1961"
              data-name="グループ 1961"
              transform="translate(59.664 19.413)"
            >
              <path
                id="パス_3214"
                data-name="パス 3214"
                d="M0,0,2.454,0v.12L0,.12Z"
                transform="matrix(0.228, -0.974, 0.974, 0.228, 0, 2.389)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1962"
              data-name="グループ 1962"
              transform="translate(55.014 18.717)"
            >
              <path
                id="パス_3215"
                data-name="パス 3215"
                d="M0,0,2.218,0v.12L0,.12Z"
                transform="matrix(0.213, -0.977, 0.977, 0.213, 0, 2.166)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1963"
              data-name="グループ 1963"
              transform="translate(50.099 17.737)"
            >
              <path
                id="パス_3216"
                data-name="パス 3216"
                d="M0,0,2.837,0v.12L0,.12Z"
                transform="matrix(0.243, -0.97, 0.97, 0.243, 0, 2.751)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1964"
              data-name="グループ 1964"
              transform="translate(55.87 23.328)"
            >
              <rect
                id="長方形_1195"
                data-name="長方形 1195"
                width="1.965"
                height="0.121"
                transform="translate(0 1.963) rotate(-87.095)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1965"
              data-name="グループ 1965"
              transform="translate(51.79 22.675)"
            >
              <path
                id="パス_3217"
                data-name="パス 3217"
                d="M0,0,2.311,0v.12L0,.12Z"
                transform="matrix(0.143, -0.99, 0.99, 0.143, 0, 2.287)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1966"
              data-name="グループ 1966"
              transform="translate(47.472 21.815)"
            >
              <path
                id="パス_3218"
                data-name="パス 3218"
                d="M0,0,2.5.006v.12L0,.12Z"
                transform="matrix(0.466, -0.885, 0.885, 0.466, 0, 2.212)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1967"
              data-name="グループ 1967"
              transform="translate(43.569 4.43)"
            >
              <path
                id="パス_3219"
                data-name="パス 3219"
                d="M0,0H.12L.128,2.822H.008Z"
                transform="matrix(0.79, -0.613, 0.613, 0.79, 0, 0.073)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1968"
              data-name="グループ 1968"
              transform="translate(49.225 4.745)"
            >
              <path
                id="パス_3220"
                data-name="パス 3220"
                d="M0,0H.12V1.086H0Z"
                transform="translate(0 0.019) rotate(-9.284)"
                fill="#996953"
              />
            </g>
            <g
              id="グループ_1969"
              data-name="グループ 1969"
              transform="translate(30.127 8.683)"
            >
              <path
                id="パス_3063"
                data-name="パス 3063"
                d="M353.076,690.637l-.1-.061.537-.9,1.119-2.64-.325-2.457v-.007l.307-3.372.12.011-.306,3.365.327,2.476-.006.015-1.129,2.662Z"
                transform="translate(-352.972 -681.203)"
                fill="#996953"
              />
            </g>
          </g>
          <path
            id="パス_3064"
            data-name="パス 3064"
            d="M310.511,653.743a11.786,11.786,0,0,0,6.072-1.1c3.282-1.633,2.876-3.641,6.855-6.456,2.444-1.728,6.048-3.411,8.5-3.236a15.773,15.773,0,0,0,5.193-.4l-6.834,4.175,4.872-.378,4.334,1.083-18.954,8.563-10.041-.182Z"
            transform="translate(-18256.166 -10906.143)"
            fill="#995944"
            opacity="0.3"
          />
          <g
            id="グループ_1971"
            data-name="グループ 1971"
            transform="translate(-17965.922 -10264.496)"
          >
            <path
              id="パス_3065"
              data-name="パス 3065"
              d="M173.225,662.22c-.9.326-2.219.808-3.818,1.4-4.585,1.695-7.4,2.736-10.243,3.894-2.326.948-5.565,2.338-9.424,4.233a3.327,3.327,0,0,1-1.235.085c-4.5-.565-8.692-11.26-5.19-15.181a5.107,5.107,0,0,1,2.753-1.493,58.338,58.338,0,0,0,23.108-4.8,46.41,46.41,0,0,0,3.414-3.773c1.872-2.315,1.979-2.917,3.248-3.808.983-.691.872-.3,4.326-1.939a11.523,11.523,0,0,1,3.051-1.257c1.584-.292,1.825.2,3.005-.23,1.368-.494,1.86-1.445,2.783-1.227a1.276,1.276,0,0,1,.827.607c.475,1.039-1.411,2.676-1.589,2.83a6.541,6.541,0,0,1-3.142,1.468l-1.906,1.129,6.015-.891,6.454-1.317,4.886-1.3a2.2,2.2,0,0,1,2.466.261,1.51,1.51,0,0,1,.423.867,2.324,2.324,0,0,1-1.76,2.135l-5.622,1.492-5.791,1.46,1.278.044,11.068-2.886a2.433,2.433,0,0,1,1.674-.511,2.085,2.085,0,0,1,1.492.747,1.6,1.6,0,0,1,.353,1.2,1.772,1.772,0,0,1-.746,1.052,3.735,3.735,0,0,1-1.911.807l-9.116,3.02-3.37,1.19,6.319-1.8,5.294-1.993a1.536,1.536,0,0,1,1.83.386,1.448,1.448,0,0,1,.116,1.494,2.128,2.128,0,0,1-1.322.934c-2.116.746-1.078.862-5.057,2.472-1.015.411-1.8.693-2.648,1-1.331.48-2.457.852-3.276,1.114a12.939,12.939,0,0,0,2.7-.492c.967-.287,1.991-.718,3.8-1.231.834-.236,1.35-.352,1.8-.073a1.392,1.392,0,0,1,.633,1.338c-.138.795-1.2,1.206-1.771,1.436-4.4,1.761-2.82,1.852-7.121,3.273a51.821,51.821,0,0,1-5.355,1.462,57.062,57.062,0,0,1-7.173,1.016C178.049,662.019,175.832,662.175,173.225,662.22Z"
              transform="translate(-142.012 -637.72)"
              fill="#fb9d82"
            />
            <path
              id="パス_3066"
              data-name="パス 3066"
              d="M636.533,663.055l-1,.22a.848.848,0,0,1-.857-.872c.01-.862.342-.862.342-.862l1.075-.481a1.331,1.331,0,0,1,.786,1.2A1.248,1.248,0,0,1,636.533,663.055Z"
              transform="translate(-575.42 -657.945)"
              fill="#fab7b6"
            />
            <path
              id="パス_3067"
              data-name="パス 3067"
              d="M657.576,689.6l-.7-.406-.287-1.1.409-.692a1.3,1.3,0,0,1,1-.22,1.246,1.246,0,0,1,.953,1.238,1.275,1.275,0,0,1-.545.922A1.4,1.4,0,0,1,657.576,689.6Z"
              transform="translate(-594.705 -680.925)"
              fill="#fab7b6"
            />
            <path
              id="パス_3068"
              data-name="パス 3068"
              d="M644.842,721.048l-.287-1.1.5-.723.943-.354a1.156,1.156,0,0,1,.408,2.16l-1.039.386Z"
              transform="translate(-584.115 -708.847)"
              fill="#fab7b6"
            />
            <path
              id="パス_3069"
              data-name="パス 3069"
              d="M618.112,769.993l-.316-.97.389-.641.789-.317a1.128,1.128,0,0,1,.875.71,1.011,1.011,0,0,1-.388,1.2l-.87.345Z"
              transform="translate(-560.574 -752.158)"
              fill="#fab7b6"
            />
            <path
              id="パス_3070"
              data-name="パス 3070"
              d="M520.075,635.473l-.435.431a2.445,2.445,0,0,1-1.173.18,2.35,2.35,0,0,1-.5-.105L519.48,635Z"
              transform="translate(-472.751 -635.002)"
              fill="#fab7b6"
            />
          </g>
          <path
            id="パス_3134"
            data-name="パス 3134"
            d="M274.449,654l4.209.837a17.11,17.11,0,0,0-1.564,3.706,17.34,17.34,0,0,0-.516,7.067l-4.8.861Z"
            transform="translate(-18222.09 -10916.226)"
            fill="#995944"
            opacity="0.3"
          />
          <path
            id="パス_3135"
            data-name="パス 3135"
            d="M317,749.674,320.969,748a24.06,24.06,0,0,0,.145,4.186,24.461,24.461,0,0,0,2.5,8.127l-4.089,1.554Z"
            transform="translate(-18261.875 -10998.988)"
            fill="#995944"
            opacity="0.3"
          />
          <path
            id="パス_3140"
            data-name="パス 3140"
            d="M0,619.726V597.61a78.447,78.447,0,0,1,12.388.12,79.544,79.544,0,0,1,25.5,6.695,18.34,18.34,0,0,0-2.045,5.26,20.4,20.4,0,0,0,2.405,14.106A166.228,166.228,0,0,1,12.147,622C7.865,621.361,3.812,620.587,0,619.726Z"
            transform="translate(-17983 -10866.41)"
            fill="#f9c824"
          />
          <path
            id="パス_3145"
            data-name="パス 3145"
            d="M0,725.053a64.337,64.337,0,0,0,35.433.534l.184,2.942,2.106,6.655L.106,732.944Z"
            transform="translate(-17983 -10978.784)"
            fill="#f8bc2c"
            opacity="0.5"
          />
          <path
            id="パス_3146"
            data-name="パス 3146"
            d="M0,775.321q.06-14.585.12-29.169A114.289,114.289,0,0,0,41.373,744a27.562,27.562,0,0,0-.361,4.782,27.885,27.885,0,0,0,1.563,8.846,59.228,59.228,0,0,1-17.559,12.313A59.74,59.74,0,0,1,0,775.321Z"
            transform="translate(-17983 -10995.466)"
            fill="#f9c824"
          />
          <path
            id="パス_3151"
            data-name="パス 3151"
            d="M188.513,621.292l4.775,1.485c-.617.206-5.093,1.785-6.69,6.328a9.947,9.947,0,0,0-.243,5.619,9.074,9.074,0,0,1,2.158-13.432Z"
            transform="translate(-18145.125 -10887.428)"
            fill="#f8bc2c"
            opacity="0.5"
          />
          <path
            id="パス_3152"
            data-name="パス 3152"
            d="M31.076,768.066A16.882,16.882,0,0,0,26.733,778.1c-.537,7.894,5.02,13.069,5.773,13.748a17.811,17.811,0,0,1-2.526-14.02,17.4,17.4,0,0,1,6.374-9.4Z"
            transform="translate(-18006.486 -11016.655)"
            fill="#f8bc2c"
            opacity="0.5"
          />
          <g
            id="グループ_2019"
            data-name="グループ 2019"
            transform="translate(-17899.086 -10252.224)"
            opacity="0.3"
          >
            <path
              id="パス_3180"
              data-name="パス 3180"
              d="M705.611,740.279a.3.3,0,0,1-.078-.01l-7.587-2.013a.3.3,0,1,1,.155-.578l7.587,2.013a.3.3,0,0,1-.077.588Z"
              transform="translate(-697.723 -737.668)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_2020"
            data-name="グループ 2020"
            transform="translate(-17899.258 -10257.546)"
            opacity="0.3"
          >
            <path
              id="パス_3181"
              data-name="パス 3181"
              d="M696.59,694.56a.3.3,0,0,1-.023-.6l10.382-.815a.3.3,0,1,1,.047.6l-10.382.815Z"
              transform="translate(-696.289 -693.147)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_2021"
            data-name="グループ 2021"
            transform="translate(-17900.92 -10270.203)"
            opacity="0.3"
          >
            <path
              id="パス_3182"
              data-name="パス 3182"
              d="M682.769,595.188a.3.3,0,0,1-.241-.12.3.3,0,0,1,.061-.418l9.847-7.322a.3.3,0,0,1,.421.06.3.3,0,0,1-.061.418l-9.847,7.322A.3.3,0,0,1,682.769,595.188Z"
              transform="translate(-682.468 -587.269)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_2022"
            data-name="グループ 2022"
            transform="translate(-17904.332 -10271.091)"
            opacity="0.3"
          >
            <path
              id="パス_3183"
              data-name="パス 3183"
              d="M654.393,587.1a.3.3,0,0,1-.185-.063.3.3,0,0,1-.051-.42l5.247-6.664a.3.3,0,0,1,.422-.051.3.3,0,0,1,.051.42l-5.247,6.664A.3.3,0,0,1,654.393,587.1Z"
              transform="translate(-654.093 -579.841)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_2023"
            data-name="グループ 2023"
            transform="translate(-17908.469 -10271.091)"
            opacity="0.3"
          >
            <path
              id="パス_3184"
              data-name="パス 3184"
              d="M620,586.154a.293.293,0,0,1-.055-.005.3.3,0,0,1-.241-.348l1.058-5.716a.3.3,0,0,1,.591.108l-1.058,5.716A.3.3,0,0,1,620,586.154Z"
              transform="translate(-619.696 -579.84)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_2024"
            data-name="グループ 2024"
            transform="translate(-17919.111 -10274.421)"
            opacity="0.3"
          >
            <path
              id="パス_3185"
              data-name="パス 3185"
              d="M531.946,558.379a.3.3,0,0,1-.3-.277l-.426-5.8a.3.3,0,0,1,.6-.043l.427,5.8a.3.3,0,0,1-.278.32Z"
              transform="translate(-531.219 -551.985)"
              fill="#996953"
            />
          </g>
          <g
            id="グループ_2025"
            data-name="グループ 2025"
            transform="translate(-17925.115 -10273.01)"
            opacity="0.3"
          >
            <path
              id="パス_3186"
              data-name="パス 3186"
              d="M484.924,569.161a.3.3,0,0,1-.247-.129l-3.33-4.777a.3.3,0,0,1,.076-.416.3.3,0,0,1,.418.075l3.33,4.777a.3.3,0,0,1-.076.416A.3.3,0,0,1,484.924,569.161Z"
              transform="translate(-481.293 -563.786)"
              fill="#996953"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
