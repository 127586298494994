import { useAtom, atom } from 'jotai';
import { ResponsePagination } from 'infra/api/type';
import { ResponseEvaluationPeriodGroups } from 'context/evaluation_period/api/useFetchEvaluationPeriodGroups';
import { useEffect, useState } from 'react';
import { format, isAfter, isBefore } from 'date-fns';
import { ResponseEvaluationPeriod } from 'context/evaluation_period/api/typeResponse';

// uncontrolledなコンポーネントにattachするため、初期値を空文字としている
export const selectedEvaluationPeriodIdAtom = atom('');
export const evaluationPeriodListAtom = atom<any[]>([]);
export const selectedEvaluationPeriodSettingAtom =
  atom<ResponseEvaluationPeriodGroups>({} as ResponseEvaluationPeriodGroups);

export const evaluationPeriodSettingState = (
  evaluationPeriods?: ResponsePagination<ResponseEvaluationPeriodGroups>
) => {
  const [
    completedselectedEvaluationPeriodSetting,
    setCompletedselectedEvaluationPeriodSetting,
  ] = useState('not_completed');
  const [evaluationPeriodList] = useAtom(evaluationPeriodListAtom);

  const [selectedEvaluationPeriodId, setSelectedEvaluationPeriodId] = useAtom(
    selectedEvaluationPeriodIdAtom
  );

  const [selectedEvaluationPeriodSetting, setSelectedEvaluationPeriodSetting] =
    useAtom(selectedEvaluationPeriodSettingAtom);

  useEffect(() => {
    if (
      evaluationPeriods !== undefined &&
      evaluationPeriods.results.length !== 0 &&
      selectedEvaluationPeriodId === ''
    ) {
      setSelectedEvaluationPeriodId(
        evaluationPeriods.results[
          evaluationPeriods.results.length - 1
        ].id.toString()
      );
    }
  }, [evaluationPeriods, selectedEvaluationPeriodId]);

  const currentEvaluationPeriod = evaluationPeriods?.results.find(
    (evaluationPeriod) =>
      evaluationPeriod.id.toString() === selectedEvaluationPeriodId
  );

  useEffect(() => {
    if (currentEvaluationPeriod) {
      setSelectedEvaluationPeriodSetting(currentEvaluationPeriod);
      setCompletedselectedEvaluationPeriodSetting('completed');
    } else {
      setCompletedselectedEvaluationPeriodSetting('empty');
    }
  }, [currentEvaluationPeriod]);

  const allEvaluationPeriods = evaluationPeriods?.results.map(
    (evaluationPeriod) => evaluationPeriod
  );

  return {
    selectedEvaluationPeriodId,
    evaluationPeriodList,
    selectedEvaluationPeriodSetting,
    setSelectedEvaluationPeriodId,
    allEvaluationPeriods,
    setSelectedEvaluationPeriodSetting,
    completedselectedEvaluationPeriodSetting,
  };
};

export const convertDateToString = (date: string) =>
  format(new Date(date), 'yyyy/MM/dd');

export const isBetweenDate = (before: string, after: string) => {
  const now = new Date();
  const beforeDate = new Date(before);
  const afterDate = new Date(after);

  return isBefore(now, afterDate) && isAfter(now, beforeDate);
};

export const getEvaluationPeriodName = (
  evaluationPeriods: ResponseEvaluationPeriod[]
) => {
  const startedAt = format(new Date(evaluationPeriods[0].startedAt), 'yyyy/MM');
  const endedAt = format(
    new Date(evaluationPeriods[evaluationPeriods.length - 1].endedAt),
    'yyyy/MM'
  );
  return `${startedAt}〜${endedAt}`;
};
