/**
 * 評価情報 スタッフ最終評価完了関連ルーティング
 */
import { ObjectiveRecordListInitializing } from 'context/objective/components/ObjectiveRecordList';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const ObjectiveRecordsUserTypeRoute: RouteType & {
  staffPath: string;
  managerPath: string;
} = {
  path: '/objective-records/:userType',
  staffPath: '/objective-records/staff/',
  managerPath: '/objective-records/manager/',
  element: <ObjectiveRecordListInitializing />,
  do_it: () => (
    <Route
      path={ObjectiveRecordsUserTypeRoute.path}
      element={ObjectiveRecordsUserTypeRoute.element}
    />
  ),
};

export const routes = () => <>{ObjectiveRecordsUserTypeRoute.do_it()}</>;
