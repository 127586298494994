export const NavigateBeforeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="グループ_22" data-name="グループ 22" transform="translate(-18 -46)">
      <circle
        id="楕円形_4"
        data-name="楕円形 4"
        cx="16"
        cy="16"
        r="16"
        transform="translate(18 46)"
        fill="#fff"
      />
      <g id="arrow" transform="translate(38 69) rotate(180)">
        <g id="ic_fluent_chevron_right_24_filled">
          <path
            id="_-Color"
            data-name="🎨-Color"
            d="M8.26,4.275a.974.974,0,0,0,0,1.326l5.594,5.9L8.26,17.4a.974.974,0,0,0,0,1.326.857.857,0,0,0,1.257,0l6.222-6.563a.974.974,0,0,0,0-1.326L9.517,4.275A.857.857,0,0,0,8.26,4.275Z"
            transform="translate(-8 -4)"
            fill="#048cf2"
          />
        </g>
      </g>
    </g>
  </svg>
);
