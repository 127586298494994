import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { RequestEvaluationPeriodGroups } from './typeRequest';

function getEvaluationPeriod(evaluationPeriod: any, evaluationCycle: number) {
  const addTimeStringAsStart = (date: string) => `${date}T00:00:00+09:00`;
  const addTimeStringAsEnd = (date: string) => `${date}T23:59:59+09:00`;

  return {
    id: evaluationPeriod.id,
    startedAt: new Date(addTimeStringAsStart(evaluationPeriod.startedAt)),
    endedAt: new Date(addTimeStringAsEnd(evaluationPeriod.endedAt)),
    objectiveCreationStartedAt: new Date(
      addTimeStringAsStart(evaluationPeriod.objectiveCreationStartedAt)
    ),
    objectiveCreationEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.objectiveCreationEndedAt)
    ),
    intermediateMyselfEvaluationStartedAt: new Date(
      addTimeStringAsStart(
        evaluationPeriod.intermediateMyselfEvaluationStartedAt
      )
    ),
    intermediateMyselfEvaluationEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.intermediateMyselfEvaluationEndedAt)
    ),
    intermediateBossEvaluationStartedAt: new Date(
      addTimeStringAsStart(evaluationPeriod.intermediateBossEvaluationStartedAt)
    ),
    intermediateBossEvaluationEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.intermediateBossEvaluationEndedAt)
    ),
    intermediateInterviewStartedAt: new Date(
      addTimeStringAsStart(evaluationPeriod.intermediateInterviewStartedAt)
    ),
    intermediateInterviewEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.intermediateInterviewEndedAt)
    ),

    finalMyselfEvaluationStartedAt: new Date(
      addTimeStringAsStart(evaluationPeriod.finalMyselfEvaluationStartedAt)
    ),
    finalMyselfEvaluationEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.finalMyselfEvaluationEndedAt)
    ),
    finalBossEvaluationStartedAt: new Date(
      addTimeStringAsStart(evaluationPeriod.finalBossEvaluationStartedAt)
    ),
    finalBossEvaluationEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.finalBossEvaluationEndedAt)
    ),
    finalInterviewStartedAt: new Date(
      addTimeStringAsStart(evaluationPeriod.finalInterviewStartedAt)
    ),
    finalInterviewEndedAt: new Date(
      addTimeStringAsEnd(evaluationPeriod.finalInterviewEndedAt)
    ),

    headquartersEvaluationConsiderationStartedAt: new Date(
      addTimeStringAsStart(
        evaluationPeriod.headquartersEvaluationConsiderationStartedAt
      )
    ),
    headquartersEvaluationConsiderationEndedAt: new Date(
      addTimeStringAsEnd(
        evaluationPeriod.headquartersEvaluationConsiderationEndedAt
      )
    ),
    headquartersEvaluationFixedDate:
      evaluationPeriod.headquartersEvaluationFixedDate,

    interviewAfterHeadquartersEvaluationStartedAt: new Date(
      addTimeStringAsStart(
        evaluationPeriod.interviewAfterHeadquartersEvaluationStartedAt
      )
    ),
    interviewAfterHeadquartersEvaluationEndedAt: new Date(
      addTimeStringAsEnd(
        evaluationPeriod.interviewAfterHeadquartersEvaluationEndedAt
      )
    ),
    headquartersEvaluationNotifiedAt: `${evaluationPeriod.headquartersEvaluationNotifiedDate}T${evaluationPeriod.headquartersEvaluationNotifiedTime}+09:00`,
    goalSettingDayCount: 0,
  };
}

export const updateEvaluationPeriod = async (
  id: string,
  evaluationPeriodGroup: Omit<
    RequestEvaluationPeriodGroups,
    | 'headquartersEvaluationNotifiedAt'
    | 'endedAt'
    | 'goalSettingDayCount'
    | 'evaluationCycle'
  > & {
    headquartersEvaluationNotifiedDate: string;
    headquartersEvaluationNotifiedTime: string;
    evaluationCycle: number;
  }
) => {
  const requestData = {
    evaluationCycle: evaluationPeriodGroup.evaluationCycle,
    evaluationPeriods: evaluationPeriodGroup.evaluationPeriods.map(
      (evaluationPeriod) =>
        getEvaluationPeriod(
          evaluationPeriod,
          evaluationPeriodGroup.evaluationCycle
        )
    ),
  };

  const response = await apiClient.patch(
    MY_TENANT_URL(`evaluation_periods/evaluation_period_groups/${id}/`),
    requestData
  );
  return response;
};
