/**
 * 運用設定 等級関連ルーティング
 */
import { GradeList } from 'context/corporation/grade/components';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const GradeListRoute: RouteType = {
  path: '/operation-setting/grades',
  element: <GradeList />,
  do_it: () => (
    <Route path={GradeListRoute.path} element={GradeListRoute.element} />
  ),
};

export const routes = () => <>{GradeListRoute.do_it()}</>;
