import {
  MandalatSquareSettingRules,
  ResponseMandalatMaster,
} from 'context/mandalat/api/typeResponse';
import { convertSubTypeAndBasicThinkingMasterId2Label } from 'context/mandalat/types/mandalatSquareSettingRules';
import { PropsWithChildren } from 'react';
import { IndexBox } from '../IndexBox';

export const RuleList = (props: {
  latestMandalatmaster: ResponseMandalatMaster;
  formValue: MandalatSquareSettingRules;
}) => {
  const { latestMandalatmaster, formValue } = props;

  return (
    <TabPanel>
      {formValue.map((rule) => {
        let themeDiv;

        switch (rule.type) {
          case '必須':
            themeDiv = (
              <div
                className="bg-main-blue rounded-full text-grayscale-100 text-label text-center px-[6px] py-[2px] min-w-[62px]
            "
              >
                必須テーマ
              </div>
            );
            break;
          case '選択テーマ':
            themeDiv = (
              <div className="border-1 border-main-blue rounded-full text-main-blue text-label text-center px-[6px] py-[2px] min-w-[62px]">
                選択テーマ
              </div>
            );
            break;
          default:
            themeDiv = (
              <div className="bg-grayscale-400 rounded-full text-grayscale-700 text-label text-center px-[6px] py-[2px] min-w-[62px]">
                {rule.type}
              </div>
            );
            break;
        }

        return (
          <div className="flex gap-[14px] items-center" key={rule.index}>
            <IndexBox>{rule.index}</IndexBox>
            {themeDiv}
            <p className="text-ui ml-[10px]">
              {convertSubTypeAndBasicThinkingMasterId2Label(
                rule,
                latestMandalatmaster
              )}
            </p>
          </div>
        );
      })}
    </TabPanel>
  );
};

const TabPanel = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return <div className="flex flex-col gap-[32px]">{children}</div>;
};
