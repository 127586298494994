import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren, useEffect } from 'react';
import { ResponseMandalatMaster } from 'context/mandalat/api/typeResponse';
import {
  PaginatedCorporationOfResponsePaginatedCorporation,
  ResponsePaginatedCorporation,
} from 'context/corporation/corporation/api/types';
import { RefetchFunction } from 'axios-hooks';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { GradeOfResponsePaginatedCorporation } from 'context/corporation/grade/api/types';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { EditMandalatThemeModal } from 'context/mandalat/components/Modal/EditMandalatThemeModal';
import { EditMandalatThemeState } from 'context/mandalat/components/Modal/state';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { useLocation } from 'react-router-dom';
import { RuleList } from './RuleList/Index';
import { GradeList } from './GradeList';
import { MandalatTiles } from './MandalatTiles';

export const ThemeSetting = (props: {
  corporation: PaginatedCorporationOfResponsePaginatedCorporation;
  latestMandalatmaster?: ResponseMandalatMaster;
  corporationRefetch: RefetchFunction<any, ResponsePaginatedCorporation>;
}) => {
  const location = useLocation();
  const editMandalatThemeState = EditMandalatThemeState();
  const { corporation, latestMandalatmaster, corporationRefetch } = props;

  const gradeList: GradeOfResponsePaginatedCorporation[] =
    corporation.positions.flatMap((position) => position.grades);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('showModal')) {
      editMandalatThemeState.ui.setModalOpen();
    }
  }, [location.search]);

  if (!corporation.positions || corporation.positions.length === 0) {
    // 職位が登録されていない場合
    return (
      <Wrapper>
        <WarningMessageBox
          message="職種・職位・等級 未登録"
          detailsMessage="職種・職位・等級が登録されていません。職種・職位・等級を1つ以上登録してください。"
        />
      </Wrapper>
    );
  }

  if (gradeList.length === 0) {
    // 職種が登録されていない場合
    return (
      <Wrapper>
        <WarningMessageBox
          message="等級 未登録"
          detailsMessage="等級が登録されていません。等級を1つ以上登録してください。"
        />
      </Wrapper>
    );
  }

  if (
    latestMandalatmaster === undefined ||
    !latestMandalatmaster.basicThinkingMasters ||
    latestMandalatmaster.basicThinkingMasters.length === 0
  ) {
    // マンダラートマスタが登録されていない場合
    return (
      <Wrapper>
        <WarningMessageBox
          message="マンダラマスタ 未登録"
          detailsMessage="マンダラマスタが登録されていません。マンダラマスタデータ（CSV）をアップロードしてください。"
        />
      </Wrapper>
    );
  }

  return (
    <div className="relative min-h-[600px]">
      <div className="relative pb-[16px] mb-[30px] mt-[60px] border-b-2 border-solid border-grayscale-500">
        <p className="font-bold">3. テーマ設定</p>
        <div className="absolute right-0 top-1/2 -translate-y-[28px] transform">
          <BaseButton
            className="!w-[100px] tracking-short-text p-button-small text-ui"
            onClick={() => {
              editMandalatThemeState.ui.setModalOpen();
            }}
          >
            編集
          </BaseButton>
        </div>
      </div>

      <Contents isLoading={editMandalatThemeState.ui.isLoading}>
        <div className="flex gap-[80px]">
          <GradeList
            gradeList={gradeList}
            currentGradeId={editMandalatThemeState.ui.currentGradeId}
            handleChangeGradeId={(gradeId) => {
              editMandalatThemeState.ui.setCurrentGradeId(gradeId);
            }}
          />
          <MandalatTiles
            gradeName={
              getGradeFromCorporation(
                editMandalatThemeState.ui.currentGradeId,
                corporation
              )?.name
            }
            latestMandalatmaster={latestMandalatmaster}
            formValue={editMandalatThemeState.ui.formValue}
          />
          <RuleList
            latestMandalatmaster={latestMandalatmaster}
            formValue={editMandalatThemeState.ui.formValue}
          />
        </div>
      </Contents>

      <Divider className="mt-[50px] mb-[40px]" />

      <EditMandalatThemeModal state={editMandalatThemeState} />
    </div>
  );
};

const Wrapper = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <div>
      <p className="font-bold pb-[16px] mb-[30px] mt-[40px] border-b-2 border-solid border-grayscale-500">
        3. テーマ設定
      </p>
      {children}
    </div>
  );
};

const getGradeFromCorporation = (
  gradeId: number,
  corporation: PaginatedCorporationOfResponsePaginatedCorporation
) => {
  return corporation.positions.flatMap((p) =>
    p.grades.filter((g) => g.id === gradeId)
  )[0];
};
