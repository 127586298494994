import { useEffect, useState } from 'react';
import { GradeOfResponsePaginatedCorporation } from 'context/corporation/grade/api/types';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { PaginatedCorporationOfResponsePaginatedCorporation } from 'context/corporation/corporation/api/types';
import { useLatestMandalatMaster } from 'context/mandalat/api/useLatestMandalatMaster';
import { MandalatSquareSettingRules } from 'context/mandalat/api/typeResponse';
import { useAtom } from 'jotai';

import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { updateGrade } from 'context/corporation/grade/api/updateGrade';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { useNavigate, useLocation } from 'react-router-dom';

export const EditMandalatThemeState = () => {
  const [{ data: corporation }, corporationRefetch] = useFetchCorporation();
  const [{ data: latestMandalatmaster }, LatestMandalatRefetch] =
    useLatestMandalatMaster();

  const gradeList: GradeOfResponsePaginatedCorporation[] =
    corporation?.positions.flatMap((position) => position.grades) ?? [];

  const [currentGradeId, setCurrentGradeId] = useState<number>(0);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState<MandalatSquareSettingRules>(
    []
  );
  const [formValue, setFormValue] = useState<MandalatSquareSettingRules>([]);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();
  const location = useLocation();
  const navigator = useNavigate();

  const setModalClose = () => {
    setIsOpen(false);
  };

  const setModalOpen = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('showModal');
    navigator(location.pathname, { replace: true });
    setIsOpen(true);
  };

  const getGradeFromCorporation = (
    gradeId: number,
    corporationData: PaginatedCorporationOfResponsePaginatedCorporation
  ) => {
    return corporationData?.positions.flatMap((p) =>
      p.grades.filter((g) => g.id === gradeId)
    )[0];
  };

  useEffect(() => {
    if (corporation && corporation.positions.length > 0) {
      const firstGradeId = corporation.positions[0].grades[0].id;
      setCurrentGradeId(firstGradeId);
    }
  }, [corporation]);

  useEffect(() => {
    if (currentGradeId === 0) return;

    const currentGradeMandalatSquareSettingRule = Array.from(
      { length: 8 },
      (_, i) => i
    ).map((index) => {
      const grade = getGradeFromCorporation(currentGradeId, corporation!);
      const mandalatSquareSettingRule =
        grade.mandalatSquareSettingRules === null
          ? null
          : grade.mandalatSquareSettingRules.filter(
              (rule) => rule.index === index + 1
            )[0];
      return {
        index: mandalatSquareSettingRule?.index ?? index + 1,
        type: mandalatSquareSettingRule?.type ?? '未指定',
        subType: mandalatSquareSettingRule?.subType ?? '',
        basicThinkingMasterId:
          mandalatSquareSettingRule?.basicThinkingMasterId ?? 0,
      };
    });
    setDefaultValue([...currentGradeMandalatSquareSettingRule]);
    setFormValue(
      currentGradeMandalatSquareSettingRule.map((rule) => ({ ...rule }))
    );
  }, [currentGradeId]);

  useEffect(() => {
    if (corporation && latestMandalatmaster) {
      setIsLoading(false);
    }
  }, [corporation, latestMandalatmaster]);

  const submit = async () => {
    await updateGrade(currentGradeId, {
      mandalatSquareSettingRules: [...formValue].map((rule) => {
        // formValueのbasicThinkingMasterIdに0があったらnullに変換
        return {
          ...rule,
          basicThinkingMasterId:
            rule.basicThinkingMasterId === 0
              ? null
              : rule.basicThinkingMasterId,
        };
      }),
    })
      .then((response) => {
        displayMessage({
          text: `${response.data.name}のテーマ設定が完了しました。`,
          isInterruption: true,
        });
        corporationRefetch();
        setModalClose();
      })
      .catch((error) => {
        displayNonFieldApiError(error);
      });
  };

  return {
    ui: {
      isOpen,
      setModalClose,
      setModalOpen,
      setCurrentGradeId,
      currentGradeId,
      formValue,
      setFormValue,
      defaultValue,
      setDefaultValue,
      isLoading,
    },
    data: {
      gradeList,
      corporation,
      latestMandalatmaster,
    },
    method: {
      getGradeFromCorporation,
      submit,
      LatestMandalatRefetch,
    },
  };
};
