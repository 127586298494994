export const ExclamationBlueIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g
      id="グループ_2930"
      data-name="グループ 2930"
      transform="translate(-145 -213)"
    >
      <circle
        id="楕円形_391"
        data-name="楕円形 391"
        cx="6"
        cy="6"
        r="6"
        transform="translate(145 213)"
        fill="#048cf2"
      />
      <g
        id="グループ_2929"
        data-name="グループ 2929"
        transform="translate(6743.75 -8011.25)"
      >
        <path
          id="パス_3523"
          data-name="パス 3523"
          d="M10,9a.75.75,0,0,1,.75.75v3.5a.75.75,0,1,1-1.5,0V9.75A.75.75,0,0,1,10,9Z"
          transform="translate(-6602.75 8220.25)"
          fill="#fff"
        />
        <path
          id="パス_3524"
          data-name="パス 3524"
          d="M10,8A1,1,0,1,0,9,7,1,1,0,0,0,10,8Z"
          transform="translate(-6602.75 8220.25)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
