import { Types } from 'infra/components/UI/Table/Pagination/Pagination';
import { MouseEventHandler } from 'react';

type CheckboxGroupProps = {
  name: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  className?: string;
  id?: string;
  value: string;
  pagerState: Types.PagerState;
};

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  const defaultClassName = `
    absolute whitespace-nowrap w-[1px] h-[1px] border-0 p-0 -m-[1px] cursor-pointer appearance-none
    before:content-[''] before:w-[21px] before:h-[21px] before:rounded before:border-1 before:border-grayscale-500 before:block
    before:absolute before:top-[50%] before:-translate-y-[16px] before:left-0 before:transform
    checked:before:bg-main-blue checked:before:border-main-blue
    after:content-[''] after:absolute after:border-b-2 after:border-l-2 after:opacity-0 after:h-[6px] after:w-[10px] after:transform after:border-grayscale-100
    after:-rotate-45 after:-top-[10px] after:left-[5px] after:block
    checked:after:opacity-100
  `;

  const checked = props.pagerState.isIncludeSearchValue(
    props.name,
    props.value
  );

  return (
    <div>
      <div className="relative inline-flex items-center cursor-pointer w-[21px]">
        <input
          type="checkbox"
          onClick={props.onClick}
          className={`${defaultClassName} ${props.className}`}
          id={props.id}
          checked={checked}
        />
      </div>
    </div>
  );
};

export const CheckBoxFilter = ({
  data,
  searchKey,
  pagerState,
  className,
}: {
  data: { label: string; value: any }[];
  searchKey: string;
  pagerState: Types.PagerState;
  className?: string;
}) => {
  return (
    <div className="grid grid-cols-2 gap-y-[20px]">
      {data?.map((item) => {
        const labelKey = item.label + item.value;

        return (
          <div className="flex base-hover w-[240px] text-base">
            <CheckboxGroup
              name={searchKey}
              pagerState={pagerState}
              className={className}
              onClick={(e: any) => {
                if (e.target.checked) {
                  pagerState.setSearchParams((prev: any) => {
                    const items = prev.get(searchKey)?.split(',') || [];
                    items.push(item.value);

                    prev.set(searchKey, items.filter(Boolean).join(','));
                    return prev;
                  });
                } else {
                  pagerState.setSearchParams((prev: any) => {
                    const items = prev.get(searchKey)?.split(',');
                    const removedSearchValue = items?.filter((i: any) => {
                      return i.toString() !== item.value.toString();
                    });
                    prev.set(searchKey, removedSearchValue?.join(','));

                    return prev;
                  });
                }
              }}
              id={labelKey}
              value={item.value}
            />
            <label
              className="ml-[5px] cursor-pointer text-grayscale-700"
              htmlFor={labelKey}
            >
              {item.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};
