import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';

export const useLogout = () => {
  const [data, execute] = useAxios(
    {
      url: MY_TENANT_URL('users/auth/logout/'),
      method: 'post',
      ...createOption(null),
      withCredentials: true,
    },
    { manual: true }
  );

  return [data, execute] as const;
};
