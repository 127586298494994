import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'infra/components/UI/Headings/ArrowRight';

/**
 * ページのタイトル部分に利用されるコンポーネント
 * 各画面ではこれを使い回す
 */
export const PageTitle = (
  props: PropsWithChildren<{ title: string; breadcrumbs: [string, string][] }>
) => (
  <div className="flex justify-between items-center">
    <h1 className="text-page-heading">{props.title}</h1>
    <div className="flex items-center">
      {props.breadcrumbs.map(([title, url]) => [
        <Link
          key={`link${title}`}
          to={url}
          className="text-meta text-main-blue"
        >
          {title}
        </Link>,
        <span key={`title${title}`} className="px-2 text-meta">
          <ArrowRight />
        </span>,
      ])}
      <span className="text-meta text-grayscale-700">{props.title}</span>
    </div>
  </div>
);
