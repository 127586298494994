import { HTMLAttributes, PropsWithChildren, useRef, useState } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { COLORS } from 'tailwind.config';
import { ErrorMessage } from './ErrorMessage';

type Props = PropsWithChildren<{
  useForm: UseFormReturn<any>;
  name: string;
  isErrorFiled: boolean;
  label?: string;
  helperText?: string;
  showHelperText?: boolean;
  placeholder?: string;
  key?: string;
}> &
  HTMLAttributes<HTMLDivElement>;

export const CalendarInput = ({
  className,
  useForm,
  name,
  isErrorFiled,
  label,
  helperText,
  showHelperText = false,
  placeholder,
  key = '',
}: Props) => {
  return (
    <div className={className}>
      {label && (
        <p className="text-grayscale-700 text-meta pb-[20px]">{label}</p>
      )}

      <input
        type="date"
        {...useForm.register(name)}
        className={`${className} py-[9px] px-[12px] border rounded w-full border-grayscale-500 text-ui`}
        placeholder={placeholder}
        key={key}
        style={{ borderColor: isErrorFiled ? COLORS.MAIN_RED : '' }}
      />

      {isErrorFiled && (
        <ErrorMessage
          message={(
            useForm.formState.errors[name] as FieldError
          )?.message?.toString()}
        />
      )}

      {showHelperText && helperText && (
        <p className="text-grayscale-700 text-meta mt-[4px]">{helperText}</p>
      )}
    </div>
  );
};
