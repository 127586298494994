export const BoxCheckIcon = () => {
  return (
    <svg
      id="コンポーネント_46_4"
      data-name="コンポーネント 46 – 4"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="グループ_1815"
        data-name="グループ 1815"
        transform="translate(-330 -321)"
      >
        <g
          id="長方形_747"
          data-name="長方形 747"
          transform="translate(330 321)"
          fill="#fff"
          stroke="#fa8f47"
          strokeWidth="1"
        >
          <rect width="16" height="16" stroke="none" />
          <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
        </g>
        <path
          id="Icon_feather-check"
          data-name="Icon feather-check"
          d="M12,9,7.875,13,6,11.182"
          transform="translate(329 318)"
          fill="none"
          stroke="#fa8f47"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
