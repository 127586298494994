import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Dialog, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { BaseFormField } from 'context/evaluation_period/components/EvaluationPeriodSetting/_components/BaseFormField';
import { ResponseEvaluationPeriodGroups } from 'context/evaluation_period/api/useFetchEvaluationPeriodGroups';

import { BaseDateInput } from 'infra/components/UI/Forms/BaseDateInput';
import { TildeIcon } from 'infra/components/UI/Icons/TildeIcon';
import tailwindConfig from 'tailwind.config';

import { useAtom } from 'jotai';
import { Radio } from 'infra/components/UI/Forms/Radio';
import { Select } from 'infra/components/UI/Forms/Select';
import { TimeMaster, EvaluationCycleOptions } from '../const';

import { ModalState } from './state.library';
import * as form from '../form';

export const EditEvaluationPeriodModal = (
  props: PropsWithChildren<{
    // eslint-disable-next-line react/no-unused-prop-types
    selectedEvaluationPeriodSetting: ResponseEvaluationPeriodGroups;
    render: (openModal: () => void, formAtom: any) => ReactNode;
    onSave: () => void;
  }>
) => {
  const modalState = ModalState();
  const formState = form.Basic.initForm();
  const [useFormInstance] = useAtom(form.Basic.useFormAtom);
  const evaluationCycleState = form.Behavior.manageEvaluationCycle(formState);
  const handleSubmit = form.integration.submit(formState, props.onSave);

  // const errorMessagesMock = [
  //   '評価スケジュール（上期）の目標設定期間が重複しています。',
  //   '評価スケジュール（下期）の中間上司評価期間が重複しています。',
  // ];

  if (!useFormInstance) {
    return <span />;
  }

  return (
    <>
      {props.render(modalState.openHandler, useFormInstance)}
      <Dialog
        open={modalState.active}
        onClose={modalState.closeHandler}
        maxWidth={false}
      >
        <BaseModal
          activeButton={
            <BaseButton
              type="submit"
              className="w-btn-small text-btn-large p-button-small tracking-short-text"
              onClick={handleSubmit}
            >
              保存
            </BaseButton>
          }
          closeHandler={modalState.closeHandler}
        >
          <div className="pb-30px">
            <h1 className="text-section-heading font-bold mb-[40px]">
              評価スケジュール設定
            </h1>

            {/* <ul className="flex flex-col gap-[10px] mb-[40px] rounded bg-soft-red p-[10px]">
              {errorMessagesMock.map((message) => (
                <li
                  key={message.toString()}
                  className="flex items-center gap-[10px]"
                >
                  <InformationIcon />
                  <span className="text-ui text-main-red font-bold border-b border-main-red">
                    {message}
                  </span>
                </li>
              ))}
            </ul> */}

            <form>
              <BaseFormField label="評価サイクル">
                <div className="flex items-center space-x-[34px]">
                  {EvaluationCycleOptions.map((option) => {
                    const labelFontSize = // @ts-ignore
                      tailwindConfig.theme!.fontSize!.ui as string;
                    const labelColor = // @ts-ignore
                      tailwindConfig.theme!.colors!.grayscale[700];

                    return (
                      <div className="flex items-center">
                        <Radio
                          name="evaluationCycle"
                          useForm={useFormInstance}
                          value={Number(option.value)}
                          isAsCheckbox
                          onClick={(e) => {
                            evaluationCycleState.onChangeListener(e);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: `${labelFontSize}`,
                              color: `${labelColor}`,
                            }}
                          >
                            {option.label}
                          </Typography>
                        </Radio>
                      </div>
                    );
                  })}
                </div>
              </BaseFormField>

              {Number(evaluationCycleState?.evaluationCycle) === 3 && (
                <EvaluationPeriodContentAsCycle3Month />
              )}
              {Number(evaluationCycleState?.evaluationCycle) === 6 && (
                <EvaluationPeriodContentAsCycle6Month />
              )}
              {Number(evaluationCycleState?.evaluationCycle) === 12 && (
                <EvaluationPeriodContentAsCycle1Year />
              )}
            </form>
          </div>
        </BaseModal>
      </Dialog>
    </>
  );
};

const FieldContent = (props: { before: JSX.Element; after: JSX.Element }) => (
  <div className="flex items-center">
    {props.before}
    <div className="mx-[14px]">
      <TildeIcon />
    </div>
    {props.after}
  </div>
);

const FieldMyselfAndBossContent = (props: {
  myself: {
    before: JSX.Element;
    after: JSX.Element;
  };
  boss: {
    before: JSX.Element;
    after: JSX.Element;
  };
}) => (
  <div>
    <p className="text-grayscale-800 text-ui mb-[6px]">自己評価</p>
    <FieldContent before={props.myself.before} after={props.myself.after} />
    <p className="text-grayscale-800 text-ui mb-[6px] mt-[20px]">上司評価</p>
    <FieldContent before={props.boss.before} after={props.boss.after} />
  </div>
);

const EvaluationPeriodContentAsCycle3Month = () => {
  return (
    <>
      <>
        <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
          評価スケジュール（第1四半期）
        </h2>
        <EvaluationPeriodContents periodIndex={0} />
      </>

      <>
        <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
          評価スケジュール（第2四半期）
        </h2>
        <EvaluationPeriodContents periodIndex={1} />
      </>

      <>
        <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
          評価スケジュール（第3四半期）
        </h2>
        <EvaluationPeriodContents periodIndex={2} />
      </>

      <>
        <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
          評価スケジュール（第4四半期）
        </h2>
        <EvaluationPeriodContents periodIndex={3} />
      </>
    </>
  );
};

const EvaluationPeriodContentAsCycle6Month = () => {
  return (
    <>
      <>
        <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
          評価スケジュール（上期）
        </h2>
        <EvaluationPeriodContents periodIndex={0} />
      </>

      <>
        <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
          評価スケジュール（下期）
        </h2>
        <EvaluationPeriodContents periodIndex={1} />
      </>
    </>
  );
};

const EvaluationPeriodContentAsCycle1Year = () => {
  return (
    <>
      <h2 className="text-item-heading font-bold mb-[20px] mt-[60px]">
        評価スケジュール（年間）
      </h2>
      <EvaluationPeriodContents periodIndex={0} />
    </>
  );
};

const EvaluationPeriodContents = (props: { periodIndex: number }) => {
  const { periodIndex } = props;
  const [formUseAtom] = useAtom(form.Basic.useFormAtom);
  const useFormInstance = formUseAtom!;

  return (
    <>
      <div className="flex items-center space-x-[30px] mb-[20px]">
        <BaseFormField label="開始日">
          <Controller
            name={`evaluationPeriods.${periodIndex}.startedAt`}
            control={useFormInstance.control}
            render={({ field, fieldState }) => (
              <BaseDateInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                error={!!fieldState.error}
              />
            )}
          />
        </BaseFormField>
        <BaseFormField label="終了日">
          <Controller
            name={`evaluationPeriods.${periodIndex}.endedAt`}
            control={useFormInstance.control}
            render={({ field, fieldState }) => (
              <BaseDateInput {...field} error={!!fieldState.error} />
            )}
          />
        </BaseFormField>
      </div>

      <div className="w-[280px] mb-[16px]">
        <BaseButton
          className="text-label bg-grayscale-300 text-grayscale-900 p-button-small w-full"
          onClick={() =>
            form.Behavior.reCalcEvaluationPeriodByStartedAt(
              formUseAtom!,
              periodIndex
            )
          }
        >
          開始日をもとに自動で初期値を入力する
        </BaseButton>
      </div>

      <BaseFormField label="目標設定期間">
        <FieldContent
          before={
            <Controller
              name={`evaluationPeriods.${periodIndex}.objectiveCreationStartedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
          after={
            <Controller
              name={`evaluationPeriods.${periodIndex}.objectiveCreationEndedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
        />
      </BaseFormField>
      <BaseFormField label="中間評価実施期間">
        <FieldMyselfAndBossContent
          myself={{
            before: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.intermediateMyselfEvaluationStartedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
            after: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.intermediateMyselfEvaluationEndedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
          }}
          boss={{
            before: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.intermediateBossEvaluationStartedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
            after: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.intermediateBossEvaluationEndedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
          }}
        />
      </BaseFormField>
      <BaseFormField
        label="中間面談実施期間（任意）"
        tooltip="中間上司評価の際、必要に応じて被評価者と面談を設定する期間です。"
      >
        <FieldContent
          before={
            <Controller
              name={`evaluationPeriods.${periodIndex}.intermediateInterviewStartedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
          after={
            <Controller
              name={`evaluationPeriods.${periodIndex}.intermediateInterviewEndedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
        />
      </BaseFormField>
      <BaseFormField label="最終評価実施期間">
        <FieldMyselfAndBossContent
          myself={{
            before: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.finalMyselfEvaluationStartedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
            after: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.finalMyselfEvaluationEndedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
          }}
          boss={{
            before: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.finalBossEvaluationStartedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
            after: (
              <Controller
                name={`evaluationPeriods.${periodIndex}.finalBossEvaluationEndedAt`}
                control={useFormInstance.control}
                render={({ field, fieldState }) => (
                  <BaseDateInput
                    {...field}
                    errormessage={fieldState?.error?.message}
                  />
                )}
              />
            ),
          }}
        />
      </BaseFormField>
      <BaseFormField
        label="最終評価前面談実施期間（任意）"
        tooltip="最終上司評価の際、必要に応じて被評価者と面談を設定する期間です。"
      >
        <FieldContent
          before={
            <Controller
              name={`evaluationPeriods.${periodIndex}.finalInterviewStartedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
          after={
            <Controller
              name={`evaluationPeriods.${periodIndex}.finalInterviewEndedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
        />
      </BaseFormField>
      <BaseFormField
        label="最終評価検討期間"
        tooltip="現場の評価完了後、評価会議等で最終評価を確定するまでの期間です。"
      >
        <FieldContent
          before={
            <Controller
              name={`evaluationPeriods.${periodIndex}.headquartersEvaluationConsiderationStartedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
          after={
            <Controller
              name={`evaluationPeriods.${periodIndex}.headquartersEvaluationConsiderationEndedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
        />
      </BaseFormField>
      <BaseFormField
        label="最終評価確定日"
        tooltip="全員の最終評価を確定する期日です。確定後の評価変更はできません。"
      >
        <Controller
          name={`evaluationPeriods.${periodIndex}.headquartersEvaluationFixedDate`}
          control={useFormInstance.control}
          render={({ field, fieldState }) => (
            <BaseDateInput
              {...field}
              errormessage={fieldState?.error?.message}
            />
          )}
        />
      </BaseFormField>
      <BaseFormField
        label="最終評価スタッフ通知日時"
        tooltip="設定した日時に各スタッフのアプリ画面に評価結果が配信されます。"
      >
        <div className="flex items-center">
          <Controller
            name={`evaluationPeriods.${periodIndex}.headquartersEvaluationNotifiedDate`}
            control={useFormInstance.control}
            render={({ field, fieldState }) => (
              <BaseDateInput
                {...field}
                errormessage={fieldState?.error?.message}
              />
            )}
          />
          <p className="text-ui text-grayscale-700 mx-[14px]">通知時刻</p>
          <Select
            name={`evaluationPeriods.${periodIndex}.headquartersEvaluationNotifiedTime`}
            useForm={useFormInstance}
            menuItems={TimeMaster.map((time) => {
              return {
                label: time,
                value: time,
              };
            })}
            isErrorFiled={
              !!useFormInstance.formState.errors.evaluationPeriods?.[
                periodIndex
              ]?.headquartersEvaluationNotifiedTime
            }
            className="w-[164px]"
          />
        </div>
      </BaseFormField>
      <BaseFormField
        label="次期目標設定面談実施期間"
        tooltip="前期の振り返りと次期目標設定に関する面談期間です。"
      >
        <FieldContent
          before={
            <Controller
              name={`evaluationPeriods.${periodIndex}.interviewAfterHeadquartersEvaluationStartedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
          after={
            <Controller
              name={`evaluationPeriods.${periodIndex}.interviewAfterHeadquartersEvaluationEndedAt`}
              control={useFormInstance.control}
              render={({ field, fieldState }) => (
                <BaseDateInput
                  {...field}
                  errormessage={fieldState?.error?.message}
                />
              )}
            />
          }
        />
      </BaseFormField>
    </>
  );
};
