import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
} from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { PropsWithChildren, useState, useEffect } from 'react';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { z } from 'zod';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import {
  PagedSuccessResponse,
  fetchCorporationAdmin,
  SuccessResponse,
} from 'context/users/corporation_admin/api/corporationAdmin.get';
import { patchCorporationAdmin } from 'context/users/corporation_admin/api/corporationAdmin.patch';
import { postCorporationAdmin } from 'context/users/corporation_admin/api/corporationAdmin.post';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { COLORS } from 'tailwind.config';
import { CorporationAdminFilterArea } from 'context/users/corporation_admin/components/CorporationAdminList/CorporationAdminFilterArea';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';
import { CorporationAdminEditDialog } from './CorporationAdminEditDialog';

export const CorporationAdminListContext = () => {
  const pagerState = useFilterAreaContext();

  useEffect(() => {
    pagerState.doitPaging(1);
  }, [pagerState.searchParams]);

  const [editingCorporationAdminId, setEditingCorporationAdminId] = useState<
    string | null
  >(null);

  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);

  const [, displayMessage] = useAtom(displayMessageAtom);

  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);

  const onSave = async (value: CorporationAdminFormWithPassword) => {
    startLoading('update_corporation_admin');
    try {
      if (editingCorporationAdminId) {
        await patchCorporationAdmin({
          id: editingCorporationAdminId,
          firstName: value?.firstName ?? '',
          lastName: value?.lastName ?? '',
          departmentName: value?.departmentName ?? '',
          email: value?.email ?? '',
          dateOfBirth: value?.dateOfBirth ?? '',
        });

        pagerState.doitPaging(pagerState.pager.page);
        displayMessage({
          text: '担当者情報を更新しました',
          isInterruption: false,
        });
      } else {
        value as CorporationAdminFormWithPassword;
        await postCorporationAdmin({
          firstName: value?.firstName ?? '',
          lastName: value?.lastName ?? '',
          departmentName: value?.departmentName ?? '',
          email: value?.email ?? '',
          id: '',
          password: value?.password ?? '',
          dateOfBirth: value?.dateOfBirth ?? '',
        });

        pagerState.doitPaging(pagerState.pager.page);
        displayMessage({
          text: '担当者情報を追加しました',
          isInterruption: false,
        });
      }
    } finally {
      stopLoading('update_corporation_admin');
      setIsOpenSaveModal(false);
    }
  };

  return (
    <div>
      <PageTitle title="担当者設定" breadcrumbs={[['ホーム', '/']]} />

      <CorporationAdminFilterArea
        setIsOpenSaveModal={setIsOpenSaveModal}
        setEditingCorporationAdminId={setEditingCorporationAdminId}
      />

      <Contents isLoading={pagerState?.pager?.isLoading}>
        <div
          className="flex pt-[20px] justify-between items-center"
          data-keyword="table-area"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-grayscale-500">
              <TableRow>
                <TableHeaderCell orderValue="last_name" pagerState={pagerState}>
                  名前
                </TableHeaderCell>

                <TableHeaderCell orderValue="email" pagerState={pagerState}>
                  メールアドレス
                </TableHeaderCell>

                <TableHeaderCell
                  orderValue="department_name"
                  pagerState={pagerState}
                >
                  部署名
                </TableHeaderCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {pagerState?.pager?.items.map(
                (corporationAdmin: SuccessResponse, index: number) => {
                  return (
                    <Row
                      key={corporationAdmin.id}
                      onClick={() => {
                        setEditingCorporationAdminId(corporationAdmin.id);
                        setIsOpenSaveModal(true);
                      }}
                      corporationAdmin={corporationAdmin}
                      onEdit={() => {
                        setEditingCorporationAdminId(corporationAdmin.id);
                        setIsOpenSaveModal(true);
                      }}
                      index={index}
                      isStripedBackgroundColor
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Contents>

      {isOpenSaveModal && (
        <CorporationAdminEditDialog
          editingCorporationAdminId={editingCorporationAdminId}
          onClose={() => setIsOpenSaveModal(false)}
          onSave={onSave}
        />
      )}

      <div className="pt-[40px]">
        <Pagination pagerState={pagerState} />
      </div>
    </div>
  );
};

const Row = (
  props: PropsWithChildren<{
    onClick: () => void;
    corporationAdmin: any;
    onEdit: () => void;
    index: number;
    isStripedBackgroundColor: boolean;
  }>
) => {
  const stripedBackgroundColor =
    props.index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;
  return (
    <TableRow
      style={{
        backgroundColor: props.isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell
        className="base-hover"
        onClick={props.onClick}
        component="th"
        scope="row"
      >
        <div className="flex items-center gap-[12px]">
          <Avatar
            sx={{ width: 32, height: 32 }}
            alt="Remy Sharp"
            src={props.corporationAdmin ?? ''}
          />
          <span className="text-ui">
            {props.corporationAdmin.lastName} {props.corporationAdmin.firstName}
          </span>
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={props.onClick}>
        <span className="text-ui">{props.corporationAdmin.email}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={props.onClick}>
        <span className="text-ui">{props.corporationAdmin.departmentName}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell>
        <div className="flex justify-end">
          <MoreMenu
            render={[
              {
                label: <span>編集</span>,
                onClick: () => {
                  props.onEdit();
                },
              },
            ]}
          />
        </div>
      </BaseTableBodyCell>
    </TableRow>
  );
};

export const schema = z.object({
  lastName: z.string().min(1, '性を入力してください。').optional(),
  firstName: z.string().min(1, '名を入力してください。').optional(),
  departmentName: z.string().optional().nullable(),
  email: z.string().email().optional().nullable(),
  dateOfBirth: z.string().optional().nullable(),
});

export const passwordScheme = z.object({
  password: z
    .string({ required_error: 'パスワードを入力してください。' })
    .min(8, 'パスワードは8文字以上で入力してください')
    .regex(
      /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/i,
      'パスワードは半角英数字混合で入力してください'
    ),
});

export type CorporationAdminForm = z.infer<typeof schema>;
export type CorporationAdminFormWithPassword = CorporationAdminForm &
  z.infer<typeof passwordScheme>;

export const CorporationAdminList = () => {
  const pagerState = usePagerState<PagedSuccessResponse>(
    (limit: number, offset: number, searchParams: URLSearchParams) => {
      return fetchCorporationAdmin(limit, offset, searchParams);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState?.pager?.isLoading}>
        <CorporationAdminListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
