import { z } from 'zod';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { BoxProps, Dialog } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import {
  EvaluationMeasure,
  EvaluationMeasureType,
  getName,
  getTypeName,
  getTypeUnit,
} from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { usePostEvaluationMeasures } from 'context/corporation/evaluation_measures/api/post.evaluationMeasures';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useAtom } from 'jotai';
import { Select } from 'infra/components/UI/Forms/Select';
import { TextField } from 'infra/components/UI/Forms/TextField';

export const EditEvaluationMeasureModal = (
  props: PropsWithChildren<{
    render: (openModal: () => void) => ReactNode;
    evaluationMeasureId: number;
    slug: keyof Omit<EvaluationMeasure, 'id'>;
    measure: number[];
    type: EvaluationMeasureType;
    onChanged: () => void;
  }>
) => {
  const [active, setActive] = useState(false);

  const [request] = usePostEvaluationMeasures();
  const [, displayMessage] = useAtom(displayMessageAtom);

  const useFormInstance = useForm<EvaluationMeasureFormData>({
    resolver: zodResolver(evaluationMeasureScheme),
    defaultValues: {
      type: props.type,
      measure: props.measure,
    },
  });

  const openHandler = () => {
    setActive(true);
  };
  const closeHandler = () => {
    setActive(false);
  };

  const name = getName(props.slug);

  const submit: SubmitHandler<EvaluationMeasureFormData> = async (data) => {
    const requestData: Partial<EvaluationMeasure> = {
      id: props.evaluationMeasureId,
    };
    requestData[props.slug] = {
      measure: data.measure,
      type: data.type,
    };

    if (
      data.type === 'ratio' &&
      data.measure.some((v) => {
        return v < 0;
      })
    ) {
      displayMessage({
        text: '達成率は、0以上の値を設定してください',
        isInterruption: true,
      });
      return;
    }

    await request(requestData).then(() => {
      displayMessage({
        text: '更新に成功しました。',
        isInterruption: false,
      });
      return props.onChanged();
    });
  };

  return (
    <>
      {props.render(openHandler)}
      <Dialog open={active} onClose={closeHandler} maxWidth={false}>
        <BaseModal
          activeButton={
            <BaseButton
              type="submit"
              className="w-btn-small text-btn-confirm p-button-small"
              onClick={useFormInstance.handleSubmit(submit)}
            >
              保存
            </BaseButton>
          }
          closeHandler={closeHandler}
        >
          <div className="pt-[10px] px-[20px] pb-[40px]">
            <h3 className="text-section-heading font-bold mb-[60px]">
              {props.slug === 'finalEvaluation'
                ? '最終評価尺度設定'
                : `MBO項目評価尺度設定(${name})`}
            </h3>
            <form>
              <div>
                <p className="text-base font-bold mb-[20px]">評価方法</p>
                <Select
                  useForm={useFormInstance}
                  name="type"
                  menuItems={[
                    {
                      label: '評価点数',
                      value: 'actual',
                    },
                    {
                      label: '達成率',
                      value: 'ratio',
                    },
                  ]}
                  isErrorFiled={!!useFormInstance.formState.errors.type}
                />
              </div>

              <div>
                <p className="text-base font-bold mb-[20px] mt-[30px]">
                  評価点数1点
                </p>

                <FieldBox>
                  <div className="mr-[8px]">{`${getTypeName(
                    useFormInstance.watch('type')
                  )}が`}</div>
                  <div className="mr-[8px]">
                    <TextField
                      useForm={useFormInstance}
                      name="measure.0"
                      isErrorFiled={
                        !!useFormInstance.formState.errors.measure?.[0]
                      }
                      helperText={
                        useFormInstance.formState.errors.measure?.[0]?.message
                      }
                      showHelperText={
                        !!useFormInstance.formState.errors.measure?.[0]?.message
                      }
                      className="w-[160px]"
                      asNumeric
                    />
                  </div>
                  {`${getTypeUnit(useFormInstance.watch('type'))}未満`}
                </FieldBox>
              </div>

              {props.measure.map((threshold, index) => {
                if (index > 0) {
                  return (
                    <>
                      <p className="text-base font-bold mb-[20px] mt-[30px]">{`評価点数${
                        index + 1
                      }点`}</p>
                      <FieldBox>
                        <div className="mr-[8px]">
                          {`${getTypeName(useFormInstance.watch('type'))}が`}
                          <b>
                            {useFormInstance.watch(`measure.${index - 1}`)}
                            {getTypeUnit(useFormInstance.watch('type'))}
                          </b>
                          以上
                        </div>
                        <div className="mr-[8px]">かつ</div>
                        <div className="mr-[8px]">
                          <TextField
                            useForm={useFormInstance}
                            name={`measure.${index}`}
                            isErrorFiled={
                              !!useFormInstance.formState.errors.measure?.[0]
                            }
                            helperText={
                              useFormInstance.formState.errors.measure?.[0]
                                ?.message
                            }
                            showHelperText={
                              !!useFormInstance.formState.errors.measure?.[0]
                                ?.message
                            }
                            className="w-[160px]"
                            asNumeric
                          />
                        </div>
                        {`${getTypeUnit(useFormInstance.watch('type'))}未満`}
                      </FieldBox>
                    </>
                  );
                }
                return null;
              })}
              <p className="text-base font-bold mb-[20px] mt-[30px]">{`評価点数${
                props.measure.length + 1
              }点`}</p>
              <FieldBox>
                {getTypeName(useFormInstance.watch('type'))}が
                <b>
                  {useFormInstance.watch(
                    `measure.${props.measure.length - 1}`
                  ) + getTypeUnit(useFormInstance.watch('type'))}
                </b>
                以上
              </FieldBox>
            </form>
          </div>
        </BaseModal>
      </Dialog>
    </>
  );
};

const FieldBox = (props: BoxProps) => {
  return (
    <div className="flex items-center text-grayscale-700 text-ui">
      {props.children}
    </div>
  );
};

const evaluationMeasureScheme = z.object({
  measure: z
    .number({
      required_error: 'しきい値を入力してください',
    })
    .array()
    .min(5, 'すべてのしきい値を入力してください'),
  type: z.custom<EvaluationMeasureType>(
    (value) => value === 'actual' || value === 'ratio'
  ),
});

type EvaluationMeasureFormData = z.infer<typeof evaluationMeasureScheme>;
