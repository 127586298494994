export const PeopleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <g
        id="コンポーネント_46_2"
        data-name="コンポーネント 46 – 2"
        opacity="0.5"
      >
        <g
          id="_-Product-Icons"
          data-name="🔍-Product-Icons"
          transform="translate(-2 -3)"
        >
          <g id="ic_fluent_people_team_24_regular">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M14.754,10a1.75,1.75,0,0,1,1.75,1.75V16.5a4.5,4.5,0,1,1-9,0V11.75A1.75,1.75,0,0,1,9.252,10Zm0,1.5h-5.5a.25.25,0,0,0-.25.25V16.5a3,3,0,1,0,6,0V11.75A.25.25,0,0,0,14.754,11.5ZM3.75,10H7.131a2.737,2.737,0,0,0-.618,1.5H3.75a.25.25,0,0,0-.25.25V15a2.5,2.5,0,0,0,3.082,2.433,5.407,5.407,0,0,0,.453,1.432A3.954,3.954,0,0,1,6,19a4,4,0,0,1-4-4V11.75A1.75,1.75,0,0,1,3.75,10Zm13.125,0H20.25A1.75,1.75,0,0,1,22,11.75V15a4,4,0,0,1-5.031,3.866,5.426,5.426,0,0,0,.456-1.433A2.5,2.5,0,0,0,20.5,15V11.75a.25.25,0,0,0-.25-.25H17.493A2.737,2.737,0,0,0,16.875,10ZM12,3A3,3,0,1,1,9,6,3,3,0,0,1,12,3Zm6.5,1A2.5,2.5,0,1,1,16,6.5,2.5,2.5,0,0,1,18.5,4ZM5.5,4A2.5,2.5,0,1,1,3,6.5,2.5,2.5,0,0,1,5.5,4Zm6.5.5A1.5,1.5,0,1,0,13.5,6,1.5,1.5,0,0,0,12,4.5Zm6.5,1a1,1,0,1,0,1,1A1,1,0,0,0,18.5,5.5Zm-13,0a1,1,0,1,0,1,1A1,1,0,0,0,5.5,5.5Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
