import { apiClient, MY_TENANT_URL } from 'infra/api/config';

import {
  ResponseBrand,
  RequestBrand,
} from 'context/corporation/brand/api/types';

export const updateBrand = async (id: number, brand: RequestBrand) => {
  return apiClient.patch<ResponseBrand>(
    MY_TENANT_URL(`corporation/brand/${id}/`),
    brand
  );
};
