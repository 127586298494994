/**
 * 店舗MBO関連のルーティング定義
 */

import { ShopMboDetails } from 'context/corporation/mbo/components/ShopMboDetail/Index';
import { ShopMboList } from 'context/corporation/mbo/components/ShopMboList';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const ShopMboListRoute: RouteType = {
  path: '/shop-mbo',
  element: <ShopMboList />,
  do_it: () => (
    <Route path={ShopMboListRoute.path} element={ShopMboListRoute.element} />
  ),
};

export const ShopMboDetailsRoute: RouteType = {
  path: '/shop-mbo/:shopId',
  element: <ShopMboDetails />,
  do_it: () => (
    <Route
      path={ShopMboDetailsRoute.path}
      element={ShopMboDetailsRoute.element}
    />
  ),
};

export const routes = () => (
  <>
    {ShopMboListRoute.do_it()}
    {ShopMboDetailsRoute.do_it()}
  </>
);
