import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/contracts/contract/';

export type Request = SnakeObjToCamelObj<
  NonNullable<
    paths['/tenant/my/contracts/contract/']['patch']['requestBody']
  >['content']['application/json']
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/contracts/contract/']['patch']['responses'][200]['content']['application/json']
>;

/**
 * 契約情報の更新
 */
export default async (request: Request): Promise<SuccessResponse> => {
  return apiClient.patch(URL, { closingDate: request.closingDate });
};
