import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Chip, ChipProps } from '@mui/material';
import {
  MandalatSquareSettingRules,
  ResponseMandalatMaster,
} from 'context/mandalat/api/typeResponse';
import { convertSubTypeAndBasicThinkingMasterId2Label } from 'context/mandalat/types/mandalatSquareSettingRules';
import { COLORS } from 'tailwind.config';
import { IndexBox } from './IndexBox';

export const MandalatTiles = (props: {
  gradeName: string;
  latestMandalatmaster: ResponseMandalatMaster;
  formValue: MandalatSquareSettingRules;
}) => {
  const { gradeName, latestMandalatmaster, formValue } = props;

  return (
    <TabPanel>
      {formValue.map((rule, index) => {
        const order = gridOrder[index];
        return (
          <MandalatTile
            gridArea={order}
            rule={rule}
            latestMandalatmaster={latestMandalatmaster}
            key={order}
          />
        );
      })}
      <div className="flex justify-center content-center flex-wrap [grid-area:midCenter]">
        <p className="text-label font-bold text-center">{gradeName}</p>
      </div>
    </TabPanel>
  );
};

const MandalatTile = (props: {
  rule: MandalatSquareSettingRules[number];
  gridArea: string;
  latestMandalatmaster: ResponseMandalatMaster;
}) => {
  const { rule, gridArea, latestMandalatmaster } = props;
  const { type, index } = rule;

  const [tileBackGroundColor, setTileBackGroundColor] = useState<string>(
    COLORS.GRAY_400
  );
  const [tileTextColor, setTileTextColor] = useState<string>(COLORS.GRAY_800);
  const [tag, setTag] = useState<ReactElement | undefined>(undefined);

  useEffect(() => {
    switch (type) {
      case '必須':
        setTileBackGroundColor(COLORS.SOFT_BLUE);
        setTileTextColor(COLORS.GRAY_800);
        setTag(<RequiredChip />);
        break;
      case '選択テーマ':
        setTileBackGroundColor(COLORS.SOFT_YELLOW);
        setTileTextColor(COLORS.GRAY_800);
        setTag(<SelectChip />);
        break;
      default:
        setTileBackGroundColor(COLORS.GRAY_400);
        setTileTextColor(COLORS.GRAY_600);
        setTag(undefined);
        break;
    }
  }, [type]);

  return (
    <div
      key={index}
      className={`[grid-area:${gridArea}] relative flex justify-center items-center px-[2px]`}
      style={{ backgroundColor: tileBackGroundColor, color: tileTextColor }}
    >
      <div className="absolute left-0 top-0">
        <IndexBox>{index}</IndexBox>
      </div>
      <div className="text-label">
        <div className="flex justify-center">{tag}</div>
        <p className="text-center font-label">
          {convertSubTypeAndBasicThinkingMasterId2Label(
            rule,
            latestMandalatmaster
          )}
        </p>
      </div>
    </div>
  );
};

const TabPanel = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <div
      role="tabpanel"
      className="
        grid
        [grid-template-columns:80px_80px_80px]
        [grid-template-rows:80px_80px_80px]
        [grid-template-areas:'topLeft_topCenter_topRight'_'midLeft_midCenter_midRight'_'bottomLeft_bottomCenter_bottomRight']
      "
    >
      {children}
    </div>
  );
};

const gridOrder = [
  'topLeft',
  'topCenter',
  'topRight',
  'midRight',
  'bottomRight',
  'bottomCenter',
  'bottomLeft',
  'midLeft',
];

const RequiredChip = () => {
  return (
    <div className="rounded-full bg-main-blue text-white px-[8px] py-[4px] text-micro mb-[7px] font-semibold">
      必須
    </div>
  );
};

const SelectChip = () => {
  return (
    <div className="rounded-full border border-1 text-main-blue bg-white px-[8px] py-[4px] text-micro mb-[7px] font-semibold">
      選択
    </div>
  );
};
