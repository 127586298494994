import { ResponseEvaluationMeasure } from '../api/typeResponse';

export type EvaluationMeasureType = 'actual' | 'ratio';

export type EvaluationMeasure = {
  id: number;
  sales: {
    measure: number[];
    type: EvaluationMeasureType;
  };
  profit: {
    measure: number[];
    type: EvaluationMeasureType;
  };
  es: {
    measure: number[];
    type: EvaluationMeasureType;
  };
  cs: {
    measure: number[];
    type: EvaluationMeasureType;
  };
  finalEvaluation: {
    measure: number[];
  };
};

export const getName = (slug: keyof EvaluationMeasure) => {
  switch (slug) {
    case 'sales':
      return '売上';
    case 'profit':
      return '利益';
    case 'es':
      return 'ES';
    case 'cs':
      return 'CS';
    case 'finalEvaluation':
      return '獲得評価';
    default:
      return '';
  }
};

export const convertApiTypeToEvaluationMeasure = (
  apiType?: ResponseEvaluationMeasure
): EvaluationMeasure | undefined => {
  if (apiType === undefined) {
    return undefined;
  }

  return {
    id: apiType.id,
    sales: {
      measure: apiType.measureSales,
      type: apiType.measureSalesType,
    },
    profit: {
      measure: apiType.measureProfit,
      type: apiType.measureProfitType,
    },
    es: {
      measure: apiType.measureEs,
      type: apiType.measureEsType,
    },
    cs: {
      measure: apiType.measureCs,
      type: apiType.measureCsType,
    },
    finalEvaluation: {
      measure: apiType.measureFinalEvaluation,
    },
  };
};

export const convertEvaluationMeasureToApiType = (
  evaluationMeasure: Partial<EvaluationMeasure>
): Partial<ResponseEvaluationMeasure> => ({
  id: evaluationMeasure.id,
  measureSales: evaluationMeasure.sales?.measure,
  measureSalesType: evaluationMeasure.sales?.type,
  measureProfit: evaluationMeasure.profit?.measure,
  measureProfitType: evaluationMeasure.profit?.type,
  measureEs: evaluationMeasure.es?.measure,
  measureEsType: evaluationMeasure.es?.type,
  measureCs: evaluationMeasure.cs?.measure,
  measureCsType: evaluationMeasure.cs?.type,
  measureFinalEvaluation: evaluationMeasure.finalEvaluation?.measure,
});

export const getTypeName = (type: EvaluationMeasureType) => {
  switch (type) {
    case 'ratio':
      return '達成率';
    case 'actual':
      return '評価点数';
    default:
      return '';
  }
};

export const getTypeUnit = (type: EvaluationMeasureType) => {
  switch (type) {
    case 'ratio':
      return '%';
    case 'actual':
      return '点';
    default:
      return '';
  }
};
