import { ReactNode, FC } from 'react';
import { CircularProgress } from '@mui/material';

type BaseLoadingProps = {
  isLoading: boolean;
  children: ReactNode;
};

export const Loading: FC<BaseLoadingProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div className="absolute inset-0 flex items-center justify-center p-[60px]">
        <CircularProgress color="primary" />
      </div>
    );
  }
  return <>{children}</>;
};
