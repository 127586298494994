import { COLORS } from 'tailwind.config';

type ArrowDownIconProps = {
  fill?: string;
};

export const ArrowDownIcon = ({
  fill = COLORS.GRAY_600,
}: ArrowDownIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="8"
    viewBox="0 0 15 8"
  >
    <g id="arrow" transform="translate(15) rotate(90)">
      <g id="ic_fluent_chevron_right_24_filled">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M8.26,4.275a.974.974,0,0,0,0,1.326l5.594,5.9L8.26,17.4a.974.974,0,0,0,0,1.326.857.857,0,0,0,1.257,0l6.222-6.563a.974.974,0,0,0,0-1.326L9.517,4.275A.857.857,0,0,0,8.26,4.275Z"
          transform="translate(-8 -4)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);
