import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { convertApiTypeToEvaluationMeasure } from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { ResponseEvaluationMeasure } from './typeResponse';

export const useGetEvaluationMeasures = (useCache = false) => {
  const [response, refetch] = useAxios<ResponseEvaluationMeasure>(
    {
      url: MY_TENANT_URL('corporation/evaluation_measures/'),
    },
    { useCache }
  );

  useNonFieldApiErrorCallback(response.error);

  // レスポンス型を汎用型に変換
  return [
    convertApiTypeToEvaluationMeasure(response.data),
    response.loading,
    refetch,
  ] as const;
};
