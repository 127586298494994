import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponseMandalatMaster } from './typeResponse';

export const useLatestMandalatMaster = (useCache = false) => {
  const response = useAxios<ResponseMandalatMaster>(
    {
      url: MY_TENANT_URL('mandalat/mandalat_master/'),
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);
  return response;
};
