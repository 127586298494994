import React, { useState } from 'react';

import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { ResponseOccupation } from 'context/corporation/occupation/api/types';

import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { OccupationEditFormDialog } from './EditForm/OccupationEditFormDialog';

type Props = {
  occupations: ResponseOccupation[];
  onSaved: () => void;
};

export const OccupationList = (props: Props) => {
  const modalState = useState(false);
  const isEmpty = !props.occupations.length;

  return (
    <div>
      <div className="flex justify-between items-center pb-[20px] mb-[30px] border-b-2 border-grayscale-500">
        <p className="font-bold">職種</p>
        <BaseButton
          className="!w-[100px] p-button-small tracking-short-text text-ui"
          onClick={() => {
            modalState[1](true);
          }}
        >
          {isEmpty ? '登録' : '編集'}
        </BaseButton>
      </div>
      <div>
        {isEmpty && (
          <div className="mb-[60px]">
            <WarningMessageBox
              message="職種を設定してください"
              detailsMessage={
                'ホール、キッチンなどの分類に加え、それぞれにサブ職種がある場合はサブ職種も設定します。\n（サブ職種例：キッチンの中で、パティシエやピッツァイオーロなど分類がある場合）'
              }
            />
            <p className="my-[20px] text-base">
              まだ登録されたデータはありません。
            </p>
            <BaseButton
              className="w-btn-small p-button-small tracking-short-text text-base bg-transparent border border-main-blue text-main-blue"
              startIcon={<AddIcon color="text-main-blue" />}
              onClick={() => {
                modalState[1](true);
              }}
            >
              登録
            </BaseButton>
          </div>
        )}

        {!isEmpty && (
          <div className="grid grid-flow-row mb-[30px]">
            {props.occupations.map((occupation) => (
              <div className="flex flex-col gap-[20px] items-start">
                <p className="bg-grayscale-400 min-w-[320px] h-[40px] px-[10px] rounded border border-grayscale-500 flex items-center text-ui">
                  {occupation.name}
                </p>

                {occupation?.subOccupations && (
                  <div className="w-full ml-[40px]">
                    {occupation?.subOccupations.map((subOccupation) => {
                      return (
                        <p className="mb-[20px] bg-grayscale-400 w-[280px] h-[40px] px-[10px] rounded border border-grayscale-500 flex items-center text-ui">
                          {subOccupation.name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <OccupationEditFormDialog
        modalState={modalState}
        onSaved={props.onSaved}
        occupations={props.occupations}
      />
    </div>
  );
};
