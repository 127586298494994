/**
 * 運用設定 マネジャー評価者設定関連ルーティング
 */
import { OutsideManagerList } from 'context/users/outside_managers/components/OutsideManagerList';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const OutsideManagerListRoute: RouteType = {
  path: '/operation-setting/outside-managers',
  element: <OutsideManagerList />,
  do_it: () => (
    <Route
      path={OutsideManagerListRoute.path}
      element={OutsideManagerListRoute.element}
    />
  ),
};

export const routes = () => <>{OutsideManagerListRoute.do_it()}</>;
