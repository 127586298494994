import { CircularProgress } from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { ResponseCareerPath } from 'context/corporation/career_path/api/types';

import { ResponseGrade } from 'context/corporation/grade/api/types';

import { useState } from 'react';
import { CreateCareerPathModal } from './CreateCareerPathModal';

type CareerPath = ResponseCareerPath;
type Grade = ResponseGrade & {
  positionName: string;
};

const convertPathToArray = (path: string): number[] => {
  // pathは、/1/2/3/の /区切り文字列
  const strIdArray = path.split('/');

  // 現状は、最初と最後のスラッシュで余計な空白が出るためそれを削除
  if (strIdArray[0] === '') {
    strIdArray.shift();
  }
  if (strIdArray[strIdArray.length - 1] === '') {
    strIdArray.pop();
  }

  // string型からnumber型に変更
  return strIdArray.map((id) => Number(id));
};

export const CareerPathSetting = () => {
  const [{ data: corporation }, refetch] = useFetchCorporation();
  const [isShowModal, setIsShowModal] = useState(false);
  const [editingCareerPath, setEditingCareerPath] = useState<CareerPath | null>(
    null
  );

  if (corporation === null) {
    return <LoadingBackdrop isShow />;
  }

  const editingCareerPathForm =
    editingCareerPath === null
      ? null
      : {
          careerPathName: editingCareerPath.name,
          path: convertPathToArray(editingCareerPath.path).map((path) => {
            return {
              id: path,
              gradeId: path,
            };
          }),
        };

  const gradeList = corporation.positions.flatMap((p) =>
    p.grades.map((grade) => ({ ...grade, positionName: p.name }))
  );
  const CareerPathItems = corporation?.careerPaths.map((careerPath) => (
    <CareerPathItem
      key={careerPath.id}
      gradeList={gradeList}
      careerPath={careerPath}
      onEdit={() => {
        setEditingCareerPath(careerPath);
        setIsShowModal(true);
      }}
    />
  ));
  return (
    <div>
      {isShowModal && (
        <CreateCareerPathModal
          editingId={editingCareerPath?.id ?? undefined}
          careerPathForm={editingCareerPathForm}
          onSaved={refetch}
          gradeList={gradeList}
          onClose={() => setIsShowModal(false)}
        />
      )}

      <PageTitle title="キャリアパス設定" breadcrumbs={[['ホーム', '/']]} />

      <div className="mt-[50px] p-main-section bg-grayscale-100">
        <div className="flex justify-end">
          <BaseButton
            className="w-btn-small p-button-small tracking-short-text text-base"
            startIcon={<AddIcon />}
            onClick={() => {
              setEditingCareerPath(null);
              setIsShowModal(true);
            }}
          >
            登録
          </BaseButton>
        </div>
        <Divider className="my-[40px]" />

        {CareerPathItems.length > 0 ? (
          <div className="overflow-x-auto w-full">
            <div className="grid grid-flow-col gap-[20px] grid-cols-[repeat(auto-fit,_minmax(360px,_max-content))]">
              {CareerPathItems}
            </div>
          </div>
        ) : (
          <>
            <WarningMessageBox
              message="キャリアパスを設定してください"
              detailsMessage="専門職・管理職などキャリアパスが分岐する場合はパスを設定します"
            />
            <p className="my-[40px] text-base">
              まだ登録されたデータはありません。
            </p>
            <BaseButton
              className="w-btn-small p-button-small text-main-blue tracking-short-text text-base bg-grayscale-100 border border-main-blue"
              startIcon={<AddIcon color="text-main-blue" />}
              onClick={() => {
                setEditingCareerPath(null);
                setIsShowModal(true);
              }}
            >
              登録
            </BaseButton>
          </>
        )}
      </div>
    </div>
  );
};

const CareerPathItem = (props: {
  careerPath: CareerPath;
  gradeList: Grade[];
  onEdit: () => void;
}): JSX.Element => {
  return (
    <div className="p-[20px] bg-grayscale-400 w-[360px]">
      <div className="flex justify-between items-center mb-[20px]">
        <p className="font-bold text-base">{props.careerPath.name}</p>
        <MoreMenu
          render={[
            {
              label: <span>編集</span>,
              onClick: props.onEdit,
            },
          ]}
        />{' '}
      </div>
      <div className="grid grid-flow-row gap-[10px] grid-row-[repeat(auto-fill, auto)]">
        {convertPathToArray(props.careerPath.path).map((gradeId) => {
          const grade = props.gradeList.find((g) => g.id === gradeId);
          if (grade === undefined) {
            return (
              <div className="text-center">
                <CircularProgress color="inherit" />
              </div>
            );
          }

          return <GradeItem key={gradeId} grade={grade} />;
        })}
      </div>
    </div>
  );
};

const GradeItem = (props: { grade: Grade }) => {
  return (
    <div
      className="rounded border border-grayscale-500 py-[12px] px-[16px] bg-grayscale-100"
      key={props.grade.id}
    >
      <p className="font-medium text-ui mb-[6px]">{props.grade.name}</p>
      <p className="text-note text-grayscale-700">{props.grade.positionName}</p>
    </div>
  );
};
