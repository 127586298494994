import { FC } from 'react';
import { QuestionCircle } from './QuestionCircle';

type Props = {
  tooltip: {
    text: string;
  };
};

export const Tooltip: FC<Props> = ({ tooltip }) => {
  return (
    <div className="relative group cursor-pointer px-2 py-1">
      <span
        className="
          px-[12px] py-[6px] absolute left-0 -top-[36px]
          bg-grayscale-700 text-grayscale-100 text-ui rounded whitespace-nowrap
          before:content-[''] before:block
          before:absolute before:left-[11px] before:top-full
          before:w-0 before:h-0 before:border-t-4 before:border-l-4 before:border-r-4 before:border-transparent before:border-t-grayscale-700
          opacity-0 transition pointer-events-none
          group-hover:opacity-100"
      >
        {tooltip.text}
      </span>
      <QuestionCircle />
    </div>
  );
};
