import { usePagination } from 'infra/components/UI/Table/Pagination/usePagination';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import { PaginationArrow } from 'infra/components/UI/Table/Pagination/PaginationArrow';

/**
 * 型定義一覧
 */
export namespace Types {
  export type Pager<ItemType = any> = {
    page: number;
    numPage: number;
    totalCount: number;
    numPerPage: number;
    start_num: number;
    end_num: number;
    isLoading: boolean;
    items: ItemType[];
    isPrevButtonDisabled: boolean;
    isNextButtonDisabled: boolean;
    showNumberItemRange: number;
  };

  export type PagerState<ItemType = any> = {
    pager: Pager<ItemType>;
    setPager: Dispatch<SetStateAction<Pager<ItemType>>>;
    searchParams: URLSearchParams;
    setSearchParams: any;
    doitPaging: (currentPage: number, signal?: AbortSignal) => void;
    refetchCurrentPage: () => void;
    resetSearchParamsCondition: () => void;
    updateNumPerPage: (newNumPerPage: number) => void;
    isIncludeSearchValue: (searchKey: string, value?: string) => boolean;
  };

  export type fetch = (
    limit: number,
    offset: number,
    searchParams: URLSearchParams,
    signal?: AbortSignal
  ) => Promise<any>;
}

export const FilterAreaContext = createContext<Types.PagerState | null>(null);

export const useFilterAreaContext = <ItemType = any,>() => {
  const context = useContext<Types.PagerState<ItemType> | null>(
    FilterAreaContext
  );
  if (!context) {
    throw new Error('useFilterAreaContextでエラーです。');
  }
  return context;
};

/**
 * ページャのステート
 * @param fetch 検索を実行する関数
 * @param numPerPage 1ページ毎に表示する件数
 * @returns
 */
export const usePagerState = <T,>(
  fetch: Types.fetch,
  initialNumPerPage: number = 10
): Types.PagerState => {
  const [pager, setPager] = useState<Types.Pager>({
    page: 1,
    numPage: 0,
    totalCount: 0,
    numPerPage: initialNumPerPage,
    start_num: 0,
    end_num: 0,
    items: [],
    isLoading: true,
    isPrevButtonDisabled: false,
    isNextButtonDisabled: false,
    showNumberItemRange: 0,
  });

  const [numPerPage, setNumPerPage] = useState(initialNumPerPage);
  const [_, { movePage, setItems }] = usePagination<T>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInitedSearch, setIsInitedSearch] = useState(false);

  const doitPaging = async (currentPage: number, signal?: AbortSignal) => {
    const currentPageFormat = Number.isNaN(currentPage) ? 1 : currentPage;

    const res = await fetch(
      numPerPage,
      numPerPage * (currentPageFormat - 1),
      searchParams,
      signal
    ).catch((e) => {
      console.error(e);
      return e;
    });

    if (res instanceof Error) {
      return;
    }

    movePage(currentPageFormat);

    setItems(res.data.results, res.data.count);

    setPager({
      page: currentPageFormat,
      numPage: Math.ceil(res.data.count / numPerPage),
      totalCount: res.data.count,
      numPerPage,
      start_num: (currentPageFormat - 1) * numPerPage + 1,
      end_num:
        res.data.results &&
        (currentPageFormat - 1) * numPerPage + (res.data.results?.length || 0),
      items: res.data.results,
      isLoading: false,
      isPrevButtonDisabled: currentPageFormat <= 1,
      isNextButtonDisabled:
        currentPageFormat >= Math.ceil(res.data.count / numPerPage),
      showNumberItemRange: 3,
    });
  };

  const refetchCurrentPage = () => {
    doitPaging(pager.page);
  };

  const resetSearchParamsCondition = () => {
    setSearchParams(new URLSearchParams());
  };

  const updateNumPerPage = (newNumPerPage: number) => {
    setNumPerPage(newNumPerPage);
    setPager((prev) => ({ ...prev, numPerPage: newNumPerPage }));
  };

  const isIncludeSearchValue = (searchKey: string, value?: string) => {
    if (!value) {
      return false;
    }

    if (!searchParams.get(searchKey)) {
      return false;
    }

    const resultItems =
      searchParams
        .get(searchKey)
        ?.split(',')
        .find((v) => v.toString() === value.toString()) || [];

    if (resultItems.length === 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (searchParams.has('page')) {
      const pageNum = parseInt(searchParams.get('page')!, 10);
      doitPaging(pageNum);
    } else {
      doitPaging(1);
    }

    setIsInitedSearch(true);
  }, []);

  useEffect(() => {
    if (isInitedSearch) {
      doitPaging(pager.page);
    }
  }, [numPerPage]);

  return {
    pager,
    setPager,
    searchParams,
    setSearchParams,
    doitPaging,
    resetSearchParamsCondition,
    updateNumPerPage,
    refetchCurrentPage,
    isIncludeSearchValue,
  };
};

/**
 * ページネーションアイテムとellipsesのコンポーネント
 * @returns
 */
const PageNumItemWithEllipses = ({
  pageNumber,
  onPaginate,
  showEllipsesBefore,
  showEllipsesAfter,
  defaultNumberItemStyles,
}: {
  pageNumber: number;
  onPaginate: (MoveToPageNumber: number) => void;
  showEllipsesBefore: boolean;
  showEllipsesAfter: boolean;
  defaultNumberItemStyles: string;
}) => {
  return (
    <ul className="flex items-center gap-[12px]">
      {showEllipsesBefore && (
        <li className="text-base leading-none" aria-hidden="true">
          ...
        </li>
      )}

      <li
        className={defaultNumberItemStyles}
        onClick={() => onPaginate(pageNumber)}
      >
        {pageNumber}
      </li>

      {showEllipsesAfter && (
        <li className="text-base leading-none" aria-hidden="true">
          ...
        </li>
      )}
    </ul>
  );
};

/**
 * ページングコンポーネント
 * @returns
 */
export const Pagination = ({
  pagerState,
}: {
  pagerState: Types.PagerState;
}) => {
  const defaultNumberItemStyles = `
    w-[35px] h-[35px] flex items-center justify-center text-ui font-medium text-grayscale-700
    border-1 border-grayscale-500 bg-grayscale-100 rounded-full cursor-pointer leading-none
    hover:border-main-blue hover:text-main-blue hover:font-semibold`;

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const renderPageNumbers = () => {
    const rangedPages = [];
    const rangedStartPage = Math.max(1, pagerState.pager.page - 2);
    const rangedEndPage = Math.min(
      pagerState.pager.page + 2,
      pagerState.pager.numPage
    );

    for (let i = rangedStartPage; i <= rangedEndPage; i += 1) {
      const isActivePage = pagerState.pager.page === i;
      rangedPages.push(
        <li
          key={i}
          className={`
          ${defaultNumberItemStyles}
          ${
            isActivePage
              ? 'bg-main-blue !text-grayscale-100 font-semibold !border-0 hover:cursor-default pointer-events-none'
              : ''
          }
          `}
          onClick={() => {
            if (isActivePage) return;
            queryParams.set('page', i.toString());
            navigate(`?${queryParams.toString()}`);
            pagerState.doitPaging(i);
          }}
        >
          {i}
        </li>
      );
    }
    return rangedPages;
  };

  return (
    <div className="flex justify-center">
      <ul className="flex items-center gap-[12px]">
        {/* 前ボタン */}
        <li
          className={`
            ${defaultNumberItemStyles} group hover:font-normal
            ${
              pagerState.pager.isPrevButtonDisabled
                ? 'opacity-40 pointer-events-none'
                : ''
            }
          `}
          onClick={() => {
            if (pagerState.pager.isPrevButtonDisabled) return;
            const page = pagerState.pager.page - 1;
            queryParams.set('page', page.toString());
            navigate(`?${queryParams.toString()}`);
            pagerState.doitPaging(page);
          }}
        >
          <PaginationArrow />
        </li>

        {/* 先頭に戻る ３点リーダ */}
        {Boolean(
          pagerState.pager.numPage - pagerState.pager.showNumberItemRange
        ) &&
          pagerState.pager.page > pagerState.pager.showNumberItemRange && (
            <PageNumItemWithEllipses
              pageNumber={1}
              onPaginate={pagerState.doitPaging}
              showEllipsesBefore={false}
              showEllipsesAfter
              defaultNumberItemStyles={defaultNumberItemStyles}
            />
          )}

        {renderPageNumbers()}

        {/* 最後 ３点リーダ */}
        {Boolean(
          pagerState.pager.numPage - pagerState.pager.showNumberItemRange
        ) &&
          pagerState.pager.page <=
            pagerState.pager.numPage - pagerState.pager.showNumberItemRange && (
            <PageNumItemWithEllipses
              pageNumber={pagerState.pager.numPage}
              onPaginate={pagerState.doitPaging}
              showEllipsesBefore
              showEllipsesAfter={false}
              defaultNumberItemStyles={defaultNumberItemStyles}
            />
          )}

        {/* 次ボタン */}
        <li
          className={`${defaultNumberItemStyles} group hover:font-normal
          ${
            pagerState.pager.isNextButtonDisabled
              ? 'opacity-40 pointer-events-none'
              : ''
          }
          `}
          onClick={() => {
            if (pagerState.pager.isNextButtonDisabled) return;

            const page = pagerState.pager.page + 1;
            queryParams.set('page', page.toString());
            navigate(`?${queryParams.toString()}`);
            pagerState.doitPaging(page);
          }}
        >
          <PaginationArrow isNext />
        </li>
      </ul>
    </div>
  );
};
