import { FieldAddButton } from 'infra/components/UI/Buttons/FieldAddButton';
import { useAtom } from 'jotai';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { Select } from 'infra/components/UI/Forms/Select';
import { EMPLOYMENT_STATUS_LIST } from 'context/users/onsite_users/_infra/state/const/employmentStatus';
import { FieldDeleteIcon } from 'infra/components/UI/Icons/FieldDeleteIcon';
import { deletePosition } from 'context/corporation/position/api/deletePosition';
import { useFieldArray } from 'react-hook-form';

export const PositionEditForm = (props: { onSaved: () => void; form: any }) => {
  const displayNonFieldApiError = useDisplayNonFieldApiError();
  const [, displayMessage] = useAtom(displayMessageAtom);

  const { control } = props.form;

  const { fields, append, remove } = useFieldArray({
    keyName: 'localId',
    name: 'positions',
    control,
  });

  const addField = () => {
    append({
      id: null,
      name: '',
      employmentStatus: '',
      grades: [{ id: null, name: '' }],
    });
  };
  const deleteField = async (postion: any, index: number) => {
    if (!postion.id) {
      props.onSaved();
      remove(index);
      return;
    }

    // eslint-disable-next-line no-alert
    if (!window.confirm('本当に削除してもよろしいですか？')) {
      return;
    }

    await deletePosition(postion.id)
      .then(() => {
        displayMessage({ text: '職位を削除しました', isInterruption: false });
        remove(index);
        props.onSaved();
      })
      .catch((error) => {
        displayNonFieldApiError(error);
      });
  };

  if (fields.length === 0) {
    addField();
  }

  return (
    <form>
      <p className="text-base font-bold mb-[24px] text-section-heading">
        職位・等級設定
      </p>
      <div className="grid grid-flow-row mb-[24px]">
        {fields.map((position, index) => {
          return (
            <div className="flex items-center gap-[20px] mb-[20px]">
              <div className="flex items-center space-x-[20px]">
                <Select
                  useForm={props.form}
                  name={`positions.${index}.employmentStatus`}
                  menuItems={EMPLOYMENT_STATUS_LIST.map(({ id, name }) => {
                    return {
                      label: name,
                      value: id,
                    };
                  })}
                  isErrorFiled={false}
                  className="w-[200px]"
                />
                <TextField
                  name={`positions.${index}.name`}
                  useForm={props.form}
                  isErrorFiled={
                    !!props.form.formState.errors.positions?.[index]?.name
                  }
                  className="w-[200px]"
                  placeholder="職位名を入力してください"
                />

                {// @ts-ignore
                position.grades?.map((grade: any, innerIndex: any) => {
                  return (
                    <TextField
                      name={`positions.${index}.grades.${innerIndex}.name`}
                      useForm={props.form}
                      isErrorFiled={
                        !!props.form.formState.errors.positions?.[index]
                          ?.grades?.[innerIndex]?.name
                      }
                      className="w-[200px]"
                      placeholder="等級名を入力してください"
                      key={position.localId}
                    />
                  );
                })}
              </div>
              <div className="base-hover">
                <FieldDeleteIcon
                  onClick={() => {
                    deleteField(position, index);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <FieldAddButton
        label="職位・等級を追加"
        onClickHandler={() => {
          addField();
        }}
      />
    </form>
  );
};
