import useAxios from 'axios-hooks';
import { useAtom } from 'jotai';

import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';

export const URL = '/tenant/my/mvps/mvp_coin_assignment/';
export type PagedSuccessResponse = SnakeObjToCamelObj<
  Required<
    paths[typeof URL]['get']['responses'][200]['content']['application/json']
  >
>;

export type MvpCoinAssignment = PagedSuccessResponse['results'][number];

/**
 * MVPコイン付与の検索
 * @param useCache
 * @returns
 */
export const getMvpCoinAssignments = (
  userId: string,
  evaluationPeriodId: number,
  params: { [k in string]: any } = {},
  useCache = false
) => {
  const response = useAxios<PagedSuccessResponse>(
    {
      url: URL,
      params: {
        ordering: '-created_at',
        limit: 9999,
        evaluator_objective_record__evaluation_period_id: evaluationPeriodId,
        user_id: userId,
        ...params,
      },
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};

export const getMvpCoinAssignmentsGroupByCategory = (
  userId: string,
  evaluationPeriodId: number
) => {
  const [{ data: allMvpCoins, loading: loadingAllMvpCoins }] =
    getMvpCoinAssignments(userId, evaluationPeriodId);
  const [{ data: minusMvpCoins, loading: loadingMinusMvpCoins }] =
    getMvpCoinAssignments(userId, evaluationPeriodId, {
      mvp_category__group: 'minusPoint',
    });

  return {
    allMvpCoins,
    minusMvpCoins,
    loading: loadingAllMvpCoins || loadingMinusMvpCoins,
  } as const;
};
