type Props = {
  bgColor: string;
};

export const RightBottomArrow = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="7"
    viewBox="0 0 7 7"
  >
    <path
      id="パス_3431"
      data-name="パス 3431"
      d="M4-3V2c0,1.448-.438,2-2,2H-3Z"
      transform="translate(3 3)"
      fill={props.bgColor}
    />
  </svg>
);
