import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';

export const importStaffCsv = async (csvFile: File, isPreview = false) => {
  const form = new FormData();
  form.append('file', csvFile);
  const response = await apiClient.post(
    MY_TENANT_URL(
      `user/onsite_users/master/import/${isPreview ? '?isPreview=true' : ''}`
    ),
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response;
};
