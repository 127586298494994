import { useState, createElement, useEffect } from 'react';
import { FilterListIcon } from 'infra/components/UI/Icons/FilterListIcon';
import { KeywordInputFilter } from 'infra/components/UI/Table/Pagination/Filter/KeywordInputFilter';
import { SelectBoxFilter } from 'infra/components/UI/Table/Pagination/Filter/SelectBoxFilter';
import { FilterCategorySelector } from 'infra/components/UI/Table/Pagination/Filter/FilterCategorySelector';
import { useFetchShops } from 'context/corporation/shop/api/fetchShops';
import { useFilterAreaContext } from 'infra/components/UI/Table/Pagination/Pagination';

type BrandType = {
  id: number;
  name: string;
};

export const ShopMboFilterArea = () => {
  const pagerState = useFilterAreaContext();
  const [{ data: shops }] = useFetchShops();

  const uniqueBrands = shops?.results.reduce<BrandType[]>((acc, shop) => {
    const brandName = shop.brand.name;
    if (!acc.some((item) => item.name === brandName)) {
      acc.push({ id: shop.brand.id, name: brandName });
    }
    return acc;
  }, []);

  const FormSelectBoxSettings = [
    {
      name: 'ブランド',
      component: SelectBoxFilter,
      params: () => {
        return {
          searchKey: 'brand__id',
          data: uniqueBrands,
          pagerState,
        };
      },
    },
    {
      name: '店舗名',
      component: KeywordInputFilter,
      params: () => {
        return {
          searchKey: 'name__contains',
          data: {},
          pagerState,
        };
      },
    },
  ];

  const [selectedForm, setSelectedForm] = useState(FormSelectBoxSettings[0]);

  const switchSelectedFilterType = (selectedItem: string) => {
    const activeFormType =
      FormSelectBoxSettings.find((item) => item.name === selectedItem) ||
      FormSelectBoxSettings[0];
    setSelectedForm(activeFormType);
  };

  useEffect(() => {
    if (shops) {
      switchSelectedFilterType(FormSelectBoxSettings[0].name);
    }
  }, [shops]);

  return (
    <div className="pt-[50px] flex justify-between items-center">
      <p className="text-base text-grayscale-700">
        全{pagerState.pager.totalCount}
        件中 {pagerState.pager.start_num}〜{pagerState.pager.end_num}
        件を表示
      </p>
      <div className="flex items-center gap-[14px]">
        <div className="flex items-center">
          <FilterListIcon />
          <p className="text-ui text-grayscale-700 pl-[10px]">絞り込み</p>
        </div>

        <button
          type="button"
          className="text-meta text-main-blue px-[6px] py-[10px]"
          onClick={() => {
            pagerState.resetSearchParamsCondition();
            switchSelectedFilterType(FormSelectBoxSettings[0].name);
          }}
        >
          検索条件のクリア
        </button>

        <div>
          <div className="flex items-center gap-[10px]">
            <FilterCategorySelector
              filterCategory={FormSelectBoxSettings}
              onFilterChange={(selectedItem) => {
                pagerState.resetSearchParamsCondition();
                switchSelectedFilterType(selectedItem);
              }}
              selectedFormName={selectedForm.name}
            />
            {createElement(selectedForm.component, selectedForm.params())}
          </div>
        </div>
      </div>
    </div>
  );
};
