import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { Dialog } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { ResponseOccupation } from 'context/corporation/occupation/api/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { useAtom } from 'jotai';
import zod from 'zod';
import { bulkOccupation } from 'context/corporation/occupation/api/bulkOccupation';
import { zodResolver } from '@hookform/resolvers/zod';
import { OccupationEditForm } from './OccupationEditForm';

const OccupationsFormSchema = zod.object({
  occupations: zod.array(
    zod.object({
      id: zod.number().nullable(),
      name: zod.string().min(1),
      subOccupations: zod.array(
        zod.object({
          id: zod.number().nullable(),
          name: zod.string().min(1),
        })
      ),
    })
  ),
});

export type OccupationsFormDataType = zod.infer<typeof OccupationsFormSchema>;

type Props = {
  modalState: [boolean, Dispatch<SetStateAction<boolean>>];
  onSaved: () => void;
  occupations: ResponseOccupation[];
};

export const OccupationEditFormDialog = (props: Props) => {
  const [isModal, setIsModal] = props.modalState;
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const defaultValues = props.occupations.map((occupation) => {
    return {
      ...occupation,
      subOccupations: occupation.subOccupations.map((subOcc) => ({
        ...subOcc,
        occupationId: occupation.id,
      })),
    };
  });

  const form = useForm<OccupationsFormDataType>({
    resolver: zodResolver(OccupationsFormSchema),
    defaultValues: { occupations: defaultValues },
  });

  useEffect(() => {
    form.reset({ occupations: defaultValues });
  }, [isModal]);

  const submit: SubmitHandler<any> = async (data) => {
    startLoading('save_occupation');

    bulkOccupation(data)
      .then(() => {
        setTimeout(() => {
          displayMessage({
            text: `職種情報を一括更新しました。`,
            isInterruption: false,
          });
          props.onSaved();
          setIsModal(false);
        }, 200);
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            form.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('save_occupation');
      });
  };

  return (
    <Dialog
      open={isModal}
      onClose={() => {
        setIsModal(false);
      }}
      maxWidth={false}
    >
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={() => {
              form.handleSubmit(submit)();
            }}
          >
            保存
          </BaseButton>
        }
        closeHandler={() => {
          setIsModal(false);
        }}
      >
        <div className="pb-[40px]">
          <OccupationEditForm onSaved={props.onSaved} form={form} />
        </div>
      </BaseModal>
    </Dialog>
  );
};
