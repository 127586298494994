import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponseShop } from './typeResponse';

export const useFetchShop = (id: string, useCache = false) => {
  const [response, refetch] = useAxios<ResponseShop>(
    {
      url: MY_TENANT_URL(`shops/${id}/`),
    },
    { useCache }
  );

  useNonFieldApiErrorCallback(response.error);

  return [response, refetch] as const;
};
