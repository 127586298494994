export const AddPhotoIcon = () => {
  return (
    <svg
      id="ic_fluent_camera_add_24_regular"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15.617"
      viewBox="0 0 16 15.617"
    >
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M13.81,9.739a4.19,4.19,0,1,1-4.19,4.19A4.19,4.19,0,0,1,13.81,9.739Zm0,1.523-.068.006a.381.381,0,0,0-.306.306l-.006.068v1.9H11.522l-.068.006a.381.381,0,0,0-.306.306l-.006.068.006.068a.381.381,0,0,0,.306.306l.068.006h1.907v1.908l.006.068a.381.381,0,0,0,.306.306l.068.006.068-.006a.381.381,0,0,0,.306-.306l.006-.068V14.31H16.1l.068-.006A.381.381,0,0,0,16.474,14l.006-.068-.006-.068a.381.381,0,0,0-.306-.306l-.068-.006H14.19v-1.9l-.006-.068a.381.381,0,0,0-.306-.306ZM11.085,2.5a1.714,1.714,0,0,1,1.478.846l.621,1.056h1.578a2.476,2.476,0,0,1,2.476,2.476v3.475A4.966,4.966,0,0,0,16.1,9.535V6.882a1.333,1.333,0,0,0-1.333-1.333h-1.9a.571.571,0,0,1-.493-.282l-.786-1.338a.571.571,0,0,0-.493-.282h-2.9a.571.571,0,0,0-.438.2L7.7,3.918,6.868,5.276a.571.571,0,0,1-.487.272h-1.9A1.333,1.333,0,0,0,3.143,6.882V14.12a1.333,1.333,0,0,0,1.333,1.333H9.1a4.93,4.93,0,0,0,.54,1.143H4.476A2.476,2.476,0,0,1,2,14.12V6.882A2.476,2.476,0,0,1,4.476,4.405H6.061L6.728,3.32A1.714,1.714,0,0,1,8.188,2.5ZM9.619,6.691a3.43,3.43,0,0,1,3.263,2.373,4.9,4.9,0,0,0-1.092.342A2.286,2.286,0,1,0,9.115,12.35a4.913,4.913,0,0,0-.236,1.118,3.429,3.429,0,0,1,.741-6.777Z"
        transform="translate(-2 -2.503)"
        fill="#b9bcbf"
      />
    </svg>
  );
};
