import { createSvgIcon } from '@mui/material/utils';

const d =
  'M11.408,2.79a2.415,2.415,0,0,1,3.142.761l.087.142,8.194,14.4a2.264,2.264,0,0,1,.3,1.121,2.34,2.34,0,0,1-2.213,2.307l-.163.005H4.368a2.427,2.427,0,0,' +
  '1-1.151-.289,2.283,2.283,0,0,1-1-3l.077-.147,8.191-14.4A2.345,2.345,0,0,1,11.408,2.79Zm1.153,13.588A1.027,1.027,0,1,0,13.616,17.4,1.041,1.041,0,0,0,12.561' +
  ',16.378Zm0-8.227a1.046,1.046,0,0,0-1.049.908l-.007.12,0,5.14.007.12a1.06,1.06,0,0,0,2.1,0l.007-.12,0-5.14-.007-.12A1.046,1.046,0,0,0,12.559,8.151Z';

export const MinusPointIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 42.083 42.084"
  >
    <g id="グループ_602" data-name="グループ 602" transform="translate(-304.4)">
      <g
        id="楕円形_58"
        data-name="楕円形 58"
        transform="translate(304.4 0)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1"
      >
        <ellipse
          cx="21.042"
          cy="21.042"
          rx="21.042"
          ry="21.042"
          stroke="none"
        />
        <ellipse cx="21.042" cy="21.042" rx="20.542" ry="20.542" fill="none" />
      </g>
      <g
        id="_-Product-Icons"
        data-name="-Product-Icons"
        transform="translate(315.304 10.152)"
      >
        <g id="ic_fluent_warning_24_filled" transform="translate(0 0)">
          <path
            id="_-Color"
            data-name="-Color"
            d={d}
            transform="translate(-1.991 -2.5)"
            fill="#f8bc2c"
          />
        </g>
      </g>
    </g>
  </svg>,
  'MinusPointIcon'
);
