/**
 * 業態(ブランド)関連のルーティング定義
 */
import { BrandCreate } from 'context/corporation/brand/components/BrandCreate';
import { BrandEdit } from 'context/corporation/brand/components/BrandEdit';
import { BrandList } from 'context/corporation/brand/components/BrandList';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const BrandListRoute: RouteType = {
  path: '/brands',
  element: <BrandList />,
  do_it: () => (
    <Route path={BrandListRoute.path} element={BrandListRoute.element} />
  ),
};

export const BrandCreateRoute: RouteType = {
  path: '/brands/create',
  element: <BrandCreate />,
  do_it: () => (
    <Route path={BrandCreateRoute.path} element={BrandCreateRoute.element} />
  ),
};

export const BrandEditRoute: RouteType = {
  path: '/brands/:brandId',
  element: <BrandEdit />,
  do_it: () => (
    <Route path={BrandEditRoute.path} element={BrandEditRoute.element} />
  ),
};

export const routes = () => (
  <>
    {BrandListRoute.do_it()}
    {BrandCreateRoute.do_it()}
    {BrandEditRoute.do_it()}
  </>
);
