import { ReactNode, useEffect, useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { COLORS } from 'tailwind.config';
import { SearchIcon } from 'infra/components/UI/Icons/SearchIcon';
import { ErrorMessage } from 'infra/components/UI/Forms/ErrorMessage';

const adjustValue = (value: any) => {
  if (typeof value === 'number') {
    return Number(value);
  }

  return String(value);
};

type Props = {
  menuItems: { label: ReactNode; value: string | number }[] | undefined;
  isErrorFiled: boolean;
  className?: string;
  order?: 'labelAsc';

  form: {
    inputValue: string | number;
    onChangeValue: (queryValue: string | number) => void;
    errorMessage?: string;
  };
};

export const SelectSearch = ({
  menuItems,
  isErrorFiled,
  className = 'w-[320px]',
  order,
  form,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  function sortMenuItems(a: any, b: any) {
    if (order === 'labelAsc') {
      return a?.label?.toString()?.localeCompare(b?.label?.toString()!);
    }

    return true;
  }

  const initValue =
    menuItems?.filter(
      (item) => adjustValue(item.value) === adjustValue(form.inputValue)
    )[0]?.label || '';

  const [searchValue, setSearchValue] = useState('');
  const [filterdMenuItems, setFilterdMenuItems] = useState(menuItems);

  useEffect(() => {
    const filtered = menuItems?.filter((menu) => {
      if (menu?.label?.toString().includes(searchValue as string)) {
        return true;
      }

      return false;
    });

    setFilterdMenuItems(filtered);
  }, [searchValue, menuItems]);

  return (
    <div>
      <div className={`relative ${className}`}>
        <button
          type="button"
          className="
            relative w-full py-[8px] pl-[12px] pr-[20px]
            border-1 border-grayscale-500 rounded text-base text-left
            select-hover
          "
          onClick={() => {
            setIsOpen(true);
          }}
          style={{ borderColor: isErrorFiled ? COLORS.MAIN_RED : '' }}
        >
          {initValue || '選択'}
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
            <ArrowDownIcon />
          </div>
        </button>
        {isOpen && (
          <div
            className="
              absolute left-0 top-[44px] w-full overflow-y-auto max-h-[300px] z-10
              flex flex-col border-1 border-grayscale-500 rounded text-base hidden-scrollbar
              shadow-main
            "
          >
            <div className="relative p-[8px]">
              <div className="absolute left-[20px] top-1/2 translate -translate-y-1/2">
                <SearchIcon />
              </div>
              <input
                type="text"
                className="border border-grayscale-500 rounded text-base text-left w-full py-[8px] pl-[36px] pr-[8px]"
                defaultValue={searchValue as string}
                placeholder="入力してください"
                value={searchValue}
                onFocus={() => {
                  setIsOpen(true);
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onBlur={() => {
                  setIsOpen(false);
                }}
              />
            </div>
            {filterdMenuItems?.sort(sortMenuItems).map((item) => (
              <button
                type="button"
                key={item.value}
                className="py-[13px] px-[20px] text-left pulldown-hover bg-selectbox"
                onMouseDown={() => {
                  form.onChangeValue(adjustValue(item.value));
                  setIsOpen(false);
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>

      {isErrorFiled && <ErrorMessage message={form.errorMessage} />}
    </div>
  );
};
