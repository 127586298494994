export const TildeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.776"
    height="2.868"
    viewBox="0 0 10.776 2.868"
  >
    <path
      id="パス_3540"
      data-name="パス 3540"
      d="M1.212-4.056A4.23,4.23,0,0,1,2.22-4.9a2.459,2.459,0,0,1,1.236-.312,3.262,3.262,0,0,1,2.16.912,5.978,5.978,0,0,0,1.668.972,3.786,3.786,0,0,0,1.14.192,3.242,3.242,0,0,0,1.584-.408,4.907,4.907,0,0,0,1.38-1.236l-.624-.54A3.2,3.2,0,0,1,9.4-4.152a2.334,2.334,0,0,1-.96.2,3.9,3.9,0,0,1-2.3-.984A7.369,7.369,0,0,0,5-5.652,3.242,3.242,0,0,0,3.5-6,3.806,3.806,0,0,0,.612-4.584Z"
      transform="translate(-0.612 6)"
      fill="#757a80"
    />
  </svg>
);
