import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import {
  ShopForm,
  ShopFormSchema,
} from 'context/corporation/shop/components/ShopForm';
import { useFetchShop } from 'context/corporation/shop/api/fetchShop';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { PropsWithChildren } from 'react';
import { ResponseShop } from 'context/corporation/shop/api/typeResponse';
import { updateShop } from 'context/corporation/shop/api/updateShop';

import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { ResponseBrandOfResponsePaginatedCorporation } from 'context/corporation/brand/api/types';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';

export const ShopEdit = () => {
  const [{ data: corporation }] = useFetchCorporation();

  const { shopId } = useParams();

  const [{ data: shop }] = useFetchShop(shopId || '');

  if (shop === undefined || corporation === null) {
    return (
      <div>
        <PageTitle
          title="店舗詳細"
          breadcrumbs={[
            ['ホーム', '/'],
            ['店舗一覧', '/shops'],
          ]}
        />
        <LoadingBackdrop isShow />
      </div>
    );
  }

  return (
    <div>
      <PageTitle
        title="店舗詳細"
        breadcrumbs={[
          ['ホーム', '/'],
          ['店舗一覧', '/shops'],
        ]}
      />
      <ShopEditLoadd shop={shop} brands={corporation.brands} />
    </div>
  );
};

export const ShopEditLoadd = (
  props: PropsWithChildren<{
    shop: ResponseShop;
    brands: ResponseBrandOfResponsePaginatedCorporation[];
  }>
) => {
  const { shop } = props;
  const { shopId } = useParams();
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm({
    resolver: zodResolver(ShopFormSchema),
    defaultValues: {
      brandId: shop.brand.id,
      name: shop.name,
      zipCode: shop.zipCode,
      phoneNumber: shop.phoneNumber,
      businessHours: shop.businessHours,
      gradedSalaryByStores: shop.gradedSalaryByStores,
      coinAvailabilityStartedDatetime: shop.coinAvailabilityStartedDatetime,
      coinAvailabilityEndedDatetime: shop.coinAvailabilityEndedDatetime,
    } as z.infer<typeof ShopFormSchema>,
  });

  const onSubmit = useFormInstance.handleSubmit((value) => {
    startLoading('update_shop');

    updateShop(shopId || '', value)
      .then(() => {
        displayMessage({ text: '店舗を更新しました', isInterruption: false });
        navigate('/shops');
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('update_shop');
      });
  });

  return (
    <div className="flex items-center mt-[50px] rounded bg-grayscale-100">
      <ShopForm
        useForm={useFormInstance}
        onSubmit={onSubmit}
        actionLabel="更新"
        brands={props.brands}
      />
    </div>
  );
};
