import { Button, TextField, MenuItem, Dialog } from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { useState, PropsWithChildren, useEffect, useCallback } from 'react';
import { CalendarMonthIcon } from 'infra/components/UI/Icons/CalendarMonthIcon';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';

import { useNavigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useFetchEvaluationPeriods } from 'context/evaluation_period/api/useFetchEvaluationPeriods';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { formatDate } from 'infra/utils/date-fns';
import { ResponseEvaluationPeriod } from 'context/evaluation_period/api/typeResponse';
import {
  fetchOnsiteUserFinalEvaluationCompleted,
  ResponseObjectiveRecord,
} from 'context/promotion/api/fetchOnsiteUserFinalEvaluationCompleted';

import { patchPromotionLogId } from 'context/promotion/api/patchPromotionLogId';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { postPromotionLogFix } from 'context/promotion/api/postPromotionLogFix';
import { ProggressBar } from 'infra/components/UI/Steps/ProggressBar';
import { BaseSelectField } from 'infra/components/UI/Forms/BaseSelectField';
import { useFetchShops } from 'context/corporation/shop/api/fetchShops';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { Controller, useForm } from 'react-hook-form';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { COLORS } from 'tailwind.config';
import { OnsiteUserCard } from './OnsiteUserCard';
import { EditingDataType, PromotionDetailModal } from './PromotionDetailModal';

export type UserType = 'staff' | 'manager';

export type EvaluationPeriod = ResponseEvaluationPeriod;
export type CurrentGrade = {
  id: number;
  name: string;
  wageType: string;
  weightCommitment: number;
  weightCs: number;
  weightEs: number;
  weightMvp: number;
  weightProfit: number;
  weightQualitative: number;
  weightQuantitative: number;
  weightSales: number;
  updatedAt: string;
  createdAt: string;
  weightQualitativeAsCommitment: number;
  weightQualitativeAsMvp: number;
};

export type ObjectiveRecord = ResponseObjectiveRecord & {
  scoreCommitment?: number;
  scoreMvp?: number;
  scoreMbo?: number;
  currentGrade?: CurrentGrade;
  previousObjectiveRecord?: ObjectiveRecord;
};

export type PromotionLog = Pick<
  NonNullable<ResponseObjectiveRecord['promotionLog']>,
  | 'id'
  | 'salary'
  | 'isTemporaryFixed'
  | 'isFixed'
  | 'isPromoted'
  | 'objectiveRecord'
>;

export const LABEL_MAP = {
  staff: 'スタッフ',
  manager: 'マネジャー',
};

export const ObjectiveRecordListInitializing = () => {
  const { userType: _userType } = useParams();
  const userType = _userType as UserType;
  return <ObjectiveRecordList key={userType} userType={userType} />;
};

export const ObjectiveRecordList = ({
  userType,
}: PropsWithChildren<{ userType: UserType }>) => {
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();

  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const [{ data: evaluationPeriodList }] = useFetchEvaluationPeriods();

  const [evaluationPeriodId, setEvaluationPeriodId] = useState<number | null>(
    null
  );
  const [objectiveRecordList, setObjectiveRecordList] = useState<
    ResponseObjectiveRecord[] | null
  >(null);

  const [canFixPromotion, setCanFixPromotion] = useState(false);

  useEffect(() => {
    if (userType !== 'staff' && userType !== 'manager') {
      displayMessage({ text: '不正なURLです、', isInterruption: true });
      navigate('/404');
    }
  }, [userType]);
  const [isDisplayFixPromotionModal, setIsDisplayFixPromotionModal] =
    useState(false);

  useEffect(() => {
    if (
      evaluationPeriodList !== undefined &&
      evaluationPeriodList.results.length !== 0 &&
      evaluationPeriodId === null
    ) {
      const currentPeriod = getCurrentEvalationPeriod(
        evaluationPeriodList.results
      );

      if (currentPeriod) {
        setEvaluationPeriodId(currentPeriod.id);
        return;
      }

      const nearPeriod = getNearPastEvalationPeriod(
        evaluationPeriodList.results
      );

      if (nearPeriod) {
        setEvaluationPeriodId(nearPeriod.id);
        return;
      }

      setEvaluationPeriodId(
        evaluationPeriodList.results[evaluationPeriodList.results.length - 1].id
      );
    }
  }, [evaluationPeriodList, evaluationPeriodId]);

  useEffect(() => {
    if (evaluationPeriodId !== null) {
      setObjectiveRecordList(null);
      fetchOnsiteUserFinalEvaluationCompleted(
        evaluationPeriodId,
        userType
      ).then((result) => {
        setObjectiveRecordList(result.data.results);
      });
    }
  }, [evaluationPeriodId]);

  const onConfirmFixPromotion = async () => {
    if (!evaluationPeriodId) {
      displayMessage({
        text: '評価期間を選択してください',
        isInterruption: true,
      });
      return;
    }
    startLoading('fix_promotion');
    await postPromotionLogFix(evaluationPeriodId, userType)
      .then(() => {
        displayMessage({ text: '評価を確定しました', isInterruption: true });

        // 閉じる
        setIsDisplayFixPromotionModal(false);
        setCanFixPromotion(false);
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
          return;
        }
        displayMessage({ text: '不正なエラーです', isInterruption: true });
      })
      .finally(() => {
        stopLoading('fix_promotion');
      });
  };

  useEffect(() => {
    if (!objectiveRecordList) {
      return;
    }

    setCanFixPromotion(
      !objectiveRecordList
        ?.map((objectiveRecord) => {
          return objectiveRecord.promotionLog;
        })
        .some((promotion) => {
          return promotion?.isFixed;
        })
    );
  }, [objectiveRecordList]);

  if (!evaluationPeriodList?.count) {
    return (
      <WarningMessageBox
        message="評価スケジュールが作成されていません。"
        detailsMessage="先に評価スケジュールを作成してください。"
      />
    );
  }

  const selectedPeriod = evaluationPeriodList.results.find(
    (e) => e.id === evaluationPeriodId
  );

  if (!selectedPeriod) {
    return <LoadingBackdrop isShow />;
  }

  if (!objectiveRecordList) {
    return <LoadingBackdrop isShow />;
  }

  return (
    <div>
      <PageTitle
        title={`${LABEL_MAP[userType]}最終評価完了一覧`}
        breadcrumbs={[['ホーム', '/']]}
      />

      <div className="flex pt-[50px] justify-between items-center">
        <div className="flex items-center gap-[10px]">
          <CalendarMonthIcon />
          <p className="text-meta text-grayscale-700 pr-[10px]">期間</p>
          <TextField
            variant="outlined"
            select
            size="small"
            value={evaluationPeriodId}
            onChange={(e) =>
              setEvaluationPeriodId(parseInt(e.target.value, 10))
            }
            sx={{ width: '240px', backgroundColor: COLORS.GRAY_100 }}
          >
            {[...evaluationPeriodList.results]
              .reverse()
              .map((evaluationPeriod) => (
                <MenuItem key={evaluationPeriod.id} value={evaluationPeriod.id}>
                  {formatDate(new Date(evaluationPeriod.startedAt))}〜
                  {formatDate(new Date(evaluationPeriod.endedAt))}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="flex items-center gap-[20px]">
          <BaseButton
            disabled={!canFixPromotion}
            className="text-btn-large bg-main-red p-button-medium"
            onClick={() => setIsDisplayFixPromotionModal(true)}
          >
            評価を締める
          </BaseButton>
        </div>
      </div>

      <ObjectiveRecordDetail
        objectiveRecordList={objectiveRecordList!}
        userType={userType}
      />

      {isDisplayFixPromotionModal && (
        <ConfirmationDialog
          userTypeLabel={userType === 'manager' ? 'マネジャー' : 'スタッフ'}
          open
          onClose={() => {
            setIsDisplayFixPromotionModal(false);
          }}
          onConfirm={onConfirmFixPromotion}
        />
      )}
    </div>
  );
};

const ObjectiveRecordDetail = (
  props: PropsWithChildren<{
    objectiveRecordList: ObjectiveRecord[];
    userType: UserType;
  }>
) => {
  const [, displayMessage] = useAtom(displayMessageAtom);
  const [{ data: shops }] = useFetchShops();
  const [{ data: corporation }] = useFetchCorporation();
  const gradeList = corporation?.positions.flatMap(
    (position) => position.grades
  );
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();
  const [displayedObjectiveRecordId, setDisplayedObjectiveRecordId] = useState<
    number | null
  >(null);

  const { control: searchControll, watch: searchWatch } = useForm();

  const watchSearchShop = searchWatch('shop', '店舗');
  const watchSearchGrade = searchWatch('grade', '等級');
  const watchSearchEmploymentStatus = searchWatch(
    'employmentStatus',
    '雇用形態'
  );

  const [searchedObjectiveRecordList, setSearchedObjectiveRecordList] =
    useState(props.objectiveRecordList);
  useEffect(() => {
    setSearchedObjectiveRecordList(
      props.objectiveRecordList.filter((objectiveRecord) => {
        let conditionsShop =
          watchSearchShop === objectiveRecord.onsiteUser.shopName;
        let conditionsGrade =
          watchSearchGrade === objectiveRecord.onsiteUser.gradeName;
        let conditionsSearchEmploymentStatus =
          watchSearchEmploymentStatus ===
          objectiveRecord.onsiteUser.employmentStatus;

        if (watchSearchShop === '店舗' || !watchSearchShop) {
          conditionsShop = true;
        }

        if (watchSearchGrade === '等級' || !watchSearchGrade) {
          conditionsGrade = true;
        }

        if (
          watchSearchEmploymentStatus === '雇用形態' ||
          !watchSearchEmploymentStatus
        ) {
          conditionsSearchEmploymentStatus = true;
        }

        return (
          conditionsShop && conditionsGrade && conditionsSearchEmploymentStatus
        );
      })
    );
  }, [watchSearchShop, watchSearchGrade, watchSearchEmploymentStatus]);

  const [editingPromotionLogs, setEditingPromotionLogs] = useState(
    searchedObjectiveRecordList
      .map((objectiveRecord) => objectiveRecord.promotionLog)
      .filter((promotionLog) => promotionLog !== null) as PromotionLog[]
  );

  const displayedPromotionLog =
    editingPromotionLogs.find(
      (p) => p.objectiveRecord === displayedObjectiveRecordId
    ) || null;
  const displayedObjectiveRecord =
    searchedObjectiveRecordList.find(
      (o) => o.id === displayedObjectiveRecordId
    ) || null;

  const onSavePromotion = useCallback(
    async (data: EditingDataType, id?: string) => {
      const requestId = displayedPromotionLog?.id || id;

      if (!requestId) {
        displayMessage({ text: '不正なエラーです', isInterruption: true });
        return;
      }

      if (data.isPromoted && (!data.salary || data.salary === 0)) {
        displayMessage({
          text: '給与を設定してください',
          isInterruption: true,
        });
        return;
      }

      startLoading('save_promotion');
      await patchPromotionLogId(requestId, {
        is_promoted: data.isPromoted,
        salary: data.salary || 0,
      })
        .then(() => {
          // ローカルデータ更新
          setEditingPromotionLogs(
            editingPromotionLogs.map((p) => {
              if (p.id === requestId) {
                return {
                  ...p,
                  isTemporaryFixed: true,
                  isPromoted: data.isPromoted,
                  salary: data.salary,
                };
              }
              return p;
            })
          );
          // モーダル閉じる
          setDisplayedObjectiveRecordId(null);
        })
        .catch((error) => {
          if (error.response?.data?.nonFieldErrors) {
            displayNonFieldApiError(error);
            return;
          }
          displayMessage({ text: '不正なエラーです', isInterruption: true });
        })
        .finally(() => {
          stopLoading('save_promotion');
        });
    },
    [displayedPromotionLog, editingPromotionLogs]
  );

  const [allCount, setAllCount] = useState(searchedObjectiveRecordList.length);
  const [completedCount, setCompletedCount] = useState(
    editingPromotionLogs.filter((p) => p.isTemporaryFixed).length
  );

  useEffect(() => {
    setAllCount(searchedObjectiveRecordList.length);
    setCompletedCount(
      searchedObjectiveRecordList
        .map((objectiveRecord) => objectiveRecord.promotionLog)
        .filter((p) => p?.isTemporaryFixed).length
    );
  }, [searchedObjectiveRecordList]);

  return (
    <div>
      <div className="flex items-center justify-between pt-[20px]">
        <div className="flex items-center flex-row gap-[10px]">
          <p className="text-base text-grayscale-700">
            全{searchedObjectiveRecordList.length}
            件中 1〜
            {searchedObjectiveRecordList.length}
            件を表示
            {'　　'}
            進捗状況
          </p>
          <ProggressBar allCount={allCount} completedCount={completedCount} />

          <p className="text-base text-grayscale-700">
            （全
            {allCount}
            人中
            {completedCount}
            人完了）
          </p>
        </div>

        <div className="flex items-center gap-[10px]">
          <Controller
            name="employmentStatus"
            control={searchControll}
            render={({ field }) => {
              return (
                <BaseSelectField
                  {...field}
                  allLabel="雇用形態"
                  defaultValue="雇用形態"
                  sx={{
                    width: 180,
                    '.MuiSelect-select': {
                      backgroundColor: COLORS.GRAY_100,
                    },
                  }}
                  menuItems={['fullTimeEmployee', 'partTimeEmployee']?.map(
                    (value) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {
                            {
                              fullTimeEmployee: '正社員',
                              partTimeEmployee: 'アルバイト・パート',
                            }[value]
                          }
                        </MenuItem>
                      );
                    }
                  )}
                />
              );
            }}
          />

          <Controller
            name="shop"
            control={searchControll}
            render={({ field }) => {
              return (
                <BaseSelectField
                  {...field}
                  allLabel="店舗"
                  defaultValue="店舗"
                  sx={{
                    width: 180,
                    '.MuiSelect-select': {
                      backgroundColor: COLORS.GRAY_100,
                    },
                  }}
                  menuItems={shops?.results?.map((shop) => {
                    return (
                      <MenuItem key={shop.id} value={shop.name}>
                        {shop.name}
                      </MenuItem>
                    );
                  })}
                />
              );
            }}
          />

          <Controller
            name="grade"
            control={searchControll}
            render={({ field }) => {
              return (
                <BaseSelectField
                  {...field}
                  allLabel="等級"
                  defaultValue="等級"
                  sx={{
                    width: 180,
                    '.MuiSelect-select': {
                      backgroundColor: COLORS.GRAY_100,
                    },
                  }}
                  menuItems={gradeList?.map((grade) => {
                    return (
                      <MenuItem key={grade.id} value={grade.name}>
                        {grade.name}
                      </MenuItem>
                    );
                  })}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="mt-[30px]">
        {(!searchedObjectiveRecordList ||
          searchedObjectiveRecordList.length === 0) && (
          <WarningMessageBox
            message="まだ最終評価が完了したスタッフはいません。"
            detailsMessage="最終自己評価と上司評価が完了すると表示されます。"
          />
        )}

        {searchedObjectiveRecordList &&
          searchedObjectiveRecordList.length > 0 && (
            <div className="grid grid-cols-3 gap-[20px] pt-[20px]">
              {searchedObjectiveRecordList.map((objectiveRecord) => (
                <button
                  type="button"
                  key={objectiveRecord.id}
                  className={`text-left rounded border-1 border-grayscale-500 bg-grayscale-100 ${
                    objectiveRecord.score
                      ? 'hover:border-grayscale-600 cursor-pointer'
                      : 'cursor-default'
                  }`}
                  onClick={() =>
                    setDisplayedObjectiveRecordId(objectiveRecord.id)
                  }
                  aria-label="最終評価を表示"
                >
                  <OnsiteUserCard
                    promotionLog={editingPromotionLogs.find((p) => {
                      return p.objectiveRecord === objectiveRecord.id;
                    })}
                    objectiveRecord={objectiveRecord}
                    onSave={onSavePromotion}
                  />
                </button>
              ))}
            </div>
          )}
      </div>

      {displayedObjectiveRecord !== null && displayedPromotionLog !== null && (
        <PromotionDetailModal
          userType={props.userType}
          promotionLog={displayedPromotionLog}
          grade={{
            currentGradeName: displayedObjectiveRecord.currentGradeName,
            nextGradeName: displayedObjectiveRecord.nextGradeName,
          }}
          objectiveRecord={displayedObjectiveRecord}
          onClose={() => setDisplayedObjectiveRecordId(null)}
          onSave={onSavePromotion}
        />
      )}
    </div>
  );
};

export const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  userTypeLabel,
}: PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  userTypeLabel: string;
}>) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <div className="p-modal">
      <h3 className="text-section-heading font-bold">
        {userTypeLabel}
        最終評価を締めて、評価を確定しますか？
      </h3>
      {userTypeLabel === 'マネジャー' && (
        <p className="mt-[40px] text-base">
          確定すると各マネジャーに最終評価結果が通知されます。
        </p>
      )}

      <p className="mt-[20px] text-warning-red text-base leading-expanded">
        ※ 確定すると、対象の
        {userTypeLabel}
        の評価情報を変更することはできません。内容に誤りがないか、確認の上確定を押してください。
      </p>

      <div className="flex justify-center items-center mt-[60px] gap-[40px]">
        <Button
          size="large"
          sx={{ pr: 2, pl: 2, width: '120px' }}
          onClick={onClose}
        >
          <p className="text-grayscale-700 font-normal">キャンセル</p>
        </Button>

        <BaseButton
          className="w-btn-small text-btn-confirm p-button-small"
          onClick={onConfirm}
        >
          確定
        </BaseButton>
      </div>
    </div>
  </Dialog>
);

/**
 * 現在の日付を基準に評価期間リストから現在の評価期間を取得します。
 * 使用例
 * const evaluationPeriod = getCurrentEvalationPeriod(evaluationPeriodList.results);
 * console.log(evaluationPeriod.id);
 *
 * @params evaluationPeriodListResults (ResponseEvaluationPeriod[]): 評価期間のリスト。
 * @return ResponseEvaluationPeriod: 現在の評価期間
 */
function getCurrentEvalationPeriod(
  evaluationPeriodListResults: ResponseEvaluationPeriod[]
) {
  const now = new Date();

  const currentPeriod = evaluationPeriodListResults.find((period) => {
    const startedAt = new Date(period.startedAt);
    const endedAt = new Date(period.endedAt);

    return endedAt >= now && startedAt <= now;
  });

  return currentPeriod;
}

/**
 * 現在の日付を基準に評価期間リストから現在に一番近い過去の評価期間を取得します。
 * 使用例
 * const evaluationPeriod = getCurrentEvalationPeriod(evaluationPeriodList.results);
 * console.log(evaluationPeriod.id);
 *
 * @params evaluationPeriodListResults (ResponseEvaluationPeriod[]): 評価期間のリスト。
 * @return ResponseEvaluationPeriod: 現在に一番近い過去の評価期間
 */
function getNearPastEvalationPeriod(
  evaluationPeriodListResults: ResponseEvaluationPeriod[]
) {
  const now = new Date();

  const pastPeriod = evaluationPeriodListResults
    .filter((period) => new Date(period.endedAt) <= now)
    .sort((a, b) => {
      const dateA = new Date(a.endedAt).getTime();
      const dateB = new Date(b.endedAt).getTime();
      return dateB - dateA;
    });

  return pastPeriod[0];
}
