export const FlagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g id="flag" transform="translate(0 0)" opacity="0.5">
        <g
          id="_-Product-Icons"
          data-name="🔍-Product-Icons"
          transform="translate(-3 -2.998)"
        >
          <g id="ic_fluent_flag_24_regular" transform="translate(3 2.998)">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M3,3.787A.77.77,0,0,1,3.75,3h16.5a.793.793,0,0,1,.6,1.263L16.686,10.1l4.163,5.843a.793.793,0,0,1-.6,1.263H4.5v5a.777.777,0,0,1-.648.782L3.75,23a.764.764,0,0,1-.743-.682L3,22.208Zm15.749.789H4.5V15.63h14.25l-3.6-5.053a.823.823,0,0,1,0-.947Z"
              transform="translate(-3 -2.998)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
