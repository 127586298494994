export const FilterListIcon = () => (
  <svg
    id="_-Product-Icons"
    data-name="🔍-Product-Icons"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 20 15"
  >
    <g id="ic_fluent_filter_24_filled">
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M11.5,18.5h5a1.25,1.25,0,0,1,.146,2.492L16.5,21h-5a1.25,1.25,0,0,1-.146-2.492L11.5,18.5h0ZM9,12.25H19a1.25,1.25,0,0,1,.146,2.492L19,14.75H9a1.25,1.25,0,0,1-.146-2.492L9,12.25H9ZM5.25,6h17.5A1.25,1.25,0,0,1,22.9,8.492L22.75,8.5H5.25A1.25,1.25,0,0,1,5.1,6.008L5.25,6h0Z"
        transform="translate(-4 -6)"
        fill="#B9BCBF"
      />
    </g>
  </svg>
);
