import zod from 'zod';
import { Dialog } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { ResponsePosition } from 'context/corporation/position/api/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { bulkPosition } from 'context/corporation/position/api/bulkPosition';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useAtom } from 'jotai';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { zodResolver } from '@hookform/resolvers/zod';
import { PositionEditForm } from './PositionEditForm';

const PositionFormSchema = zod.object({
  positions: zod.array(
    zod.object({
      id: zod.number().nullable(),
      name: zod.string().min(1),
      employmentStatus: zod.string().min(1),
      grades: zod.array(
        zod.object({
          id: zod.number().nullable(),
          name: zod.string().min(1),
        })
      ),
    })
  ),
});

type PositionFormDataType = zod.infer<typeof PositionFormSchema>;

type Props = {
  modalState: [boolean, Dispatch<SetStateAction<boolean>>];
  onSaved: () => void;
  positions: ResponsePosition[];
};

export const PositionEditFormDialog = (props: Props) => {
  const [isModal, setIsModal] = props.modalState;

  const defaultValues = props.positions;
  const form = useForm<PositionFormDataType>({
    resolver: zodResolver(PositionFormSchema),
    defaultValues: {
      positions: defaultValues,
    },
  });

  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  useEffect(() => {
    form.reset({ positions: defaultValues });
  }, [isModal]);

  const submit: SubmitHandler<any> = (data) => {
    startLoading('save_occupation');

    const requestPostionData = data.positions.map((position: any) => ({
      id: position.id,
      name: position.name,
      gradeId: position.grades[0]?.id,
      employmentStatus: position.employmentStatus,
      gradeName: position.grades[0].name,
    }));

    bulkPosition({ positions: requestPostionData })
      .then(() => {
        displayMessage({
          text: `職位・等級を一括更新しました。`,
          isInterruption: false,
        });
        props.onSaved();
        setIsModal(false);
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            form.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('save_occupation');
      });
  };

  return (
    <Dialog
      open={isModal}
      onClose={() => {
        setIsModal(false);
      }}
      maxWidth={false}
    >
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={() => {
              form.handleSubmit(submit)();
            }}
          >
            保存
          </BaseButton>
        }
        closeHandler={() => {
          setIsModal(false);
        }}
        height="700px"
      >
        <div className="pb-[40px]">
          <PositionEditForm onSaved={props.onSaved} form={form} />
        </div>
      </BaseModal>
    </Dialog>
  );
};
