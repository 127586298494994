/**
 * 運用設定 キャリアパス関連ルーティング
 */
import { CareerPathSetting } from 'context/corporation/career_path/components';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const CareerPathSettingRoute: RouteType = {
  path: '/operation-setting/career-path-setting',
  element: <CareerPathSetting />,
  do_it: () => (
    <Route
      path={CareerPathSettingRoute.path}
      element={CareerPathSettingRoute.element}
    />
  ),
};

export const routes = () => <>{CareerPathSettingRoute.do_it()}</>;
