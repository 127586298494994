export const BusinessIcon = () => (
  <svg
    id="コンポーネント_47_5"
    data-name="コンポーネント 47 – 5"
    xmlns="http://www.w3.org/2000/svg"
    width="26.261"
    height="32"
    viewBox="0 0 26.261 32"
  >
    <g id="building.1">
      <path
        id="パス_3016"
        data-name="パス 3016"
        d="M9.141,5.5a1.641,1.641,0,1,0,1.641,1.641A1.641,1.641,0,0,0,9.141,5.5Z"
        transform="translate(-1.756 0.244)"
        fill="#048cf2"
      />
      <path
        id="パス_3017"
        data-name="パス 3017"
        d="M7.5,14.141a1.641,1.641,0,1,1,1.641,1.641A1.641,1.641,0,0,1,7.5,14.141Z"
        transform="translate(-1.756 4.731)"
        fill="#048cf2"
      />
      <path
        id="パス_3018"
        data-name="パス 3018"
        d="M9.141,9a1.641,1.641,0,1,0,1.641,1.641A1.641,1.641,0,0,0,9.141,9Z"
        transform="translate(-1.756 2.487)"
        fill="#048cf2"
      />
      <path
        id="パス_3019"
        data-name="パス 3019"
        d="M11,7.141a1.641,1.641,0,1,1,1.641,1.641A1.641,1.641,0,0,1,11,7.141Z"
        transform="translate(0.487 0.244)"
        fill="#048cf2"
      />
      <path
        id="パス_3020"
        data-name="パス 3020"
        d="M12.641,12.5a1.641,1.641,0,1,0,1.641,1.641A1.641,1.641,0,0,0,12.641,12.5Z"
        transform="translate(0.487 4.731)"
        fill="#048cf2"
      />
      <path
        id="パス_3021"
        data-name="パス 3021"
        d="M14.5,14.141a1.641,1.641,0,1,1,1.641,1.641A1.641,1.641,0,0,1,14.5,14.141Z"
        transform="translate(2.731 4.731)"
        fill="#048cf2"
      />
      <path
        id="パス_3022"
        data-name="パス 3022"
        d="M12.641,9a1.641,1.641,0,1,0,1.641,1.641A1.641,1.641,0,0,0,12.641,9Z"
        transform="translate(0.487 2.487)"
        fill="#048cf2"
      />
      <path
        id="パス_3023"
        data-name="パス 3023"
        d="M7.692,2A3.692,3.692,0,0,0,4,5.692V32.769A1.231,1.231,0,0,0,5.231,34h23.8a1.231,1.231,0,0,0,1.231-1.231V18a3.692,3.692,0,0,0-3.692-3.692H24.513V5.692A3.692,3.692,0,0,0,20.821,2ZM6.462,5.692A1.231,1.231,0,0,1,7.692,4.462H20.821a1.231,1.231,0,0,1,1.231,1.231v9.845a1.231,1.231,0,0,0,1.231,1.231h3.287A1.231,1.231,0,0,1,27.8,18v13.54H24.513V27.026a1.231,1.231,0,0,0-1.231-1.231H10.974a1.231,1.231,0,0,0-1.231,1.231v4.513H6.462Zm15.59,22.564v3.282H18.359V28.256Zm-6.154,0v3.282H12.205V28.256Z"
        transform="translate(-4 -2)"
        fill="#048cf2"
      />
    </g>
  </svg>
);
