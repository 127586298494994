import { useState, ChangeEvent, useEffect } from 'react';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { addDays, format } from 'date-fns';
import { ResponseEvaluationPeriod } from 'context/evaluation_period/api/typeResponse';

import { formatDate } from 'infra/utils/date-fns';
import { getEndedAt } from 'context/evaluation_period/utils';
import { UseFormReturn, useForm } from 'react-hook-form';
import { createEvaluationPeriod } from 'context/evaluation_period/api/createEvaluationPeriod';
import { updateEvaluationPeriod } from 'context/evaluation_period/api/updateEvaluationPeriod';
import { useAtom, atom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';

import { ResponseEvaluationPeriodGroups } from 'context/evaluation_period/api/useFetchEvaluationPeriodGroups';
import { ModalState } from './_components/state.library';

/**
 * 本ファイルで扱う型定義
 */
export namespace Types {
  export const EvaluationPeriodScheme = z.object({
    id: z.number().min(1).optional(),
    startedAt: z.string().min(1),
    endedAt: z.string().min(1),
    objectiveCreationStartedAt: z.string().min(1),
    objectiveCreationEndedAt: z.string().min(1),
    intermediateMyselfEvaluationStartedAt: z.string().min(1),
    intermediateMyselfEvaluationEndedAt: z.string().min(1),
    intermediateBossEvaluationStartedAt: z.string().min(1),
    intermediateBossEvaluationEndedAt: z.string().min(1),
    intermediateInterviewStartedAt: z.string().min(1),
    intermediateInterviewEndedAt: z.string().min(1),
    finalMyselfEvaluationStartedAt: z.string().min(1),
    finalMyselfEvaluationEndedAt: z.string().min(1),
    finalBossEvaluationStartedAt: z.string().min(1),
    finalBossEvaluationEndedAt: z.string().min(1),
    finalInterviewStartedAt: z.string().min(1),
    finalInterviewEndedAt: z.string().min(1),
    headquartersEvaluationConsiderationStartedAt: z.string().min(1),
    headquartersEvaluationConsiderationEndedAt: z.string().min(1),
    headquartersEvaluationFixedDate: z.string().min(1),
    headquartersEvaluationNotifiedDate: z.string().min(1),
    headquartersEvaluationNotifiedTime: z.string().min(1),
    interviewAfterHeadquartersEvaluationStartedAt: z.string().min(1),
    interviewAfterHeadquartersEvaluationEndedAt: z.string().min(1),
  });

  export const EvaluationPeriodFromSchame = z.object({
    id: z.number().min(1).optional(),
    evaluationCycle: z.coerce.number().min(1),
    evaluationPeriods: EvaluationPeriodScheme.array(),
  });

  export type EvaluationPeriodFormListData = z.infer<
    typeof EvaluationPeriodFromSchame
  >;

  /** ユーザーが入力可能な要素のみで型を定義 */
  export type EditablePropertyInEvaluationPeriod = Pick<
    ResponseEvaluationPeriod,
    | 'startedAt'
    | 'objectiveCreationStartedAt'
    | 'objectiveCreationEndedAt'
    | 'intermediateMyselfEvaluationStartedAt'
    | 'intermediateMyselfEvaluationEndedAt'
    | 'intermediateBossEvaluationStartedAt'
    | 'intermediateBossEvaluationEndedAt'
    | 'intermediateInterviewStartedAt'
    | 'intermediateInterviewEndedAt'
    | 'finalMyselfEvaluationStartedAt'
    | 'finalMyselfEvaluationEndedAt'
    | 'finalBossEvaluationStartedAt'
    | 'finalBossEvaluationEndedAt'
    | 'finalInterviewStartedAt'
    | 'finalInterviewEndedAt'
    | 'headquartersEvaluationConsiderationStartedAt'
    | 'headquartersEvaluationConsiderationEndedAt'
    | 'headquartersEvaluationFixedDate'
    | 'interviewAfterHeadquartersEvaluationStartedAt'
    | 'interviewAfterHeadquartersEvaluationEndedAt'
  > & {
    headquartersEvaluationNotifiedDate: string;
    headquartersEvaluationNotifiedTime: string;
  };
}

/**
 * フォーム関連の基本機能
 */
export namespace Basic {
  export const useFormAtom =
    atom<UseFormReturn<Types.EvaluationPeriodFormListData> | null>(null);

  export const defaultValues = (
    evaluationPeriodFormListData: ResponseEvaluationPeriodGroups
  ) => {
    const formatDateTime = (date: string) =>
      format(new Date(date), 'yyyy-MM-dd');

    return {
      id: evaluationPeriodFormListData.id,
      evaluationCycle: evaluationPeriodFormListData.evaluationCycle,
      evaluationPeriods: evaluationPeriodFormListData.evaluationPeriods?.map(
        (evaluationPeriod) => {
          return {
            id: evaluationPeriod.id,
            startedAt: formatDateTime(evaluationPeriod.startedAt),
            endedAt: formatDateTime(evaluationPeriod.endedAt),
            objectiveCreationStartedAt: formatDateTime(
              evaluationPeriod.objectiveCreationStartedAt
            ),
            objectiveCreationEndedAt: formatDateTime(
              evaluationPeriod.objectiveCreationEndedAt
            ),
            intermediateMyselfEvaluationStartedAt: formatDateTime(
              evaluationPeriod.intermediateMyselfEvaluationStartedAt
            ),
            intermediateMyselfEvaluationEndedAt: formatDateTime(
              evaluationPeriod.intermediateMyselfEvaluationEndedAt
            ),
            intermediateBossEvaluationStartedAt: formatDateTime(
              evaluationPeriod.intermediateBossEvaluationStartedAt
            ),
            intermediateBossEvaluationEndedAt: formatDateTime(
              evaluationPeriod.intermediateBossEvaluationEndedAt
            ),
            intermediateInterviewStartedAt: formatDateTime(
              evaluationPeriod.intermediateInterviewStartedAt
            ),
            intermediateInterviewEndedAt: formatDateTime(
              evaluationPeriod.intermediateInterviewEndedAt
            ),
            finalMyselfEvaluationStartedAt: formatDateTime(
              evaluationPeriod.finalMyselfEvaluationStartedAt
            ),
            finalMyselfEvaluationEndedAt: formatDateTime(
              evaluationPeriod.finalMyselfEvaluationEndedAt
            ),
            finalBossEvaluationStartedAt: formatDateTime(
              evaluationPeriod.finalBossEvaluationStartedAt
            ),
            finalBossEvaluationEndedAt: formatDateTime(
              evaluationPeriod.finalBossEvaluationEndedAt
            ),
            finalInterviewStartedAt: formatDateTime(
              evaluationPeriod.finalInterviewStartedAt
            ),
            finalInterviewEndedAt: formatDateTime(
              evaluationPeriod.finalInterviewEndedAt
            ),
            headquartersEvaluationConsiderationStartedAt: formatDateTime(
              evaluationPeriod.headquartersEvaluationConsiderationStartedAt
            ),
            headquartersEvaluationConsiderationEndedAt: formatDateTime(
              evaluationPeriod.headquartersEvaluationConsiderationEndedAt
            ),
            headquartersEvaluationFixedDate: formatDateTime(
              evaluationPeriod.headquartersEvaluationFixedDate
            ),
            headquartersEvaluationNotifiedDate: format(
              new Date(evaluationPeriod.headquartersEvaluationNotifiedAt),
              'yyyy-MM-dd'
            ),
            headquartersEvaluationNotifiedTime: format(
              new Date(evaluationPeriod.headquartersEvaluationNotifiedAt),
              'HH:mm'
            ),
            interviewAfterHeadquartersEvaluationStartedAt: formatDateTime(
              evaluationPeriod.interviewAfterHeadquartersEvaluationStartedAt
            ),
            interviewAfterHeadquartersEvaluationEndedAt: formatDateTime(
              evaluationPeriod.interviewAfterHeadquartersEvaluationEndedAt
            ),
          };
        }
      ),
    };
  };

  export const initForm = () => {
    const [_, setFormAtom] = useAtom(useFormAtom);

    const form = useForm<Types.EvaluationPeriodFormListData>({
      resolver: zodResolver(Types.EvaluationPeriodFromSchame),
    });

    useEffect(() => {
      setFormAtom(form);
    }, []);

    return form;
  };

  export const restForm = (
    formUseAtom: UseFormReturn<Types.EvaluationPeriodFormListData>,
    evaluationPeriodFormListData: ResponseEvaluationPeriodGroups
  ) => {
    return formUseAtom.reset(evaluationPeriodFormListData);
  };
}

/**
 * フォームの振る舞い管理
 */
export namespace Behavior {
  /**
   * 評価サイクルの切り替え設定の状態管理
   */
  export const manageEvaluationCycle = (
    formUseAtom: UseFormReturn<Types.EvaluationPeriodFormListData>
  ) => {
    const { watch, setValue } = formUseAtom;

    const evaluationCycle = watch('evaluationCycle');
    const [numberOfPeriods, setNumberOfPeriods] = useState(0);

    return {
      onChangeListener(event: ChangeEvent<HTMLInputElement>) {
        const value = Number(event.target.value);
        setValue('evaluationCycle', value);
        setNumberOfPeriods(12 / value);

        formUseAtom.reset({
          ...formUseAtom.formState.defaultValues,
          evaluationCycle: value,
          evaluationPeriods: [
            formUseAtom.formState.defaultValues?.evaluationPeriods?.[0],
          ],
        });
      },
      numberOfPeriods,
      evaluationCycle,
    };
  };

  export const reCalcEvaluationPeriodByStartedAt = (
    formUseAtom: UseFormReturn<Types.EvaluationPeriodFormListData>,
    index: number
  ) => {
    const { setValue, getValues } = formUseAtom;

    const startedAt = getValues(`evaluationPeriods.${index}.startedAt`);
    const cycle = getValues('evaluationCycle');

    if (!startedAt || !cycle) {
      return false;
    }

    const yyyymmddAddedDays = (yyyymmdd: string, unit: number) =>
      formatDate(
        addDays(new Date(`${yyyymmdd} 00:00:00`), unit * 10 * (cycle / 3)),
        '-'
      );

    const _ = setValue;
    _(`evaluationPeriods.${index}.objectiveCreationStartedAt`, startedAt);

    _(
      `evaluationPeriods.${index}.objectiveCreationEndedAt`,
      yyyymmddAddedDays(startedAt, 1)
    );
    _(
      `evaluationPeriods.${index}.intermediateMyselfEvaluationStartedAt`,
      yyyymmddAddedDays(startedAt, 1)
    );
    _(
      `evaluationPeriods.${index}.intermediateMyselfEvaluationEndedAt`,
      yyyymmddAddedDays(startedAt, 2)
    );
    _(
      `evaluationPeriods.${index}.intermediateBossEvaluationStartedAt`,
      yyyymmddAddedDays(startedAt, 2)
    );
    _(
      `evaluationPeriods.${index}.intermediateBossEvaluationEndedAt`,
      yyyymmddAddedDays(startedAt, 3)
    );
    _(
      `evaluationPeriods.${index}.intermediateInterviewStartedAt`,
      yyyymmddAddedDays(startedAt, 3)
    );
    _(
      `evaluationPeriods.${index}.intermediateInterviewEndedAt`,
      yyyymmddAddedDays(startedAt, 4)
    );
    _(
      `evaluationPeriods.${index}.finalMyselfEvaluationStartedAt`,
      yyyymmddAddedDays(startedAt, 3)
    );
    _(
      `evaluationPeriods.${index}.finalMyselfEvaluationEndedAt`,
      yyyymmddAddedDays(startedAt, 4)
    );
    _(
      `evaluationPeriods.${index}.finalBossEvaluationStartedAt`,
      yyyymmddAddedDays(startedAt, 4)
    );
    _(
      `evaluationPeriods.${index}.finalBossEvaluationEndedAt`,
      yyyymmddAddedDays(startedAt, 5)
    );
    _(
      `evaluationPeriods.${index}.finalInterviewStartedAt`,
      yyyymmddAddedDays(startedAt, 5)
    );
    _(
      `evaluationPeriods.${index}.finalInterviewEndedAt`,
      yyyymmddAddedDays(startedAt, 6)
    );
    _(
      `evaluationPeriods.${index}.headquartersEvaluationConsiderationStartedAt`,
      yyyymmddAddedDays(startedAt, 5)
    );
    _(
      `evaluationPeriods.${index}.headquartersEvaluationConsiderationEndedAt`,
      yyyymmddAddedDays(startedAt, 6)
    );
    _(
      `evaluationPeriods.${index}.headquartersEvaluationFixedDate`,
      yyyymmddAddedDays(startedAt, 7)
    );
    _(
      `evaluationPeriods.${index}.headquartersEvaluationNotifiedDate`,
      yyyymmddAddedDays(startedAt, 8)
    );
    _(`evaluationPeriods.${index}.headquartersEvaluationNotifiedTime`, '16:00');
    _(
      `evaluationPeriods.${index}.interviewAfterHeadquartersEvaluationStartedAt`,
      yyyymmddAddedDays(startedAt, 8)
    );
    _(
      `evaluationPeriods.${index}.interviewAfterHeadquartersEvaluationEndedAt`,
      yyyymmddAddedDays(startedAt, 9)
    );

    return true;
  };
}

/**
 * 外部との統合関連
 */
export namespace integration {
  export const submit = (
    formUseAtom: UseFormReturn<Types.EvaluationPeriodFormListData>,
    onSave: () => void
  ) => {
    const modalState = ModalState();

    const [, startLoading] = useAtom(startLoadingAtom);
    const [, stopLoading] = useAtom(stopLoadingAtom);
    const [, displayMessage] = useAtom(displayMessageAtom);
    const displayNonFieldApiError = useDisplayNonFieldApiError();

    if (!formUseAtom) {
      return <span />;
    }
    const { handleSubmit, setError } = formUseAtom;

    return handleSubmit(
      (data: any) => {
        startLoading('save_occupation');
        const isCreate = !data?.id;
        const process = isCreate
          ? createEvaluationPeriod(data)
          : updateEvaluationPeriod(data?.id!, data);

        process
          .then(() => {
            displayMessage({
              text: `評価スケジュールを${isCreate ? '登録' : '更新'}しました`,
              isInterruption: false,
            });
            modalState.closeHandler();
            onSave();
            window.location.reload();
          })
          .catch((error) => {
            if (error.response?.data?.nonFieldErrors) {
              displayNonFieldApiError(error);
            }

            const errorDetailMap = error.response?.data as {
              [key: string]: string[];
            };
            if (errorDetailMap) {
              Object.keys(errorDetailMap).forEach((key) => {
                setError(key as any, {
                  type: 'custom',
                  message: errorDetailMap[key][0],
                });
              });
            }
          })
          .finally(() => {
            stopLoading('save_occupation');
          });
      },
      (error: any) => {
        console.warn(error);
      }
    );
  };
}
