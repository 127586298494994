import { OnsiteUserDetailResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { getBusinessTypeLabel } from 'context/users/onsite_users/_infra/state/const/businessType';
import { formatDate, formatYearMonth } from 'infra/utils/date-fns';
import { getEmploymentStatusLabel } from 'context/users/onsite_users/_infra/state/const/employmentStatus';
import { getFinalEvaluationLabel } from 'context/users/onsite_users/_infra/state/const/finalEducation';
import { PREFECTURES } from 'context/users/onsite_users/_infra/state/const/prefecture';
import { getQualificationLabel } from 'context/users/onsite_users/_infra/state/const/qualification';
import { Divider } from 'infra/components/UI/Elements/Divider';

const LabelAndValueItem = (props: {
  label: string;
  value?: string | null;
  isLast?: boolean;
}) => {
  const borderClass = props.isLast ? '' : 'border-b-1 border-grayscale-500';
  return (
    <div className={`${borderClass} pb-[22px] mb-[22px]`}>
      <p className="text-meta text-grayscale-700">{props.label}</p>
      <p className="text-base whitespace-pre pt-[6px]">{props.value}</p>
    </div>
  );
};

export const ProfileTab = (props: { onsiteUser: OnsiteUserDetailResponse }) => {
  const EMPTY = '（未設定）';
  const { qualifications } = props.onsiteUser;
  return (
    <div className="flex px-[30px] py-[40px] flex-col">
      <p className="font-bold">基本情報</p>

      <Divider className="border-b-2 mt-[20px] mb-[24px]" />

      <LabelAndValueItem
        label="お名前"
        value={`${props.onsiteUser.lastName} ${props.onsiteUser.firstName}`}
      />

      <LabelAndValueItem
        label="メールアドレス"
        value={props.onsiteUser.email}
      />

      <LabelAndValueItem
        label="性別"
        value={props.onsiteUser.sex === '0' ? '男性' : '女性'}
      />

      <LabelAndValueItem
        label="電話番号"
        value={props.onsiteUser.phoneNumber}
      />

      <LabelAndValueItem
        label="住所"
        value={
          props.onsiteUser.prefecture
            ? PREFECTURES[props.onsiteUser.prefecture]
            : EMPTY
        }
      />

      <LabelAndValueItem
        label="生年月日"
        value={
          props.onsiteUser.dateOfBirth
            ? formatDate(new Date(props.onsiteUser.dateOfBirth))
            : EMPTY
        }
        isLast
      />

      <p className="font-bold">学歴・資格</p>

      <Divider className="border-b-2 mt-[20px] mb-[24px]" />

      <LabelAndValueItem
        label="最終学歴"
        value={
          props.onsiteUser.finalEducation
            ? getFinalEvaluationLabel(props.onsiteUser.finalEducation)
            : EMPTY
        }
      />

      <LabelAndValueItem
        label="学部・学科"
        value={props.onsiteUser.department || EMPTY}
      />

      <LabelAndValueItem
        label="卒業年"
        value={
          props.onsiteUser.graduationYearMonth
            ? formatDate(new Date(props.onsiteUser.graduationYearMonth))
            : EMPTY
        }
      />

      <LabelAndValueItem
        label="保有資格"
        value={
          qualifications
            ?.map((q) => getQualificationLabel(q.qualification))
            .join('\n') || EMPTY
        }
        isLast
      />

      {props.onsiteUser.workExperiences.map((w, i) => {
        return (
          <>
            <p className="font-bold" key={`職歴${w.id}`}>
              職歴
              {i + 1}
            </p>

            <Divider
              className="border-b-2 mt-[20px] mb-[24px]"
              key={`職歴Divider${w.id}`}
            />

            <LabelAndValueItem
              key={`入社日${w.id}`}
              label="入社日"
              value={w.joinedYearMonth && formatYearMonth(w.joinedYearMonth)}
            />

            <LabelAndValueItem
              key={`退職日${w.id}`}
              label="退職日"
              value={
                w.leftYearMonth ? formatYearMonth(w.leftYearMonth) : '現在'
              }
            />

            <LabelAndValueItem
              key={`会社名・店舗名${w.id}`}
              label="会社名・店舗名"
              value={w.workplaceName}
            />

            <LabelAndValueItem
              key={`業態${w.id}`}
              label="業態"
              value={w.businessType && getBusinessTypeLabel(w.businessType)}
            />

            <LabelAndValueItem
              key={`雇用形態${w.id}`}
              label="雇用形態"
              value={
                w.employmentStatus &&
                getEmploymentStatusLabel(w.employmentStatus)
              }
            />

            <LabelAndValueItem
              key={`業務内容${w.id}`}
              label="業務内容"
              value={w.businessContent}
              isLast
            />
          </>
        );
      })}
    </div>
  );
};
