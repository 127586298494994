export const RightArrowIcon = () => {
  return (
    <svg
      id="_-System-Icons"
      data-name="🔍-System-Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="17.997"
      height="12.001"
      viewBox="0 0 17.997 12.001"
    >
      <g id="ic_fluent_arrow_forward_24_filled">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M14.21,6.387l.083-.094a1,1,0,0,1,1.32-.083l.094.083,5,5a1,1,0,0,1,.083,1.319L20.7,12.7l-5,5a1,1,0,0,1-1.5-1.319l.083-.094L17.581,13H4a1,1,0,0,1-.993-.883L3,12a1,1,0,0,1,.883-.993L4,11H17.584L14.293,7.707a1,1,0,0,1-.083-1.32l.083-.094Z"
          transform="translate(-3 -6)"
          fill="#b9bcbf"
        />
      </g>
    </svg>
  );
};
