import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { OccupationList } from './Occupation/OccupationList';
import { PositionList } from './Position/PositionList';

export const OccupationPositionSetting = () => {
  const [{ data: corporation }, refetch] = useFetchCorporation();

  const onSaved = () => {
    refetch();
  };

  if (corporation === null) {
    return (
      <div>
        <PageTitle
          title="職種・職位・等級設定"
          breadcrumbs={[['ホーム', '/']]}
        />
        <div className="mt-[50px] p-[40px] bg-grayscale-100">
          <LoadingBackdrop isShow />
        </div>
      </div>
    );
  }
  return (
    <div>
      <PageTitle title="職種・職位・等級設定" breadcrumbs={[['ホーム', '/']]} />
      <div className="mt-[50px] p-main-section bg-grayscale-100">
        <OccupationList
          occupations={corporation.occupations}
          onSaved={onSaved}
        />

        <PositionList positions={corporation.positions} onSaved={onSaved} />
      </div>
    </div>
  );
};
