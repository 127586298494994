export const EditIcon = () => (
  <svg
    id="edit"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g id="_-Product-Icons" data-name="🔍-Product-Icons">
      <g id="ic_fluent_edit_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M13.373,2.548a2.139,2.139,0,0,1,0,3.024L6.22,12.725a1.345,1.345,0,0,1-.6.346l-3.057.834a.448.448,0,0,1-.55-.55L2.849,10.3A1.345,1.345,0,0,1,3.2,9.7l7.153-7.153A2.139,2.139,0,0,1,13.373,2.548ZM9.769,4.4,3.83,10.334a.448.448,0,0,0-.115.2l-.627,2.3,2.3-.627a.448.448,0,0,0,.2-.115l5.939-5.94Zm1.213-1.213-.58.579,1.756,1.757.58-.58a1.242,1.242,0,0,0-1.757-1.757Z"
          transform="translate(-1.999 -1.922)"
          fill="#158cf2"
        />
      </g>
    </g>
  </svg>
);
