import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import {
  convertEvaluationMeasureToApiType,
  EvaluationMeasure,
} from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { ResponseEvaluationMeasure } from './typeResponse';

export const usePostEvaluationMeasures = () => {
  const request = async (data: Partial<EvaluationMeasure>) => {
    const inputData = convertEvaluationMeasureToApiType(data);
    return apiClient.post<ResponseEvaluationMeasure>(
      MY_TENANT_URL('corporation/evaluation_measures/'),
      inputData
    );
  };

  return [request] as const;
};
