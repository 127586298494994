import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';

export const ProgressEvaluationAchievementPercent = (props: {
  objectiveRecord: ResponseObjectiveRecord;
}) => {
  const progress = props.objectiveRecord.evaluationAchievementPercent;
  return (
    <div className="flex space-x-4 mt-[12px]">
      <div>
        <div className="rounded border-1 border-main-blue px-[6px] py-[4px] mr-[8px]">
          <p className="text-label text-main-blue font-bold w-[61px]">
            目標進捗状況
          </p>
        </div>
      </div>

      <div className="relative w-full">
        <div
          className="absolute top-[-28px] transform -translate-x-1/2"
          style={{
            left: `${progress}%`,
            width: progress === 100 ? '40px' : '33px',
          }}
        >
          <div className="bg-main-red text-grayscale-100 text-xs font-medium rounded-full px-[4px] py-[2px] shadow-lg text-label">
            {progress}%
            <div
              className="absolute left-1/2 transform -translate-x-1/2 top-full h-0 w-0 border-l-4 border-r-4 border-t-4 border-t-main-red"
              style={{
                borderRight: '4px solid transparent',
                borderBottom: '4px solid transparent',
                borderLeft: '4px solid transparent',
              }}
            />
          </div>
        </div>

        <div className="w-full bg-grayscale-400 rounded h-[24px] w-[185px] flex items-center">
          <div
            className="h-[20px] rounded bg-gradient-to-r from-main-blue to-main-green"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="flex justify-between text-grayscale-700 text-micro mt-[5px]">
          <span>0</span>
          <span>50</span>
          <span>100</span>
        </div>
      </div>
    </div>
  );
};
