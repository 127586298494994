import { CalendarIcon } from 'infra/components/UI/Icons/CalendarIcon';
import { ReactNode, useState } from 'react';
import { OnsiteUserDetailResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { useFetchEvaluationPeriods } from 'context/evaluation_period/api/useFetchEvaluationPeriods';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { ResponseEvaluationPeriod } from 'context/evaluation_period/api/typeResponse';
import {
  getMvpCoinAssignmentsGroupByCategory,
  MvpCoinAssignment,
} from 'context/mvps/api/get.mvpCoinAssignment';
import { formatDate } from 'infra/utils/date-fns';
import { COLORS } from 'tailwind.config';
import { MinusPointIcon } from './MinusPointIcon';
import { TotalPointIcon } from './TotalPointIcon';
import { MVPLogItem } from './MVPLogItem';

type EvaluationPeriod = ResponseEvaluationPeriod;

export const MVPTab = (props: { onsiteUser: OnsiteUserDetailResponse }) => {
  const [{ data: evaluationPeriods }, refetch] =
    useFetchEvaluationPeriods(true);

  if (evaluationPeriods === undefined) {
    return <LoadingBackdrop isShow />;
  }

  if (evaluationPeriods.count === 0) {
    return (
      <div className="p-[16px]">
        <div className="bg-grayscale-300 p-[20px]">
          <p className="text-section-heading text-center font-bold">
            現在の評価期間が存在していません
          </p>
        </div>
      </div>
    );
  }

  const currentEvaluationPeriod = evaluationPeriods.results[0];

  return (
    <MVPTabDetail
      onsiteUser={props.onsiteUser}
      evaluationPeriod={currentEvaluationPeriod}
    />
  );
};

type DisplayType = 'すべて' | 'もらった' | 'おくった';

const filterByType = (
  myId: string,
  mvpCoinAssignments: MvpCoinAssignment[],
  displayType: DisplayType
) => {
  return mvpCoinAssignments.filter((m) => {
    if (displayType === 'おくった') return m.fromOnsiteUser.id === myId;
    if (displayType === 'もらった') return m.toOnsiteUser.id === myId;
    // すべては何もしない
    return true;
  });
};

const MVPTabDetail = (props: {
  onsiteUser: OnsiteUserDetailResponse;
  evaluationPeriod: EvaluationPeriod;
}) => {
  const [displayType, setDisplayType] = useState<DisplayType>('すべて');

  const { allMvpCoins, minusMvpCoins, loading } =
    getMvpCoinAssignmentsGroupByCategory(
      props.onsiteUser.id,
      props.evaluationPeriod.id
    );

  if (loading || allMvpCoins === undefined || minusMvpCoins === undefined) {
    return <LoadingBackdrop isShow />;
  }

  const filtered = filterByType(
    props.onsiteUser.id,
    allMvpCoins.results,
    displayType
  );

  return (
    <div>
      <div className="p-[16px] pt-[48px] bg-grayscale-800">
        <div className="flex justify-center gap-[60px] mb-[24px]">
          <Score
            title="MVPポイント"
            point={allMvpCoins.count - minusMvpCoins.count}
            icon={<TotalPointIcon fontSize="inherit" />}
          />
          <Score
            title="マイナスポイント"
            point={minusMvpCoins.count}
            icon={<MinusPointIcon fontSize="inherit" />}
          />
        </div>
        <div className="flex justify-end items-end gap-[8px] mb-[4px]">
          <CalendarIcon color={COLORS.GRAY_700} />
          <p className="text-meta text-grayscale-700">
            {formatDate(new Date(props.evaluationPeriod.startedAt), '.')} 〜
          </p>
        </div>
      </div>
      <div className="p-[30px]">
        <div className="flex gap-[48px]">
          <button
            type="button"
            aria-label="すべて"
            className="rounded-full bg-main-blue text-grayscale-100 text-btn-large font-bold py-[6px] px-[18px]"
            style={{
              color:
                displayType === 'すべて' ? COLORS.GRAY_100 : COLORS.GRAY_700,
              backgroundColor:
                displayType === 'すべて' ? COLORS.MAIN_BLUE : COLORS.GRAY_100,
            }}
            onClick={() => setDisplayType('すべて')}
          >
            すべて
          </button>

          <button
            type="button"
            aria-label="もらった"
            className="rounded-full bg-main-blue text-grayscale-100 text-btn-large font-bold py-[6px] px-[18px]"
            style={{
              color:
                displayType === 'もらった' ? COLORS.GRAY_100 : COLORS.GRAY_700,
              backgroundColor:
                displayType === 'もらった' ? COLORS.MAIN_BLUE : COLORS.GRAY_100,
            }}
            onClick={() => setDisplayType('もらった')}
          >
            もらった
          </button>

          <button
            type="button"
            aria-label="おくった"
            className="rounded-full bg-main-blue text-grayscale-100 text-btn-large font-bold py-[6px] px-[18px]"
            style={{
              color:
                displayType === 'おくった' ? COLORS.GRAY_100 : COLORS.GRAY_700,
              backgroundColor:
                displayType === 'おくった' ? COLORS.MAIN_BLUE : COLORS.GRAY_100,
            }}
            onClick={() => setDisplayType('おくった')}
          >
            おくった
          </button>
        </div>

        <p className="text-base text-grayscale-700 pt-[32px]">
          全{filtered.length}
          件中 1〜
          {filtered.length}
          件を表示
        </p>

        <div className="flex flex-col gap-[20px] pt-[20px]">
          {filtered.map((mvpCoinAssignment) => {
            const from = mvpCoinAssignment.fromOnsiteUser;
            const to = mvpCoinAssignment.toOnsiteUser;
            return (
              <MVPLogItem
                color={mvpCoinAssignment.mvpCategory.color}
                MVPCategoryName={mvpCoinAssignment.mvpCategory.name}
                from={{
                  avatar: from.avatar || '',
                  name: `${from.lastName || ''} ${from.firstName || ''}`,
                  icon: mvpCoinAssignment.stamp,
                }}
                to={{
                  avatar: to.avatar || '',
                  name: `${to.lastName || ''} ${to.firstName || ''}`,
                  icon: mvpCoinAssignment.reactionType,
                }}
                content={mvpCoinAssignment.message || ''}
                createdAt={new Date(mvpCoinAssignment.createdAt)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Score = (props: { title: string; point: number; icon: ReactNode }) => {
  return (
    <div className="text-grayscale-100">
      <p className="text-center mb-[10px]">{props.title}</p>
      <div className="flex justify-end text-[40px] items-end mt-[20px]">
        {props.icon}
        <p className="pl-[10px] leading-none">{props.point}</p>
        <p className="text-[18px]">pt</p>
      </div>
    </div>
  );
};
