import { MenuItem, Select, SelectProps } from '@mui/material';

export const EditSubTypeSelect = (
  props: SelectProps & {
    currentType: string;
  }
) => {
  const { currentType, ...selectProps } = props;

  return (
    <Select {...selectProps} displayEmpty>
      {selectItems
        .filter((selectItem) => currentType === selectItem.type)
        .map((selectItem) => (
          <MenuItem key={selectItem.key} value={selectItem.value}>
            {selectItem.label}
          </MenuItem>
        ))}
    </Select>
  );
};

const selectItems = [
  {
    key: 'subtype任意',
    value: '',
    label: '任意',
    type: '必須',
  },
  {
    key: 'subtype心',
    value: '心',
    label: '心 Spirit',
    type: '選択テーマ',
  },
  {
    key: 'subtype技',
    value: '技',
    label: '技 Skill',
    type: '選択テーマ',
  },
  {
    key: 'subtype体',
    value: '体',
    label: '体 Stance',
    type: '選択テーマ',
  },
  {
    key: 'subtype生活',
    value: '生活',
    label: '生活 Style',
    type: '選択テーマ',
  },
];
