import { Button } from '@mui/base/Button';
import { FieldAddIcon } from 'infra/components/UI/Buttons/FieldAddIcon';

export const FieldAddButton = (props: {
  label: string;
  onClickHandler: () => void;
}) => (
  <Button
    onClick={() => {
      props.onClickHandler();
    }}
  >
    <div className="flex items-center">
      <FieldAddIcon style={{ width: '20px', marginRight: 12 }} />
      <span className="text-meta text-main-blue font-medium">
        {props.label}
      </span>
    </div>
  </Button>
);
