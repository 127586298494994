import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/user/onsite_users/app_usage_state/{id}/';

export type Request = SnakeObjToCamelObj<
  NonNullable<
    paths[typeof URL]['patch']['requestBody']
  >['content']['application/json']
>;

export type Response = SnakeObjToCamelObj<
  NonNullable<
    paths[typeof URL]['patch']['responses']
  >['200']['content']['application/json']
>;

export const patchAppUsageState = (
  onsiteUserId: string,
  request: Request
): Promise<any> => {
  return apiClient.patch<Response>(
    URL.replace('{id}', onsiteUserId),
    request,
    {}
  );
};
