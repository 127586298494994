/**
 * 運用設定 職種・職位・等級設定関連ルーティング
 */
import { OccupationPositionSetting } from 'context/corporation/occupation/components/OccupationPositionSetting';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const OccupationPositionSettingRoute: RouteType = {
  path: '/operation-setting/occupation-position-setting',
  element: <OccupationPositionSetting />,
  do_it: () => (
    <Route
      path={OccupationPositionSettingRoute.path}
      element={OccupationPositionSettingRoute.element}
    />
  ),
};

export const routes = () => <>{OccupationPositionSettingRoute.do_it()}</>;
