import { ReactNode, FC } from 'react';
import { Loading } from 'infra/components/UI/Overlays/Loading';

type ContentsProps = {
  isLoading: boolean;
  children: ReactNode;
};

export const Contents: FC<ContentsProps> = ({ isLoading, children }) => {
  return <Loading isLoading={isLoading}>{children}</Loading>;
};
