import twemoji from 'twemoji';
import { getStamp } from 'context/mvps/types/stamp';

export const TwemojiIconFromEmoji = (props: {
  emoji: ReturnType<typeof getStamp>;
  size: string;
  onClick?: () => void;
}) => {
  return (
    <button
      type="button"
      aria-label="コメントを書く"
      style={{
        width: props.size,
        height: props.size,
      }}
      dangerouslySetInnerHTML={{
        __html: twemoji.parse(props.emoji, {}),
      }}
      onClick={props.onClick}
    />
  );
};
