import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';

export const postPromotionLogFix = (
  evaluationPeriodId: number,
  userType: 'staff' | 'manager'
) => {
  const response = apiClient.post(
    MY_TENANT_URL('promotion/promotion_logs/fix/'),
    {
      evaluation_period_id: evaluationPeriodId,
      user_type: userType,
    }
  );
  return response;
};
