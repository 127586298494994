import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { Button } from '@mui/base/Button';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useNavigate, useParams } from 'react-router-dom';

import {
  hasNonFieldError,
  useDisplayNonFieldApiError,
} from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import {
  StaffForm,
  StaffFormSchema,
} from 'context/users/onsite_users/_infra/components/StaffForm';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import {
  OnsiteUserDetailResponse,
  useGetOnsiteUser,
} from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { patchOnsiteuser } from 'context/users/onsite_users/_infra/api/onsiteUser.patch';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const FooterSaveArea = (props: {
  actionLabel: string;
  onSubmit: () => void;
}) => {
  const navigate = useNavigate();

  const scrollElement = document.getElementById('main')!;
  const injectedFooterArea = document.getElementById(
    'injected-bottom-content-area'
  )!;

  useEffect(() => {
    const isFixedSaveButtonListener = () => {
      const { scrollTop } = scrollElement;
      const { scrollHeight } = scrollElement;
      const { clientHeight } = scrollElement;

      if (scrollTop + clientHeight + 140 >= scrollHeight) {
        injectedFooterArea.style.opacity = '0';
      } else {
        injectedFooterArea.style.opacity = '1';
      }
    };

    scrollElement.addEventListener('scroll', isFixedSaveButtonListener);
    return () => {
      scrollElement.removeEventListener('scroll', isFixedSaveButtonListener);
    };
  }, []);

  return (
    <>
      {createPortal(
        <div
          className={`flex items-center justify-center space-x-[40px] h-full `}
        >
          <Button
            className="w-btn-small text-grayscale-700 base-hover"
            onClick={() => navigate(-1)}
          >
            キャンセル
          </Button>

          <BaseButton
            className="w-btn-small text-btn-large p-button-small"
            onClick={props.onSubmit}
            style={{ width: '104px', height: '40px' }}
          >
            {props.actionLabel}
          </BaseButton>
        </div>,
        document.getElementById('injected-bottom-content-area')!
      )}
    </>
  );
};

export const StaffEdit = () => {
  const { staffId } = useParams();

  const [{ data: onsiteUser, loading }] = useGetOnsiteUser(staffId!);

  if (staffId === undefined || loading || onsiteUser === undefined) {
    return <LoadingBackdrop isShow />;
  }

  return <StaffEditLoaded onsiteUser={onsiteUser} />;
};
export const StaffEditLoaded = (props: {
  onsiteUser: OnsiteUserDetailResponse;
}) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm({
    resolver: zodResolver(StaffFormSchema),
    defaultValues: {
      firstName: props.onsiteUser.firstName,
      lastName: props.onsiteUser.lastName,
      firstNameKana: props.onsiteUser.firstNameKana,
      lastNameKana: props.onsiteUser.lastNameKana,
      email: props.onsiteUser.email,
      // bossIdでUI上外部評価者とマネージャの管理を行う
      bossId: props.onsiteUser.outsideManagerId
        ? JSON.stringify({
            id: props.onsiteUser.outsideManagerId,
            type: 'outside_manager',
          })
        : JSON.stringify({
            id: props.onsiteUser.bossId,
            type: 'manager',
          }),
      yearOfjoinedAt: props.onsiteUser.joinedAt.split('-')[0],
      monthOfjoinedAt: props.onsiteUser.joinedAt.split('-')[1],
      dayOfjoinedAt: props.onsiteUser.joinedAt.split('-')[2],
      outsideManagerId: props.onsiteUser.outsideManagerId || '',
      occupationId: props.onsiteUser.occupation.id,
      subOccupationId: props.onsiteUser.subOccupation?.id || null,
      shopId: props.onsiteUser.shop.id,
      isManager: props.onsiteUser.isManager,
      sex: props.onsiteUser.sex,
      phoneNumber: props.onsiteUser.phoneNumber,
      gradeId: props.onsiteUser.grade.id,
      salary: props.onsiteUser.onsiteUserHr.salary,
      employmentStatus: props.onsiteUser.onsiteUserHr.employmentStatus,
      allowances:
        props.onsiteUser.allowances.length >= 1
          ? props.onsiteUser.allowances
          : [{ name: '', amount: null }],
      isEvaluableShops: props.onsiteUser.isEvaluableShops,
      evaluableShops:
        props.onsiteUser.evaluableShops.length >= 1
          ? props.onsiteUser.evaluableShops
          : [{ shopId: '' }],
      yearOfunsubscribeAt: props.onsiteUser.unsubscribeAt?.split('-')[0],
      monthOfunsubscribeAt: props.onsiteUser.unsubscribeAt?.split('-')[1],
      dayOfunsubscribeAt: props.onsiteUser.unsubscribeAt
        ?.split('-')[2]
        .split('T')[0],
      isSuspended: props.onsiteUser.isSuspended,
    } as z.infer<typeof StaffFormSchema>,
  });

  const onEdit = useFormInstance.handleSubmit((value) => {
    startLoading('update_staff');

    value.evaluableShops.forEach((item, index) => {
      if (!item.shopId) {
        value.evaluableShops.splice(index, 1);
      }
    });

    if (value.outsideManagerId === '' && value.bossId === '') {
      displayMessage({
        text: '評価者1もしくは2のいずれかを設定してください',
        isInterruption: true,
      });
      stopLoading('update_staff');
      return;
    }

    const bossIdWithType = JSON.parse(value.bossId);
    const joinedAt = `${value.yearOfjoinedAt}-${value.monthOfjoinedAt.padStart(
      2,
      '0'
    )}-${value.dayOfjoinedAt.padStart(2, '0')}`;

    let unsubscribeAt = null;

    if (
      value.yearOfunsubscribeAt &&
      value.monthOfunsubscribeAt &&
      value.dayOfunsubscribeAt
    ) {
      unsubscribeAt = `${
        value.yearOfunsubscribeAt
      }-${value.monthOfunsubscribeAt.padStart(
        2,
        '0'
      )}-${value.dayOfunsubscribeAt.padStart(2, '0')} 00:00:00+09:00`;
    }

    patchOnsiteuser(props.onsiteUser.id, {
      ...value,
      joinedAt,
      unsubscribeAt,
      gradeId: value.gradeId,
      occupationId: value.occupationId,
      subOccupationId: value.subOccupationId!,
      bossId: bossIdWithType.type === 'manager' ? bossIdWithType.id : null,
      outsideManagerId:
        bossIdWithType.type === 'outside_manager' ? bossIdWithType.id : null,
    })
      .then(() => {
        displayMessage({
          text: 'スタッフの情報を更新しました',
          isInterruption: false,
        });
        navigate(-1);
      })
      .catch((error) => {
        if (hasNonFieldError(error)) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('update_staff');
      });
  }, console.error);

  return (
    <div>
      <PageTitle
        title="スタッフ詳細"
        breadcrumbs={[
          ['ホーム', '/'],
          ['スタッフ一覧', '/staffs_manage/staffs/'],
        ]}
      />
      <div className="mt-[50px] rounded bg-grayscale-100">
        <StaffForm
          useForm={useFormInstance}
          avatarImage={props.onsiteUser.avatar || undefined}
          actionLabel="保存"
          onSubmit={onEdit}
          onsiteUser={props.onsiteUser}
          isEdit
        />
      </div>
      <FooterSaveArea onSubmit={onEdit} actionLabel="保存" />
    </div>
  );
};
