import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { PropsWithChildren, useEffect, useState } from 'react';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { components } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { COLORS } from 'tailwind.config';
import { useAtom } from 'jotai';

import {
  FilterAreaContext,
  useFilterAreaContext,
  usePagerState,
  Pagination,
} from 'infra/components/UI/Table/Pagination/Pagination';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';
import { useNavigate } from 'react-router-dom';
import { GradeEditDialog } from './GradeList/GradeEditDialog';
import { GradeFilterArea } from './_components/GradeFilterArea';
import { fetchGradeList } from '../api/fetchGradeList';

// NOTE: 他のPRでGradeを触っているので、バッティングしないようにインラインで定義
export type Grade = Pick<
  SnakeObjToCamelObj<components['schemas']['GradeTenant']>,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'name'
  | 'weightQuantitative'
  | 'weightQualitative'
  | 'weightCommitment'
  | 'weightMvp'
  | 'weightSales'
  | 'weightProfit'
  | 'weightEs'
  | 'weightCs'
  | 'salary'
  | 'position'
  | 'isDifferentHourlyWageByShops'
  | 'evaluationMethodDescription'
  | 'wageType'
> & {
  position: {
    name: string;
  };
};

export const GradeListContext = () => {
  const pagerState = useFilterAreaContext();
  const isEmpty = pagerState?.pager?.items.length === 0;
  const navigate = useNavigate();

  useEffect(() => {
    pagerState.doitPaging(1);
  }, [pagerState.searchParams]);

  const [editingGradeId, setEditingGradeId] = useState<number | null>(null);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);

  const [{ data: corporation }, refetch] = useFetchCorporation();

  if (corporation === null) {
    return <LoadingBackdrop isShow />;
  }

  const gradeList = corporation.positions.flatMap((p) =>
    p.grades.map((grade: any) => {
      return {
        ...grade,
        positionName: p.name,
      } as Grade;
    })
  );
  const positionList = corporation.positions;

  const onSave = () => {
    setIsOpenSaveModal(false);
    refetch();
  };

  const openSaveModal = () => {
    setEditingGradeId(null);
    setIsOpenSaveModal(true);
  };

  return (
    <div>
      <PageTitle
        title="給与 / 評価ウェイト設定"
        breadcrumbs={[['ホーム', '/']]}
      />
      {isEmpty && (
        <div className="mt-[30px]">
          <WarningMessageBox
            message="給与 / 評価ウェイトを設定してください"
            detailsMessage="等級ごとに、基本給と定量及び定性評価のウェイトを設定します。"
          />
          <p className="my-[20px] text-base">
            先に職位・等級設定を行う必要があります。
          </p>
          <BaseButton
            className="w-btn-small p-button-small tracking-short-text text-base bg-transparent border border-main-blue text-main-blue"
            startIcon={<AddIcon color="text-main-blue" />}
            onClick={() => {
              navigate('/operation-setting/occupation-position-setting');
            }}
          >
            登録
          </BaseButton>
        </div>
      )}

      {!isEmpty && (
        <>
          <GradeFilterArea />
          <Contents isLoading={pagerState?.pager?.isLoading}>
            <div className="flex justify-between items-center pt-[20px]">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="bg-grayscale-500">
                  <TableRow>
                    <TableHeaderCell
                      orderValue="position__name"
                      pagerState={pagerState}
                    >
                      職位
                    </TableHeaderCell>
                    <TableHeaderCell orderValue="name" pagerState={pagerState}>
                      等級名
                    </TableHeaderCell>
                    <TableHeaderCell
                      orderValue="salary"
                      pagerState={pagerState}
                    >
                      基本給
                    </TableHeaderCell>

                    <TableHeaderCell>評価ウェイト</TableHeaderCell>
                    <TableHeaderCell aria-hidden="true"> </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gradeList.length === 0 ? (
                    <EmptyRow openSaveModal={openSaveModal} />
                  ) : (
                    pagerState?.pager?.items.map((grade, index) => (
                      <Row
                        key={grade.id}
                        grade={grade}
                        onClick={() => {
                          setEditingGradeId(grade.id);
                          setIsOpenSaveModal(true);
                        }}
                        bgClass={
                          index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200
                        }
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </div>
          </Contents>

          <div className="pt-[40px]">
            <Pagination pagerState={pagerState} />
          </div>
        </>
      )}

      {isOpenSaveModal && (
        <GradeEditDialog
          gradeId={editingGradeId}
          positionList={positionList}
          grade={gradeList.find((grade) => grade.id === editingGradeId) || null}
          onClose={() => setIsOpenSaveModal(false)}
          onSave={onSave}
        />
      )}
    </div>
  );
};

const EmptyRow = (props: PropsWithChildren<{ openSaveModal: () => void }>) => (
  <TableRow>
    <TableCell colSpan={99999} padding="none">
      <div className="p-main-section bg-grayscale-100">
        <WarningMessageBox
          message="給与 / 評価ウェイトを設定してください"
          detailsMessage="等級ごとに、基本給と定量及び定性評価のウェイトを設定します。"
        />

        <p className="my-[40px]">まだ登録されたデータはありません。</p>

        <BaseButton
          className="w-btn-small p-button-small text-main-blue tracking-short-text text-base bg-grayscale-100 border border-main-blue"
          startIcon={<AddIcon color="text-main-blue" />}
          onClick={props.openSaveModal}
        >
          登録
        </BaseButton>
      </div>
    </TableCell>
  </TableRow>
);

const Row = (
  props: PropsWithChildren<{
    grade: Grade;
    onClick: () => void;
    bgClass?: string;
  }>
) => {
  return (
    <TableRow style={{ backgroundColor: `${props.bgClass}` }}>
      <BaseTableBodyCell onClick={props.onClick} className="base-hover">
        {props.grade.position.name}
      </BaseTableBodyCell>
      <BaseTableBodyCell onClick={props.onClick} className="base-hover">
        {props.grade.name}
      </BaseTableBodyCell>
      <BaseTableBodyCell onClick={props.onClick} className="base-hover">
        {props.grade.salary.toLocaleString()}
      </BaseTableBodyCell>
      <BaseTableBodyCell onClick={props.onClick} className="base-hover">
        定性{' '}
        <span className="font-semibold">{props.grade.weightQualitative}%</span>
        （マンダラ{' '}
        <span className="font-semibold">{props.grade.weightCommitment}%</span> /
        MVPコイン{' '}
        <span className="font-semibold">{props.grade.weightMvp}%</span>） 定量{' '}
        <span className="font-semibold">{props.grade.weightQuantitative}%</span>
        （売上 <span className="font-semibold">
          {props.grade.weightSales}%
        </span>{' '}
        / 利益{' '}
        <span className="font-semibold">{props.grade.weightProfit}%</span> / ES{' '}
        <span className="font-semibold">{props.grade.weightEs}%</span> / CS{' '}
        <span className="font-semibold">{props.grade.weightCs}%</span>）
      </BaseTableBodyCell>

      <BaseTableBodyCell>
        <div className="flex justify-end">
          <MoreMenu
            render={[
              {
                label: <span>編集</span>,
                onClick: () => {
                  props.onClick();
                },
              },
            ]}
          />
        </div>
      </BaseTableBodyCell>
    </TableRow>
  );
};

export const GradeList = () => {
  const pagerState = usePagerState<any>(
    (limit: number, offset: number, searchParams: URLSearchParams) => {
      return fetchGradeList(limit, offset, searchParams);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState?.pager?.isLoading}>
        <GradeListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
