type ArrowProps = {
  isNext?: boolean;
};

export const PaginationArrow = ({ isNext = false }: ArrowProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.414"
      height="14.828"
      viewBox="0 0 8.414 14.828"
      className={`stroke-current group-hover:stroke-main-blue ${
        isNext ? 'transform rotate-180' : ''
      }`}
    >
      <path
        id="chevron-left"
        d="M15,18,9,12l6-6"
        transform="translate(-8 -5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
