import {
  addMonths,
  eachMonthOfInterval,
  format,
  isBefore,
  subYears,
} from 'date-fns';

/** 選択肢として選択が可能な最小の日付 */
const MINIMUM_SELECTABLE_DATE = new Date('2022-05-05');

/**
 * 選択可能な年度の一覧を取得
 * @param startedAt 開始年月（日にちは1日固定）
 * @returns
 */
export const getSelectableYearDurationList = (startedAt: Date) => {
  const selectableYears: { startDate: Date; endDate: Date }[] = [];

  let tempDate = startedAt;
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < 100; index++) {
    selectableYears.push({
      startDate: tempDate,
      endDate: addMonths(tempDate, 11),
    });

    // これ以前の年度は選択肢に含まれない
    if (isBefore(tempDate, MINIMUM_SELECTABLE_DATE)) {
      break;
    }

    tempDate = subYears(tempDate, 1);
  }
  return selectableYears.map(({ startDate, endDate }) => {
    return {
      startDate,
      endDate,
      label: `${format(startDate, 'yyyy年MM月')} ~ ${format(
        endDate,
        'yyyy年MM月'
      )}`,
    };
  });
};

export const getInputRequiredYearMonthList = (
  now: Date,
  usageStartedAt: Date
) => {
  return eachMonthOfInterval({ start: usageStartedAt, end: now }).map((v) =>
    format(v, 'yyyyMM')
  );
};
