import { ArrowUpIcon } from 'pages/β_layputs/icons/ArrowUpIcon';

export const Footer = () => {
  const scrollContentToTop = () => {
    document.querySelector('#main')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="sticky bottom-0 p-0 z-50 pl-[16px] bg-grayscale-100 h-[60px] shadow-main">
      <div className="relative">
        <div
          id="injected-bottom-content-area"
          className="
            absolute z-[999] bg-grayscale-100 w-full h-full
            transition-opacity ease-in-out delay-150 duration-300`
            "
        />
        <div className="flex items-center justify-between w-full">
          <p className="text-base text-grayscale-700">
            {new Date().getFullYear()} &copy; CANaYELL
          </p>
          <button
            type="button"
            aria-label="ページトップへ"
            className="flex items-center justify-center w-[60px] h-[60px] bg-grayscale-800 base-hover"
            onClick={() => scrollContentToTop()}
          >
            <ArrowUpIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
