import { apiClient, MY_TENANT_URL } from 'infra/api/config';
import { ResponseOccupation } from 'context/corporation/occupation/api/types';

export const bulkPosition = async (position: any) => {
  const response = await apiClient.post<ResponseOccupation>(
    MY_TENANT_URL('corporation/position/bulk/'),
    position
  );

  return response;
};
