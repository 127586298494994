import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { Dialog, FormHelperText, FormControl, IconButton } from '@mui/material';
import { PropsWithChildren, useRef } from 'react';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { FieldDeleteIcon } from 'infra/components/UI/Icons/FieldDeleteIcon';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';
import update from 'immutability-helper';
import { DragIcon } from 'context/corporation/career_path/components/DragIcon';
import { FieldAddButton } from 'infra/components/UI/Buttons/FieldAddButton';
import { createCareerPath } from 'context/corporation/career_path/api/createCareerPath';
import { useAtom } from 'jotai';

import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import {
  hasNonFieldError,
  useDisplayNonFieldApiError,
} from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { ResponseGrade } from 'context/corporation/grade/api/types';
import { updateCareerPath } from 'context/corporation/career_path/api/updateCareerPath';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { SelectSearch } from 'infra/components/UI/Forms/SelectSearch';

const careerPathSchema = z.object({
  careerPathName: z
    .string({
      required_error: 'キャリアパス名を入力してください',
    })
    .min(1, 'キャリアパス名を入力してください'),
  path: z
    .object({
      id: z.number(),
      gradeId: z.number().min(1, '等級を選択してください'),
    })
    .array()
    .min(1, '1つ以上の等級を設定してください'),
});

type CareerPathFormData = z.infer<typeof careerPathSchema>;

const defaultFormData: CareerPathFormData = {
  careerPathName: 'サンプル',
  path: [
    {
      id: 1,
      gradeId: 1,
    },
    {
      id: 2,
      gradeId: 2,
    },
    {
      id: 3,
      gradeId: 3,
    },
    {
      id: 4,
      gradeId: 4,
    },
    {
      id: 5,
      gradeId: 0,
    },
  ],
};

type DragManageObj = {
  index: number;
  id: number;
  type: string;
};

const convertGradeArray2PathString = (
  gradeArray: { id: number; gradeId: number }[]
) => `/${gradeArray.map((grade) => grade.gradeId).join('/')}/`;

export const CreateCareerPathModal = (
  props: PropsWithChildren<{
    editingId?: number;
    careerPathForm: CareerPathFormData | null;
    gradeList: ResponseGrade[];
    onSaved: () => void;
    onClose: () => void;
  }>
) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm<CareerPathFormData>({
    resolver: zodResolver(careerPathSchema),
    defaultValues:
      props.careerPathForm ??
      ({
        careerPathName: '',
        path: [
          {
            id: 1,
            gradeId: 0, // 0は未設定状態
          },
          {
            id: 2,
            gradeId: 0, // 0は未設定状態
          },
          {
            id: 3,
            gradeId: 0, // 0は未設定状態
          },
        ],
      } as CareerPathFormData),
  });

  const moveItemHandler = async (dragIndex: number, hoverIndex: number) => {
    useFormInstance.setValue(
      'path',
      update(useFormInstance.watch('path'), {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, useFormInstance.watch('path')[dragIndex]],
        ],
      })
    );
    await useFormInstance.trigger();
  };
  const updateItemHandler = async (
    index: number,
    newValue: { id: number; gradeId: number }
  ) => {
    useFormInstance.setValue(`path.${index}`, newValue);
  };
  const addItemHandler = (newValue: { id: number; gradeId: number }) => {
    useFormInstance.setValue(
      'path',
      update(useFormInstance.watch('path'), {
        $push: [newValue],
      })
    );
  };
  const removeItemHandler = async (index: number) => {
    useFormInstance.setValue(
      'path',
      update(useFormInstance.watch('path'), {
        $splice: [[index, 1]],
      })
    );
    await useFormInstance.trigger();
  };

  const submit: SubmitHandler<CareerPathFormData> = (data) => {
    startLoading('save_career_path');

    const isCreate = props.editingId === undefined;
    const process = isCreate
      ? createCareerPath({
          name: data.careerPathName,
          path: convertGradeArray2PathString(data.path),
        })
      : updateCareerPath(props.editingId!, {
          name: data.careerPathName,
          path: convertGradeArray2PathString(data.path),
        });

    process
      .then(() => {
        displayMessage({
          text: `キャリアパスを${isCreate ? '登録' : '更新'}しました`,
          isInterruption: false,
        });
        props.onClose();
        props.onSaved();
      })
      .catch((error) => {
        if (hasNonFieldError(error)) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('save_career_path');
      });
  };

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={useFormInstance.handleSubmit(submit, (error) =>
              console.log(error)
            )}
          >
            保存
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div className="px-[20px] py-[25px]">
          <h3 className="font-bold text-section-heading mb-[60px]">
            キャリアパス設定
          </h3>
          <form>
            <div>
              <p className="text-base font-bold mb-[20px]">キャリアパス名</p>
              <TextField
                useForm={useFormInstance}
                name="careerPathName"
                isErrorFiled={!!useFormInstance.formState.errors.careerPathName}
                helperText={
                  useFormInstance.formState.errors.careerPathName?.message
                }
                showHelperText={
                  !!useFormInstance.formState.errors.careerPathName?.message
                }
                className="w-[320px] text-grayscale-700"
              />
            </div>

            <p className="text-base font-bold mb-[20px] mt-[30px]">等級設定</p>
            <p className="text-ui text-grayscale-700 mb-[14px] leading-expanded">
              キャリアパスに紐づく等級を昇順で登録してください。
              <br />
              ドラッグアンドドロップによる並び替えが可能です。
            </p>

            <DndProvider backend={HTML5Backend}>
              <div>
                {useFormInstance.watch('path').map((path, index) => (
                  <SortableSelectField
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    path={path}
                    gradeList={props.gradeList}
                    index={index}
                    moveItem={moveItemHandler}
                    updateItem={updateItemHandler}
                    removeItem={removeItemHandler}
                    errors={useFormInstance.formState.errors}
                    useForm={useFormInstance}
                  />
                ))}
              </div>
            </DndProvider>
            <FieldAddButton
              label="等級を追加"
              onClickHandler={() => {
                addItemHandler({
                  id: defaultFormData.path.length + 1,
                  gradeId: 0,
                });
              }}
            />
          </form>
          {useFormInstance.formState.errors.path && (
            <ErrorMessage
              errors={useFormInstance.formState.errors}
              name="path"
              render={({ message }) => (
                <p className="text-meta text-main-red">{message}</p>
              )}
            />
          )}
        </div>
      </BaseModal>
    </Dialog>
  );
};

const SortableSelectField = (props: {
  useForm: any;
  path: {
    id: number;
    gradeId: number;
  };
  gradeList: ResponseGrade[];
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  updateItem: (
    index: number,
    newValue: { id: number; gradeId: number }
  ) => void;
  removeItem: (index: number) => void;
  errors: FieldErrors<CareerPathFormData>;
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'selectField',
    item: () => ({
      id: props.path.id,
      index: props.index,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, drop] = useDrop<
    Omit<DragManageObj, 'type'>,
    unknown,
    { handlerId: Identifier | null }
  >({
    accept: 'selectField',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item: Omit<DragManageObj, 'type'>) {
      /**
       * https://react-dnd.github.io/react-dnd/examples/sortable/simple
       * ↑を参照して実装
       */

      // 要素の実態がない場合、キャンセル
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // つかんでいる要素と重なっている要素が同じ要素の場合 = つかんでいるだけでドラッグしていない場合、キャンセル
      if (dragIndex === hoverIndex) {
        return;
      }

      // ドラッグを実行
      props.moveItem(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      className="grid mb-[24px] gap-[12px] row-gap-1 grid-flow-row grid-cols-[320px_auto] items-center"
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0.6 : 1 }}
      ref={preview}
    >
      <FormControl className="relative">
        <IconButton
          disableRipple
          ref={ref}
          style={{
            position: 'absolute',
            left: -24,
            top: 0,
            bottom: 0,
            margin: 'auto',
          }}
        >
          <DragIcon />
        </IconButton>
        <SelectSearch
          useForm={props.useForm}
          name={`path.${props.index}`}
          initLabel={
            props.gradeList.find((item) => {
              return (
                item.id === props.useForm.watch(`path.${props.index}.gradeId`)
              );
            })?.name || '選択'
          }
          menuItems={props.gradeList?.map((grade) => {
            return {
              label: grade.name,
              value: grade.id!,
            };
          })}
          isErrorFiled={!!props.errors?.path?.[props.index]?.gradeId}
          onMouseDown={(form, name, item) => {
            props.updateItem(props.index, {
              id: Number(props.path.id),
              gradeId: Number(item.value),
            });
          }}
        />
      </FormControl>
      <FieldDeleteIcon
        onClick={() => {
          props.removeItem(props.index);
        }}
      />
      <ErrorMessage
        errors={props.errors}
        name={`path.${props.index}.gradeId`}
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
    </div>
  );
};
