import { Chip, InputProps } from '@mui/material';
import { AddPhotoIcon } from 'context/mvps/components/EditMvpCategoryModal/AddPhotoIcon';
import { ChangeEventHandler, useRef } from 'react';
import { COLORS, STYLES } from 'tailwind.config';

export const CustomImageInput = (
  props: InputProps & {
    checked: boolean;
    handleUpdate: (image: File) => void;
  }
) => {
  const { checked } = props;
  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.handleUpdate(e.target.files[0]);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="customImageIconUpload"
        onChange={(e) => {
          handleChange(e);
        }}
        className="hidden"
        alt="customImageIcon"
        accept="image/*"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="customImageIconUpload">
        <Chip
          clickable
          label={<AddPhotoIcon />}
          variant={checked ? 'outlined' : 'filled'}
          color={checked ? 'primary' : 'default'}
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          style={{
            width: 48,
            height: 48,
            borderRadius: STYLES.BORDER_RADIUS.FULL,
            marginRight: '12px',
            backgroundColor: COLORS.GRAY_400,
          }}
        />
      </label>
    </>
  );
};
