import { useAtom, atom } from 'jotai';

const modalIsActiveAtom = atom(false);

export const ModalState = () => {
  const [active, setActive] = useAtom(modalIsActiveAtom);

  return {
    active,

    openHandler: () => {
      setActive(true);
    },
    closeHandler: () => {
      setActive(false);
    },
  };
};
