import { createSvgIcon } from '@mui/material/utils';

export const FieldDeleteIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 16 16"
  >
    <path
      id="Icon_ionic-ios-add-circle"
      data-name="Icon ionic-ios-add-circle"
      d="M11.375,3.375a8,8,0,1,0,8,8A8,8,0,0,0,11.375,3.375Zm3.481,8.615H7.894a.615.615,0,1,1,0-1.231h6.962a.615.615,0,1,1,0,1.231Z"
      transform="translate(-3.375 -3.375)"
      fill="#fc506e"
    />
  </svg>,
  'FieldDelete'
);
