import { components } from 'infra/api/schema';

// eslint-disable-next-line consistent-return
export const getBusinessTypeLabel = (
  businessType: components['schemas']['BusinessTypeEnum']
): string => {
  try {
    return {
      japaneseFood: '和食 ',
      italian: 'イタリアン ',
      french: 'フレンチ',
      japanesePub: '居酒屋',
      cafe: 'カフェ',
      chaKaiseki: '日本料理・懐石',
      sushi: '寿司',
      patissier: 'パティスリー・製菓',
      bakery: 'ベーカリー・製パン',
      westernFood: '洋食・西洋料理',
      chineseFood: '中華料理',
      chicken: '焼き鳥・鳥料理',
      koreanBarbecue: '焼肉・肉料理',
      stake: 'ステーキ・鉄板焼',
      ramen: 'ラーメン',
      noodle: 'うどん・蕎麦',
      pub: 'バル',
      spanishFood: 'スペイン料理',
      asianFood: 'アジアン・エスニック',
      bar: 'BAR',
      hotel: 'ホテル・旅館',
      wedding: 'ウェディング',
      specialty: '専門店（各国料理）',
      meatAndFish: '精肉・鮮魚',
      deli: '惣菜・DELI・仕出し・弁当',
      cafeteria: '給食・社員食堂・集団料理',
      other: 'その他',
    }[businessType];
  } catch (error) {
    throw new Error('`businessType`が不正な値です');
  }
};
