export const MandalatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="コンポーネント_48_2"
        data-name="コンポーネント 48 – 2"
        opacity="0.5"
      >
        <g
          id="_-System-Icons"
          data-name="🔍-System-Icons"
          transform="translate(-3 -3)"
        >
          <g id="ic_fluent_table_24_regular">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M17.75,3A3.25,3.25,0,0,1,21,6.25v11.5A3.25,3.25,0,0,1,17.75,21H6.25A3.25,3.25,0,0,1,3,17.75V6.25A3.25,3.25,0,0,1,6.25,3ZM14,15.5H10v4h4Zm5.5,0h-4v4h2.25a1.75,1.75,0,0,0,1.75-1.75h0Zm-11,0h-4v2.25A1.75,1.75,0,0,0,6.25,19.5H8.5Zm0-5.5h-4v4h4ZM14,10H10v4h4Zm5.5,0h-4v4h4ZM8.5,4.5H6.25A1.75,1.75,0,0,0,4.5,6.25V8.5h4Zm9.25,0H15.5v4h4V6.25A1.75,1.75,0,0,0,17.75,4.5ZM14,4.5H10v4h4Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
