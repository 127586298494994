import { Paper, Rating } from '@mui/material';
import { PropsWithChildren } from 'react';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { NavigateBeforeIcon } from 'infra/components/UI/Icons/NavigateBeforeIcon';
import {
  isFullInputedScoreListAtom,
  resetScore,
  scoreListAtom,
  displayModeAtom,
  FinalEvaluationByOutsideManager,
} from 'context/objective/store/evaluationByOutsideManager';
import { useAtom } from 'jotai';

export const EvaluationLayoutFooter = (
  /* eslint-disable @typescript-eslint/no-unused-vars */
  props: PropsWithChildren<{
    onConfirm: () => boolean;
    onBack: () => void;
    displayFixButton: boolean;
    evaluationType: 'middle' | 'final';
  }>
) => {
  const [isFullInputedScoreList] = useAtom(isFullInputedScoreListAtom);
  const [, reset] = useAtom(resetScore);
  const [currentRatingPage, setCurrentRatingPage] = useAtom(
    FinalEvaluationByOutsideManager.currentRatingPage
  );
  const [scoreList, setScoreList] = useAtom(scoreListAtom);
  const [displayMode, setDisplayMode] = useAtom(displayModeAtom);
  const [, resetActiveIndex] = useAtom(
    FinalEvaluationByOutsideManager.resetCurrentRatingPage
  );

  const evaluateManagerByOutsideManager = (evaluateScore: number) => {
    const newScoreList = [...scoreList];
    newScoreList[currentRatingPage] = {
      ...scoreList[currentRatingPage],
      score: evaluateScore,
    };

    setScoreList(newScoreList);
    const nextCurrentRatingPage = currentRatingPage + 1;
    const isLastRatingPage = nextCurrentRatingPage >= scoreList.length;
    if (!isLastRatingPage) {
      setCurrentRatingPage(nextCurrentRatingPage);
    }
  };

  return (
    <Paper elevation={3}>
      <div className="flex items-center justify-center relative">
        <div className="absolute left-0 flex items-center justify-start base-hover">
          <button
            className="flex items-center pl-[30px]"
            type="button"
            onClick={() => {
              reset();
              resetActiveIndex();
              props.onBack();
            }}
          >
            <NavigateBeforeIcon />
            <p className="text-base">戻る</p>
          </button>
        </div>
        {/* ボタンを非表示にした場合でも高さを保ちたいため,Boxで高さを維持 */}
        <div className="h-[136px] flex items-center">
          {props.displayFixButton && displayMode !== 'end' && (
            <BaseButton
              className="btn-confirm rounded-full"
              disabled={!isFullInputedScoreList}
              onClick={() => {
                if (props.onConfirm()) {
                  setDisplayMode('end');
                }
              }}
            >
              確定する
            </BaseButton>
          )}
          {!isFullInputedScoreList &&
            props.evaluationType === 'final' &&
            displayMode === 'input' && (
              <div className="relative text-center">
                <div className="relative bg-main-blue rounded py-[4px] px-[10px] text-grayscale-100 text-center text-meta mb-[14px]">
                  最終評価を採点してください
                  <div
                    className="
                    absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]
                    w-0 h-0 border-l-[3px] border-l-transparent border-r-[3px] border-r-transparent border-t-[6px] border-t-main-blue border-b-0
                  "
                  />
                </div>
                <Rating
                  key={currentRatingPage}
                  size="large"
                  name="disabled"
                  max={4}
                  value={scoreList[currentRatingPage].score}
                  onChange={(e, inputedScore) =>
                    evaluateManagerByOutsideManager(inputedScore!)
                  }
                />
              </div>
            )}
        </div>
        <p className="absolute right-[30px] text-base text-grayscale-700">
          {new Date().getFullYear()} &copy; CANaYELL
        </p>
      </div>
    </Paper>
  );
};
