import {
  Table,
  TableBody,
  TableContainerProps,
  TableContainerTypeMap,
  TableHead,
  TableRow,
} from '@mui/material';
import { BaseTableBorderCell } from 'infra/components/UI/Table/BaseTableBorderCell';
import { EvaluationMeasure } from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { MeasureTableRow } from 'context/corporation/evaluation_measures/components/MeasureTableRow';
import { COLORS } from 'tailwind.config';

export const MboMeasureTable = (
  props: TableContainerProps<
    TableContainerTypeMap['defaultComponent'],
    {
      evaluationMeasure: EvaluationMeasure;
      onChanged: () => void;
    }
  >
) => {
  return (
    <div className="overflow-x-auto base-scrollbar">
      <Table sx={{ tableLayout: 'fixed', minWidth: '1040px' }}>
        <TableHead>
          <TableRow>
            <BaseTableBorderCell
              backGroundColor={COLORS.GRAY_100}
              className="w-[120px]"
            />
            {[...Array(6)].map((value, i) => (
              <BaseTableBorderCell
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                sx={{ paddingY: '11px' }}
                align="center"
                backGroundColor={COLORS.GRAY_100}
              >
                <p className="text-label leading-tight mb-[2px]">評価点数</p>
                <p className="text-meta font-bold leading-tight">{` ${
                  i + 1
                }点`}</p>
              </BaseTableBorderCell>
            ))}
            <BaseTableBorderCell
              backGroundColor={COLORS.GRAY_100}
              className="w-[80px]"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {(
            Object.keys(props.evaluationMeasure) as (keyof EvaluationMeasure)[]
          ).map(
            (slug, index) =>
              slug !== 'finalEvaluation' &&
              slug !== 'id' && (
                <MeasureTableRow
                  evaluationMeasureId={props.evaluationMeasure.id}
                  key={slug}
                  slug={slug}
                  onChanged={props.onChanged}
                  measure={props.evaluationMeasure[slug].measure}
                  type={props.evaluationMeasure[slug].type}
                  bgClass={index % 2 === 0 ? COLORS.GRAY_200 : COLORS.GRAY_100}
                />
              )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
