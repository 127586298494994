import { Card, CardProps } from '@mui/material';
import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';
import { formatDate } from 'infra/utils/date-fns';
import { CalendarIcon } from 'infra/components/UI/Icons/CalendarIcon';
import { COLORS } from 'tailwind.config';
import { CommitmentSheet } from '../../../../../../../objective/components/evaluate/CommitmentSheet';
import { ScoreDisplay } from './ScoreDisplay';
import { ProgressItemList } from './ProgressItemList';
import { ProgressEvaluationAchievementPercent } from './ProgressEvaluationAchievementPercent/ProgressEvaluationAchievementPercent';

export const CommitmentCard = (props: {
  cardProps: CardProps;
  objectiveRecord: ResponseObjectiveRecord;
}) => {
  return (
    <Card
      {...props.cardProps}
      style={{
        padding: '30px',
        border: `1px solid ${COLORS.GRAY_500}`,
        boxShadow: 'none',
      }}
    >
      <div className="flex gap-[50px] items-center">
        <div className="self-start">
          <CommitmentSheet
            basicThinkings={props.objectiveRecord.basicThinkings}
            currentGrade={props.objectiveRecord.currentGrade}
            nextGrade={props.objectiveRecord.nextGrade}
          />
        </div>
        <div className="flex flex-col gap-[30px]">
          <div className="flex items-center gap-[10px] ">
            <CalendarIcon color={COLORS.GRAY_700} />
            <p className="text-ui leading-none pt-[4px]">
              {formatDate(
                new Date(props.objectiveRecord.evaluationPeriod.startedAt),
                '.'
              )}
              〜
              {formatDate(
                new Date(props.objectiveRecord.evaluationPeriod.endedAt),
                '.'
              )}
            </p>
          </div>
          <ScoreDisplay
            score={props.objectiveRecord.score}
            prevScore={props.objectiveRecord.previousScore}
          />
          {/* <EvaluationWeightsCharts objectiveRecord={props.objectiveRecord} /> */}
          <ProgressEvaluationAchievementPercent
            objectiveRecord={props.objectiveRecord}
          />
          <ProgressItemList objectiveRecord={props.objectiveRecord} />
        </div>
      </div>
    </Card>
  );
};
