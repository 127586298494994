import { FC } from 'react';
import { Dialog } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { GradeList } from 'context/mandalat/components/ImportMandalatMasterCsv/ThemeSetting/GradeList';
import { GradeOfResponsePaginatedCorporation } from 'context/corporation/grade/api/types';
import { MandalatTiles } from 'context/mandalat/components/ImportMandalatMasterCsv/ThemeSetting/MandalatTiles';
import { PaginatedCorporationOfResponsePaginatedCorporation } from 'context/corporation/corporation/api/types';
import {
  ResponseMandalatMaster,
  MandalatSquareSettingRules,
} from 'context/mandalat/api/typeResponse';
import { EditRuleList } from 'context/mandalat/components/Modal/EditRuleList/Index';

export namespace Types {
  export type State = {
    ui: {
      isOpen: boolean;
      setModalOpen: () => void;
      setModalClose: () => void;
      setCurrentGradeId: (gradeId: number) => void;
      currentGradeId: number;
      formValue: MandalatSquareSettingRules;
      setFormValue: (newValue: MandalatSquareSettingRules) => void;
      defaultValue: MandalatSquareSettingRules;
    };
    data: {
      gradeList: GradeOfResponsePaginatedCorporation[];
      corporation: PaginatedCorporationOfResponsePaginatedCorporation | null;
      latestMandalatmaster: ResponseMandalatMaster | undefined;
    };
    method: {
      getGradeFromCorporation: (
        gradeId: number,
        corporation: PaginatedCorporationOfResponsePaginatedCorporation
      ) => GradeOfResponsePaginatedCorporation;
      submit: () => void;
    };
  };

  export type Props = {
    state: State;
  };
}

export const EditMandalatThemeModal: FC<Types.Props> = ({ state }) => {
  return (
    <Dialog
      open={state.ui.isOpen}
      onClose={state.ui.setModalClose}
      maxWidth="md"
    >
      <div className="min-w-[880px] relative">
        <div className="p-modal overflow-y-auto h-[600px]">
          <h6 className="text-section-heading font-bold mb-[40px]">
            テーマ設定
          </h6>

          <div className="flex gap-[30px]">
            <GradeList
              gradeList={state.data.gradeList}
              currentGradeId={state.ui.currentGradeId}
              handleChangeGradeId={(gradeId) => {
                state.ui.setCurrentGradeId(gradeId);
              }}
            />
            <MandalatTiles
              gradeName={
                state.method.getGradeFromCorporation(
                  state.ui.currentGradeId,
                  state.data.corporation!
                )?.name
              }
              latestMandalatmaster={state.data.latestMandalatmaster!}
              formValue={state.ui.formValue}
            />
            <EditRuleList
              defaultValue={state.ui.defaultValue}
              formValue={state.ui.formValue}
              handleUpdateFormValue={(newValue) => {
                state.ui.setFormValue(newValue);
              }}
              latestMandalatmaster={state.data.latestMandalatmaster}
              currentGradeId={state.ui.currentGradeId}
            />
          </div>
        </div>
        <div className="flex justify-center gap-12 py-[30px] px-8 shadow-modal">
          <button
            type="button"
            className="w-[120px]"
            onClick={() => {
              state.ui.setModalClose();
            }}
          >
            <p className="text-grayscale-700">キャンセル</p>
          </button>
          <BaseButton
            type="button"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={() => {
              state.method.submit();
            }}
          >
            保存
          </BaseButton>
        </div>
      </div>
    </Dialog>
  );
};
