export const SearchIcon = () => {
  return (
    <svg
      id="_-Product-Icons"
      data-name="🔍-Product-Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="ic_fluent_search_24_filled">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M8.986,2.5a6.487,6.487,0,0,1,5.158,10.42l4.1,4.1a.865.865,0,0,1-1.142,1.3l-.081-.072-4.1-4.1A6.487,6.487,0,1,1,8.986,2.5Zm0,1.73a4.757,4.757,0,1,0,4.757,4.757A4.757,4.757,0,0,0,8.986,4.23Z"
          transform="translate(-2.5 -2.5)"
          fill="#b9bcbf"
        />
      </g>
    </svg>
  );
};
