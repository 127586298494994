import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import zod from 'zod';
import { useAtom } from 'jotai';
import { FieldDeleteIcon } from 'infra/components/UI/Icons/FieldDeleteIcon';
import { FieldAddButton } from 'infra/components/UI/Buttons/FieldAddButton';
import { deleteSubOccupation } from 'context/corporation/occupation_sub/api/deleteSubOccupation';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { OccupationsFormDataType } from '../OccupationEditFormDialog';

export const SubOccupationEditForm = (props: {
  onSaved: () => void;
  form: UseFormReturn<OccupationsFormDataType>;
  outerIndex: number;
}) => {
  const { control } = props.form;

  const { fields, append, remove } = useFieldArray({
    keyName: 'localId',
    name: `occupations.${props.outerIndex}.subOccupations`,
    control,
  });

  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const deleteField = async (field: any, index: number) => {
    if (!field.id) {
      props.onSaved();
      remove(field.localId);
      return;
    }

    // eslint-disable-next-line no-alert
    if (!window.confirm('本当に削除してもよろしいですか？')) {
      return;
    }

    await deleteSubOccupation(field.id)
      .then(() => {
        displayMessage({
          text: 'サブ職種を削除しました。',
          isInterruption: false,
        });
        remove(index);
        props.onSaved();
      })
      .catch((error) => {
        displayNonFieldApiError(error);
        props.onSaved();
      });
  };

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div
            className="flex items-center mb-[20px] w-[324px]"
            data-id={field.localId}
          >
            <TextField
              name={`occupations.${props.outerIndex}.subOccupations.${index}.name`}
              useForm={props.form}
              isErrorFiled={
                !!props.form.formState.errors?.occupations?.[props.outerIndex]
                  ?.subOccupations?.[index]?.name
              }
              className="w-[320px]"
              placeholder="サブ職種名を入力してください"
              key={field.localId}
            />
            <div className="ml-[20px]">
              <FieldDeleteIcon
                onClick={() => {
                  deleteField(field, index);
                }}
              />
            </div>
          </div>
        );
      })}

      <FieldAddButton
        label="サブ職種を追加"
        onClickHandler={() => {
          append({
            id: null,
            name: '',
          });
        }}
      />
    </div>
  );
};
