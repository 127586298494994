import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { MboMeasureTable } from 'context/corporation/evaluation_measures/components/MboMeasureTable';
import { FinalMeasureTable } from 'context/corporation/evaluation_measures/components/FinalMeasureTable';
import { useGetEvaluationMeasures } from 'context/corporation/evaluation_measures/api/get.evaluationMeasures';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { EvaluationMeasure } from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { Divider } from 'infra/components/UI/Elements/Divider';

export const EvaluationMeasureSetting = () => {
  const [convertedResponse, loading, refetch] = useGetEvaluationMeasures();
  if (loading) {
    return <LoadingBackdrop isShow />;
  }

  const onChanged = async () => {
    await refetch();
  };

  return (
    <EvaluationMeasureSettingDetail
      onChanged={onChanged}
      evaluationMeasure={convertedResponse!}
    />
  );
};
export const EvaluationMeasureSettingDetail = (props: {
  evaluationMeasure: EvaluationMeasure;
  onChanged: () => void;
}) => {
  return (
    <div>
      <PageTitle title="評価尺度設定" breadcrumbs={[['ホーム', '/']]} />
      <div className="mt-[50px] p-main-section bg-grayscale-100">
        <h3 className="font-bold">MBO項目評価尺度設定</h3>
        <Divider className="my-[30px] border-b-[2px]" />
        <MboMeasureTable
          evaluationMeasure={props.evaluationMeasure}
          onChanged={props.onChanged}
        />
        <p className="text-grayscale-700 text-meta pt-[10px]">
          ※ デフォルトの値はCANaYELL推奨値です
        </p>
        <h3 className="font-bold mt-[50px]">最終評価尺度設定</h3>
        <Divider className="my-[30px] border-b-[2px]" />
        <FinalMeasureTable
          evaluationMeasure={props.evaluationMeasure}
          onChanged={props.onChanged}
        />

        <p className="text-grayscale-700 text-meta pt-[10px]">
          ※ 評価尺度A以上が昇給・昇格ライン
          <br />※ デフォルトの値はCANaYELL推奨値です
        </p>
      </div>
    </div>
  );
};
