import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { Location } from '@remix-run/router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { getLoginInfo } from 'context/auth/api/login';
import { userIdAtom } from 'context/auth/store/auth';
import { useAtom } from 'jotai';

export const WatchScreenViewAsLogin = () => {
  const location = useLocation();
  const [{ data: me }] = getLoginInfo();

  useEffect(() => {
    const analytics = getAnalytics();

    if (me) {
      setUserProperties(analytics, {
        corporation_id: me.corporationId,
        corporation: me.corporationName,
        email: me.email,
      });
    }

    logEvent(analytics, 'screen_view', {
      firebase_screen: location.pathname,
      firebase_screen_class: getCategoryFromPath(location),
    });
  }, [location, me]);

  return <span />;
};

export const WatchScreenView = () => {
  const [userId] = useAtom(userIdAtom);

  if (userId) {
    return <WatchScreenViewAsLogin />;
  }

  return <span />;
};

export const getCategoryFromPath = (location: Location) => {
  const path = location.pathname;
  const category = path.split('/');

  if (category.length >= 2) {
    return category[1];
  }

  return 'no category';
};
