import { AttentionIcon } from 'infra/components/UI/Feedbacks/AttentionIcon';

export const AttentionMessageBox = (props: { message: string }) => {
  return (
    <div className="inline-block py-[9px] px-[10px] rounded bg-attention">
      <div className="flex items-center justify-start">
        <AttentionIcon sx={{ fontSize: '20px' }} />
        <p className="text-ui text-main-red font-bold ml-[6px]">
          {props.message}
        </p>
      </div>
    </div>
  );
};
