export const DownGraphIcon = () => (
  <svg
    id="グラフアイコン"
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="7"
    viewBox="0 0 11 7"
  >
    <g id="ic_fluent_arrow_trending_24_regular" transform="translate(0 0)">
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M8.461,12.5h4.155l.055-.007h0l.054-.015h0l.033-.014h0a.4.4,0,0,0,.12-.081l.022-.024h0l.024-.03h0l.03-.049h0l.021-.049h0l.011-.035h0l.007-.034h0L13,12.111h0V8.055A.413.413,0,0,0,12.178,8l0,.055v3.067L7.929,6.964a.419.419,0,0,0-.537-.039l-.046.039-1.634,1.6L2.7,5.618a.419.419,0,0,0-.583,0,.4.4,0,0,0-.04.526l.04.045,3.3,3.231a.419.419,0,0,0,.537.039L6,9.421l1.634-1.6,3.953,3.871H8.461a.41.41,0,0,0-.409.349l0,.055a.407.407,0,0,0,.357.4Z"
        transform="translate(-1.999 -5.5)"
        fill="#f44369"
      />
    </g>
  </svg>
);
