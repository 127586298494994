import { useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { HackCloseDropDown } from 'infra/components/UI/Forms/utils/HackCloseDropDown';
import {
  HourMaster,
  MinuteMaster,
} from 'context/corporation/shop/components/const';

type BaseSelectProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export const TimeSelect = ({ value, onChange }: BaseSelectProps) => {
  const [isOpenHour, setIsOpenHour] = useState(false);
  const [isOpenMinute, setIsOpenMinute] = useState(false);

  const toggleHourDropdown = () => {
    setIsOpenHour(!isOpenHour);
  };

  const toggleMinuteDropdown = () => {
    setIsOpenMinute(!isOpenMinute);
  };

  const updateSelectHour = (hour: string) => {
    const newValue = `${hour}:${value?.split(':')[1]}`;
    onChange(newValue);
  };

  const updateSelectMinute = (minute: string) => {
    const newValue = `${value?.split(':')[0]}:${minute}`;
    onChange(newValue);
  };

  return (
    <div className="flex items-center gap-[6px]">
      <div className="relative">
        <button
          type="button"
          className="
            relative w-[80px] py-[6px] pl-[12px] pr-[20px]
            border-1 border-grayscale-600 rounded text-base text-left
            select-hover
          "
          onClick={() => {
            toggleHourDropdown();
          }}
        >
          {value?.split(':')[0]}
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
            <ArrowDownIcon />
          </div>
        </button>
        {isOpenHour && (
          <div
            className="
              absolute left-0 top-[36px] w-full overflow-y-auto max-h-[200px] z-10 py-[6px]
              flex flex-col border-1 border-grayscale-500 rounded text-base hidden-scrollbar
              shadow-main bg-grayscale-100
            "
          >
            {HourMaster.map((item) => (
              <button
                type="button"
                key={item}
                className="p-[10px] pulldown-hover bg-selectbox"
                onMouseDown={() => {
                  updateSelectHour(item);
                  toggleHourDropdown();
                }}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="text-base">:</div>
      <div className="relative">
        <button
          type="button"
          className="
            relative w-[80px] py-[6px] pl-[12px] pr-[20px]
            border-1 border-grayscale-600 rounded text-base text-left
            select-hover
          "
          onClick={() => {
            toggleMinuteDropdown();
          }}
        >
          {value?.split(':')[1]}
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
            <ArrowDownIcon />
          </div>
        </button>
        {isOpenMinute && (
          <div
            className="
              absolute left-0 top-[36px] w-full overflow-y-auto max-h-[200px] z-10 py-[6px]
              flex flex-col border-1 border-grayscale-500 rounded text-base hidden-scrollbar
              shadow-main bg-grayscale-100
            "
          >
            {MinuteMaster.map((item) => (
              <button
                type="button"
                key={item}
                className="p-[10px] pulldown-hover bg-selectbox"
                onMouseDown={() => {
                  updateSelectMinute(item);
                  toggleMinuteDropdown();
                }}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
      <HackCloseDropDown
        isOpen={isOpenHour || isOpenMinute}
        setIsOpen={setIsOpenHour || setIsOpenMinute}
      />
    </div>
  );
};
