/**
 * 企業情報 ご契約情報関連ルーティング
 */
import { ContractSetting } from 'context/corporation/contracts/components/ContractSetting';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const ContractSettingRoute: RouteType = {
  path: '/operation-setting/contract-setting',
  element: <ContractSetting />,
  do_it: () => (
    <Route
      path={ContractSettingRoute.path}
      element={ContractSettingRoute.element}
    />
  ),
};

export const routes = () => <>{ContractSettingRoute.do_it()}</>;
