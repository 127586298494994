export const PeopleIcon = () => (
  <svg
    id="コンポーネント_46_12"
    data-name="コンポーネント 46 – 12"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="28.8"
    viewBox="0 0 32 28.8"
  >
    <g id="_-Product-Icons" data-name="🔍-Product-Icons">
      <g id="ic_fluent_people_team_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M22.406,14.2a2.8,2.8,0,0,1,2.8,2.8v7.6a7.2,7.2,0,1,1-14.4,0V17a2.8,2.8,0,0,1,2.8-2.8Zm0,2.4H13.6a.4.4,0,0,0-.4.4v7.6a4.8,4.8,0,1,0,9.6,0V17A.4.4,0,0,0,22.406,16.6ZM4.8,14.2l5.41,0a4.38,4.38,0,0,0-.989,2.4H4.8a.4.4,0,0,0-.4.4v5.2a4.006,4.006,0,0,0,4.931,3.893,8.651,8.651,0,0,0,.724,2.291A6.327,6.327,0,0,1,8.4,28.6,6.4,6.4,0,0,1,2,22.2V17A2.8,2.8,0,0,1,4.8,14.2Zm21,0,5.4,0A2.8,2.8,0,0,1,34,17v5.2a6.405,6.405,0,0,1-8.049,6.185,8.682,8.682,0,0,0,.729-2.292A4,4,0,0,0,31.6,22.2V17a.4.4,0,0,0-.4-.4H26.789A4.38,4.38,0,0,0,25.8,14.2ZM18,3a4.8,4.8,0,1,1-4.8,4.8A4.8,4.8,0,0,1,18,3ZM28.4,4.6a4,4,0,1,1-4,4A4,4,0,0,1,28.4,4.6ZM7.6,4.6a4,4,0,1,1-4,4A4,4,0,0,1,7.6,4.6ZM18,5.4a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,18,5.4ZM28.4,7A1.6,1.6,0,1,0,30,8.6,1.6,1.6,0,0,0,28.4,7ZM7.6,7A1.6,1.6,0,1,0,9.2,8.6,1.6,1.6,0,0,0,7.6,7Z"
          transform="translate(-2 -3)"
          fill="#048cf2"
        />
      </g>
    </g>
  </svg>
);
