export const TimeMaster = [
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
];

export const EvaluationCycleOptions = [
  { label: '3ヶ月', value: 3 },
  { label: '6ヶ月（推奨）', value: 6 },
  { label: '1年', value: 12 },
];
