import { Backdrop, CircularProgress } from '@mui/material';
import { STYLES } from 'tailwind.config';

/** ページのタイトル表示 */
export const LoadingBackdrop = ({ isShow }: { isShow: boolean }) => (
  <Backdrop
    style={{ color: '#fff', zIndex: STYLES.Z_INDEX.LOADING_BACK_DROP }}
    open={isShow}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);
