import { WealthLabel } from 'context/wealth/WealthLabel/WealthLabel';
import { PropsWithChildren } from 'react';

type OnsiteUserAffiliationProps = PropsWithChildren<{
  align?: 'start' | 'center' | 'end';
  isTextCenter?: boolean;
  shopName?: string;
  occupationName?: string;
  positionName?: string;
  gradeName?: string;
  wealthProfileResult?: string;
}>;

export const OnsiteUserAffiliation = ({
  align = 'start',
  shopName = 'てんぽめい',
  occupationName = 'ホール',
  gradeName = 'SAM1',
  positionName = 'アシスタントマネジャー',
  wealthProfileResult = '',
  isTextCenter = true,
  children,
}: OnsiteUserAffiliationProps) => {
  const alignClasses = {
    start: 'items-start',
    center: 'items-center',
    end: 'items-end',
  };
  return (
    <div
      className={`flex flex-col gap-[3px] ${
        alignClasses[align] || 'items-end'
      }`}
    >
      <div className={isTextCenter ? 'text-center' : ''}>
        <p className="text-label text-grayscale-700 mb-[5px]">
          {occupationName} / {positionName}
        </p>
        <p className="text-label text-grayscale-700">{gradeName}</p>
        {wealthProfileResult && (
          <p className="mt-[4px]">
            <WealthLabel label={wealthProfileResult} />
          </p>
        )}
      </div>

      <p className="text-note text-grayscale-700">{shopName}</p>
    </div>
  );
};
