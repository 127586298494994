import { atom } from 'jotai';

export type Score = {
  score: number | null;
  comment: string | null;
};

export type ValidatedScore = Score & { score: NonNullable<Score['score']> };

export const FULL_LENGTH = 8;

export const scoreListAtom = atom<Score[]>([]);
export const inputedScoreListAtom = atom((get) => {
  return get(scoreListAtom).filter((s) => s.score !== null);
});

export const isFullInputedScoreListAtom = atom(
  (get) => get(inputedScoreListAtom).length === FULL_LENGTH
);

export const resetScore = atom(null, (get, set) => {
  set(
    scoreListAtom,
    Array.from({ length: FULL_LENGTH }).fill({
      score: null,
      comment: null,
    }) as Score[]
  );
});

export const displayModeAtom = atom<'start' | 'input' | 'end'>('start');

export namespace FinalEvaluationByOutsideManager {
  export const currentRatingPage = atom(0);

  export const resetCurrentRatingPage = atom(null, (get, set) => {
    set(currentRatingPage, 0);
  });
}
