export const RedPersonIcon = () => {
  return (
    <svg
      id="user"
      xmlns="http://www.w3.org/2000/svg"
      width="8.001"
      height="10"
      viewBox="0 0 8.001 10"
    >
      <g id="_-Product-Icons" data-name="🔍-Product-Icons">
        <g id="ic_fluent_person_24_filled">
          <path
            id="_-Color"
            data-name="🎨-Color"
            d="M10.88,8A1.125,1.125,0,0,1,12,9.128v.459a1.375,1.375,0,0,1-.256.8A4.3,4.3,0,0,1,8,12a4.292,4.292,0,0,1-3.744-1.619A1.375,1.375,0,0,1,4,9.588v-.46A1.125,1.125,0,0,1,5.128,8ZM8,2a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,8,2Z"
            transform="translate(-4.004 -2.005)"
            fill="#F44369"
          />
        </g>
      </g>
    </svg>
  );
};
