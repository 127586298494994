import { useState, ChangeEvent } from 'react';
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TildeIcon } from 'infra/components/UI/Icons/TildeIcon';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { CircleTrophy } from 'context/corporation/rankings/mvp_ranking/CircleTrophy';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { COLORS } from 'tailwind.config';
import { MvpRankingState } from 'context/corporation/rankings/mvp_ranking/state';
import { WealthLabel } from 'context/wealth/WealthLabel/WealthLabel';

export const MvpRanking = () => {
  const state = MvpRankingState();
  const sliceStartIndex = 3;
  const [maxVisibleStaffs, setMaxVisibleStaffs] = useState(13);
  const navigate = useNavigate();

  const setTodayAfterClear = (
    e: ChangeEvent<HTMLInputElement>,
    rangePoint: 'start' | 'end'
  ) => {
    const newDateValue =
      e.target.value || new Date().toISOString().split('T')[0];

    if (rangePoint === 'start') {
      state.search.setQueryParameter(
        'created_at__gte',
        `${newDateValue} 00:00:00` // 終了日の状態を更新
      );
    } else if (rangePoint === 'end') {
      state.search.setQueryParameter(
        'created_at__lte',
        `${newDateValue} 23:59:59` // 終了日の状態を更新
      );
    }
  };

  const showMoreStaffs = () => {
    setMaxVisibleStaffs((prevMax) => prevMax + 10); // さらに10名を表示
  };

  const categoryItemStyles = (category: string) =>
    `rounded-full py-[6px] px-[10px] text-ui bg-grayscale-500 leading-none ${
      state.search.selectedCategory === category
        ? 'bg-main-blue text-grayscale-100 font-bold cursor-default'
        : 'base-hover'
    }`;

  const getTop3RankBadgeStyles = (index: number) => {
    switch (index) {
      case 1:
        return { backgroundColor: COLORS.MAIN_YELLOW };
      case 2:
        return { backgroundColor: COLORS.GRAY_600 };
      case 3:
        return { backgroundColor: COLORS.MAIN_ORANGE };
      default:
        return { backgroundColor: COLORS.GRAY_800 };
    }
  };

  return (
    <>
      <ul className="flex items-center gap-[10px] overflow-x-auto whitespace-nowrap mt-[30px]">
        <li
          className={categoryItemStyles('all')}
          onClick={() => {
            state.search.setQueryParameter('mvp_category_id', '');
            state.search.setSelectedCategory('all');
          }}
        >
          すべて
        </li>
        {state.data.mvpData?.results
          ?.filter((data) => data.name !== 'マイナスポイント')
          .map((data) => (
            <li
              className={categoryItemStyles(data.id.toString())}
              onClick={() => {
                state.search.setQueryParameter(
                  'mvp_category_id',
                  data.id.toString()
                );

                state.search.setSelectedCategory(data.id.toString());
              }}
            >
              {data.name}
            </li>
          ))}
      </ul>
      <div>
        <div className="mt-[30px] flex items-center">
          <ul className="flex items-center gap-[16px]">
            <li className="flex items-center gap-[10px]">
              <p className="text-ui text-grayscale-700 pl-[14px]">店舗</p>
              <FormControl size="small">
                <Select
                  defaultValue="すべて"
                  className="w-[200px]"
                  onChange={(e) => {
                    state.search.setQueryParameter(
                      'mvp_coin_assignment_log__to_shop_id',
                      e.target.value
                    );
                  }}
                >
                  <MenuItem key={0} value="すべて">
                    <span className="text-ui text-grayscale-800">すべて</span>
                  </MenuItem>
                  {state.data.shops?.results.map((shop) => (
                    <MenuItem key={shop.id} value={shop.id}>
                      <span className="text-ui text-grayscale-800">
                        {shop.name}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </li>

            <li className="flex items-center gap-[10px]">
              <p className="text-ui text-grayscale-700 pl-[14px]">業態</p>
              <FormControl size="small">
                <Select
                  defaultValue="すべて"
                  className="w-[200px]"
                  onChange={(e) => {
                    state.search.setQueryParameter(
                      'mvp_coin_assignment_log__to_shop_brand_business_type',
                      e.target.value
                    );
                  }}
                >
                  <MenuItem key={0} value="すべて">
                    <span className="text-ui text-grayscale-800">すべて</span>
                  </MenuItem>
                  {Object.entries(state.const.BUSINESS_TYPE_LIST).map(
                    ([englishName, japaneseName]) => {
                      return (
                        <MenuItem key={englishName} value={englishName}>
                          <span className="text-ui text-grayscale-800">
                            {japaneseName}
                          </span>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </li>

            <li className="flex items-center gap-[10px]">
              <p className="text-ui text-grayscale-700 pl-[14px]">等級</p>
              <FormControl size="small">
                <Select
                  defaultValue="すべて"
                  className="w-[200px]"
                  onChange={(e) => {
                    state.search.setQueryParameter(
                      'mvp_coin_assignment_log__to_grade_id',
                      e.target.value
                    );
                  }}
                >
                  <MenuItem key="すべて" value="すべて">
                    <span className="text-ui text-grayscale-800">すべて</span>
                  </MenuItem>
                  {state.data.corporation?.positions.map(({ id, grades }) =>
                    grades.map((grade) => (
                      <MenuItem key={grade.id!} value={grade.id!}>
                        <span className="text-ui text-grayscale-800">
                          {grade.name}
                        </span>
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </li>
          </ul>
        </div>

        <div className="flex mt-[30px] items-center">
          <ul className="flex items-center gap-[16px]">
            <li className="flex items-center gap-[10px]">
              <p className="text-ui text-grayscale-700 pl-[14px]">期間指定</p>
              <input
                className="w-[200px] text-ui text-grayscale-800 rounded border border-grayscale-600 px-[10px] py-[8px]"
                type="date"
                onChange={(e) => setTodayAfterClear(e, 'start')}
              />
            </li>

            <li>
              <TildeIcon />
            </li>

            <li className="flex items-center gap-[10px]">
              <input
                className="w-[200px] text-ui text-grayscale-800 rounded border border-grayscale-600 px-[10px] py-[8px]"
                type="date"
                onChange={(e) => setTodayAfterClear(e, 'end')}
              />
            </li>
          </ul>

          <div className="ml-[20px]">
            <BaseButton
              className="w-btn-cell p-button-small tracking-short-text"
              onClick={() => state.search.startSearch()}
            >
              検索
            </BaseButton>
          </div>
        </div>
      </div>

      {state.ui.isLoading ? (
        <div className="flex items-center justify-center w-full p-[60px]">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className="mt-[60px]">
          <ul className="flex flex-wrap -mx-[20px]">
            {state.data.mvpRankings?.results
              .sort((a, b) => a.order - b.order)
              .slice(0, 3)
              .map((staff) => (
                <li
                  key={staff.onsiteUser.id}
                  onClick={() => {
                    navigate(`/staffs_manage/staffs/${staff.onsiteUser.id}`);
                  }}
                  className="
                  relative
                  w-[calc(33.333%_-_40px)] p-[20px] mx-[20px] border-1 border-grayscale-500 rounded base-hover
                "
                >
                  <span
                    className="
                    absolute left-0 top-0 -translate-x-1/2 -translate-y-1/2
                    w-[28px] h-[28px] flex items-center justify-center
                    text-grayscale-100 rounded-full text-base leading-none"
                    style={getTop3RankBadgeStyles(staff.order)}
                  >
                    {staff.order}
                  </span>
                  <div className="flex items-center gap-[10px]">
                    <img
                      className="w-[32px] h-[32px] rounded-full"
                      src={staff.onsiteUser.avatar!}
                      alt={`${staff.onsiteUser.lastName} ${staff.onsiteUser.firstName}の画像`}
                    />
                    <div>
                      <p className="text-grayscale-800 text-ui font-bold">
                        {`${staff.onsiteUser.lastName} ${staff.onsiteUser.firstName}`}
                      </p>
                      <p className="text-label text-grayscale-700 mt-[4px]">
                        {staff.onsiteUser.shop.name}
                      </p>
                    </div>
                  </div>
                  <ul className="mt-[16px]">
                    <li className="text-label text-grayscale-700">
                      {staff.onsiteUser.occupation.name} /{' '}
                      {staff.onsiteUser.grade.position.name} /{' '}
                      {staff.onsiteUser.grade.name}
                    </li>
                    <li className="mt-[10px]">
                      <WealthLabel
                        label={staff.onsiteUser.wealthProfileResult}
                      />
                    </li>
                  </ul>
                  <div className="mt-[16px]">
                    <p className="text-label text-grayscale-700 font-bold">
                      MVPスコア
                    </p>
                    <div className="flex items-center gap-[20px] mt-[4px]">
                      <div className="flex items-center gap-[10px] leading-none">
                        <CircleTrophy />
                        <div className="flex items-end gap-[4px]">
                          <span className="font-bold">{staff.mvpPoint}</span>
                          <span className="text-label">点</span>
                        </div>
                      </div>
                      {/* <div className="flex items-center bg-grayscale-300 rounded px-[10px] py-[4px]">
                        <TrophyIcon />
                        <div className="flex items-center ml-[10px]">
                          <span className="text-label">獲得ポイント</span>
                          <span className="font-bold text-meta ml-[10px]">
                            60/100
                          </span>
                          <span className="text-label ml-[2px]">pt</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          <ul className="mt-[30px] flex flex-col gap-[10px] overflow-x-auto whitespace-nowrap">
            {state.data.mvpRankings?.results
              .sort((a, b) => a.order - b.order)
              .slice(sliceStartIndex, maxVisibleStaffs)
              .map((staff) => (
                <li
                  key={staff.onsiteUser.id}
                  onClick={() => {
                    navigate(`/staffs_manage/staffs/${staff.onsiteUser.id}`);
                  }}
                  className="
                    flex items-center
                    w-full p-[20px] border-1 border-grayscale-500 rounded base-hover
                  "
                >
                  <span
                    className="
                    w-[28px] h-[28px] flex items-center justify-center mr-[30px]
                    text-grayscale-100 rounded-full text-base"
                    style={getTop3RankBadgeStyles(staff.order)}
                  >
                    {staff.order}
                  </span>
                  <div className="flex items-center gap-[10px] min-w-[160px] mr-[40px]">
                    <img
                      className="w-[32px] h-[32px] rounded-full"
                      src={staff.onsiteUser.avatar!}
                      alt={`${staff.onsiteUser.lastName} ${staff.onsiteUser.firstName}の画像`}
                    />
                    <div>
                      <p className="text-grayscale-800 text-ui font-bold">
                        {`${staff.onsiteUser.lastName} ${staff.onsiteUser.firstName}`}
                      </p>
                      <p className="text-label text-grayscale-700 mt-[4px]">
                        {staff.onsiteUser.shop.name}
                      </p>
                    </div>
                  </div>
                  <ul className="min-w-[160px] mr-[40px]">
                    <li className="text-label text-grayscale-700">
                      {staff.onsiteUser.occupation.name} /{' '}
                      {staff.onsiteUser.grade.position.name} /{' '}
                      {staff.onsiteUser.grade.name}
                    </li>
                    <li className="mt-[6px]">
                      <WealthLabel
                        label={staff.onsiteUser.wealthProfileResult}
                      />
                    </li>
                  </ul>
                  <div>
                    <p className="text-label text-grayscale-700 font-bold">
                      MVPスコア
                    </p>
                    <div className="flex items-center gap-[20px] mt-[6px]">
                      <div className="flex items-center gap-[10px] leading-none">
                        <CircleTrophy />
                        <div className="flex items-end gap-[4px]">
                          <span className="font-bold">{staff.mvpPoint}</span>
                          <span className="text-label">点</span>
                        </div>
                      </div>
                      {/* <div className="flex items-center bg-grayscale-300 rounded px-[10px] py-[4px]">
                        <TrophyIcon />
                        <div className="flex items-center ml-[10px]">
                          <span className="text-label">獲得ポイント</span>
                          <span className="font-bold text-meta ml-[10px]">
                            60/100
                          </span>
                          <span className="text-label ml-[2px]">pt</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {maxVisibleStaffs < state.data.mvpRankings?.results.length! && (
            <div className="flex justify-center my-[30px]">
              <BaseButton
                className="w-btn-medium p-button-small bg-grayscale-100 border-main-blue border-1 text-main-blue"
                endIcon={<ArrowDownIcon fill={COLORS.MAIN_BLUE} />}
                onClick={showMoreStaffs}
              >
                もっと見る
              </BaseButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};
