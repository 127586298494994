import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useNavigate } from 'react-router-dom';
import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { AccessTimeIcon } from 'infra/components/UI/Icons/AccessTimeIcon';
import { importMandalatMaster } from 'context/mandalat/api/importMandalatMaster';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { useLatestMandalatMaster } from 'context/mandalat/api/useLatestMandalatMaster';
import { formatDateTime } from 'infra/utils/date-fns';
import { exportMandalatMaster } from 'context/mandalat/api/exportMandalatMaster';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { COLORS } from 'tailwind.config';
import { ThemeSetting } from './ImportMandalatMasterCsv/ThemeSetting/Index';

const CSV_INPUT_FORM_WIDTH = 640;

export const ImportMandalatMasterCsv = () => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();

  const [
    { data: corporation, loading: corporationLoading },
    corporationRefetch,
  ] = useFetchCorporation();
  const [
    { data: latestMandalatmaster, loading: latestMandalatmasterLoading },
    refetch,
  ] = useLatestMandalatMaster();

  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const [csvFile, setCsvFile] = useState<File | null>(null);

  if (
    corporationLoading ||
    latestMandalatmasterLoading ||
    corporation === null
  ) {
    return <LoadingBackdrop isShow />;
  }

  const onSubmit = (file: File) => {
    setCsvFile(file);
    if (file === null) {
      displayMessage({
        text: 'CSVファイルがアップロードされていません。CSVファイルをアップロードしてください。',
        isInterruption: true,
      });
      return;
    }

    startLoading('import-mandalat-master-csv');
    importMandalatMaster(file)
      .then(() => {
        displayMessage({
          text: '一括登録に成功しました',
          isInterruption: true,
          onClose: () => {
            navigate('?showModal');
          },
        });
        refetch();
      })
      .catch((error) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }
      })
      .finally(() => {
        stopLoading('import-mandalat-master-csv');
      });
  };

  return (
    <div>
      <PageTitle title="マンダラ設定" breadcrumbs={[['ホーム', '/']]} />

      <div className="flex items-center mt-[50px] rounded bg-grayscale-100">
        <div className="w-full p-main-section">
          <p className="font-bold pb-[16px] mb-[30px] border-b-2 border-solid border-grayscale-500">
            1. CSVファイルの用意
          </p>

          <p className="text-base text-grayscale-700 mb-[60px]">
            マンダラのCSVファイルを
            <p
              className="text-main-blue cursor-pointer inline-block"
              onClick={() => {
                exportMandalatMaster();
                return false;
              }}
            >
              ダウンロード
            </p>
            して、必要情報を追加・更新してください。
          </p>

          <p className="font-bold pb-[16px] mb-[30px] border-b-2 border-solid border-grayscale-500">
            2. CSVファイルのアップロード
          </p>

          <DragDropFile
            height={180}
            width={CSV_INPUT_FORM_WIDTH}
            file={csvFile}
            handleFile={(file) => {
              onSubmit(file);
            }}
          />

          {latestMandalatmaster?.importedAt && (
            <div>
              <div
                className="flex justify-between mt-[30px] pb-[12px] border-b-1 border-solid border-grayscale-500"
                style={{ width: CSV_INPUT_FORM_WIDTH }}
              >
                <p className="text-base text-grayscale-700">
                  {latestMandalatmaster !== undefined
                    ? latestMandalatmaster.importFileName
                    : '--'}
                </p>
                <div className="flex items-center gap-[6px]">
                  <AccessTimeIcon />
                  <p className="text-meta text-grayscale-600">
                    {latestMandalatmaster !== undefined
                      ? formatDateTime(latestMandalatmaster.importedAt)
                      : '--'}
                  </p>
                </div>
              </div>
            </div>
          )}
          <ThemeSetting
            corporation={corporation}
            latestMandalatmaster={latestMandalatmaster}
            corporationRefetch={corporationRefetch}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * ドラッグアンドドロップコンポーネント
 * ファイルがセットされると`handleFile`が呼び出される
 * LINK: https://www.codemzy.com/blog/react-drag-drop-file-upload
 */
const DragDropFile = (props: {
  width: number;
  height: number;
  file: File | null;
  handleFile: (f: File) => void;
}) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag: DragEventHandler<HTMLFormElement | HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.handleFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <form
      className="max-w-full text-center relative"
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      style={{
        height: props.height,
        width: props.width,
      }}
    >
      <input
        className="hidden"
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple
        onChange={handleChange}
        accept=".csv"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className="w-full h-full flex items-center justify-center border-2 border-dashed border-grayscale-500"
        style={{
          backgroundColor: dragActive ? COLORS.SOFT_BLUE : COLORS.GRAY_400,
        }}
      >
        <div>
          <p className="pb-[30px] text-base text-grayscale-700">
            {props.file === null
              ? 'CSVファイルをドラッグ&ドロップ'
              : props.file.name}
          </p>
          <BaseButton
            className="w-btn-medium text-base p-button-small"
            onClick={onButtonClick}
          >
            {props.file === null ? 'アップロード' : '変更する'}
          </BaseButton>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className="absolute w-full h-full inset-0"
        />
      )}
    </form>
  );
};
