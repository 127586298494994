import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export const Divider: FC<Props> = ({ className = '' }) => {
  const defaultClasses = `
    border border-b border-t-0 border-grayscale-500
  `;

  return <hr className={`${twMerge(defaultClasses, className)}`} />;
};
