/**
 * キーワードに基づく検索入力フィールドです。キーワード検索に使用されます。
 * ユーザーが入力したテキストに基づいて検索を行うために使用されます。
 *
 * ユーザーが入力フィールドにテキストを入力すると、その値が検索パラメータとして渡され条件に応じてフィルタリングされます。
 *
 * また、入力フィールドに値が存在する場合、「文字をクリア」アイコンが表示され、
 * このボタンをクリックすると入力フィールドの値がクリアされます。
 *
 * Props:
 * - `searchKey`: 検索時に使用するキー。このキーに基づいて入力された値が検索パラメータとして設定されます。
 *
 * 使用例:
 * <KeywordInputFilter searchKey="boss_name" />
 */
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { CloseButton } from 'infra/components/UI/Icons/CloseButton';
import { Types } from 'infra/components/UI/Table/Pagination/Pagination';
import { SearchIcon } from 'infra/components/UI/Icons/SearchIcon';

export const KeywordInputFilter = ({
  searchKey,
  pagerState,
  placeholder = 'キーワード',
  className,
  icon,
}: {
  searchKey: string;
  pagerState: Types.PagerState;
  placeholder?: string;
  className?: string;
  icon?: ReactElement;
}) => {
  const [inputValue, setInputValue] = useState(
    pagerState.searchParams.get(searchKey) || ''
  );

  // HACK: ステートの変更は検知できているが、再レンダリングがされないため、強制的に再レンダリングを行う
  const forceUpdate = useCallback(() => setInputValue(''), []);

  useEffect(() => {
    if (!pagerState.searchParams.get(searchKey)) {
      forceUpdate();
    }
  }, [pagerState.searchParams]);

  const clearInputValue = () => {
    setInputValue('');
    pagerState.setSearchParams((prev: any) => {
      prev.delete(searchKey);
      return prev;
    });
  };

  return (
    <div className="relative">
      {icon && (
        <div className="absolute top-[48%] left-[10px] -translate-y-1/2">
          {icon}
        </div>
      )}

      <input
        type="text"
        className={`
          w-[200px] pt-[8px] pb-[9px] pl-[12px] pr-[20px]
          border-1 border-grayscale-600 rounded text-ui text-left bg-grayscale-100
          ${className || ''}
          ${icon ? 'pl-[35px]' : ''}
        `}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => {
          pagerState.setSearchParams((prev: any) => {
            prev.delete('page');
            prev.set(searchKey, e.target.value);
            return prev;
          });
          setInputValue(e.target.value);
        }}
      />
      {inputValue && (
        <button
          type="button"
          className="absolute top-[48%] right-[10px] -translate-y-1/2"
          onClick={clearInputValue}
          aria-label="Name"
        >
          <CloseButton />
        </button>
      )}
    </div>
  );
};
