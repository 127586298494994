import { PropsWithChildren } from 'react';

const getCappedScore = (score?: number | null) => {
  if (score === undefined || score === null) {
    return '-';
  }

  return score > 100 ? 100 : score.toFixed(1);
};

export const ScoreRow = (
  props: PropsWithChildren<{
    score?: number;
    previewScore?: number | null;
    isShowPrevScore?: boolean;
  }> = {
    score: 85.5,
    previewScore: 56.5,
  }
) => (
  <div className="flex gap-[10px] items-center">
    <div className="rounded border-1 border-main-blue px-[6px] py-[4px]">
      <p className="text-main-blue text-micro font-bold">評価スコア</p>
    </div>

    <p className="text-score">{getCappedScore(props.score!)}</p>
    <p className="text-ui pt-[4px]">点</p>

    {props.isShowPrevScore && (
      <div className="flex gap-[10px] items-center">
        <div className="rounded border-1 border-main-blue px-[6px] py-[4px]">
          <p className="text-main-blue text-micro font-bold">前回評価スコア</p>
        </div>
        {props.previewScore === undefined || props.previewScore === null ? (
          <p className="text-score">-</p>
        ) : (
          <>
            <p className="text-score">{getCappedScore(props.previewScore!)}</p>
            <p className="text-ui pt-[4px]">点</p>
          </>
        )}
      </div>
    )}
  </div>
);

export const ScoreRowVertical = (
  props: PropsWithChildren<{
    score?: number;
    previewScore?: number | null;
    isShowPrevScore?: boolean;
  }> = {}
) => (
  <div className="flex items-center">
    <div className="w-[70px]">
      <div className="rounded border-1 border-main-blue px-[6px] py-[4px] text-center mb-[11px]">
        <p className="text-main-blue text-micro font-bold">今期評価スコア</p>
      </div>

      <div className="flex items-center justify-between">
        <p className="text-score font-bold">{getCappedScore(props.score!)}</p>
        <p className="text-label pt-[4px]">点</p>
      </div>
    </div>

    {props.isShowPrevScore && (
      <div className="w-[70px] ml-[24px]">
        <div className="rounded border-1 border-main-blue px-[6px] py-[4px]  text-center  mb-[11px]">
          <p className="text-main-blue text-micro font-bold">前回評価スコア</p>
        </div>

        <div className="flex items-center justify-between">
          {props.previewScore === undefined || props.previewScore === null ? (
            <p className="text-score">-</p>
          ) : (
            <>
              <p className="text-score font-bold">
                {getCappedScore(props.previewScore!)}
              </p>
              <p className="text-label pt-[4px]">点</p>
            </>
          )}
        </div>
      </div>
    )}
  </div>
);
