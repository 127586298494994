import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { ResponsePagination } from 'infra/api/type';

const LIST_URL = '/tenant/my/corporation/grade/';

export type GradeListResponse = SnakeObjToCamelObj<
  paths[typeof LIST_URL]['get']['responses'][200]['content']['application/json']
>;

export const fetchGradeList = (
  limit: number,
  offset: number,
  searchParams: URLSearchParams
) => {
  const params = Object.fromEntries(searchParams.entries());
  const response = apiClient.get<ResponsePagination<GradeListResponse>>(
    LIST_URL,
    {
      params: {
        limit,
        offset,
        ...params,
      },
    }
  );

  return response;
};
