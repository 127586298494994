import { useState, useEffect } from 'react';
import { useFetchShops } from 'context/corporation/shop/api/fetchShops';
import { EMPLOYMENT_STATUS_LIST } from 'context/users/onsite_users/_infra/state/const/employmentStatus';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { getMvpCategories } from 'context/mvps/api/get.mvpCategory';
import { BUSINESS_TYPE_LIST } from 'context/corporation/brand/components/BrandForm';
import {
  getMvpRankings,
  queryParameter as _queryParameter,
} from 'context/corporation/rankings/api/get.mvp_ranking';

export const MvpRankingState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [querylocalParameter, setlocalQueryParameter] =
    useState(_queryParameter);

  const [queryParameterState, setQueryParameterState] =
    useState(_queryParameter);
  const [{ data: mvpRankings }, refetch] = getMvpRankings(queryParameterState);
  const [{ data: shops }] = useFetchShops();
  const [{ data: corporation }] = useFetchCorporation();
  const [{ data: mvpData }, _] = getMvpCategories();

  const [selectedCategory, setSelectedCategory] = useState('all');

  useEffect(() => {
    setIsLoading(true);
    refetch()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('リクエストエラー:', error);
      });

    return () => {
      setIsLoading(false);
    };
  }, [queryParameterState]);

  function startSearch() {
    setQueryParameterState({
      ...querylocalParameter,
    });
  }

  function setQueryParameter(key: string, value: any) {
    setlocalQueryParameter({
      ...querylocalParameter,
      [key]: value,
    });
  }

  return {
    data: {
      mvpRankings,
      shops,
      corporation,
      mvpData,
      refetch,
    },
    search: {
      startSearch,
      querylocalParameter,
      setQueryParameter,
      selectedCategory,
      setSelectedCategory,
    },
    const: {
      EMPLOYMENT_STATUS_LIST,
      BUSINESS_TYPE_LIST,
    },
    ui: {
      isLoading,
    },
  };
};
