import { FC } from 'react';
import { COLORS } from 'tailwind.config';

const d =
  'M16.931,3A3.069,3.069,0,0,1,20,6.069V16.931A3.069,3.069,0,0,1,16.931,20H6.069A3.069,3.069,0,0,1,3,16.931V6.069A3.069,3.069,0,0,1,6.069,3Zm1.653,5.19' +
  '4H4.417v8.736a1.653,1.653,0,0,0,1.653,1.653H16.931a1.653,1.653,0,0,0,1.653-1.653Zm-11.1,5.667a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,7.486,13.861' +
  'Zm4.014,0a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,11.5,13.861ZM7.486,10.083a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,7.486,10.083Zm4.014,0a' +
  '1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,11.5,10.083Zm4.014,0a1.181,1.181,0,1,1-1.181,1.181A1.181,1.181,0,0,1,15.514,10.083Zm1.417-5.667H6.069A1.65' +
  '3,1.653,0,0,0,4.417,6.069v.708H18.583V6.069A1.653,1.653,0,0,0,16.931,4.417Z';

const colorMapping = {
  'text-main-blue': COLORS.MAIN_BLUE,
  white: COLORS.GRAY_100,
};

type Props = {
  color?: string;
};

export const CalendarIcon: FC<Props> = ({ color = '#ffffff' }) => {
  const fillColor = colorMapping[color as keyof typeof colorMapping] || color;
  return (
    <svg
      id="_-Product-Icons"
      data-name="-Product-Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="ic_fluent_calendar_24_regular">
        <path id="_-Color" data-name="-Color" d={d} fill={fillColor} />
      </g>
    </svg>
  );
};
