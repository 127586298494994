export const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="9"
    viewBox="0 0 5 9"
  >
    <defs>
      <clipPath id="a">
        <rect width="9" height="5" fill="none" />
      </clipPath>
    </defs>
    <g transform="translate(5) rotate(90)" opacity="0.4" clipPath="url(#a)">
      <path
        d="M13,12,9.5,9,6,12"
        transform="translate(-5 -8)"
        fill="none"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);
