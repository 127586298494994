import {
  AppBar,
  CircularProgress,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { HelpCircleIcon } from 'pages/β_layputs/icons/HelpCircleIcon';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { getLoginInfo } from 'context/auth/api/login';
import { useAtom } from 'jotai';
import { logoutAtom } from 'context/auth/store/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLogout } from 'context/auth/api/logout';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';

import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { MenuDrawer } from './MenuDrawer';
import { Footer } from './Footer';
import { ExpandSidebar } from './icons/ExpandSidebar';

/** 共通の画面レイアウト */
export const CommonLayout = (props: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const [expandSidebarState, setExpandSidebarState] = useState(true);

  const [, displayMessage] = useAtom(displayMessageAtom);

  const [{ data: me, error, loading: loadingMyData }] = getLoginInfo();

  useEffect(() => {
    if (error?.response?.status !== 401) return;

    console.warn('未ログインのためログイン画面へ遷移');
    displayMessage({ text: 'ログインしてください', isInterruption: false });
    navigate('/guest/login');
  }, [error]);

  const [{ data: corporation, loading: loadingCorporation }] =
    useFetchCorporation(true);

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const [, logout] = useAtom(logoutAtom);
  const [, executeLogout] = useLogout();

  const onLogout = async () => {
    await executeLogout();
    logout();
    navigate('/guest/login');
  };

  const isLoading = loadingMyData || loadingCorporation;

  return (
    // 横幅が大きくなりすぎると各アイテムの縦幅も大きくなりすぎるため
    <div className="h-screen flex bg-grayscale-400">
      <MenuDrawer expandSidebarState={expandSidebarState} />
      <div className="flex flex-col w-full">
        <div
          className="
              sticky top-0 z-50 bg-grayscale-100 text-grayscale-800
              box-shadow-main min-h-[60px] flex items-center
              pr-[30px]
            "
        >
          <p
            className="px-[10px] base-hover"
            onClick={() => {
              setExpandSidebarState(!expandSidebarState);
            }}
          >
            <ExpandSidebar />
          </p>
          <p className="text-base font-bold flex-grow">{corporation?.name}</p>

          {/* <div className="mr-[20px]">
            <HelpCircleIcon />
          </div> */}

          <button
            type="button"
            aria-label="アカウントメニュー"
            onClick={(event) => setMenuAnchorElement(event.currentTarget)}
          >
            <p className="text-base text-main-blue font-bold">
              {me?.lastName} {me?.firstName}
            </p>
          </button>
          <Menu
            anchorEl={menuAnchorElement}
            open={menuAnchorElement !== null}
            onClose={() => setMenuAnchorElement(null)}
            // スクロールバーが消えるとposition:absoluteな要素がずれるので、スクロールバーを消さないように
            disableScrollLock
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={onLogout}>ログアウト</MenuItem>
          </Menu>
        </div>

        <div className="flex-grow bg-grayscale-400 overflow-y-scroll" id="main">
          {isLoading ? (
            <div className="w-full h-full grid place-items-center h-full">
              <CircularProgress size={60} color="primary" />
            </div>
          ) : (
            <div className="p-[32px] pb-[120px] min-w-0 max-w-[1184px]">
              {props.children}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};
