import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';
import { isPast } from 'date-fns';
import { ProgressActiveIcon } from 'context/users/onsite_users/_infra/components/StaffDetails/_components/CommitmentCard/ProgressActiveIcon';
import { ProgressIcon } from 'context/users/onsite_users/_infra/components/StaffDetails/_components/CommitmentCard/ProgressIcon';
import { ThreeDotIcon } from 'context/users/onsite_users/_infra/components/StaffDetails/_components/CommitmentCard/ThreeDotIcon';
import { COLORS } from 'tailwind.config';

export const ProgressItemList = (props: {
  objectiveRecord: ResponseObjectiveRecord;
}) => {
  const isCompleteMiddleEvaluation =
    props.objectiveRecord.isCompletedMiddleEvaluationByBoss || false;
  return (
    <div className="flex items-start gap-[10px]">
      <ProgressItem isActive label="目標設定" />
      <div className="flex items-center h-[40px]">
        <ThreeDotIcon style={{ fontSize: 16 }} />
      </div>
      <ProgressItem
        isActive={isPast(
          new Date(
            props.objectiveRecord.evaluationPeriod.intermediateMyselfEvaluationStartedAt
          )
        )}
        label="中間評価"
      />
      <div className="flex items-center h-[40px]">
        <ThreeDotIcon style={{ fontSize: 16 }} />
      </div>
      <ProgressItem
        isActive={
          isPast(
            new Date(
              props.objectiveRecord.evaluationPeriod.finalMyselfEvaluationStartedAt
            )
          ) && isCompleteMiddleEvaluation
        }
        label="最終評価"
      />
      <div className="flex items-center h-[40px]">
        <ThreeDotIcon style={{ fontSize: 16 }} />
      </div>
      <ProgressItem
        isActive={props.objectiveRecord.score !== null}
        label="評価確定"
      />
    </div>
  );
};

const ProgressItem = (props: { label: string; isActive?: boolean }) => {
  return (
    <div className="flex flex-col gap-[10px]">
      {props.isActive ? (
        <ProgressActiveIcon style={{ fontSize: 40 }} />
      ) : (
        <ProgressIcon style={{ fontSize: 40 }} />
      )}
      <p
        className="text-label font-bold"
        style={{
          color: !props.isActive ? COLORS.GRAY_400 : undefined,
        }}
      >
        {props.label}
      </p>
    </div>
  );
};
