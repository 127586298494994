import { PropsWithChildren } from 'react';

export const IndexBox = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <div className="flex justify-center items-center bg-grayscale-900 text-grayscale-100 text-label text-center font-semibold w-[20px] h-[20px]">
      {children}
    </div>
  );
};
