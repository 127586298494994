import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOnsiteUser } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { useEffect, useState } from 'react';
import { useFetchObjectiveRecords } from 'context/objective/api/useFetchObjectiveRecords';
import { StaffPickUpInfo } from './StaffPickUpInfo';
import { StaffTabs } from './StaffTabs';

export const StaffDetails = () => {
  const { staffId } = useParams();

  const navigate = useNavigate();

  const [{ data: objectiveRecords }] = useFetchObjectiveRecords(staffId!);
  const [selectedObjectiveRecordId, setSelectedObjectiveRecordId] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (staffId === undefined) {
      navigate(-1);
    }
  }, []);

  const [{ data: onsiteUser, loading }] = useGetOnsiteUser(staffId!);
  if (loading || onsiteUser === undefined) {
    return <LoadingBackdrop isShow />;
  }

  return (
    <div className="h-full">
      <PageTitle
        title="スタッフ詳細"
        breadcrumbs={[
          ['ホーム', '/'],
          ['スタッフ一覧', '/staffs_manage/staffs/'],
        ]}
      />

      <div className="flex gap-[20px] pt-[50px] h-full">
        <div className="min-w-[300px] min-h-full">
          <StaffPickUpInfo onsiteUser={onsiteUser} />
        </div>
        <StaffTabs
          staffId={staffId}
          selectedObjectiveRecordId={selectedObjectiveRecordId}
          objectiveRecords={objectiveRecords?.results ?? []}
          onsiteUser={onsiteUser}
          onSelectObjectiveRecord={(id) => setSelectedObjectiveRecordId(id)}
        />
      </div>
    </div>
  );
};
