import { Dialog } from '@mui/material';

import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren } from 'react';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ResponsePosition } from 'context/corporation/position/api/types';
import {
  hasNonFieldError,
  useDisplayNonFieldApiError,
} from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

import { updateGrade } from 'context/corporation/grade/api/updateGrade';
import { AttentionMessageBox } from 'infra/components/UI/Feedbacks/AttentionMessageBox';
import { getWageType } from 'context/corporation/grade/type';
import { COLORS } from 'tailwind.config';
import { DoughnutChart } from 'context/corporation/grade/components/GradeList/DoughnutChart';
import { LabeledScoreEditor } from 'context/corporation/grade/components/GradeList/GradeEditDialog/_components/LabeledScoreEditor';
import { GradeEditDialogState } from 'context/corporation/grade/components/GradeList/GradeEditDialog/state';
import { Textarea } from 'infra/components/UI/Forms/Textarea';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { Checkbox } from 'infra/components/UI/Forms/Checkbox';

import { useFilterAreaContext } from 'infra/components/UI/Table/Pagination/Pagination';
import { Grade } from '../..';

function getDefaultValue(val: any, dValue = 50) {
  if (Number(val) === 0) {
    return 0;
  }

  if (!val) {
    return dValue;
  }

  return val;
}

export const GradeEditDialog = (
  props: PropsWithChildren<{
    // nullの場合は新規登録
    // eslint-disable-next-line react/no-unused-prop-types
    gradeId: number | null;
    grade: Grade | null;
    positionList: ResponsePosition[];
    onClose: () => void;
    onSave: () => void;
  }>
) => {
  const useFormInstance = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: props.grade?.name || '',
      position: props.grade?.position || '',
      weightQualitative: getDefaultValue(props.grade?.weightQualitative),
      weightQuantitative: getDefaultValue(props.grade?.weightQuantitative),
      weightCommitment: getDefaultValue(props.grade?.weightCommitment),
      weightMvp: getDefaultValue(props.grade?.weightMvp),
      weightSales: getDefaultValue(props.grade?.weightSales, 25),
      weightProfit: getDefaultValue(props.grade?.weightProfit, 25),
      weightEs: getDefaultValue(props.grade?.weightEs, 25),
      weightCs: getDefaultValue(props.grade?.weightCs, 25),
      salary: getDefaultValue(props.grade?.salary, 250000),
      isDifferentHourlyWageByShops:
        props.grade?.isDifferentHourlyWageByShops || false,
      evaluationMethodDescription: props.grade?.evaluationMethodDescription,
    } as GradeForm,
  });

  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);

  const pagerState = useFilterAreaContext();

  const onSubmit = useFormInstance.handleSubmit((value) => {
    startLoading('save_grade');

    const isCreate = false;
    const process = updateGrade(props.gradeId!, value);
    process
      .then(() => {
        displayMessage({
          text: `給与 / 評価ウェイトを${isCreate ? '登録' : '更新'}しました`,
          isInterruption: false,
        });
        props.onSave();
      })
      .catch((error) => {
        if (hasNonFieldError(error)) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(async () => {
        await pagerState.doitPaging(pagerState.pager.page);
        stopLoading('save_grade');
      });
  });

  const isDifferentHourlyWageByShops = useFormInstance.watch(
    'isDifferentHourlyWageByShops'
  );
  const gradeEditDialogState = GradeEditDialogState();

  const chartDataEvaluation = gradeEditDialogState.reactive.updateChartArea(
    ['weightQualitative', 'weightQuantitative'],
    useFormInstance.control
  );

  const chartDataQualitative = gradeEditDialogState.reactive.updateChartArea(
    ['weightCommitment', 'weightMvp'],
    useFormInstance.control
  );

  const chartDataQuantitative = gradeEditDialogState.reactive.updateChartArea(
    ['weightSales', 'weightProfit', 'weightEs', 'weightCs'],
    useFormInstance.control
  );

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={onSubmit}
          >
            保存
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div className="pb-[40px]">
          <h2 className="text-section-heading font-bold">
            給与 / 評価ウェイト設定
          </h2>

          <input type="hidden" {...useFormInstance.register('position')} />
          <input type="hidden" {...useFormInstance.register('name')} />

          <div className="mb-[20px] mt-[30px]">
            <p className="text-base font-bold mb-[20px]">職位名</p>
            <p className="text-ui">
              {
                props.positionList.find(
                  (item) => item.id === useFormInstance.watch('position')
                )?.name
              }
            </p>
          </div>

          <div className="mb-[20px] mt-[30px]">
            <p className="text-base font-bold mb-[20px]">等級名</p>
            <p className="text-ui">{useFormInstance.watch('name')}</p>
          </div>

          <div className="mb-[40px]">
            <p className="text-base font-bold pb-[20px]">基本給</p>
            <div className="flex items-center pb-[10px]">
              <span className="text-ui">{getWageType(props.grade!)}</span>
              <div className="flex items-center">
                <div className="px-[10px]">
                  <TextField
                    useForm={useFormInstance}
                    name="salary"
                    isErrorFiled={!!useFormInstance.formState.errors.name}
                    className="w-[320px]"
                    asNumeric
                  />
                </div>
                <span className="text-ui">円</span>
              </div>
            </div>

            <div className="flex items-center">
              <Checkbox
                name="isDifferentHourlyWageByShops"
                useForm={useFormInstance}
                className="p-[0px]"
                id="isDifferentHourlyWageByShops"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="isDifferentHourlyWageByShops"
                className="text-ui pl-[30px] no-selection-bg"
              >
                店舗ごとにパート・アルバイトの時給が異なる
              </label>
            </div>

            {isDifferentHourlyWageByShops && (
              <div className="my-[10px]">
                <AttentionMessageBox message="各店舗の詳細時給は後ほど店舗管理画面から登録してください" />
              </div>
            )}
          </div>

          <p className="pb-[30px] text-base font-bold">評価ウェイト</p>

          <div>
            <div className="mb-[30px] p-[30px]">
              <p className="pb-[30px] text-base font-bold text-grayscale-700">
                ① 評価ウェイトを設定してください。
              </p>

              <div className="flex items-center gap-[40px]">
                <div className="w-[120px] h-[120px]">
                  <DoughnutChart
                    datasets={[
                      {
                        data: [
                          props.grade?.weightQualitative ?? 0,
                          props.grade?.weightQuantitative ?? 0,
                        ],
                        backgroundColor: [COLORS.MAIN_BLUE, COLORS.SUB_GREEN],
                        borderWidth: 0,
                      },
                    ]}
                    updateChartArea={chartDataEvaluation}
                  />
                </div>
                <LabeledScoreEditor
                  scores={[
                    {
                      name: 'weightQualitative',
                      color: COLORS.MAIN_BLUE,
                      label: '定性',
                    },
                    {
                      name: 'weightQuantitative',
                      color: COLORS.SUB_GREEN,
                      label: '定量',
                    },
                  ]}
                  control={useFormInstance.control}
                />
              </div>
            </div>

            <div className="mb-[30px] p-[30px] bg-grayscale-200 rounded">
              <p className="pb-[30px] text-base font-bold text-grayscale-700">
                ② 定性の評価ウェイトを設定してください。
              </p>

              <div className="flex items-center justify-start flex-1 gap-[40px]">
                <div className="w-[120px] h-[120px]">
                  <DoughnutChart
                    datasets={[
                      {
                        data: [
                          props.grade?.weightCommitment ?? 0,
                          props.grade?.weightMvp ?? 0,
                        ],
                        backgroundColor: [COLORS.MAIN_BLUE, COLORS.SUB_GREEN],
                        borderWidth: 0,
                      },
                    ]}
                    updateChartArea={chartDataQualitative}
                  />
                </div>

                <LabeledScoreEditor
                  scores={[
                    {
                      name: 'weightCommitment',
                      color: COLORS.MAIN_BLUE,
                      label: 'マンダラ',
                    },
                    {
                      name: 'weightMvp',
                      color: COLORS.SUB_GREEN,
                      label: 'MVPコイン',
                    },
                  ]}
                  control={useFormInstance.control}
                />
              </div>
            </div>

            <div className="mb-[30px] p-[30px] bg-grayscale-200 rounded">
              <p className="pb-[30px] text-base font-bold text-grayscale-700">
                ③定量の評価ウェイトを設定してください。
              </p>

              <div className="flex items-center justify-start flex-1 gap-[40px]">
                <div className="w-[120px] h-[120px]">
                  <DoughnutChart
                    datasets={[
                      {
                        data: [
                          props.grade?.weightSales ?? 0,
                          props.grade?.weightProfit ?? 0,
                          props.grade?.weightEs ?? 0,
                          props.grade?.weightCs ?? 0,
                        ],
                        backgroundColor: [
                          COLORS.MAIN_BLUE,
                          COLORS.SUB_GREEN,
                          COLORS.MAIN_YELLOW,
                          COLORS.SUB_PINK,
                        ],
                        borderWidth: 0,
                      },
                    ]}
                    updateChartArea={chartDataQuantitative}
                  />
                </div>

                <LabeledScoreEditor
                  scores={[
                    {
                      name: 'weightSales',
                      color: COLORS.MAIN_BLUE,
                      label: '売上',
                    },
                    {
                      name: 'weightProfit',
                      color: COLORS.SUB_GREEN,
                      label: '利益',
                    },
                    {
                      name: 'weightEs',
                      color: COLORS.MAIN_YELLOW,
                      label: 'ES',
                    },
                    {
                      name: 'weightCs',
                      color: COLORS.SUB_PINK,
                      label: 'CS',
                    },
                  ]}
                  control={useFormInstance.control}
                />
              </div>
            </div>

            <p className="text-base font-bold mb-[20px]">評価方法の説明</p>
            <Textarea
              useForm={useFormInstance}
              name="evaluationMethodDescription"
            />
          </div>
        </div>
      </BaseModal>
    </Dialog>
  );
};

const schema = z.object({
  name: z.string().min(1),
  position: z.number().min(1),
  weightQualitative: z.number().gte(0).lte(100),
  weightQuantitative: z.number().gte(0).lte(100),
  weightCommitment: z.number().gte(0).lte(100),
  weightMvp: z.number().gte(0).lte(100),
  weightSales: z.number().gte(0).lte(100),
  weightProfit: z.number().gte(0).lte(100),
  weightEs: z.number().gte(0).lte(100),
  weightCs: z.number().gte(0).lte(100),
  salary: z.number().gte(0).lte(9999999999),
  isDifferentHourlyWageByShops: z.boolean().default(false),
  evaluationMethodDescription: z.string().max(20000).optional(),
});

export type GradeForm = z.infer<typeof schema>;
