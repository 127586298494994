import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

type LabeledScoreEditorProps = {
  control: any;
  scores: {
    name: string;
    color: string;
    label: string;
  }[];
};

export const LabeledScoreEditor = ({
  scores,
  control,
}: LabeledScoreEditorProps) => {
  return (
    <div className="flex flex-start gap-[20px] w-full">
      {scores.map(({ name, color, label }) => (
        <div key={name}>
          <div className="flex items-center gap-[10px] mb-[10px]">
            <div
              className="w-[20px] h-[20px] rounded-full"
              style={{ backgroundColor: color }}
            />
            <p className="text-base font-bold">{label}</p>
          </div>
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                onChange={(v) => {
                  const { value } = v.target;
                  if (/^\d+$/.test(value)) {
                    field.onChange(parseInt(value, 10));
                  } else if (value === '') {
                    field.onChange('');
                  } else {
                    field.onChange(0);
                  }
                }}
                size="small"
                type="text"
                sx={{
                  width: scores.length <= 2 ? '120px' : '80px',
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
                error={fieldState.error !== undefined}
                helperText={fieldState.error?.message}
                label=""
                variant="outlined"
              />
            )}
          />
        </div>
      ))}
    </div>
  );
};
