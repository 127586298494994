import { apiClient } from 'infra/api/config';

/**
 * 中間上司評価の登録
 */
export const postMiddleEvaluation = async (
  objectiveRecordId: string,
  scoreList: { score: number; comment: string | null }[]
) => {
  return apiClient.post(
    `/tenant/my/objective/${objectiveRecordId}/evaluation_results/middle_evaluation_by_boss/`,
    {
      evaluation_results: scoreList,
    }
  );
};
