import { Chip, ChipProps } from '@mui/material';
import { COLORS } from 'tailwind.config';
import { colorToRgba } from '../../../../context/mvps/types/mvpCategory';

export const BaseChip = (
  props: ChipProps & {
    color16?: string;
  }
) => {
  const { color16, ...chipProps } = props;
  const color = color16 === undefined ? COLORS.GRAY_700 : color16;

  return (
    <Chip
      {...chipProps}
      style={{
        marginRight: 10,
        fontSize: 10,
        borderRadius: 6,
        color,
        background: colorToRgba(color, 0.1),
      }}
    />
  );
};
