import { Link } from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { NewTabIcon } from 'context/corporation/contracts/components/NewTabIcon';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';

import getContract from 'context/corporation/contracts/api/get.contract';
import postBillingURL from 'context/corporation/contracts/api/post.billing_url';
import patchContract from 'context/corporation/contracts/api/patch.contract';
import { useAtom } from 'jotai';

import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

import { useEffect } from 'react';
import { parseISO } from 'date-fns';
import { Divider } from 'infra/components/UI/Elements/Divider';

import { Select } from 'infra/components/UI/Forms/Select';

const contractScheme = z.object({
  closingDateMonth: z.string().min(2, '決算日の「月」を選択してください'),
  closingDateDay: z.string().min(2, '決算日の「日」を選択してください'),
});

type ContractFormData = z.infer<typeof contractScheme>;

export const ContractSetting = () => {
  const [{ data, loading }] = getContract();
  const [, displayMessage] = useAtom(displayMessageAtom);

  const useFormInstance = useForm<ContractFormData>({
    resolver: zodResolver(contractScheme),
    defaultValues: {
      closingDateMonth: '01',
      closingDateDay: '01',
    },
  });

  useEffect(() => {
    if (!loading && data?.closingDate) {
      useFormInstance.setValue(
        'closingDateMonth',
        [...data.closingDate].splice(0, 2).join('')
      );
      useFormInstance.setValue(
        'closingDateDay',
        [...data.closingDate].splice(2, 2).join('')
      );
    }
  }, [loading]);

  if (loading) {
    return <LoadingBackdrop isShow />;
  }

  const submit: SubmitHandler<ContractFormData> = async (contractFormData) => {
    const date = parseISO(
      `2023-${contractFormData.closingDateMonth}-${contractFormData.closingDateDay}`
    );
    if (Number.isNaN(date.getTime())) {
      displayMessage({
        text: '利用開始日が不正な日付になっています。確認してください',
        isInterruption: true,
      });
      return;
    }
    patchContract({
      closingDate: `${contractFormData.closingDateMonth}${contractFormData.closingDateDay}`,
    });
    displayMessage({
      text: '契約情報を更新しました。',
      isInterruption: false,
    });
  };

  const submitToPostBillingURL = async () => {
    const response = await postBillingURL();
    window.open(response.data.stripePortalUrl, '_blank');
  };

  return (
    <div>
      <PageTitle title="ご契約情報" breadcrumbs={[['ホーム', '/']]} />
      <form>
        <div className="mt-[50px] p-main-section bg-grayscale-100">
          <div>
            <p className="text-base font-bold">会社名</p>
            <p className="pl-[10px] text-grayscale-700 p-[10px] mt-[16px] text-ui">
              {data?.corporation.name}
            </p>
          </div>
          <div>
            <p className="text-base font-bold mt-[20px]">電話番号</p>
            <p className="pl-[10px] text-grayscale-700 p-[10px] mt-[16px] text-ui">
              {data?.contactPhoneNumber}
            </p>
          </div>
          <div>
            <p className="text-base font-bold mt-[16px]">決算月</p>
            <div className="flex items-center mt-[20px]">
              <div className="flex items-center mr-[20px]">
                <Select
                  useForm={useFormInstance}
                  name="closingDateMonth"
                  menuItems={[...Array(12)].map((_, index) => {
                    const month = (index + 1).toString();
                    return {
                      label: month,
                      value: month.length === 1 ? `0${month}` : month,
                    };
                  })}
                  isErrorFiled={
                    !!useFormInstance.formState.errors.closingDateMonth
                  }
                  className="w-[80px]"
                />
                <span className="ml-[10px] text-ui text-grayscale-700">月</span>
              </div>

              <div className="flex items-center">
                <Select
                  useForm={useFormInstance}
                  name="closingDateDay"
                  menuItems={[...Array(31)].map((_, index) => {
                    const month = (index + 1).toString();
                    return {
                      label: month,
                      value: month.length === 1 ? `0${month}` : month,
                    };
                  })}
                  isErrorFiled={
                    !!useFormInstance.formState.errors.closingDateDay
                  }
                  className="w-[80px]"
                />

                <span className="ml-[10px] text-ui text-grayscale-700">日</span>
              </div>
            </div>
          </div>
          <div>
            <p className="text-base font-bold mt-[20px]">利用開始日</p>
            <p className="pl-[10px] text-grayscale-700 p-[10px] mt-[16px] text-ui">
              {new Date(data?.usageStartedAt ?? '').getFullYear()}年
              {new Date(data?.usageStartedAt ?? '').getMonth() + 1}月
              {new Date(data?.usageStartedAt ?? '').getDate()}日
            </p>
          </div>
          {/* <div>
            <p className="text-base font-bold mt-[20px]">
              お支払い設定・ご利用履歴
            </p>
            <BaseButton
              className="w-btn-large p-button-small font-normal text-meta bg-grayscale-100 text-main-blue border mt-[24px]"
              startIcon={<NewTabIcon />}
              onClick={submitToPostBillingURL}
            >
              お支払い設定・ご利用履歴
            </BaseButton>
          </div> */}
          <div>
            <Divider className="mt-[60px] mb-[40px]" />
            <div className="flex justify-center items-center relative">
              <BaseButton
                type="submit"
                className="w-btn-small text-btn-confirm p-button-small"
                onClick={useFormInstance.handleSubmit(submit)}
              >
                変更
              </BaseButton>
              <div className="flex items-center absolute right-0 top-0 bottom-0">
                <Link href="mailto:contact@thanxi.co.jp" target="_blank">
                  メールでお問い合わせ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
