import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { useAtom } from 'jotai';
import { Dialog } from '@mui/material';
import { useState } from 'react';
import { exportStaffInfoCsv } from '../../_infra/api/exportStaffInfoCsv';

const Modal = (props: any) => {
  const [isModal, setIsModal] = props.modalState;

  return (
    <Dialog
      open={isModal}
      onClose={() => {
        setIsModal(false);
      }}
      maxWidth={false}
    >
      <div className="px-[130px] pt-[60px] pb-[60px] min-w-[750px]">
        <h1 className="text-section-heading font-bold mb-[35px]">
          CSVダウンロード
        </h1>

        <div className="flex items-center mb-[30px] text-base leading-relaxed">
          選択したデータのCSVダウンロードを開始します。
          <br />
          よろしいですか？
        </div>

        <div className="mt-[60px] gap-[60px] flex justify-center">
          <button type="button" onClick={() => setIsModal(false)}>
            <p className="text-grayscale-700 font-normal">キャンセル</p>
          </button>

          <BaseButton
            className="!w-[108px] text-btn-confirm p-button-small"
            onClick={() => {
              exportStaffInfoCsv();
              setIsModal(false);
            }}
          >
            OK
          </BaseButton>
        </div>
      </div>
    </Dialog>
  );
};

export const CsvDownloader = () => {
  const modalState = useState(false);

  return (
    <div>
      <BaseButton
        onClick={() => {
          modalState[1](true);
        }}
        className="!w-[130px] p-button-small text-main-blue !text-ui bg-grayscale-100 border border-main-blue"
      >
        CSVダウンロード
      </BaseButton>
      <Modal modalState={modalState} />
    </div>
  );
};
