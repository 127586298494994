import { Button } from '@mui/base/Button';

export const SmallModal = (props: {
  children: JSX.Element;
  activeButton: JSX.Element;
  closeHandler: () => void;
}) => {
  return (
    <div className="w-[800px]">
      <div className="px-[40px] pt-[50px] overflow-y-auto">
        {props.children}
      </div>
      <div className="flex justify-center gap-12 pt-[40px] pb-[80px]">
        <Button
          className="w-[120px]"
          onClick={() => {
            props.closeHandler();
          }}
        >
          <p className="text-grayscale-700 text-[16px]">キャンセル</p>
        </Button>
        {props.activeButton}
      </div>
    </div>
  );
};
