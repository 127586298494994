import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { Dialog, RadioGroup, Chip } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z, ZodError } from 'zod';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { zodResolver } from '@hookform/resolvers/zod';
import { TwemojiIconFromCodePoint } from 'context/mvps/components/TwemojiIconFromCodePoint';
import { ColorIcon } from 'context/mvps/components/EditMvpCategoryModal/ColorIcon';
import {
  CodePoint,
  CategoryColor,
  MvpCategory,
} from 'context/mvps/types/mvpCategory';
import { patchMvpCategory } from 'context/mvps/api/patch.mvpCategory';

import { getImageBase64, toBase64 } from 'infra/utils/base64';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { useAtom } from 'jotai';
import Avatar from '@mui/material/Avatar';
import { COLORS, STYLES } from 'tailwind.config';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { Textarea } from 'infra/components/UI/Forms/Textarea';
import { Select } from 'infra/components/UI/Forms/Select';
import { CustomImageInput } from './CustomImageInput';

export const EditMvpCategoryModal = (
  props: PropsWithChildren<{
    render: (openModal: () => void) => ReactNode;
    mvpCategory?: MvpCategory;
    onComplated: () => void;
  }>
) => {
  const [active, setActive] = useState(false);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm<MvpCategoryFormData>({
    resolver: zodResolver(mvpCategoryScheme),
  });

  useEffect(() => {
    defaultFormData(props.mvpCategory).then((data) => {
      useFormInstance.reset(data);
    });
  }, [props.mvpCategory, useFormInstance]);

  const openHandler = () => {
    setActive(true);
  };
  const closeHandler = () => {
    useFormInstance.reset();
    setActive(false);
  };

  const submit: SubmitHandler<MvpCategoryFormData> = async (data) => {
    if (!props?.mvpCategory?.id) {
      displayMessage({ text: 'エラーが発生しました', isInterruption: true });
      return;
    }

    await patchMvpCategory({
      id: props.mvpCategory.id,
      name: data.name,
      icon: data.icon,
      color: data.color,
      description: data.description,
      customIconImage: data.customIconImage,
    })
      .then(() => {
        displayMessage({
          text: 'MVPカテゴリを更新しました。',
          isInterruption: false,
        });
        setActive(false);
        props.onComplated();
      })
      .catch((error) => {
        displayNonFieldApiError(error);
      });
  };

  const handleError = () => {
    try {
      mvpCategoryScheme.parse(useFormInstance.watch());
    } catch (e) {
      const error = e as ZodError;
      displayMessage({
        text: error.issues.map((issue) => issue.message).join('\n'),
        isInterruption: true,
      });
    }
  };

  return (
    <>
      {props.render(openHandler)}
      <Dialog open={active} onClose={closeHandler} maxWidth={false}>
        <BaseModal
          activeButton={
            <BaseButton
              type="submit"
              className="w-btn-small text-btn-confirm p-button-small"
              onClick={useFormInstance.handleSubmit(submit, handleError)}
            >
              保存
            </BaseButton>
          }
          closeHandler={closeHandler}
        >
          <div className="pt-[10px] pb-[32px]">
            <h3 className="text-section-heading font-bold mb-[60px]">
              MVPカテゴリ設定
            </h3>
            <form>
              <div>
                <p className="text-base font-bold mb-[20px]">カテゴリ名</p>
                <TextField
                  useForm={useFormInstance}
                  name="name"
                  isErrorFiled={!!useFormInstance.formState.errors.name}
                  helperText="※バリューを体現している総称的なタイトルをオススメします"
                  showHelperText
                  className="w-[340px]"
                />
              </div>

              <p className="text-base font-bold mb-[20px] mt-[30px]">
                絵文字（アイコン）
              </p>
              {useFormInstance.watch('customIconImage') === null ? (
                <Chip
                  variant="outlined"
                  label={
                    <TwemojiIconFromCodePoint
                      codePoint={useFormInstance.watch('icon') as CodePoint}
                      size={56}
                    />
                  }
                  sx={{
                    width: 96,
                    height: 96,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: COLORS.GRAY_500,
                    borderRadius: '50%',
                    marginBottom: 3,
                  }}
                />
              ) : (
                // 画像をclipしたいので、Chipを使わない
                <Avatar
                  sx={{
                    height: 96,
                    width: 96,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: COLORS.GRAY_100,
                    marginBottom: 3,
                  }}
                  alt="Remy Sharp"
                  src={useFormInstance.watch('customIconImage') ?? undefined}
                />
              )}

              <RadioGroup row>
                <Controller
                  name="customIconImage"
                  control={useFormInstance.control}
                  render={({ field }) => (
                    <CustomImageInput
                      checked={field.value !== null}
                      handleUpdate={async (image) => {
                        const base64 = await toBase64(image);
                        field.onChange(base64);
                        useFormInstance.setValue('icon', null);
                      }}
                    />
                  )}
                />
                <Controller
                  name="icon"
                  control={useFormInstance.control}
                  render={({ field }) => (
                    <>
                      {iconMaster.map((codePoint) => {
                        const checked = codePoint === field.value;
                        return (
                          <Chip
                            clickable
                            key={codePoint}
                            onClick={() => {
                              field.onChange(codePoint);
                              useFormInstance.setValue('customIconImage', null);
                            }}
                            label={
                              <TwemojiIconFromCodePoint
                                codePoint={codePoint}
                                size={20}
                              />
                            }
                            variant={checked ? 'outlined' : 'filled'}
                            color={checked ? 'primary' : 'default'}
                            style={{
                              width: 48,
                              height: 48,
                              borderRadius: STYLES.BORDER_RADIUS.FULL,
                              backgroundColor: COLORS.GRAY_400,
                              marginRight: '12px',
                              borderWidth: 2,
                              display: 'flex',
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                />
              </RadioGroup>

              <div>
                <p className="text-base font-bold mb-[20px] mt-[30px]">
                  カテゴリカラー
                </p>
                <Select
                  useForm={useFormInstance}
                  name="color"
                  menuItems={colorMaster.map((color) => {
                    return {
                      label: (
                        <div className="flex items-center">
                          <ColorIcon color={color} />
                          <span className="ml-[10px]"> {color}</span>
                        </div>
                      ),
                      value: color,
                    };
                  })}
                  isErrorFiled={!!useFormInstance.formState.errors.color}
                  className="w-[180px]"
                />
              </div>

              <div>
                <p className="text-base font-bold mb-[20px] mt-[30px]">
                  カテゴリ説明
                </p>
                <Textarea useForm={useFormInstance} name="description" />
              </div>
            </form>
          </div>
        </BaseModal>
      </Dialog>
    </>
  );
};

const mvpCategoryScheme = z
  .object({
    id: z.number().nullable(),
    name: z
      .string({
        required_error: 'カテゴリ名を入力してください',
      })
      .min(1, 'カテゴリ名を入力してください'),
    icon: z.custom<CodePoint>().nullable(),
    customIconImage: z
      .string()
      .nullable()
      .refine(
        (customIconImage) => {
          if (customIconImage === null) {
            return true;
          }
          return atob(customIconImage.split(',')[1]).length < 512000;
        },
        () => {
          return {
            message:
              'カスタムアイコンの画像サイズを500kb以下で設定してください。',
          };
        }
      ),
    color: z
      .string({
        required_error: 'カテゴリカラーを選択してください',
      })
      .min(1, 'カテゴリカラーを選択してください'),
    description: z
      .string({
        required_error: 'カテゴリ説明を入力してください',
      })
      .min(1, 'カテゴリ説明を入力してください'),
  })
  .refine(
    (data) => data.icon !== null || data.customIconImage !== null,
    () => {
      return {
        message:
          'アイコン画像をアップロードするか、絵文字アイコンを選択してください。',
      };
    }
  );

type MvpCategoryFormData = z.infer<typeof mvpCategoryScheme>;

const defaultFormData = async (
  mvpCategory?: MvpCategory
): Promise<MvpCategoryFormData> => {
  if (mvpCategory === undefined) {
    // create
    return {
      id: null,
      name: '',
      icon: null,
      customIconImage: null,
      color: '',
      description: '',
    };
  }

  // edit
  return {
    id: mvpCategory.id,
    name: mvpCategory.name,
    icon: mvpCategory.icon,
    color: mvpCategory.color,
    customIconImage: mvpCategory.customIconImage
      ? ((await getImageBase64(mvpCategory.customIconImage!)) as string)
      : null,
    description: mvpCategory.description,
  };
};

const iconMaster: CodePoint[] = [
  '1f3c5',
  '1f4a1',
  '1f973',
  '1f46c',
  '1f970',
  '1f4aa',
  '1f91d',
  '1f9d7',

  // '1f49d', 削除
];

const colorMaster: CategoryColor[] = [
  '#1FC8C2',
  '#F9C824',
  '#FA8F47',
  '#FC506E',
  '#7E7CF9',
  '#2DEAA8',
  '#ED71AD',
  '#048CF2',
];
