import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useEffect } from 'react';
import { COLORS } from 'tailwind.config';

type DoughnutChartProps = {
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
  }[];
  updateChartArea: number[];
};

ChartJS.register(ArcElement);

export const DoughnutChart = ({
  datasets,
  updateChartArea,
}: DoughnutChartProps) => {
  useEffect(() => {
    datasets.forEach((dataset) => {
      if (dataset.data.length !== dataset.backgroundColor.length) {
        throw new Error(
          'データセットのデータと背景色の個数が一致していません。'
        );
      }
    });
  }, [datasets]);

  const updatedDatasets = datasets.map((dataset) => {
    const chartData =
      updateChartArea.length > 0 ? updateChartArea : dataset.data;
    const totalValue = chartData.reduce(
      (currentSum, value) => currentSum + value,
      0
    );
    const unusedPercentage = 100 - totalValue;

    return {
      ...dataset,
      data: [...chartData, unusedPercentage],
      backgroundColor: [...dataset.backgroundColor, COLORS.GRAY_400],
    };
  });

  const totalPercentage =
    updatedDatasets.reduce((currentSum, dataset) => {
      return (
        currentSum +
        dataset.data
          .slice(0, -1)
          .reduce((innerCurrentSum, value) => innerCurrentSum + value, 0)
      );
    }, 0) / updatedDatasets.length;

  const data = {
    datasets: updatedDatasets,
  };

  const options = {
    animation: true,
  };

  return (
    <div className="relative w-full h-full">
      <Doughnut data={data} options={options} />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
        <span className="text-micro text-center uppercase">total</span>
        <span className="text-label font-bold">{totalPercentage}%</span>
      </div>
    </div>
  );
};
