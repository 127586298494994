import { toBase64 } from 'infra/utils/base64';
import { ChangeEvent, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  useForm: UseFormReturn<any>;
  name: string;
  children: ReactNode;
  replaceImg: string;
};

export const FileField = (props: Props) => {
  const onChangeWrapper = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files === null || files.length === 0) {
      return;
    }

    const encodedImage = await toBase64(files[0]);
    props.useForm.setValue(props.name, encodedImage);
  };

  const fileImageValue = props.useForm.watch(props.name);

  return (
    <div>
      <div className="pb-[30px]">
        <div className="relative w-btn-small p-button-small text-ui text-main-blue border-r-main-blue border-1 rounded text-center base-hover">
          {props.children}
          <input
            type="file"
            className="absolute left-0 top-0 w-full h-full overflow-hidden opacity-0 z-10 cursor-pointer"
            accept="image/png, image/gif, image/jpeg"
            onChange={onChangeWrapper}
          />
        </div>
      </div>
      {fileImageValue &&
      !fileImageValue?.includes('brands-logs_default.jpg') ? (
        <div className="inline-flex max-w-[136px]">
          <img
            className="max-h-[300px] max-w-full shadow-main"
            alt="ロゴ画像"
            src={fileImageValue}
          />{' '}
        </div>
      ) : (
        <div className="inline-flex max-w-[136px]">
          <img
            className="max-h-[300px] max-w-full shadow-main"
            alt="ロゴ画像"
            src={props.replaceImg}
          />{' '}
        </div>
      )}

      {props.useForm.formState.errors[props.name]?.message && (
        <ErrorMessage
          message={props.useForm.formState.errors[
            props.name
          ]?.message?.toString()}
        />
      )}
    </div>
  );
};
