export type CodePoint =
  | '1f3c5'
  | '1f4a1'
  | '1f973'
  | '1f46c'
  | '1f970'
  | '1f4aa'
  | '1f91d'
  | '1f9d7';

export type CategoryColor =
  | '#1FC8C2'
  | '#F9C824'
  | '#FA8F47'
  | '#FC506E'
  | '#7E7CF9'
  | '#2DEAA8'
  | '#ED71AD'
  | '#048CF2';

export type MvpCategory = {
  id: number;
  name: string;
  icon: CodePoint | null;
  customIconImage: string | null;
  color: CategoryColor;
  description: string;
};

export const colorToRgba = (categoryColor: string, alpha: number = 1) =>
  `rgba(${parseInt(categoryColor[1] + categoryColor[2], 16)}, ${parseInt(
    categoryColor[3] + categoryColor[4],
    16
  )}, ${parseInt(categoryColor[5] + categoryColor[6], 16)}, ${alpha})`;
