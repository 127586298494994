import { components } from 'infra/api/schema';

export const EMPLOYMENT_STATUS_LIST = [
  { id: 'boardMember', name: '役員' },
  { id: 'fullTimeEmployee', name: '正社員' },
  { id: 'contractEmployee', name: '契約社員' },
  { id: 'temporaryEmployee', name: '派遣社員' },
  { id: 'partTimeEmployee', name: 'アルバイト・パート' },
  { id: 'subcontracting', name: '業務委託' },
  { id: 'other', name: 'その他' },
];

// eslint-disable-next-line consistent-return
export const getEmploymentStatusLabel = (
  employmentStatus: components['schemas']['EmploymentStatusEnum']
) => {
  try {
    return {
      boardMember: '役員',
      fullTimeEmployee: '正社員',
      contractEmployee: '契約社員',
      temporaryEmployee: '派遣社員',
      partTimeEmployee: 'アルバイト・パート',
      subcontracting: '業務委託',
      other: 'その他',
    }[employmentStatus];
  } catch (error) {
    throw new Error('`employmentStatus`が不正な値です');
  }
};
