import { createSvgIcon } from '@mui/material/utils';

export const TimeIcon = createSvgIcon(
  <svg
    id="time"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
  >
    <g
      id="_-Product-Icons"
      data-name="🔍-Product-Icons"
      transform="translate(-2 -2)"
    >
      <g id="ic_fluent_clock_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d={
            'M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2Zm0,1.667A8.333,8.333,0,1,0,20.333,12,8.342,8.342,0,0,0,12,3.667ZM11.25,6a.75.75,0,0,1,.743.648l.007.1V12h' +
            '3.25a.75.75,0,0,1,.1,1.493l-.1.007h-4a.75.75,0,0,1-.743-.648l-.007-.1v-6A.75.75,0,0,1,11.25,6Z'
          }
          fill="#b9bcbf"
        />
      </g>
    </g>
  </svg>,
  'Time'
);
