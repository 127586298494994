import { WarningIcon } from 'infra/components/UI/Feedbacks/WarningIcon';

export const WarningMessageBox = (props: {
  message: string;
  detailsMessage: string;
}) => {
  return (
    <div className="p-warning rounded-large bg-soft-yellow">
      <div className="flex items-center pb-2">
        <div className="w-[20px] h-[18px]">
          <WarningIcon />
        </div>
        <h3 className="text-base font-bold pl-3">{props.message}</h3>
      </div>
      <p className="mt-1 text-base whitespace-pre-wrap">
        {props.detailsMessage}
      </p>
    </div>
  );
};
