import { Rating } from '@mui/material';
import { BasicThinkingTitle } from 'infra/components/UI/Icons/BasicThinkingTitle';
import { PropsWithChildren } from 'react';
import { COLORS } from 'tailwind.config';

export const EvaluationCard = (
  props: PropsWithChildren<{
    color: string;
    basicThinkingLabel: string;
    finalMyselfEvaluation?: number;
    finalBossEvaluation?: number;
  }>
) => (
  <div className="pb-[30px]">
    <BasicThinkingTitle
      color={props.color}
      basicThinkingLabel={props.basicThinkingLabel}
    />
    <div
      className="rounded mt-[20px] p-[20px]"
      style={{ backgroundColor: COLORS.SOFT_BLUE }}
    >
      <p className="pb-[8px] text-base font-bold border-b-1 border-grayscale-500">
        最終評価
      </p>
      <div className="flex items-center py-[20px] gap-[30px]">
        <div className="rounded border-1 border-main-blue p-[8px] bg-grayscale-100">
          <p className="text-label font-bold text-main-blue">最終自己評価</p>
        </div>
        <Rating
          size="large"
          name="disabled"
          max={4}
          value={props.finalMyselfEvaluation}
          readOnly
        />
      </div>
      <div className="flex items-center pb-[20px] gap-[30px]">
        <div className="rounded p-[10px] bg-main-blue">
          <p className="text-label font-bold text-grayscale-100">
            最終確定評価
          </p>
        </div>
        <Rating
          size="large"
          name="disabled"
          max={4}
          value={props.finalBossEvaluation}
          readOnly
        />
        <p className="text-page-heading font-bold">
          {props.finalBossEvaluation}
        </p>
      </div>
    </div>
  </div>
);
