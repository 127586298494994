export const GraphIcon = () => (
  <svg
    id="グラフアイコン"
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="7"
    viewBox="0 0 11 7"
  >
    <g id="ic_fluent_arrow_trending_24_regular">
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M8.461,5.5h4.155l.055.007h0l.054.015h0l.033.014h0a.4.4,0,0,1,.12.081l.022.024h0l.024.03h0l.03.049h0l.021.049h0l.011.035h0l.007.034h0L13,5.889h0V9.945a.413.413,0,0,1-.821.055l0-.055V6.878L7.929,11.036a.419.419,0,0,1-.537.039l-.046-.039-1.634-1.6L2.7,12.382a.419.419,0,0,1-.583,0,.4.4,0,0,1-.04-.526l.04-.045,3.3-3.231a.419.419,0,0,1,.537-.039L6,8.579l1.634,1.6L11.59,6.308H8.461a.41.41,0,0,1-.409-.349l0-.055A.407.407,0,0,1,8.4,5.5Z"
        transform="translate(-1.999 -5.5)"
        fill="#2deaa8"
      />
    </g>
  </svg>
);
