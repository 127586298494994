export const FlagIcon = () => (
  <svg
    id="flag"
    xmlns="http://www.w3.org/2000/svg"
    width="28.8"
    height="32"
    viewBox="0 0 28.8 32"
  >
    <g id="_-Product-Icons" data-name="🔍-Product-Icons">
      <g id="ic_fluent_flag_24_regular" transform="translate(0 0)">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M3,4.261A1.233,1.233,0,0,1,4.2,3H30.6a1.269,1.269,0,0,1,.96,2.021L24.9,14.367l6.661,9.348a1.269,1.269,0,0,1-.96,2.021H5.4v8a1.244,1.244,0,0,1-1.037,1.252L4.2,35a1.222,1.222,0,0,1-1.189-1.092L3,33.735ZM28.2,5.524H5.4V23.21H28.2l-5.761-8.085a1.317,1.317,0,0,1,0-1.516Z"
          transform="translate(-3 -2.998)"
          fill="#048cf2"
        />
      </g>
    </g>
  </svg>
);
