import { useAtom } from 'jotai';
import {
  scoreListAtom,
  FinalEvaluationByOutsideManager,
  isFullInputedScoreListAtom,
  FULL_LENGTH,
} from 'context/objective/store/evaluationByOutsideManager';
import { getLoginInfo } from 'context/auth/api/login';
import { ObjectiveRecord } from 'context/objective/components/evaluate/evaluateMiddle';
import { FinalScoreItem } from 'context/objective/components/evaluate/EvaluationByOutsideManager/_components/FinalScoreItem';
import { MiddleScoreItem } from 'context/objective/components/evaluate/EvaluationByOutsideManager/_components/MiddleScoreItem';

export const InputScoreForm = ({
  user,
  objectiveRecord,
  evaluationType,
}: {
  user: ObjectiveRecord['onsiteUser'];
  objectiveRecord: ObjectiveRecord;
  evaluationType: 'middle' | 'final';
}) => {
  const [scoreList, setScoreList] = useAtom(scoreListAtom);
  const [isFullInputedScoreList] = useAtom(isFullInputedScoreListAtom);

  const [{ data: me }] = getLoginInfo();

  const [currentRatingPage, setCurrentRatingPage] = useAtom(
    FinalEvaluationByOutsideManager.currentRatingPage
  );
  const stepupCurrentItemIndex = () => {
    if (currentRatingPage < objectiveRecord.basicThinkings.length - 1) {
      setCurrentRatingPage(currentRatingPage + 1);
    }
  };

  return (
    <div>
      {evaluationType === 'middle' &&
        objectiveRecord.basicThinkings.map((basicThinking, i) => {
          return (
            <div className="py-[20px]">
              <MiddleScoreItem
                user={user}
                evaluationType={evaluationType}
                evaluatorFullName={`${me?.lastName} ${me?.firstName}`}
                currentScore={scoreList[i]}
                basicThinking={basicThinking}
                index={i}
                onSaveScore={(score, order) => {
                  const newScoreList = [...scoreList];
                  newScoreList[order] = score;
                  setScoreList(newScoreList);
                }}
              />
            </div>
          );
        })}

      {evaluationType === 'final' && !isFullInputedScoreList && (
        <div className="py-[20px]">
          <FinalScoreItem
            user={user}
            evaluationType={evaluationType}
            evaluatorFullName={`${me?.lastName} ${me?.firstName}`}
            currentScore={scoreList[currentRatingPage]}
            index={currentRatingPage}
            basicThinking={objectiveRecord.basicThinkings[currentRatingPage]}
            onSaveScore={(score, order) => {
              const newScoreList = [...scoreList];
              newScoreList[order] = score;
              setScoreList(newScoreList);
              stepupCurrentItemIndex();
            }}
          />
        </div>
      )}

      {evaluationType === 'final' && isFullInputedScoreList && (
        <div className="py-[20px]">
          {[...Array(FULL_LENGTH)].map((_, i) => {
            return (
              <div className="mb-[40px]">
                <FinalScoreItem
                  user={user}
                  evaluationType={evaluationType}
                  evaluatorFullName={`${me?.lastName} ${me?.firstName}`}
                  currentScore={scoreList[i]}
                  index={i}
                  basicThinking={objectiveRecord.basicThinkings[i]}
                  onSaveScore={() => {}}
                  isListed
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
