import { PropsWithChildren, memo, useEffect, useState } from 'react';
import { CheckIcon } from 'context/objective/components/ObjectiveRecordList/CheckIcon';

import NoteIcon from 'context/objective/assets/note_icon01.svg';
import { Tooltip } from 'context/objective/components/ObjectiveRecordList/_components/Tooltip';
import { is } from 'date-fns/locale';
import { ScoreRowVertical } from './ScoreRow';
import { PromitionSwitch } from './PromotionSwitch';
import { ObjectiveRecord, PromotionLog } from '.';
import { OnsiteUserName } from './OnsiteUserName';
import { OnsiteUserAffiliation } from './OnsiteUserAffiliation';
import { EditingDataType } from './PromotionDetailModal';

export const OnsiteUserCard = (
  props: PropsWithChildren<{
    objectiveRecord: ObjectiveRecord;
    promotionLog?: PromotionLog;
    onSave: (editedData: EditingDataType, id: string) => void;
  }>
) => {
  const { objectiveRecord } = props;
  const { onsiteUser } = objectiveRecord;

  const [editingPromotionLog, setEditingPromotionLog] =
    useState<EditingDataType>({
      salary: props.promotionLog?.salary || null,
      isPromoted: props.promotionLog?.isPromoted || false,
      isTemporaryFixed: props.promotionLog?.isTemporaryFixed || false,
    });

  useEffect(() => {
    setEditingPromotionLog({
      salary: props.promotionLog?.salary || null,
      isPromoted: props.promotionLog?.isPromoted || false,
      isTemporaryFixed: props.promotionLog?.isTemporaryFixed || false,
    });
  }, [props.promotionLog]);

  let isReadonly = false;
  if (objectiveRecord?.promotionLog === undefined) {
    isReadonly = true;
  }

  if (objectiveRecord?.promotionLog) {
    isReadonly = objectiveRecord?.promotionLog.isFixed;
  }

  const isWithdrawal =
    objectiveRecord.onsiteUser.appUsageState === 'withdrawal';

  return (
    <div className="relative">
      <div className="p-[20px]">
        <OnsiteUserName
          fullName={`${onsiteUser.lastName} ${onsiteUser.firstName}`}
          image={onsiteUser.avatar || ''}
          isWithdrawal={onsiteUser.appUsageState === 'withdrawal'}
        />

        <OnsiteUserAffiliation
          shopName={onsiteUser.shopName}
          occupationName={onsiteUser.occupationName}
          gradeName={onsiteUser.gradeName}
          positionName={onsiteUser.gradePositionName}
          isTextCenter={false}
        />

        <div className="pt-[14px]">
          {objectiveRecord.score ? (
            <ScoreRowVertical
              score={objectiveRecord.score}
              previewScore={objectiveRecord.previousObjectiveRecord?.score}
              isShowPrevScore
            />
          ) : (
            <>
              <div className="inline-flex items-center py-[4px] px-[10px] gap-[6px] bg-soft-yellow rounded-full">
                <img src={NoteIcon} alt="ビックリマークのアイコン" />
                <p className="text-label text-grayscale-700 font-bold">
                  評価スコア反映待ちです
                </p>
              </div>
              <ul className="flex items-center gap-[24px] mt-[20px]">
                <li className="flex flex-col gap-[10px] items-start leading-none">
                  <p
                    className="
                        w-auto p-[5px] border-1 border-main-blue text-main-blue text-micro font-bold rounded text-center
                      "
                  >
                    前期評価スコア
                  </p>
                  <div className="flex items-center gap-[6px]">
                    <p className="text-score font-bold">-</p>
                    <p className="text-label">スコア未定義</p>
                  </div>
                </li>
                <li className="flex flex-col gap-[10px] items-start leading-none">
                  <p
                    className="
                        w-auto p-[5px] border-1 border-main-blue text-main-blue text-micro font-bold rounded text-center
                      "
                  >
                    後期評価スコア
                  </p>
                  <div className="flex items-center gap-[6px]">
                    <p className="text-score font-bold">-</p>
                    <p className="text-label">スコア未定義</p>
                  </div>
                </li>
              </ul>
            </>
          )}
        </div>

        <div className="flex justify-between items-center pt-[14px]">
          {objectiveRecord.promotionLog !== null && (
            <div className="min-w-[240px] flex flex-col items-start">
              <PromitionSwitch
                readonly={isWithdrawal || isReadonly}
                isPromoted={editingPromotionLog.isPromoted}
                setSalary={(value) => {
                  setEditingPromotionLog({
                    ...editingPromotionLog,
                    salary: value,
                  });
                }}
                changedSalary={(value) => {
                  props.onSave(
                    {
                      ...editingPromotionLog,
                      salary: value,
                    },
                    props.promotionLog?.id!
                  );
                }}
                salary={editingPromotionLog.salary}
                setIsPromoted={(value) => {
                  // 昇給昇格ボタンを押した時に、給与が0円の場合は、次の給与をセットする
                  if (
                    value &&
                    (editingPromotionLog.salary === 0 ||
                      editingPromotionLog.salary === null)
                  ) {
                    const updatedPromotionLog = {
                      ...editingPromotionLog,
                      salary:
                        objectiveRecord.nextGradeSalaryIncludeingAllowances,
                      isPromoted: value,
                    };
                    setEditingPromotionLog(updatedPromotionLog);
                    props.onSave(updatedPromotionLog, props.promotionLog?.id!);
                    // 昇給昇格ボタン解除の際、給与情報はそのままで、昇給昇格フラグだけを更新する
                  } else {
                    const updatedPromotionLog = {
                      ...editingPromotionLog,
                      isPromoted: value,
                    };
                    setEditingPromotionLog(updatedPromotionLog);
                    props.onSave(updatedPromotionLog, props.promotionLog?.id!);
                  }
                }}
                nextGradeWageType={objectiveRecord.nextGradeWageType}
              />
              {props.promotionLog!.isTemporaryFixed && (
                <div className="absolute right-[20px] bottom-[20px] w-[24px] h-[24px]">
                  <CheckIcon />
                </div>
              )}
              {props.promotionLog!.isPromoted && (
                <div className="ml-auto mt-[10px]">
                  <div className="flex items-center gap-[4px]">
                    {objectiveRecord.onsiteUser.allowances.length > 0 && (
                      <>
                        <Tooltip
                          content={objectiveRecord.onsiteUser.allowances.map(
                            (allowance) => {
                              return {
                                ...allowance,
                                amount: allowance.amount.toLocaleString(),
                              };
                            }
                          )}
                        />
                        <span className="text-label text-main-blue font-bold">
                          手当{' '}
                          {objectiveRecord.onsiteUser.allowances
                            .reduce(
                              (total, allowance) => total + allowance.amount,
                              0
                            )
                            .toLocaleString()}
                          円を含む
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
