import { FC } from 'react';
import { COLORS } from 'tailwind.config';

const colorMapping = {
  'text-main-blue': COLORS.MAIN_BLUE,
  white: COLORS.GRAY_100,
};

type Props = {
  color?: string;
};

export const AddIcon: FC<Props> = ({ color = '#ffffff' }) => {
  const fillColor = colorMapping[color as keyof typeof colorMapping] || color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        d="M8.922,3,9,3a.667.667,0,0,1,.662.589l0,.078V8.333h4.667A.667.667,0,0,1,15,8.922L15,9a.667.667,0,0,1-.589.662l-.078,0H9.667v4.667A.667.667,0,0,1,9.078,15L9,15a.667.667,0,0,1-.662-.589l0-.078V9.667H3.667A.667.667,0,0,1,3,9.078L3,9a.667.667,0,0,1,.589-.662l.078,0H8.333V3.667A.667.667,0,0,1,8.922,3L9,3Z"
        transform="translate(-3 -3)"
        fill={fillColor}
      />
    </svg>
  );
};
