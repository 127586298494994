import {
  Table,
  TableBody,
  TableContainerProps,
  TableContainerTypeMap,
  TableHead,
  TableRow,
} from '@mui/material';
import { BaseTableBorderCell } from 'infra/components/UI/Table/BaseTableBorderCell';
import { EvaluationMeasure } from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { MeasureTableRow } from 'context/corporation/evaluation_measures/components/MeasureTableRow';
import { COLORS } from 'tailwind.config';

export const FinalMeasureTable = (
  props: TableContainerProps<
    TableContainerTypeMap['defaultComponent'],
    {
      evaluationMeasure: EvaluationMeasure;
      onChanged: () => void;
    }
  >
) => {
  return (
    <div className="overflow-x-auto base-scrollbar">
      <Table style={{ tableLayout: 'fixed', minWidth: '1040px' }}>
        <TableHead>
          <TableRow>
            <BaseTableBorderCell
              backGroundColor={COLORS.GRAY_100}
              className="w-[120px]"
            />
            {HeaderTexts.map((text, index) => (
              <BaseTableBorderCell
                key={text}
                align="center"
                backGroundColor={index > 2 ? COLORS.SOFT_BLUE : COLORS.GRAY_100}
              >
                <p className="text-label leading-tight mb-[2px]">評価尺度</p>
                <p className="text-meta font-bold leading-tight">{text}</p>
              </BaseTableBorderCell>
            ))}
            <BaseTableBorderCell
              backGroundColor={COLORS.GRAY_100}
              className="w-[80px]"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <MeasureTableRow
            evaluationMeasureId={props.evaluationMeasure.id}
            slug="finalEvaluation"
            type="actual"
            onChanged={props.onChanged}
            measure={props.evaluationMeasure.finalEvaluation.measure}
            bgClass={COLORS.GRAY_100}
          />
        </TableBody>
      </Table>
    </div>
  );
};

const HeaderTexts: string[] = ['D', 'C', 'B', 'A', 'S', 'SS'];
