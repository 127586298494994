/**
 * マンダラ関連のルーティング定義
 */
import { ImportMandalatMasterCsv } from 'context/mandalat/components/Index';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const ImportMandalatMasterCsvRoute: RouteType = {
  path: '/mandalat/import-mandalat-master-csv',
  element: <ImportMandalatMasterCsv />,
  do_it: () => (
    <Route
      path={ImportMandalatMasterCsvRoute.path}
      element={ImportMandalatMasterCsvRoute.element}
    />
  ),
};

export const routes = () => <>{ImportMandalatMasterCsvRoute.do_it()}</>;
