import { Link } from 'react-router-dom';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';

export const ErrorMessageView = (props: { errorMessage?: string }) => (
  <div className="flex items-center justify-center flex-col p-[40px] pt-[50px] gap-[40px]">
    <p className="text-section-heading">
      {props.errorMessage || 'このページは存在しません'}
    </p>

    <Link to="/">
      <BaseButton className="w-btn-medium p-button-small text-base">
        トップページへ戻る
      </BaseButton>
    </Link>
  </div>
);
