import { apiClient } from 'infra/api/config';

export const URL = '/tenant/wealth/profiletest/activation/';

export const postProfiletestActivation = (onsiteUserId: string) => {
  return apiClient.post(
    URL,
    {
      onsiteUserId,
    },
    {}
  );
};
