import { GradeOfResponsePaginatedCorporation } from 'context/corporation/grade/api/types';

export const GradeList = (props: {
  currentGradeId: number;
  gradeList: GradeOfResponsePaginatedCorporation[];
  handleChangeGradeId: (gradeId: number) => void;
}) => {
  const { currentGradeId, gradeList, handleChangeGradeId } = props;

  return (
    <div>
      {gradeList.map((grade) => {
        return (
          <div
            className="grid grid-cols-[1fr_16px] items-center gap-[10px] mb-[20px]"
            key={grade.id}
          >
            <p
              className={`text-base whitespace-nowrap cursor-pointer ${
                grade.id === currentGradeId
                  ? 'text-grayscale-800'
                  : 'text-grayscale-600'
              }`}
              style={{
                cursor: grade.id === currentGradeId ? 'default' : 'pointer',
              }}
              onClick={() => {
                handleChangeGradeId(grade.id);
              }}
            >
              {grade.name}
            </p>
          </div>
        );
      })}
    </div>
  );
};
