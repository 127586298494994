/**
 * 店舗関連のルーティング定義
 */
import { ShopCreate } from 'context/corporation/shop/components/ShopCreate';
import { ShopEdit } from 'context/corporation/shop/components/ShopEdit';
import { ShopList } from 'context/corporation/shop/components/ShopList';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const ShopListRoute: RouteType = {
  path: '/shops',
  element: <ShopList />,
  do_it: () => (
    <Route path={ShopListRoute.path} element={ShopListRoute.element} />
  ),
};

export const ShopCreateRoute: RouteType = {
  path: '/shops/create',
  element: <ShopCreate />,
  do_it: () => (
    <Route path={ShopCreateRoute.path} element={ShopCreateRoute.element} />
  ),
};

export const ShopEditRoute: RouteType = {
  path: '/shops/:shopId',
  element: <ShopEdit />,
  do_it: () => (
    <Route path={ShopEditRoute.path} element={ShopEditRoute.element} />
  ),
};

export const routes = () => (
  <>
    {ShopListRoute.do_it()}
    {ShopCreateRoute.do_it()}
    {ShopEditRoute.do_it()}
  </>
);
