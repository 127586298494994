import { HTMLAttributes } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  useForm: UseFormReturn<any>;
  name: string;
} & HTMLAttributes<HTMLDivElement>;

export const Textarea = ({ useForm, name }: Props) => {
  return (
    <div>
      <textarea
        className="text-grayscale-700
                w-full h-[120px] rounded-[5px]
                border-1 border-grayscale-500
                py-[20px] px-[10px] resize-none"
        {...useForm.register(name)}
      />
      {!!useForm.formState.errors[name]?.message && (
        <ErrorMessage
          message={useForm.formState.errors[name]?.message?.toString()}
        />
      )}
    </div>
  );
};
