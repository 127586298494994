import { FC } from 'react';
import { TableRow, Avatar } from '@mui/material';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import {
  DeleteOrSuspendModal,
  state as DeleteOrSuspendModalState,
} from 'context/users/onsite_users/_infra/components/Modal/DeleteOrSuspendModal';
import { formatAsYearMonthDay } from 'infra/utils/date-fns';
import { COLORS } from 'tailwind.config';
import { WealthLabel } from 'context/wealth/WealthLabel/WealthLabel';
import * as state from './state';

export type RowProps = {
  onsiteUser: state.Types.OnsiteUserListResponseExtended;
  index: number;
  isStripedBackgroundColor: boolean;
};

export const UnSubscribedStaffRow: FC<RowProps> = ({
  onsiteUser,
  index,
  isStripedBackgroundColor,
}) => {
  const deleteOrSuspendModalState = DeleteOrSuspendModalState.local();
  const navigateToStaffDetail = state.evetns.navigateToStaffDetail(onsiteUser);
  const navigateToStaffEdit = state.evetns.navigateToStaffEdit(onsiteUser);

  const stripedBackgroundColor =
    index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;

  return (
    <TableRow
      style={{
        backgroundColor: isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[18%] base-hover"
      >
        <div className="flex items-center gap-[6px] p-[16px]">
          <Avatar
            sx={{ width: 32, height: 32 }}
            alt="Remy Sharp"
            src={onsiteUser.avatar ?? ''}
          />
          <p className="text-ui text-grayscale-800 font-normal text-left">
            {onsiteUser.lastName} {onsiteUser.firstName}
          </p>
        </div>
      </th>

      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[15%] p-[16px] base-hover"
      >
        <p className="text-ui text-grayscale-800 font-normal text-left">
          <WealthLabel label={onsiteUser.wealthProfileResult} />
        </p>
      </th>

      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[9%] p-[16px] base-hover"
      >
        <p className="text-ui text-grayscale-800 font-normal text-left">
          {onsiteUser.occupation.name}
        </p>
      </th>

      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[6%] p-[16px] base-hover"
      >
        <p className="text-ui text-grayscale-800 font-normal text-left">
          {onsiteUser.grade.position.name}
        </p>
      </th>
      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[12%] p-[16px] base-hover"
      >
        <p
          className="
          text-ui text-grayscale-800 font-normal text-left"
        >
          {onsiteUser.grade.name}
        </p>
      </th>
      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[12%] p-[16px] base-hover"
      >
        <p className="text-ui text-grayscale-800 font-normal text-left">
          {onsiteUser.shop.name}
        </p>
      </th>
      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[18%] p-[16px] base-hover"
      >
        <div className="flex gap-[6px] items-center">
          <Avatar
            sx={{ width: 32, height: 32 }}
            alt="Remy Sharp"
            src={onsiteUser.evaluator?.avatar || ''}
          />
          <p className="text-ui text-grayscale-800 font-normal text-left">
            {onsiteUser.evaluator?.lastName} {onsiteUser.evaluator?.firstName}
          </p>
        </div>
      </th>
      <th
        onClick={() => navigateToStaffDetail()}
        className="w-[11%] p-[16px] base-hover"
      >
        <p className="text-ui text-grayscale-800 font-normal text-left">
          {onsiteUser.unsubscribeAt
            ? formatAsYearMonthDay(onsiteUser.unsubscribeAt)
            : ''}
        </p>
      </th>
      <th className="w-[4%] p-[16px]" aria-label="メニューボタンの列">
        <MoreMenu
          render={[
            {
              label: <span>編集</span>,
              onClick: () => {
                navigateToStaffEdit();
              },
            },
          ]}
        />
      </th>

      <DeleteOrSuspendModal
        _state={deleteOrSuspendModalState}
        onsiteUser={onsiteUser}
      />
    </TableRow>
  );
};
