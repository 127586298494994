import { createSvgIcon } from '@mui/material/utils';

export const ThreeDotIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 16 4"
  >
    <g
      id="グループ_1122"
      data-name="グループ 1122"
      transform="translate(-119 -578)"
    >
      <g
        id="グループ_1123"
        data-name="グループ 1123"
        transform="translate(-2243 1442)"
      >
        <circle
          id="楕円形_148"
          data-name="楕円形 148"
          cx="2"
          cy="2"
          r="2"
          transform="translate(2374 -864)"
          fill="#e3e3e3"
        />
      </g>
      <g
        id="グループ_1121"
        data-name="グループ 1121"
        transform="translate(-2249 1442)"
      >
        <circle
          id="楕円形_148-2"
          data-name="楕円形 148"
          cx="2"
          cy="2"
          r="2"
          transform="translate(2374 -864)"
          fill="#e3e3e3"
        />
      </g>
      <g
        id="グループ_1049"
        data-name="グループ 1049"
        transform="translate(-2255 1442)"
      >
        <circle
          id="楕円形_148-3"
          data-name="楕円形 148"
          cx="2"
          cy="2"
          r="2"
          transform="translate(2374 -864)"
          fill="#e3e3e3"
        />
      </g>
    </g>
  </svg>,
  'ThreeDotIcon'
);
