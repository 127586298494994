export const CalendarMonthIcon = () => (
  <svg
    id="calendar"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="_-Product-Icons"
      data-name="🔍-Product-Icons"
      transform="translate(-3 -3)"
    >
      <g id="ic_fluent_calendar_24_regular" transform="translate(3 3)">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M19.389,3A3.611,3.611,0,0,1,23,6.611V19.389A3.611,3.611,0,0,1,19.389,23H6.611A3.611,3.611,0,0,1,3,19.389V6.611A3.611,3.611,0,0,1,6.611,3Zm1.944,6.111H4.667V19.389a1.944,1.944,0,0,0,1.944,1.944H19.389a1.944,1.944,0,0,0,1.944-1.944ZM8.278,15.778a1.389,1.389,0,1,1-1.389,1.389A1.389,1.389,0,0,1,8.278,15.778Zm4.722,0a1.389,1.389,0,1,1-1.389,1.389A1.389,1.389,0,0,1,13,15.778ZM8.278,11.333a1.389,1.389,0,1,1-1.389,1.389A1.389,1.389,0,0,1,8.278,11.333Zm4.722,0a1.389,1.389,0,1,1-1.389,1.389A1.389,1.389,0,0,1,13,11.333Zm4.722,0a1.389,1.389,0,1,1-1.389,1.389A1.389,1.389,0,0,1,17.722,11.333Zm1.667-6.667H6.611A1.944,1.944,0,0,0,4.667,6.611v.833H21.333V6.611A1.944,1.944,0,0,0,19.389,4.667Z"
          transform="translate(-3 -3)"
          fill="#b9bcbf"
        />
      </g>
    </g>
  </svg>
);
