import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/user/outside_managers/';
export type Request = SnakeObjToCamelObj<
  NonNullable<
    paths['/tenant/my/user/outside_managers/{id}/']['patch']['requestBody']
  >['content']['application/json'] & {
    dateOfBirth?: string;
  }
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/outside_managers/{id}/']['patch']['responses'][200]['content']['application/json']
>;

export const patchOutsideManager = (request: Request) => {
  return apiClient.patch(`${URL}${request.id}/`, {
    ...request,
  });
};
