import { BoxCheckIcon } from 'context/objective/components/ObjectiveRecordList/BoxCheckIcon';
import { CheckBoxOutlineIcon } from 'context/objective/components/ObjectiveRecordList/CheckBoxOutlineIcon';
import { Button } from '@mui/base/Button';
import { PropsWithChildren } from 'react';
import InputBase from '@mui/material/InputBase';

import { NumericFormat } from 'react-number-format';
import { COLORS, STYLES } from 'tailwind.config';

export const PromitionSwitch = (
  props: PropsWithChildren<{
    readonly: boolean;
    isPromoted: boolean;
    setIsPromoted: (value: boolean) => void;
    salary: number | null;
    setSalary: (value: number) => void;
    changedSalary?: (value: number) => void;
    nextGradeWageType: string;
  }>
) => {
  return (
    <button
      className="relative"
      type="button"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <NumericFormat
        value={props.salary}
        onChange={(e) => {
          if (props.readonly) return;
          const salary = Number(e.target.value.replace(/,/g, ''));
          props.setSalary(salary);
        }}
        onBlur={(e) => {
          const salary = Number(e.target.value.replace(/,/g, ''));
          props?.changedSalary?.(salary);
        }}
        style={{
          visibility: props.isPromoted ? 'visible' : 'hidden',
          position: 'absolute',
          left: '80px',
          // 昇給昇格ボタンと同じ高さに
          height: '32px',
          width: '160px',
          backgroundColor: COLORS.GRAY_400,
          paddingRight: '16px',
          paddingLeft: '66px',
          borderRadius: STYLES.BORDER_RADIUS.FULL,
          color: COLORS.GRAY_800,
        }}
        disabled={props.readonly}
        inputProps={{
          style: {
            textAlign: 'right',
            WebkitTextFillColor: props.readonly ? COLORS.GRAY_800 : 'inherit',
          },
        }}
        thousandSeparator=","
        decimalScale={0}
        customInput={InputBase}
      />
      <p
        className={`absolute left-[130px] top-1/2 -translate-y-1/2 text-meta font-bold
        whitespace-nowrap ${props.isPromoted ? 'visible' : 'invisible'}`}
      >
        {props.nextGradeWageType === 'monthly' ? '月給' : '時給'}
      </p>
      <Button
        style={{
          backgroundColor: props.isPromoted
            ? COLORS.MAIN_ORANGE
            : COLORS.GRAY_600,
          pointerEvents: props.readonly ? 'none' : 'auto',
        }}
        className="w-btn-small text-grayscale-100 rounded-full px-[10px] py-[6px] relative"
        onClick={() => {
          if (props.readonly) return;
          props.setIsPromoted(!props.isPromoted);
        }}
      >
        <div className="flex items-center">
          {props.isPromoted ? (
            <div className="mr-[5px]">
              <BoxCheckIcon />
            </div>
          ) : (
            <div className="mr-[5px]">
              <CheckBoxOutlineIcon />
            </div>
          )}
          <p className="text-meta text-grayscale-100 pl-[4px] pt-[2px]">
            昇給・昇格
          </p>
        </div>
      </Button>
    </button>
  );
};
