import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { components } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { ResponsePagination } from 'infra/api/type';

export type ResponseObjectiveRecord = SnakeObjToCamelObj<
  components['schemas']['OnsiteUserFinalEvaluationCompletedTenant']
> & {
  onsiteUser: {
    wealthResultProfile?: string;
  };
};

export const fetchOnsiteUserFinalEvaluationCompleted = (
  evaluationPeriodId: number,
  userType: 'staff' | 'manager',
  limit = 9999,
  offset = 0
) => {
  const response = apiClient.get<ResponsePagination<ResponseObjectiveRecord>>(
    MY_TENANT_URL('promotion/onsite_user_final_evaluation_completed/'),
    {
      params: {
        limit,
        offset,
        evaluation_period_id: evaluationPeriodId,
        is_manager: userType === 'manager',
      },
    }
  );
  return response;
};
