export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.947"
      height="20"
      viewBox="0 0 18.947 20"
    >
      <g id="home" opacity="0.6">
        <g
          id="_-System-Icons"
          data-name="🔍-System-Icons"
          transform="translate(-3 -2.002)"
        >
          <g id="ic_fluent_home_24_regular" transform="translate(3 2.002)">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M14,2.546l7.105,5.84a2.285,2.285,0,0,1,.842,1.765V20.207A1.819,1.819,0,0,1,20.105,22H16.421a1.819,1.819,0,0,1-1.842-1.8V14.564a.26.26,0,0,0-.263-.256H10.632a.26.26,0,0,0-.263.256v5.643A1.819,1.819,0,0,1,8.526,22H4.842A1.819,1.819,0,0,1,3,20.207V10.15a2.285,2.285,0,0,1,.842-1.765l7.105-5.84A2.416,2.416,0,0,1,14,2.546ZM11.965,3.722,4.859,9.562a.762.762,0,0,0-.281.588V20.207a.26.26,0,0,0,.263.256H8.526a.26.26,0,0,0,.263-.256V14.564a1.819,1.819,0,0,1,1.842-1.8h3.684a1.819,1.819,0,0,1,1.842,1.8v5.643a.26.26,0,0,0,.263.256h3.684a.26.26,0,0,0,.263-.256V10.15a.762.762,0,0,0-.281-.588l-7.105-5.84A.805.805,0,0,0,11.965,3.722Z"
              transform="translate(-3 -2.002)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
