import useAxios from 'axios-hooks';
import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePagination } from 'infra/api/type';
import { ResponseShop } from './typeResponse';

export const useFetchShops = (useCache = false, limit = 9999, offset = 0) => {
  const [response, refetch] = useAxios<ResponsePagination<ResponseShop>>(
    {
      url: MY_TENANT_URL('shops/'),
      params: { limit, offset },
    },
    { useCache }
  );

  useNonFieldApiErrorCallback(response.error);

  return [response, refetch] as const;
};

export const fetchShops = (
  limit: number,
  offset: number,
  searchParams: URLSearchParams
) => {
  const params = Object.fromEntries(searchParams.entries());
  const response = apiClient.get<ResponsePagination<ResponseShop>>(
    MY_TENANT_URL('shops/'),
    {
      params: { limit, offset, ...params },
    }
  );
  return response;
};
