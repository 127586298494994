import { z } from 'zod';

// NaNも弾き、数値でない場合のエラーメッセージをカスタマイズ
export const numberSchema = z.preprocess(
  (val) => {
    if (val === '') return null; // 空文字の場合はnullに変換
    if (val === null) return null;
    if (typeof val === 'string') return parseFloat(val); // 文字列の場合は数値に変換
    return val;
  },
  z
    .number({
      invalid_type_error: '半角数字で入力してください',
    })
    .nullable()
    .refine(
      (val) => {
        return val === null || !Number.isNaN(val);
      },
      {
        message: '半角数字で入力してください',
      }
    )
);

export const numberSchemaWithOutNullable = z.preprocess(
  (val) => {
    if (val === '') return null; // 空文字の場合はnullに変換
    if (val === null) return null;
    if (typeof val === 'string') return parseFloat(val); // 文字列の場合は数値に変換
    return val;
  },
  z
    .number({
      invalid_type_error: '半角数字で入力してください',
    })
    .refine(
      (val) => {
        return val === null || !Number.isNaN(val);
      },
      {
        message: '半角数字で入力してください',
      }
    )
);
