import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { UnSubscribedStaffRow } from 'context/users/onsite_users/UnSubscribed/_components/UnSubscribedStaffRow';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { UnsubscribedFilterArea } from 'context/users/onsite_users/UnSubscribed/_components/UnsubscribedFilterArea';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';
import {
  OnsiteUserListResponse,
  fetchOnsiteUsers,
  OnsiteUserDetailResponse,
} from '../_infra/api/onsiteUsers.get';

const abortContollerList: AbortController[] = [];
export const UnsubscribedStaffListContext = () => {
  const pagerState = useFilterAreaContext();

  useEffect(() => {
    // 以前の通信は破棄
    abortContollerList[0]?.abort();
    abortContollerList.pop();

    const abortController = new AbortController();
    abortContollerList.push(abortController);

    pagerState.doitPaging(1, abortController.signal);
  }, [pagerState.searchParams]);

  return (
    <div>
      <PageTitle title="退職者一覧" breadcrumbs={[['ホーム', '/']]} />

      <UnsubscribedFilterArea />

      <Contents isLoading={pagerState?.pager?.isLoading}>
        <div className="mt-[20px] min-h-[340px] relative">
          <Table className="m-w-[650px]">
            <TableHead className="bg-grayscale-500">
              <TableRow>
                <th className="w-[18%]">
                  <TableHeaderCell
                    orderValue="last_name"
                    pagerState={pagerState}
                  >
                    スタッフ名
                  </TableHeaderCell>
                </th>

                <th className="w-[15%]">
                  <TableHeaderCell>タイプ</TableHeaderCell>
                </th>

                <th className="w-[9%]">
                  <TableHeaderCell
                    orderValue="occupation__name"
                    pagerState={pagerState}
                  >
                    職種
                  </TableHeaderCell>
                </th>
                <th className="w-[10%]">
                  <TableHeaderCell
                    orderValue="grade__position__name"
                    pagerState={pagerState}
                  >
                    職位
                  </TableHeaderCell>
                </th>
                <th className="w-[10%]">
                  <TableHeaderCell
                    orderValue="grade__name"
                    pagerState={pagerState}
                  >
                    等級
                  </TableHeaderCell>
                </th>
                <th className="w-[10%]">
                  <TableHeaderCell
                    orderValue="shop__name"
                    pagerState={pagerState}
                  >
                    所属店舗
                  </TableHeaderCell>
                </th>
                <th className="w-[16%]">
                  <TableHeaderCell
                    orderValue="boss__last_name"
                    pagerState={pagerState}
                  >
                    評価者
                  </TableHeaderCell>
                </th>
                <th className="w-[9%]">
                  <TableHeaderCell
                    orderValue="unsubscribe_at"
                    pagerState={pagerState}
                  >
                    退職日
                  </TableHeaderCell>
                </th>
                <th className="w-[4%]" aria-label="メニューボタンの列" />
              </TableRow>
            </TableHead>
            <TableBody>
              {pagerState?.pager?.items?.map(
                (user: OnsiteUserDetailResponse, index: number) => {
                  return (
                    <UnSubscribedStaffRow
                      key={user.id}
                      onsiteUser={user}
                      index={index}
                      isStripedBackgroundColor
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Contents>
      <div className="pt-[40px]">
        <Pagination pagerState={pagerState} />
      </div>
    </div>
  );
};

export const UnsubscribedStaffList = () => {
  const pagerState = usePagerState<OnsiteUserListResponse>(
    (
      limit: number,
      offset: number,
      searchParams: URLSearchParams,
      signal?: AbortSignal
    ) => {
      searchParams.set('only_withdrawal', 'true'.toString());

      return fetchOnsiteUsers(limit, offset, searchParams, signal);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState.pager.isLoading}>
        <UnsubscribedStaffListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
