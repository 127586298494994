import { Dialog } from '@mui/material';
import { Button } from '@mui/base/Button';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useAtom } from 'jotai';
import { OnsiteUserDetailResponse } from '../../api/onsiteUsers.get';
import { postProfiletestActivation } from '../../api/requestWealth';

export const ProfileTestModal = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  onsiteUser?: OnsiteUserDetailResponse;
}) => {
  const [, displayMessage] = useAtom(displayMessageAtom);

  return (
    <Dialog open={props.isModalOpen} onClose={() => {}} data-testid="dialog">
      <div className="p-modal">
        <h6 className="text-section-heading font-bold">プロファイル診断</h6>
        <p className="mt-[30px] leading-relaxed">
          やることリストにウェルスの診断項目が追加されます。
        </p>

        <div className="flex pt-2 justify-center gap-5 items-center mt-[40px]">
          <Button
            className="w-btn-small text-btn-large text-grayscale-700"
            onClick={() => {
              props.setIsModalOpen(false);
            }}
          >
            キャンセル
          </Button>
          <BaseButton
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={async () => {
              const id = props.onsiteUser?.id;
              await postProfiletestActivation(id!);
              props.setIsModalOpen(false);
              displayMessage({
                text: 'やることリストにウェルスプロファイル診断を用意しました。',
                isInterruption: false,
              });

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }}
          >
            OK
          </BaseButton>
        </div>
      </div>
    </Dialog>
  );
};
