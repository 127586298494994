export const AccessTimeIcon = () => (
  <svg
    id="time"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g id="_-Product-Icons" data-name="🔍-Product-Icons">
      <g id="ic_fluent_clock_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M8,2A6,6,0,1,1,2,8,6,6,0,0,1,8,2ZM8,3a5,5,0,1,0,5,5A5.005,5.005,0,0,0,8,3ZM7.55,4.4A.45.45,0,0,1,8,4.789L8,4.85V8H9.95a.45.45,0,0,1,.061.9l-.061,0H7.55A.45.45,0,0,1,7.1,8.511l0-.061V4.85A.45.45,0,0,1,7.55,4.4Z"
          transform="translate(-2 -2)"
          fill="#b9bcbf"
        />
      </g>
    </g>
  </svg>
);
