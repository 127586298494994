import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/user/outside_managers/';
export type Request = SnakeObjToCamelObj<
  paths['/tenant/my/user/outside_managers/']['post']['requestBody']['content']['application/json'] & {
    dateOfBirth?: string;
  }
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/outside_managers/']['post']['responses'][201]['content']['application/json']
>;

export const postOutsideManager = (request: Request) => {
  return apiClient.post(URL, {
    email: request.email,
    firstName: request.firstName,
    lastName: request.lastName,
    position: request.position,
    password: request.password,
    dateOfBirth: request.dateOfBirth,
  });
};
