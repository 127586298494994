import { CssBaseline, Snackbar } from '@mui/material';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { ErrorMessageView } from 'pages/ErrorMessageView';
import { NotificationDialog } from 'infra/components/UI/Feedbacks/NotificationDialog';
import { CommonLayout } from 'pages/β_layputs/CommonLayout';
import { Home } from 'pages/00_home/Index';
import { isLoadingAtom } from 'infra/components/UI/Overlays/atomLoading';
import {
  displayMessageAtom,
  latestMessageAtom,
} from 'infra/components/UI/Overlays/globalmessage';
import { Debug } from 'pages/α_misc/Debug';
import { GuestLayout } from 'pages/β_layputs/GuestLayout';
import { Login } from 'context/auth/components/Login';

import { PropsWithChildren } from 'react';

import { EvaluateFinal } from 'context/objective/components/evaluate/evaluateFinal';
import { EvaluateMiddle } from 'context/objective/components/evaluate/evaluateMiddle';

import * as BrandsRoutes from 'pages/01_shops/01_brands_routes';
import * as ShopsRoutes from 'pages/01_shops/02_shops_routes';
import * as ShopsMboRoutes from 'pages/02_shop_mbo/routes';
import * as StaffsRoutes from 'pages/03_staffs/routes';
import * as MandalatRoutes from 'pages/04_mandalat/routes';
import * as OccupationPositionSettingRoutes from 'pages/05_operation-setting/01_occupation-position-setting_routes';
import * as GradeListRoutes from 'pages/05_operation-setting/02_grade_routes';
import * as CareerPathSettingRoute from 'pages/05_operation-setting/03_career-path-setting_routes';
import * as EvaluationMeasureSettingRoutes from 'pages/05_operation-setting/04_evaluation-measure-setting_routes';
import * as EvaluationPeriodSettingRoute from 'pages/05_operation-setting/05_evaluation-period-setting_routes';
import * as OutsideManagerListRoutes from 'pages/05_operation-setting/06_outside-managers_routes';
import * as MvpCategorySettingListRoute from 'pages/05_operation-setting/07_mvp-category-setting_routes';
import * as CorporationRankingsRoute from 'pages/06_objective-records/01_CorporationRankingsRoute';
import * as ObjectiveRecordsUserTypeRoute from 'pages/06_objective-records/02_ObjectiveRecordsRoute';
import * as ContractSettingRoute from 'pages/07_operation-setting/01_contract-setting_routes';
import * as CorporationAdminListRoute from 'pages/07_operation-setting/02_corporation-admins_routes';
import { WatchScreenView } from 'infra/components/Firebase/GA';

const App = () => {
  const [isLoading] = useAtom(isLoadingAtom);
  const [latestMessage, hideMessage] = useAtom(latestMessageAtom);

  return (
    <>
      <CssBaseline />
      <WatchScreenView />

      <Routes>
        <Route path="/debug" element={<Debug />} />
        <Route
          path="/guest"
          element={
            <GuestRoute>
              <GuestLayout>
                <Outlet />
              </GuestLayout>
            </GuestRoute>
          }
        >
          <Route path="/guest/login" element={<Login />} />
          <Route path="/guest/password-reset" element={<Home />} />
        </Route>
        <Route
          path="/"
          element={
            <LoggedInRoute>
              <Outlet />
            </LoggedInRoute>
          }
        >
          <Route
            path="/evaluate/:objectiveRecordId/middle"
            element={<EvaluateMiddle />}
          />
          <Route
            path="/evaluate/:objectiveRecordId/final"
            element={<EvaluateFinal />}
          />
          <Route
            path="/"
            element={
              <CommonLayout>
                <Outlet />
              </CommonLayout>
            }
          >
            <Route path="/" element={<Home />} />

            {ShopsRoutes.routes()}
            {ShopsMboRoutes.routes()}
            {BrandsRoutes.routes()}
            {StaffsRoutes.routes()}
            {MandalatRoutes.routes()}
            {OccupationPositionSettingRoutes.routes()}
            {GradeListRoutes.routes()}
            {CareerPathSettingRoute.routes()}
            {EvaluationMeasureSettingRoutes.routes()}
            {EvaluationPeriodSettingRoute.routes()}
            {OutsideManagerListRoutes.routes()}
            {OutsideManagerListRoutes.routes()}
            {MvpCategorySettingListRoute.routes()}
            {ObjectiveRecordsUserTypeRoute.routes()}
            {CorporationRankingsRoute.routes()}
            {ContractSettingRoute.routes()}
            {CorporationAdminListRoute.routes()}

            <Route path="/debug" element={<Debug />} />
          </Route>
          <Route path="/404" element={<ErrorMessageView />} />
          <Route path="*" element={<ErrorMessageView />} />
        </Route>
      </Routes>

      {/* メッセージがある場合は表示、いくつか表示パターンがある */}
      <Snackbar
        open={latestMessage !== null && !latestMessage.isInterruption}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          latestMessage?.onClose?.();
          hideMessage();
        }}
        message={latestMessage?.text}
      />
      <NotificationDialog
        open={latestMessage !== null && latestMessage.isInterruption}
        title=""
        onClose={() => {
          latestMessage?.onClose?.();
          hideMessage();
        }}
      >
        {latestMessage?.text}
      </NotificationDialog>

      {/* 画面全体ローディング */}
      <LoadingBackdrop isShow={isLoading} />
    </>
  );
};

const LoggedInRoute = (props: PropsWithChildren<{}>) => {
  return props.children as JSX.Element;
};

const GuestRoute = (props: PropsWithChildren<{}>) => {
  return props.children as JSX.Element;
};

export default App;
