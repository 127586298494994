import ProgressPopOver from './progress_pop_over.png';

const WIDTH = 160;

export const EvaluationProgressBar = (props: { value: number }) => {
  const ratio = props.value / 100;
  return (
    <div
      className="relative flex items-center flex-col mt-[20px]"
      style={{ width: `${WIDTH + 4}px` }}
    >
      <div
        className="absolute top-[-30px]"
        style={{ left: `${ratio * WIDTH - 16}px` }}
      >
        <div className="relative">
          <img src={ProgressPopOver} width="34px" alt="ロゴ画像" />
          <p className="absolute left-1/2 top-[3px] transform -translate-x-1/2 text-label text-grayscale-100 font-bold whitespace-nowrap">
            {Math.round(props.value)}%
          </p>
        </div>
      </div>
      <div className="w-full p-[2px] rounded bg-grayscale-400">
        <div
          className="h-[20px] rounded bg-gradient-blue-green"
          style={{ width: `${ratio * WIDTH}px` }}
        />
      </div>
      <div className="flex justify-between w-full">
        <p className="text-grayscale-700 text-micro">0</p>
        <p className="text-grayscale-700 text-micro">50</p>
        <p className="text-grayscale-700 text-micro">100</p>
      </div>
    </div>
  );
};
