import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { PropsWithChildren, useState, useEffect } from 'react';
import { BaseChip } from 'infra/components/UI/Helps/BaseChip';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { getMbos, MboListResponse } from 'context/corporation/mbo/api/get.mbos';
import {
  useFetchShops,
  fetchShops,
} from 'context/corporation/shop/api/fetchShops';
import { ResponseShop } from 'context/corporation/shop/api/typeResponse';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { getInputRequiredYearMonthList } from 'context/corporation/mbo/mbo';
import getContact from 'context/corporation/contracts/api/get.contract';
import { ShopMboFilterArea } from 'context/corporation/mbo/components/ShopMboFIlterArea';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import ReplaceImg from 'context/corporation/mbo/assets/replace_img01.png';
import { COLORS } from 'tailwind.config';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';

type Shop = ResponseShop;
type Mbo = NonNullable<MboListResponse['results']>[number];

const MBO_TYPE_MAP = {
  Cs: { label: 'CS', color: '#FC506E' },
  Es: { label: 'ES', color: '#FA8F47' },
  Profit: { label: '利益', color: '#FC506E' },
  Sales: { label: '売上', color: '#1FC8C2' },
} as const;

export const ShopMboListContext = () => {
  const pagerState = useFilterAreaContext();
  const [{ data: mboList }] = getMbos();
  const [{ data: shopList }] = useFetchShops();
  const [{ data: contact }] = getContact();
  const [now, _] = useState(new Date());

  useEffect(() => {
    pagerState.doitPaging(1);
  }, [pagerState.searchParams]);

  if (mboList === undefined || shopList === undefined) {
    return <LoadingBackdrop isShow />;
  }

  const yearMonthList =
    contact !== undefined
      ? getInputRequiredYearMonthList(now, new Date(contact?.usageStartedAt))
      : ([] as string[]);

  const mbosGroupByShop =
    mboList?.results?.reduce(
      (acc, mbo) => {
        if (acc[mbo.shop] === undefined) {
          acc[mbo.shop] = [];
        }
        acc[mbo.shop].push(mbo);
        return acc;
      },
      {} as { [key: string]: Mbo[] }
    ) ?? {};

  return (
    <div>
      <PageTitle title="店舗MBO管理" breadcrumbs={[['ホーム', '/']]} />

      <ShopMboFilterArea />

      <Contents isLoading={pagerState?.pager?.isLoading}>
        <div className="flex justify-between items-center pt-[20px]">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-grayscale-500">
              <TableRow>
                <TableHeaderCell
                  orderValue="brand__name"
                  pagerState={pagerState}
                >
                  ブランド
                </TableHeaderCell>
                <TableHeaderCell orderValue="name" pagerState={pagerState}>
                  店舗名
                </TableHeaderCell>
                <TableHeaderCell>ステータス</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagerState?.pager?.items?.map((shop: Shop, index: number) => {
                return (
                  <Row
                    key={shop.id}
                    shop={shop}
                    mbos={mbosGroupByShop[shop.id] || []}
                    yearMonthList={yearMonthList}
                    index={index}
                    isStripedBackgroundColor
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Contents>

      <div className="pt-[40px]">
        <Pagination pagerState={pagerState} />
      </div>
    </div>
  );
};

const Row = (
  props: PropsWithChildren<{
    shop: Shop;
    mbos: Mbo[];
    yearMonthList: string[];
    index: number;
    isStripedBackgroundColor: boolean;
  }>
) => {
  const navigate = useNavigate();

  // 指定された全ての年月でデータが入っていること
  const isCompleted = props.yearMonthList.every((yearMonth) => {
    return props.mbos.some((mbo) => mbo.yearMonth === yearMonth && mbo.isFixed);
  });

  const stripedBackgroundColor =
    props.index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;

  return (
    <TableRow
      onClick={() => {
        navigate(`/shop-mbo/${props.shop.id}`);
      }}
      style={{
        backgroundColor: props.isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell className="base-hover">
        <div className="flex gap-[20px] items-center text-ui">
          {props.shop.brand.brandLogo &&
          !props.shop.brand.brandLogo.includes('brands-logs_default.jpg') ? (
            <img
              src={props.shop.brand.brandLogo}
              alt="ブランドロゴ"
              className="max-w-[60px]"
            />
          ) : (
            <img
              src={ReplaceImg}
              alt="ブランドロゴ代替画像"
              className="max-w-[60px]"
            />
          )}
          {props.shop.brand.name}
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover">
        <span className="text-ui">{props.shop.name}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell>
        {isCompleted && <BaseChip label="入力完了" />}

        {/* NOTE: mapでやるとkeyのエラーを解消できないためreduceにしている */}
        {(['Cs', 'Es', 'Profit', 'Sales'] as const).reduce((prev, v) => {
          const isInputedAllPredictedValues = props.yearMonthList.every(
            (yearMonth) => {
              return props.mbos.some(
                (mbo) =>
                  mbo.yearMonth === yearMonth &&
                  mbo[`predictedValue${v}`] !== undefined &&
                  mbo[`predictedValue${v}`] !== null
              );
            }
          );
          // 予測値が全て入力されていなければ
          if (!isInputedAllPredictedValues) {
            prev.push(
              <BaseChip
                key={`predicted${v}`}
                className="base-hover"
                sx={{
                  marginY: '4px',
                  cursor: 'pointer',
                }}
                label={`${MBO_TYPE_MAP[v].label}目標未入力`}
                color16={MBO_TYPE_MAP[v].color}
              />
            );
          }

          const isInputedAllActualValues = props.yearMonthList.every(
            (yearMonth) => {
              return props.mbos.some(
                (mbo) =>
                  mbo.yearMonth === yearMonth &&
                  mbo[`actualValue${v}`] !== undefined &&
                  mbo[`actualValue${v}`] !== null
              );
            }
          );
          // 実績値が全て入力されていなければ
          if (!isInputedAllActualValues) {
            prev.push(
              <BaseChip
                className="base-hover"
                key={`actual${v}`}
                sx={{ marginY: '4px', cursor: 'pointer' }}
                label={`${MBO_TYPE_MAP[v].label}実績未入力`}
                color16={MBO_TYPE_MAP[v].color}
              />
            );
          }

          return prev;
        }, [] as JSX.Element[])}
      </BaseTableBodyCell>
    </TableRow>
  );
};

export const ShopMboList = () => {
  const pagerState = usePagerState(
    (limit: number, offset: number, searchParams: URLSearchParams) => {
      return fetchShops(limit, offset, searchParams);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState.pager.isLoading}>
        <ShopMboListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
