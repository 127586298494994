import { tr } from 'date-fns/locale';
import { components } from 'infra/api/schema';

// eslint-disable-next-line consistent-return
export const getFinalEvaluationLabel = (
  finalEducation: components['schemas']['FinalEducationEnum']
): string => {
  try {
    return {
      graduateSchool: '大学院',
      university: '大学',
      juniorCollege: '短大',
      vocationalCollege: '専門学校',
      higherProfessionalSchool: '高専',
      highSchool: '高校',
      juniorHighSchool: '中学校',
    }[finalEducation];
  } catch (error) {
    throw new Error('`finalEducation`が不正な値です');
  }
};
