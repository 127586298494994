import { createSvgIcon } from '@mui/material/utils';

export const FieldAddIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 16 16"
  >
    <path
      id="Icon_ionic-ios-add-circle"
      data-name="Icon ionic-ios-add-circle"
      d={
        'M11.375,3.375a8,8,0,1,0,8,8A8,8,0,0,0,11.375,3.375Zm3.481,8.615H11.99v2.865a.615.615,0,0,1-1.231,0V11.99H7.894a.615.615,0,1,1,0-1.231H10.76V7.894a.61' +
        '5.615,0,1,1,1.231,0V10.76h2.865a.615.615,0,1,1,0,1.231Z'
      }
      transform="translate(-3.375 -3.375)"
      fill="#048cf2"
    />
  </svg>,
  'FieldAdd'
);
