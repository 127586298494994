import { Avatar, Rating } from '@mui/material';
import { BasicThinkingTitle } from 'infra/components/UI/Icons/BasicThinkingTitle';
import { PracticalThinkingCheckbox } from 'infra/components/UI/Icons/PracticalThinkingCheckbox';
import { useState } from 'react';
import { Score } from 'context/objective/store/evaluationByOutsideManager';
import { SpeechBubble } from 'context/users/onsite_users/_infra/components/StaffDetails/StaffTabs/GoalDetailsTab';
import { ObjectiveRecord } from 'context/objective/components/evaluate/evaluateMiddle';
import * as EvaluationResult from 'context/objective/evaluationResult';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { EditIcon } from 'infra/components/UI/Icons/EditIcon';
import { CommentModal } from '../CommentModal';

export const MiddleScoreItem = ({
  currentScore,
  evaluatorFullName,
  evaluationType,
  basicThinking,
  user,
  onSaveScore,
  index,
}: {
  currentScore: Score;
  evaluatorFullName: string;
  evaluationType: 'middle' | 'final';
  user: ObjectiveRecord['onsiteUser'];
  basicThinking: ObjectiveRecord['basicThinkings'][number];
  onSaveScore: (score: Score, order: number) => void;
  index: number;
}) => {
  const evaluationMaster = basicThinking.basicThinkingMaster;

  const [isShowCommentModal, setIsShowCommentModal] = useState(false);

  return (
    <div>
      <div className="mb-[30px]">
        <BasicThinkingTitle
          color={evaluationMaster.category.color}
          basicThinkingLabel={evaluationMaster.basicThinkingLabel}
        />
      </div>

      <div className="mb-[30px]">
        {basicThinking.practicalThinkings.map((p) => {
          return (
            <PracticalThinkingCheckbox
              isChecked={p.hasAchieved || false}
              isRecentGoal={p.isRecentGoal || false}
              description={p.practicalThinkingMaster.practicalThinkingLabel}
            />
          );
        })}
      </div>

      <div className="p-[20px] rounded bg-grayscale-400">
        <div className="flex items-center gap-[10px] pb-[20px]">
          <p className="text-base font-bold">中間評価</p>
        </div>

        <Divider />
        <div className="flex items-center pt-[30px] pb-[20px] gap-[20px]">
          <div className="flex items-center rounded border-1 border-grayscale-700 px-[8px] py-[4px] bg-grayscale-100 min-w-[78px] min-h-[26px] text-center">
            <p className="text-label">中間自己評価</p>
          </div>
          <Rating
            size="large"
            name="disabled"
            max={4}
            value={EvaluationResult.getScore(
              basicThinking.evaluationResults,
              'middle',
              'myself'
            )}
            readOnly
          />
        </div>
        <div className="flex items-center pb-[20px] gap-[20px]">
          <div className="flex items-center rounded px-[8px] py-[4px] bg-grayscale-700 min-w-[78px] min-h-[26px] text-center">
            <p className="text-label text-grayscale-100">中間上司評価</p>
          </div>

          <Rating
            size="large"
            name="disabled"
            max={4}
            readOnly={evaluationType !== 'middle'}
            value={
              evaluationType !== 'middle'
                ? EvaluationResult.getScore(
                    basicThinking.evaluationResults,
                    'middle',
                    'boss'
                  )
                : currentScore.score
            }
            onChange={(event, inputedScore) => {
              if (inputedScore === null) {
                return;
              }
              onSaveScore({ ...currentScore, score: inputedScore }, index);
            }}
          />
        </div>

        {currentScore.comment ? (
          <button
            type="button"
            aria-label="コメントを編集"
            className="w-full text-left base-hover"
            onClick={() => {
              setIsShowCommentModal(true);
            }}
          >
            <div className="mb-[14px]">
              <SpeechBubble marginBottom={2.25} text={currentScore.comment} />
            </div>
            <div className="flex justify-between">
              <div className="flex gap-[10px] items-center">
                <Avatar src="" sx={{ width: 24, height: 24 }} />
                <p className="text-base font-bold">
                  {evaluatorFullName}
                  さん
                </p>
              </div>
              <div className="flex items-center gap-[5px]">
                <EditIcon />
                <p className="text-base text-main-blue">コメントを編集</p>
              </div>
            </div>
          </button>
        ) : (
          // NOTE: 中間評価の場合のみ、コメントが記入可能なように制限をかけている、将来的にそうでない場合でもコメント記入可能になる可能性が高い
          evaluationType === 'middle' && (
            <button
              className="py-[10px] base-hover"
              type="button"
              aria-label="コメントを追加"
              onClick={() => {
                setIsShowCommentModal(true);
              }}
            >
              <div className="flex items-center gap-[10px]">
                <Avatar src="" sx={{ width: 24, height: 24 }} />
                <p className="text-meta text-main-blue font-medium">
                  コメントを追加
                </p>
              </div>
            </button>
          )
        )}
      </div>
      {isShowCommentModal && (
        <CommentModal
          currentComment={currentScore.comment}
          onSubmit={(comment) => {
            onSaveScore({ ...currentScore, comment }, index);
          }}
          onClose={() => setIsShowCommentModal(false)}
        />
      )}
    </div>
  );
};
