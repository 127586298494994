export const ExpandSidebar = () => {
  return (
    <svg
      id="コンポーネント_247_3"
      data-name="コンポーネント 247 – 3"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="長方形_1999"
        data-name="長方形 1999"
        width="32"
        height="32"
        fill="#fff"
      />
      <g
        id="_-Product-Icons"
        data-name="🔍-Product-Icons"
        transform="translate(6 9)"
      >
        <g id="ic_fluent_navigation_24_regular">
          <path
            id="_-Color"
            data-name="🎨-Color"
            d="M2.753,18h18.5a.75.75,0,0,1,.1,1.493l-.1.007H2.753a.75.75,0,0,1-.1-1.493l.1-.007h0Zm0-6.5h18.5a.75.75,0,0,1,.1,1.493l-.1.007H2.753a.75.75,0,0,1-.1-1.493l.1-.007h0Zm0-6.5h18.5a.75.75,0,0,1,.1,1.493l-.1.007H2.752a.75.75,0,0,1-.1-1.493L2.752,5h0Z"
            transform="translate(-2.002 -5.003)"
            fill="#757a80"
          />
        </g>
      </g>
    </svg>
  );
};
