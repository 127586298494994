import { BoxProps } from '@mui/material';
import { colorToRgba } from 'context/mvps/types/mvpCategory';
import { ErrorIcon } from 'context/objective/components/evaluate/CommitmentSheet/CommitmentSheetItem/ErrorIcon';
import { CircleFlagIcon } from 'context/objective/components/evaluate/CommitmentSheet/CommitmentSheetItem/CircleFlagIcon';
import { COLORS } from 'tailwind.config';
import { RightBottomArrow } from 'context/mandalat/assets/RightBottomArrow';
import HeartIcon from 'context/mandalat/assets/hearticon01.svg';
import HandIcon from 'context/mandalat/assets/handicon01.svg';
import WakabaIcon from 'context/mandalat/assets/wakabaicon01.svg';
import PersonIcon from 'context/mandalat/assets/personicon01.svg';
import CanayellIcon from 'context/mandalat/assets/canayellicon01.svg';

type IconMap = {
  [category: string]: string;
};

export const CommitmentSheetItem = (
  props: BoxProps & {
    color16?: string;
    category?: string;
    displayFlag?: boolean;
    displayAttension?: boolean;
  }
) => {
  const { color16, children } = props;
  const color = color16 === undefined ? COLORS.GRAY_700 : color16;

  const iconMap: IconMap = {
    カルチャー: HeartIcon,
    マインド: HeartIcon,
    ベーシック: HandIcon,
    オペレーション: HandIcon,
    ホスピタリティ: HandIcon,
    ナレッジ: HandIcon,
    エキスパート: HandIcon,
    ウェルビーイング: WakabaIcon,
    グリット: PersonIcon,
  };
  const bgIcon = () => {
    const category = props.category || '';
    return iconMap[category] || CanayellIcon;
  };

  return (
    <div
      className="
        relative flex justify-center items-center w-[92px] h-[92px] p-[3px]
        rounded bg-no-repeat bg-center bg-cover"
      style={{
        backgroundColor: colorToRgba(color, 0.3),
        backgroundImage: `url(${bgIcon()})`,
      }}
    >
      {props.category && (
        <>
          <div
            className="absolute left-[5px] top-[5px] rounded-full"
            style={{ backgroundColor: colorToRgba(color) }}
          >
            <p className="px-[5px] py-[5px] leading-none text-atom font-bold text-grayscale-100 overflow-hidden max-w-[70px] overflow-ellipsis whitespace-pre">
              {props.category}
            </p>
          </div>
          <div className="absolute right-[3px] bottom-[3px]">
            <RightBottomArrow bgColor={color} />
          </div>
        </>
      )}
      <div className="absolute left-[5px] bottom-[3px] flex gap-[3px] items-center">
        {props.displayAttension && <ErrorIcon />}
        {props.displayFlag && <CircleFlagIcon />}
      </div>
      {children}
    </div>
  );
};
