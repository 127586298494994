import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/user/onsite_users/{id}/';
export type Request = SnakeObjToCamelObj<
  NonNullable<
    paths[typeof URL]['patch']['requestBody']
  >['content']['application/json']
>;

export const patchOnsiteuser = (onsiteUserId: string, request: Request) => {
  return apiClient.patch(URL.replace('{id}', onsiteUserId), request, {});
};
