import { MenuItem, Select, SelectProps } from '@mui/material';
import { STYLES } from 'tailwind.config';

type SelectItem = {
  key: string;
  value?: string | number;
  label: string;
  type: string;
  group: string;
};

export const EditBasicThinkingMasterIdSelect = (
  props: SelectProps & {
    currentType: string;
    selectableItems?: SelectItem[];
  }
) => {
  /**
   * 結果は以下のようなデータ型です。
   * {
   *  'グループ名': [
   *   { key: 'キー', value: '値', label: 'ラベル', type: 'タイプ', group: 'グループ名' },
   *   { key: 'キー', value: '値', label: 'ラベル', type: 'タイプ', group: 'グループ名' },
   * ],
   */
  const groupedItems = props.selectableItems
    ?.filter((item) => props.currentType === item.type)
    .reduce<Record<string, SelectItem[]>>((collection, item) => {
      const groupKey = item.group;
      if (collection[groupKey]) {
        collection[groupKey].push(item);
      } else {
        // eslint-disable-next-line no-param-reassign
        collection[groupKey] = [item];
      }
      return collection;
    }, {});

  return (
    <Select {...props} displayEmpty>
      <MenuItem value={0} sx={{ fontSize: STYLES.FONT_SIZE.BASE }}>
        任意
      </MenuItem>
      {Object.entries(groupedItems || {}).map(([group, items]) => [
        <MenuItem
          key={`group-${group}`}
          disabled
          sx={{ fontSize: STYLES.FONT_SIZE.BASE }}
        >
          {group}
        </MenuItem>,
        items.map((item) => (
          <MenuItem
            key={item.key}
            value={item.value}
            sx={{ fontSize: STYLES.FONT_SIZE.BASE, paddingLeft: '30px' }}
          >
            {item.label}
          </MenuItem>
        )),
      ])}
    </Select>
  );
};
