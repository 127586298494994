import { PropsWithChildren } from 'react';
import LogoImage from './icons/logo.png';

export const GuestLayout = (props: PropsWithChildren<{}>) => {
  return (
    <div className="flex min-h-screen">
      <div className="w-1/2">
        <div className="flex items-center justify-center h-full bg-grayscale-800">
          <img src={LogoImage} width="300" alt="ロゴ画像" />
        </div>
      </div>
      <div className="w-1/2">{props.children}</div>
    </div>
  );
};
