export const CloseButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g
        id="グループ_87"
        data-name="グループ 87"
        transform="translate(-194 -123)"
      >
        <circle
          id="楕円形_1"
          data-name="楕円形 1"
          cx="7"
          cy="7"
          r="7"
          transform="translate(194 123)"
          fill="#b9bdbf"
        />
        <g
          id="グループ_86"
          data-name="グループ 86"
          transform="translate(199 128)"
        >
          <line
            id="線_88"
            data-name="線 88"
            y1="4"
            x2="4"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="線_89"
            data-name="線 89"
            x1="4"
            y1="4"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
