import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/evaluation_periods/corporation/mbos/';
export type MboPostRequestBody = Omit<
  SnakeObjToCamelObj<
    NonNullable<
      paths[typeof URL]['post']['requestBody']
    >['content']['application/json']
  >,
  'id' | 'createdAt' | 'updatedAt'
>;

/**
 * MBOの登録
 * @param useCache
 * @returns
 */
export const postMbo = (
  requestBody: {
    predictedValueSales: number | null;
    predictedValueProfit: number | null;
    predictedValueEs: number | null;
    predictedValueCs: number | null;
    actualValueSales: number | null;
    actualValueProfit: number | null;
    actualValueEs: number | null;
    actualValueCs: number | null;
  },
  shopId: string,
  yearMonth: string
) => {
  const response = apiClient.post<any, MboPostRequestBody>(URL, {
    ...requestBody,
    shop: shopId,
    yearMonth,
  });
  return response;
};
