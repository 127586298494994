import { createSvgIcon } from '@mui/material/utils';

export const AttentionIcon = createSvgIcon(
  <svg
    id="icon_alert"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="info" transform="translate(-1.998 -1.999)">
      <g id="ic_fluent_info_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm0,7a.75.75,0,0,1,.744.648l.007.1,0,5.5a.75.75,0,0,1-1.493.1l-.007-.1,0-5.5A.75.75,0,0,1,12,10.5ZM12,7a1,1,0,1,1-1,1A1,1,0,0,1,12,7Z"
          fill="#fc506e"
        />
      </g>
    </g>
  </svg>,
  'Attention'
);
