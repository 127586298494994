import { COLORS } from 'tailwind.config';

import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

type DoughnutChartProps = {
  datasets: {
    data: number[];
    backgroundColor: string[];
  }[];
  evaluateWeight: number;
  label: string;
};

ChartJS.register(ArcElement);

/**
 * ラベル付きドーナツチャート(スコア内訳用)
 * @param props
 * @returns
 */
export const DoughnutChartWithLabel = (props: DoughnutChartProps) => {
  const updatedDatasets = props.datasets.map((dataset) => {
    const score = dataset.data[0];
    const percentage = (score / props.evaluateWeight) * 100;
    return {
      ...dataset,
      cutout: '68%',
      data: [percentage, 100 - percentage],
      backgroundColor:
        dataset.backgroundColor.length > 1
          ? dataset.backgroundColor
          : [dataset.backgroundColor[0], COLORS.GRAY_500],
    };
  });

  const data = {
    datasets: updatedDatasets,
  };

  const options = {
    animation: true,
  };

  return (
    <div className="relative w-full h-full relative">
      <Doughnut data={data} options={options} />
      <div className="absolute top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-[50%] flex items-center">
        <span className="text-label font-bold">{props.label}</span>
        <span style={{ fontSize: '6px', fontWeight: 'bold' }}>点</span>
      </div>
    </div>
  );
};

type EvaluationWeightsChartsItemProps = {
  name: string;
  score: number;
  evaluateWeight: number;
  backgroundColor: string;
  className?: string;
};

export const EvaluationWeightsChartsItem = (
  props: EvaluationWeightsChartsItemProps
) => {
  return (
    <li className={`${props.className}`}>
      <div className="">
        <div className="w-[80px] h-[80px]">
          <DoughnutChartWithLabel
            datasets={[
              {
                data: [Math.floor(props.score)],
                backgroundColor: [props.backgroundColor],
              },
            ]}
            evaluateWeight={props.evaluateWeight}
            label={`${Math.floor(props.score)}/${props.evaluateWeight}`}
          />
        </div>
        <div className="flex flex-col items-center mt-[5px]">
          <p className="font-bold text-label">{props.name}</p>
          <div className="mt-[2px] flex flex-col items-center">
            <p className="text-micro">評価ウェイト</p>
            <p className="text-label">{props.evaluateWeight}%</p>
          </div>
        </div>
      </div>
    </li>
  );
};
