/**
 * ドラッグアンドドロップコンポーネント
 * ファイルがセットされると`handleFile`が呼び出される
 * LINK: https://www.codemzy.com/blog/react-drag-drop-file-upload
 */

import { useState, useRef, DragEventHandler, ChangeEventHandler } from 'react';
import { COLORS } from 'tailwind.config';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';

export const DragDropFile = (props: {
  width: number | string;
  height: number;
  file: File | null;
  handleFile: (f: File) => void;
}) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag: DragEventHandler<HTMLFormElement | HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.handleFile(e.dataTransfer.files[0]);
      // @ts-ignore 同名のファイルをドロップした場合にも発火するようにする
      inputRef.current.value = null;
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.handleFile(e.target.files[0]);
      // @ts-ignore 同名のファイルをドロップした場合にも発火するようにする
      inputRef.current.value = null;
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <form
      className="max-w-full text-center relative"
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      style={{
        height: props.height,
        width: props.width,
      }}
    >
      <input
        className="hidden"
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple
        onChange={handleChange}
        accept=".csv"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className="w-full h-full flex items-center justify-center border-2 border-dashed border-grayscale-500"
        style={{
          backgroundColor: dragActive ? COLORS.SOFT_BLUE : COLORS.GRAY_400,
        }}
      >
        <div>
          <p className="pb-[30px] text-base text-grayscale-700">
            {props.file === null
              ? 'CSVファイルをドラッグ&ドロップ'
              : props.file.name}
          </p>
          <BaseButton
            className="w-btn-medium text-base p-button-small"
            onClick={onButtonClick}
          >
            {props.file === null ? 'アップロード' : '変更する'}
          </BaseButton>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className="absolute w-full h-full inset-0"
        />
      )}
    </form>
  );
};
