/**
 * 指定スタッフの一時停止または削除を行うモーダルコンポーネント。
 */

import { FC, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { Button } from '@mui/base/Button';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import {
  displayMessageAtom,
  Message,
} from 'infra/components/UI/Overlays/globalmessage';
import { useAtom } from 'jotai';
import {
  OnsiteUserDetailResponse,
  OnsiteUserListResponse,
} from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { patchAppUsageState } from 'context/users/onsite_users/_infra/api/appUsageState.patch';

/**
 * Types
 */
export namespace Types {
  export type State = {
    isModalOpen: boolean;
    setModalOpen: () => void;
    setModalClose: () => void;
    actionType: ActiveCondition;
    updateActionType: (type: ActiveCondition) => void;
    showModal: (passedType: ActiveCondition) => void;
    isSuspendExecuteCompleted: boolean;
    setIsSuspendExecuteCompleted: (isSuspended: boolean) => void;
    isDeletedCompleted: boolean;
    setIsDeletedCompleted: (isDeleted: boolean) => void;
  };

  export type Props = {
    _state: State;
    onsiteUser?: OnsiteUser;
  };

  export type ActiveCondition = 'suspend' | 'delete' | 'reactivate' | null;

  export type OnsiteUser = OnsiteUserDetailResponse | OnsiteUserListResponse;
}

export namespace state {
  export const global = {
    isShow: (onsiteUser: Types.OnsiteUser) => {
      if (!onsiteUser || onsiteUser.appUsageState !== 'withdrawal') {
        return true;
      }
      return false;
    },
  };

  /**
   * `state` にはこのモーダルコンポーネントの状態管理のすべてが入っています。
   * このモーダルコンポーネントに関連する状態管理はここを参照してください。
   * このモーダルコンポーネントに状態を追加する場合は、ここ以外に記述しないでください。
   * モーダルの表示状態、ユーザーの一時停止状態、および実行されるアクションの種類を管理します。
   *
   * @returns {object} 状態管理に必要な変数と関数を含むオブジェクト
   */
  export const local = (): Types.State => {
    /**
     * モーダルが開いているか閉じているかを管理する状態変数。
     * @type {boolean}
     */
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * モーダル内で実行されるアクションの種類を管理する状態変数。
     * 'suspend'、'delete'、または `null` の値を取ります。
     * @type {(UserActive)}
     */
    const [actionType, setActionType] = useState<Types.ActiveCondition>(null);

    /**
     * 一時停止状態の更新を行ったかどうかを判定する状態変数。
     * boolean
     */
    const [isSuspendExecuteCompleted, _setIsSuspendExecuteCompleted] =
      useState(false);

    /**
     * 削除操作が完了したかを判定する状態変数。
     * boolean
     */
    const [isDeletedCompleted, _setIsDeletedCompleted] = useState(false);

    return {
      isModalOpen,
      setModalOpen: () => {
        setIsModalOpen(true);
      },
      setModalClose: () => {
        setIsModalOpen(false);
      },

      actionType,
      updateActionType: (type: Types.ActiveCondition) => {
        setActionType(type);
      },

      showModal: (passedType: Types.ActiveCondition) => {
        setActionType(passedType);
        setIsModalOpen(true);
      },
      isSuspendExecuteCompleted,
      setIsSuspendExecuteCompleted: (isSuspended: boolean) => {
        _setIsSuspendExecuteCompleted(isSuspended);
      },

      isDeletedCompleted,
      setIsDeletedCompleted: (isDeleted: boolean) => {
        _setIsDeletedCompleted(isDeleted);
      },
    } as Types.State;
  };
}

/**
 * `events` は、モーダルコンポーネント内で発生するイベントやメソッドを管理します。
 */
const events = (
  displayMessage: (message: Message) => void,
  userState: Types.State
) => {
  /**
   * ユーザーの状態に応じてAPIリクエストを実行し、結果に基づいてメッセージを表示する関数。
   *
   * @async
   * @function switchCondition
   * @param {OnsiteUserDetailResponse} user - ユーザーのデータ。
   * @returns {Promise<void>} 非同期処理の結果。
   */
  const switchCondition = async (user: Types.OnsiteUser): Promise<void> => {
    switch (userState?.actionType) {
      case 'suspend': {
        await patchAppUsageState(user.id, {
          isSuspended: true,
        });
        displayMessage({
          text: 'ユーザーが一時停止されました',
          isInterruption: false,
        });
        userState?.setIsSuspendExecuteCompleted(true);
        break;
      }
      case 'reactivate': {
        await patchAppUsageState(user.id, {
          isSuspended: false,
        });

        displayMessage({
          text: '一時停止状態を解除しました。',
          isInterruption: false,
        });
        await userState?.setIsSuspendExecuteCompleted(false);
        break;
      }
      case 'delete': {
        await patchAppUsageState(user.id, {
          appUsageState: 'withdrawal',
        });
        displayMessage({
          text: 'ユーザーが削除されました',
          isInterruption: false,
        });
        userState?.setIsDeletedCompleted(true);
        break;
      }
      default: {
        break;
      }
    }

    userState?.setModalClose();
  };

  /**
   * モーダルコンポーネント内で表示されるコンテンツを生成し、返却する関数。
   * actionTypeに応じて異なるコンテンツを表示します。
   * @function renderContent
   * @returns {ReactElement | null} - レンダリングするReact要素またはnull。
   */
  const renderContent = () => {
    switch (userState.actionType) {
      case 'suspend':
        return (
          <>
            <h6 className="text-section-heading font-bold">
              アカウントの一時停止
            </h6>
            <p className="mt-[30px] leading-relaxed">
              アカウントを一時停止すると、アカウントへのアクセスが一時的に制限されます。一時停止してもよろしいですか？
            </p>
          </>
        );
      case 'delete':
        return (
          <>
            <h6 className="text-section-heading font-bold">アカウントの削除</h6>
            <p className="mt-[30px] leading-relaxed">
              アカウントを削除すると、データが完全に消去されます。
              この操作は取り消せません。本当にこのアカウントを削除しますか?
            </p>
          </>
        );

      case 'reactivate':
        return (
          <>
            <h6 className="text-section-heading font-bold">一時停止の解除</h6>
            <p className="mt-[40px] leading-loose">
              アカウントの一時停止を解除しますか？
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return {
    switchCondition,
    renderContent,
  };
};

/**
 * `DeleteOrSuspendModal` コンポーネントは、スタッフの就業状態を変更するモーダルです。
 * スタッフの「一時停止」または「削除」のアクションを実行することができます。
 *
 * @param {Props} props - コンポーネントに渡されるプロパティ。

 * @returns {ReactElement} レンダリングされたコンポーネント。
 */
export const DeleteOrSuspendModal: FC<Types.Props> = ({
  _state,
  onsiteUser,
}) => {
  const [, displayMessage] = useAtom(displayMessageAtom);
  const { switchCondition, renderContent } = events(displayMessage, _state);

  useEffect(() => {
    _state.setIsSuspendExecuteCompleted(onsiteUser?.isSuspended!);
  }, [onsiteUser]);

  return (
    <Dialog
      open={_state.isModalOpen}
      onClose={_state.setModalClose}
      data-testid="dialog"
    >
      <div className="p-modal">
        {renderContent()}
        <div className="flex pt-2 justify-center gap-5 items-center mt-[40px]">
          <Button
            className="w-btn-small text-btn-large text-grayscale-700"
            onClick={_state?.setModalClose}
          >
            キャンセル
          </Button>
          <BaseButton
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={() => onsiteUser && switchCondition(onsiteUser)}
          >
            {_state.actionType === 'reactivate' ? '解除' : 'OK'}
          </BaseButton>
        </div>
      </div>
    </Dialog>
  );
};
