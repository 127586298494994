export const ErrorIcon = () => (
  <svg
    id="必須アイコン_大_"
    data-name="必須アイコン（大）"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <g
      id="グループ_2461"
      data-name="グループ 2461"
      transform="translate(92 -2194)"
    >
      <g id="必須アイコン" transform="translate(-92 2194)">
        <circle
          id="楕円形_206"
          data-name="楕円形 206"
          cx="5"
          cy="5"
          r="5"
          transform="translate(2 2)"
          fill="#fff"
        />
        <g id="ic_fluent_info_24_filled">
          <path
            id="_-Color"
            data-name="🎨-Color"
            d="M8.5,15A6.5,6.5,0,1,0,2,8.5,6.5,6.5,0,0,0,8.5,15Zm0-5.362a.65.65,0,0,1-.645-.575l0-.076,0-3.575,0-.076a.65.65,0,0,1,1.291,0l0,.076,0,3.575,0,.076A.65.65,0,0,1,8.5,9.637Zm0,2.437a.813.813,0,1,1,.813-.813A.813.813,0,0,1,8.5,12.074Z"
            transform="translate(-1.998 -1.999)"
            fill="#1a222c"
          />
        </g>
      </g>
    </g>
  </svg>
);
