import { PropsWithChildren } from 'react';

export const BaseSpeechBubble = (
  props: PropsWithChildren<{
    color: string;
  }>
) => (
  <div className="pt-[4px] relative">
    <div className="flex justify-center">
      <div
        className="
          absolute w-0 h-0
          border-l-[8px] border-l-grayscale-100 border-r-[8px] border-r-grayscale-100 border-b-[12px]
          -translate-y-[12px]
        "
        style={{
          borderBottomColor: props.color,
        }}
      />
    </div>
    <div className="rounded p-[20px]" style={{ backgroundColor: props.color }}>
      {props.children}
    </div>
  </div>
);
