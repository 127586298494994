import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePagination } from 'infra/api/type';
import { formatDate } from 'infra/utils/date-fns';
import { ResponseEvaluationPeriod } from './typeResponse';

export const useFetchEvaluationPeriods = (
  isOnlyCurrent: boolean = false,
  useCache = false
) => {
  const queryParameter: { [k in string]: any } = {
    limit: 9999,
  };
  if (isOnlyCurrent) {
    const now = `${formatDate(new Date(), '-')} 00:00:00`;
    queryParameter.started_at__lte = now;
    queryParameter.ended_at__gte = now;
  }

  const response = useAxios<ResponsePagination<ResponseEvaluationPeriod>>(
    {
      url: MY_TENANT_URL('evaluation_periods/evaluation_periods/'),
      params: queryParameter,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);
  return response;
};
