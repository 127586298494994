export const MandalatIcon = () => (
  <svg
    id="コンポーネント_48_3"
    data-name="コンポーネント 48 – 3"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="_-System-Icons" data-name="🔍-System-Icons">
      <g id="ic_fluent_table_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M29.222,3A5.778,5.778,0,0,1,35,8.778V29.222A5.778,5.778,0,0,1,29.222,35H8.778A5.778,5.778,0,0,1,3,29.222V8.778A5.778,5.778,0,0,1,8.778,3ZM22.556,25.222H15.444v7.111h7.111Zm9.778,0H25.222v7.111h4a3.111,3.111,0,0,0,3.111-3.111h0Zm-19.556,0H5.667v4a3.111,3.111,0,0,0,3.111,3.111h4Zm0-9.778H5.667v7.111h7.111Zm9.778,0H15.444v7.111h7.111Zm9.778,0H25.222v7.111h7.111ZM12.778,5.667h-4A3.111,3.111,0,0,0,5.667,8.778v4h7.111Zm16.444,0h-4v7.111h7.111v-4a3.111,3.111,0,0,0-3.111-3.111Zm-6.667,0H15.444v7.111h7.111Z"
          transform="translate(-3 -3)"
          fill="#048cf2"
        />
      </g>
    </g>
  </svg>
);
