import { useState } from 'react';

/**
 * チェックボックスの状態管理カスタムフック
 * ページネーションを伴う表に対して利用することを想定している
 *
 * チェックされる対象の各要素には、識別子として`id: string`を含む必要がある
 */
export const useCheckSelectorWithPagination = <
  T extends { id: string },
>(props: {
  allItemsInPage: T[] | null;
}) => {
  const [selectedItemMap, setSelectedItemMap] = useState<Map<string, T>>(
    new Map()
  );

  const items = props.allItemsInPage ?? [];

  const isAllItemInPageSelected = (items ?? []).every((item) =>
    selectedItemMap.has(item.id)
  );

  return [
    { selectedItemMap, size: selectedItemMap.size, isAllItemInPageSelected },
    {
      clearSelectedItems: () => {
        setSelectedItemMap(new Map());
      },
      onChangeHeaderCheckbox: () => {
        setSelectedItemMap((original) => {
          items.forEach((item) => {
            if (isAllItemInPageSelected) {
              original.delete(item.id);
            } else {
              original.set(item.id, item);
            }
          });
          return new Map(original);
        });
      },
      onChangeRowCheckbox: (id: string) => {
        setSelectedItemMap((original) => {
          if (original.has(id)) {
            original.delete(id);
          } else {
            const selectedItem = items.find((item) => item.id === id);
            if (selectedItem === undefined) {
              throw new Error(
                `選択されたこのIDのアイテムは画面上に存在していません, id=${id}`
              );
            }
            original.set(id, selectedItem);
          }
          return new Map(original);
        });
      },
    },
  ] as const;
};
