import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { PropsWithChildren, useEffect } from 'react';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { useNavigate, Link } from 'react-router-dom';
import { fetchShops } from 'context/corporation/shop/api/fetchShops';
import { ResponseShop } from 'context/corporation/shop/api/typeResponse';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { WarningMessageBox } from 'infra/components/UI/Feedbacks/WarningMessageBox';
import { getBrandName } from 'context/corporation/brand/components/BrandForm';

import { useAtom } from 'jotai';
import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import ReplaceImg from 'context/corporation/brand/assets/replace_img01.png';
import { COLORS } from 'tailwind.config';
import { ShopListFilterArea } from 'context/corporation/shop/components/ShopListFIlterArea';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';

export const ShopListContext = () => {
  const pagerState = useFilterAreaContext();
  const isEmpty = pagerState?.pager.items.length === 0;

  useEffect(() => {
    pagerState.doitPaging(1);
  }, [pagerState.searchParams]);

  return (
    <div>
      <PageTitle title="店舗一覧" breadcrumbs={[['ホーム', '/']]} />

      {isEmpty && (
        <div className="mt-[40px]">
          <WarningMessageBox
            message="店舗 未登録"
            detailsMessage="店舗が登録されていません。店舗を1つ以上登録してください"
          />
          <p className="my-[20px] text-base">
            まだ登録されたデータはありません。
          </p>
          <Link to="/shops/create">
            <BaseButton
              className="w-btn-small p-button-small tracking-short-text text-base bg-transparent border border-main-blue text-main-blue"
              startIcon={<AddIcon color="text-main-blue" />}
            >
              登録
            </BaseButton>
          </Link>
        </div>
      )}

      {!isEmpty && (
        <>
          <ShopListFilterArea />

          <Contents isLoading={pagerState?.pager?.isLoading}>
            <div className="flex pt-[20px] justify-between items-center">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="bg-grayscale-500">
                  <TableRow>
                    <TableHeaderCell
                      orderValue="brand__name"
                      pagerState={pagerState}
                    >
                      ブランド名
                    </TableHeaderCell>

                    <TableHeaderCell orderValue="name" pagerState={pagerState}>
                      店舗名
                    </TableHeaderCell>
                    <TableHeaderCell
                      orderValue="brand__business_type"
                      pagerState={pagerState}
                    >
                      業態
                    </TableHeaderCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagerState.pager.items?.map(
                    (shop: ResponseShop, index: number) => (
                      <Row
                        key={shop.id}
                        shop={shop}
                        index={index}
                        isStripedBackgroundColor
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </Contents>

          <div className="pt-[40px]">
            <Pagination pagerState={pagerState} />
          </div>
        </>
      )}
    </div>
  );
};

const Row = (
  props: PropsWithChildren<{
    shop: ResponseShop;
    index: number;
    isStripedBackgroundColor: boolean;
  }>
) => {
  const { shop, index, isStripedBackgroundColor } = props;
  const navigate = useNavigate();

  const onClick = () => navigate(`/shops/${shop.id}`);
  const stripedBackgroundColor =
    index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;

  return (
    <TableRow
      style={{
        backgroundColor: isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell
        className="base-hover"
        onClick={onClick}
        component="th"
        scope="row"
      >
        <div className="flex gap-[20px] items-center">
          {shop.brand.brandLogo &&
          !shop.brand.brandLogo.includes('brands-logs_default.jpg') ? (
            <img
              src={shop.brand.brandLogo}
              alt="ブランドロゴ"
              className="max-w-[60px]"
            />
          ) : (
            <img src={ReplaceImg} alt="代替画像" className="max-w-[60px]" />
          )}
          {shop.brand.name}
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={onClick}>
        {shop.name}
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={onClick}>
        {getBrandName(shop.brand.businessType)}
      </BaseTableBodyCell>
      <BaseTableBodyCell>
        <div className="flex justify-end">
          <MoreMenu
            render={[
              {
                label: <span>編集</span>,
                onClick: () => {
                  onClick();
                },
              },
            ]}
          />
        </div>
      </BaseTableBodyCell>
    </TableRow>
  );
};

export const ShopList = () => {
  const pagerState = usePagerState<ResponseShop>(
    (limit: number, offset: number, searchParams: URLSearchParams) => {
      return fetchShops(limit, offset, searchParams);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState.pager.isLoading}>
        <ShopListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
