import { apiClient } from 'infra/api/config';

export const exportMandalatMaster = () => {
  apiClient
    .get('/tenant/my/mandalat/mandalat_master/export/', {})
    .then(async (response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
      );

      setTimeout(() => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'マンダラートマスタ.csv');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 0);
    });
};
