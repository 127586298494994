import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { useNavigate, useParams } from 'react-router-dom';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { BrandForm } from 'context/corporation/brand/components/BrandForm';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { PropsWithChildren } from 'react';
import { ResponseBrandOfResponsePaginatedCorporation } from 'context/corporation/brand/api/types';
import { updateBrand } from 'context/corporation/brand/api/updateBrand';
import { AxiosError } from 'axios';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';

const schema = z.object({
  businessType: z.string(),
  name: z.string(),
  brandLogo: z.string().optional(),
});

export const BrandEdit = () => {
  const { brandId } = useParams();

  const [{ data: corporation }] = useFetchCorporation();
  const brand =
    corporation?.brands?.find(
      (_brand) => _brand.id === parseInt(brandId || '', 10)
    ) || null;

  if (brand === null) {
    return (
      <div>
        <PageTitle
          title="業態詳細"
          breadcrumbs={[
            ['ホーム', '/'],
            ['業態一覧', '/brands'],
          ]}
        />
        <LoadingBackdrop isShow />
      </div>
    );
  }

  return (
    <div>
      <PageTitle
        title="業態詳細"
        breadcrumbs={[
          ['ホーム', '/'],
          ['業態一覧', '/brands'],
        ]}
      />
      <BrandEditLoaded brand={brand} />
    </div>
  );
};

export const BrandEditLoaded = ({
  brand,
}: PropsWithChildren<{
  brand: ResponseBrandOfResponsePaginatedCorporation;
}>) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();

  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      businessType: brand.businessType,
      name: brand.name,
      brandLogo: brand.brandLogo,
    } as z.infer<typeof schema>,
  });

  const onSubmit = useFormInstance.handleSubmit((value) => {
    startLoading('edit_brand');

    const requestForm = { ...value };
    // 変更されていない場合は未変更
    if (value.brandLogo?.startsWith('http')) {
      requestForm.brandLogo = undefined;
    }

    updateBrand(brand.id, requestForm)
      .then(() => {
        displayMessage({ text: '業態を更新しました', isInterruption: false });
        navigate('/brands');
      })
      .catch((error: AxiosError<any>) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('edit_brand');
      });
  });

  return (
    <div className="flex items-center mt-[50px] rounded bg-grayscale-100">
      <BrandForm
        onSubmit={onSubmit}
        useForm={useFormInstance}
        actionLabel="更新"
      />
    </div>
  );
};
