import { Avatar, BoxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BaseSpeechBubble } from 'context/users/onsite_users/_infra/components/StaffDetails/StaffPickUpInfo/BaseSpeechBubble';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { OnsiteUserDetailResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { useState } from 'react';
import { COLORS } from 'tailwind.config';
import { colorToRgba } from 'context/mvps/types/mvpCategory';
import { OnePeopleIcon } from 'context/users/onsite_users/_infra/components/StaffDetails/StaffPickUpInfo/OnePeopleIcon';
import { NavigateBeforeIcon } from 'infra/components/UI/Icons/NavigateBeforeIcon';
import { WealthLabel } from 'context/wealth/WealthLabel/WealthLabel';
import { EvaluateeListModal } from './EvaluateeListModal';

export const StaffPickUpInfo = (props: {
  onsiteUser: OnsiteUserDetailResponse;
}) => {
  const [isShowEvaluateeListModal, setIsShowEvaluateeListModal] =
    useState(false);

  const user = props.onsiteUser;

  const navigate = useNavigate();
  return (
    <div className="py-[40px] px-[20px] w-[300px] bg-grayscale-100 h-full">
      <button
        className="flex items-center mb-[40px] text-base text-main-blue"
        type="button"
        onClick={() => navigate(-1)}
      >
        <NavigateBeforeIcon />
        <p className="text-base">
          {user.appUsageState === 'withdrawal' ? '退職者' : 'スタッフ'}
          一覧へ戻る
        </p>
      </button>
      <div className="flex flex-col">
        <div className="flex flex-col mb-[30px]">
          <CenteringBox>
            <div className="mb-[16px]">
              <Avatar
                style={{ width: 96, height: 96 }}
                src={user.avatar || ''}
              />
            </div>
          </CenteringBox>
          <CenteringBox>
            <p className="text-base font-bold mb-[20px]">
              {user.lastName} {user.firstName} さん
            </p>
          </CenteringBox>
          <CenteringBox>
            {user.isManager && (
              <div className="flex items-center gap-[6px] bg-soft-blue rounded px-[4px] mb-[20px]">
                <OnePeopleIcon style={{ width: 10 }} />
                <p className="text-label font-bold text-main-blue">評価者</p>
              </div>
            )}
          </CenteringBox>
          <div className="text-center flex flex-col items-center">
            <p className="text-label text-grayscale-700">
              {user.occupation.name} / {user.grade.position.name}
            </p>
            <p className="text-label text-grayscale-700 mt-[4px]">
              {user.grade.name}
            </p>
            <p className="mt-[4px]">
              <WealthLabel label={user.wealthProfileResult} />
            </p>
          </div>

          <CenteringBox>
            <div className="text-center mt-[20px]">
              <p className="text-label">所属店舗</p>
              <p className="text-note font-bold mt-[6px]">{user.shop.name}</p>
            </div>
          </CenteringBox>

          {user.evaluableShops.length > 0 && (
            <CenteringBox>
              <div className="text-center mt-[14px] p-[18px] w-full bg-grayscale-400 rounded">
                <p className="text-label">評価店舗</p>
                <ul className="mt-[8px]">
                  {user.evaluableShops.map((shop) => (
                    <li
                      className="text-label font-bold mt-[6px]"
                      key={shop.shopId}
                    >
                      {shop.name}
                    </li>
                  ))}
                </ul>
              </div>
            </CenteringBox>
          )}
          <CenteringBox>
            <div className="text-center mt-[20px]">
              <p className="text-label">給与額</p>
              <span className="font-bold">
                {user.onsiteUserHr.salary.toLocaleString()}円
              </span>
            </div>
          </CenteringBox>

          <CenteringBox>
            {user.allowances.length > 0 && (
              <ul
                className="
                  flex flex-col w-full  mt-[10px] p-[18px] gap-[10px]
                  bg-grayscale-400 rounded text-center
                "
              >
                {user.allowances.map((allowance: any) => {
                  return (
                    <li className="flex flex-col items-center justify-center">
                      <p className="text-label text-grayscale-800 mb-[4px]">
                        {allowance.name}
                      </p>
                      <p className="text-meta font-bold">
                        {allowance.amount.toLocaleString()}円
                      </p>
                    </li>
                  );
                })}
              </ul>
            )}
          </CenteringBox>
        </div>

        <BaseSpeechBubble color={colorToRgba(COLORS.MAIN_BLUE, 0.1)}>
          <div className="flex gap-[14px]">
            <Avatar
              src={user.evaluator.avatar}
              style={{
                width: 48,
                height: 48,
                border: `solid 2px ${COLORS.GRAY_100}`,
              }}
            />
            <div className="flex flex-col gap-[2px]">
              <p className="text-label text-main-blue font-bold">評価者</p>
              <p className="flex items-end text-meta font-bold">
                {user.evaluator.lastName} {user.evaluator.firstName}
                <p className="text-label ml-[4px]">さん</p>
              </p>
              <p className="text-label text-grayscale-700">
                {user.bossId
                  ? `${user.evaluator?.occupation} / ${user.evaluator?.grade}`
                  : ''}
                {user.outsideManagerId ? `${user.evaluator?.position}` : ''}
              </p>
            </div>
          </div>
        </BaseSpeechBubble>

        {user.isManager && (
          <div className="flex justify-center mt-[30px]">
            <BaseButton
              className="p-button-small w-btn-medium bg-grayscale-100 border-1 border-main-blue rounded text-ui text-main-blue font-normal"
              startIcon={<OnePeopleIcon style={{ width: 14 }} />}
              onClick={() => setIsShowEvaluateeListModal(true)}
            >
              被評価者一覧
            </BaseButton>
          </div>
        )}

        {isShowEvaluateeListModal && (
          <EvaluateeListModal
            evaluateeList={user.evaluateeOnsiteUsers}
            onClose={() => {
              setIsShowEvaluateeListModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

const CenteringBox = (props: BoxProps) => (
  <div className="flex justify-center">{props.children}</div>
);
