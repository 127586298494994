import { components } from 'infra/api/schema';

// eslint-disable-next-line consistent-return
export const getStamp = (stamp: components['schemas']['StampEnum']) => {
  switch (stamp) {
    case 'ok':
      return '👌' as const;
    case 'heart':
      return '❤️' as const;
    case 'clap':
      return '👏' as const;
    case 'pray':
      return '🙏' as const;
    case 'wink':
      return '😉' as const;
    case 'up':
      return '👍' as const;
    case 'v':
      return '✌️' as const;
    case 'handsUp':
      return '🙌' as const;
    case 'minusPoint':
      return '⚠️' as const;
    case 'smile':
      return '😄' as const;
    default:
  }
  ((param: never) => {
    throw new Error('`stamp`が不正な値です', param);
  })(stamp);
};
