import { useState, createElement } from 'react';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { FilterListIcon } from 'infra/components/UI/Icons/FilterListIcon';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { KeywordInputFilter } from 'infra/components/UI/Table/Pagination/Filter/KeywordInputFilter';
import { FilterCategorySelector } from 'infra/components/UI/Table/Pagination/Filter/FilterCategorySelector';
import { useFilterAreaContext } from 'infra/components/UI/Table/Pagination/Pagination';

type Props = {
  setIsOpenSaveModal: (isOpen: boolean) => void;
  setEditingOutsideManagerId: (id: string | null) => void;
};

export const OutsideManagerFilterArea = ({
  setIsOpenSaveModal,
  setEditingOutsideManagerId,
}: Props) => {
  const pagerState = useFilterAreaContext();

  const FormSelectBoxSettings = [
    {
      name: 'マネジャー評価者名',
      component: KeywordInputFilter,
      params: () => {
        return {
          searchKey: 'user_name',
          data: {},
          pagerState,
        };
      },
    },
    {
      name: '職位',
      component: KeywordInputFilter,
      params: () => {
        return {
          searchKey: 'position__contains',
          data: {},
          pagerState,
        };
      },
    },
  ];

  const [selectedForm, setSelectedForm] = useState(FormSelectBoxSettings[0]);
  const switchSelectedFilterType = (selectedItem: string) => {
    const activeFormType =
      FormSelectBoxSettings.find((item) => item.name === selectedItem) ||
      FormSelectBoxSettings[0];
    setSelectedForm(activeFormType);
  };

  return (
    <div className="pt-[50px] flex justify-between items-center">
      <p className="text-base text-grayscale-700">
        全{pagerState.pager.totalCount}
        件中 {pagerState.pager.start_num}〜{pagerState.pager.end_num}
        件を表示
      </p>
      <div className="flex items-center gap-[14px]">
        <BaseButton
          className="w-btn-small p-button-small tracking-short-text text-base"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingOutsideManagerId(null);
            setIsOpenSaveModal(true);
          }}
        >
          登録
        </BaseButton>

        <div className="flex items-center">
          <FilterListIcon />
          <p className="text-ui text-grayscale-700 pl-[10px]">絞り込み</p>
        </div>

        <button
          type="button"
          className="text-meta text-main-blue px-[6px] py-[10px]"
          onClick={() => {
            pagerState.resetSearchParamsCondition();
            switchSelectedFilterType(FormSelectBoxSettings[0].name);
          }}
        >
          検索条件のクリア
        </button>

        <div>
          <div className="flex items-center gap-[10px]">
            <FilterCategorySelector
              filterCategory={FormSelectBoxSettings}
              onFilterChange={(selectedItem) => {
                pagerState.resetSearchParamsCondition();
                switchSelectedFilterType(selectedItem);
              }}
              selectedFormName={selectedForm.name}
            />
            {createElement(selectedForm.component, selectedForm.params())}
          </div>
        </div>
      </div>
    </div>
  );
};
