/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuItem, Avatar } from '@mui/material';
import { Button } from '@mui/base/Button';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { any, z } from 'zod';
import { EMPLOYMENT_STATUS_LIST } from 'context/users/onsite_users/_infra/state/const/employmentStatus';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { FieldAddButton } from 'infra/components/UI/Buttons/FieldAddButton';
import { useFetchShops } from 'context/corporation/shop/api/fetchShops';
import {
  useGetOnsiteUsers,
  OnsiteUserDetailResponse,
} from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { getOutsideManagers } from 'context/users/outside_managers/api/outsideManagers.get';
import { BaseSelectField } from 'infra/components/UI/Forms/BaseSelectField';
import { FieldDeleteIcon } from 'infra/components/UI/Icons/FieldDeleteIcon';
import {
  DeleteOrSuspendModal,
  state as DeleteOrSuspendModalState,
} from 'context/users/onsite_users/_infra/components/Modal/DeleteOrSuspendModal';
import { ResponseShop } from 'context/corporation/shop/api/typeResponse';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { Select } from 'infra/components/UI/Forms/Select';
import { Switch } from 'infra/components/UI/Forms/Switch';
import { Checkbox } from 'infra/components/UI/Forms/Checkbox';
import { Radio } from 'infra/components/UI/Forms/Radio';
import { SelectSearch } from 'infra/components/UI/Forms/SelectSearch';
import { numberSchemaWithOutNullable } from 'infra/config/custom.scheme';
import { ErrorMessage } from 'infra/components/UI/Forms/ErrorMessage';
import { ProfileTestModal } from '../Modal/ProfileTestModal';

export const StaffFormSchema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    firstNameKana: z
      .string()
      .min(1)
      .regex(/^[\u30A0-\u30FF\uFF65-\uFF9F\s]+$/, {
        message: 'カタカナで入力ください。',
      }),
    lastNameKana: z
      .string()
      .min(1)
      .regex(/^[\u30A0-\u30FF\uFF65-\uFF9F\s]+$/, {
        message: 'カタカナで入力ください。',
      }),
    sex: z.enum(['0', '1'], {
      required_error: '性別を選択してください',
      invalid_type_error: '性別を選択してください',
    }),
    phoneNumber: z.string().min(10).max(11),
    email: z.string(),
    yearOfjoinedAt: z.string().min(4, '4桁の年を入力してください'),
    monthOfjoinedAt: z.string().min(2, '2桁の月を入力してください'),
    dayOfjoinedAt: z.string().min(2, '2桁の日を入力してください'),

    isManager: z.boolean().default(false),
    isSuspended: z.boolean().default(false),
    occupationId: z.number().min(1),
    subOccupationId: z.number().optional().nullable(),
    gradeId: z.number().min(1),
    allowances: z
      .array(
        z.object({
          name: z.string().min(0),
          amount: any(),
        })
      )
      .refine(
        (v) => {
          for (let i = 0; i < v.length; i += 1) {
            // 両方入力されていない場合は許可して次のtransformで除外
            if (!v[i].name && !v[i].amount) {
              return true;
            }

            // 片方しか入力ないのはエラー
            if (!v[i].name || !v[i].amount) {
              return false;
            }
          }

          return true;
        },
        {
          message:
            '「手当て名」 または 「手当て金額」を両方入力するか、空にしてください。',
          path: ['amount'],
        }
      )
      .transform((v) => {
        return v
          .filter((a) => {
            return a.name && a.amount;
          })
          .map((a) => {
            return {
              name: a.name,
              amount: Number(a.amount),
            };
          });
      }),
    shopId: z.string().min(1),
    salary: numberSchemaWithOutNullable,
    bossId: z.string(),
    outsideManagerId: z.string(),
    employmentStatus: z.string().min(1),
    isEvaluableShops: z.boolean().default(false),
    evaluableShops: z.array(
      z.object({
        shopId: z.string().min(0),
      })
    ),
    yearOfunsubscribeAt: z
      .string()
      .min(4, '4桁の年を入力してください')
      .optional()
      .or(z.literal('')),
    monthOfunsubscribeAt: z
      .string()
      .min(2, '2桁の月を入力してください')
      .optional()
      .or(z.literal('')),
    dayOfunsubscribeAt: z
      .string()
      .min(2, '2桁の日を入力してください')
      .optional()
      .or(z.literal('')),
  })
  .refine(
    (data) => {
      const date = new Date(
        `${data.yearOfjoinedAt}-${data.monthOfjoinedAt.padStart(
          2,
          '0'
        )}-${data.dayOfjoinedAt.padStart(2, '0')}`
      );
      return !Number.isNaN(date.getTime());
    },
    {
      message: '存在する年月日を入力してください。',
      path: ['dayOfjoinedAt'],
    }
  )
  .refine(
    (data) => {
      const date = new Date(
        `${data.yearOfunsubscribeAt}-${data.monthOfunsubscribeAt?.padStart(
          2,
          '0'
        )}-${data.dayOfunsubscribeAt?.padStart(2, '0')}`
      );
      // return !Number.isNaN(date.getTime());
      return true;
    },
    {
      message: '存在する年月日を入力してください。',
      path: ['dayOfunsubscribeAt'],
    }
  );

type TypeStaffForm = z.infer<typeof StaffFormSchema>;

export const StaffForm = (
  props: PropsWithChildren<{
    useForm: UseFormReturn<TypeStaffForm>;
    onSubmit: () => void;
    actionLabel: string;
    avatarImage?: string;
    onsiteUser?: OnsiteUserDetailResponse & {
      wealthProfileResult?: string;
      wealthProfileUnderDiagnosis?: boolean;
    };
    isEdit?: boolean;
  }>
) => {
  const navigate = useNavigate();
  const [{ data: corporation }] = useFetchCorporation();
  const [{ data: shops }] = useFetchShops();
  const [{ data: staffResponse }] = useGetOnsiteUsers();
  const [{ data: outsideManagerResponse }] = getOutsideManagers();

  const outsideManages = outsideManagerResponse?.results || [];
  const staffs = staffResponse?.results || [];

  const subOccupations = corporation?.occupations.find((o) => {
    return o.id === Number(props.useForm.watch('occupationId'));
  })?.subOccupations;

  const uniquePostionList = [
    ...new Set(corporation?.positions?.map((p) => p.employmentStatus)),
  ].map((status) => ({
    id: status,
    name: EMPLOYMENT_STATUS_LIST.find((e) => e.id === status)?.name,
  }));

  const allowancesField = useFieldArray({
    name: 'allowances',
    control: props.useForm.control,
  });

  const evaluableShopsField = useFieldArray({
    name: 'evaluableShops',
    control: props.useForm.control,
  });

  const deleteOrSuspendModalState = DeleteOrSuspendModalState.local();

  const isManager = props.useForm.watch('isManager');
  const shopId = props.useForm.watch('shopId');
  const evaluableShops = props.useForm.watch('evaluableShops');
  const isEvaluableShops = props.useForm.watch('isEvaluableShops');
  const gradeId = props.useForm.watch('gradeId');

  const [evaluableShopsSelect, setEvaluableShopsSelect] = useState<any>({});
  const [evaluableShopsFormItemList, setEvaluableShopsFormItemList] = useState<
    any[]
  >([]);

  const [isShowModalProfileType, setIsShowModalProfileType] = useState(false);

  useEffect(() => {
    setEvaluableShopsFormItemList(shops?.results!);
  }, [shopId, shops?.results, evaluableShops, evaluableShopsSelect]);

  useEffect(() => {
    if (deleteOrSuspendModalState.isDeletedCompleted) {
      navigate('/staffs_manage/staffs/');
    }
  }, [deleteOrSuspendModalState.isDeletedCompleted]);

  function getWageTypeLabel() {
    let label = '月給';

    corporation?.positions?.forEach((p) => {
      p.grades.forEach((g) => {
        if (g.id === Number(gradeId)) {
          label = g.wageType === 'monthly' ? '月給' : '時給';
        }
      });
    });

    return label;
  }

  return (
    <div className="flex flex-col p-[40px] gap-[40px]">
      <p className="text-base font-bold pb-0">スタッフ名</p>
      <Avatar
        sx={{ width: 96, height: 96 }}
        alt="アバター画像"
        src={props.avatarImage}
      />
      <div className="flex gap-[20px]">
        <div>
          <p className="text-base font-bold pb-[20px]">姓</p>
          <TextField
            useForm={props.useForm}
            name="lastName"
            isErrorFiled={!!props.useForm.formState.errors.lastName}
            className="w-[160px]"
          />
        </div>

        <div>
          <p className="text-base font-bold pb-[20px]">名</p>
          <TextField
            useForm={props.useForm}
            name="firstName"
            isErrorFiled={!!props.useForm.formState.errors.firstName}
            className="w-[160px]"
          />
        </div>
      </div>
      <div className="flex gap-[20px]">
        <div>
          <p className="text-base font-bold pb-[20px]">姓（フリガナ）</p>
          <TextField
            useForm={props.useForm}
            name="lastNameKana"
            isErrorFiled={!!props.useForm.formState.errors.lastNameKana}
            className="w-[160px]"
          />
        </div>

        <div>
          <p className="text-base font-bold pb-[20px]">名（フリガナ）</p>
          <TextField
            useForm={props.useForm}
            name="firstNameKana"
            isErrorFiled={!!props.useForm.formState.errors.firstNameKana}
            className="w-[160px]"
          />
        </div>
      </div>
      <div>
        <p className="text-base font-bold pb-[10px]">性別</p>
        <div className="flex items-center gap-[60px]">
          <Radio name="sex" useForm={props.useForm} value={0}>
            男性
          </Radio>
          <Radio name="sex" useForm={props.useForm} value={1}>
            女性
          </Radio>
        </div>
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">携帯電話番号</p>
        <TextField
          useForm={props.useForm}
          name="phoneNumber"
          isErrorFiled={!!props.useForm.formState.errors.phoneNumber}
          className="w-[320px]"
        />
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">メールアドレス</p>
        <TextField
          useForm={props.useForm}
          name="email"
          isErrorFiled={!!props.useForm.formState.errors.email}
          className="w-[320px]"
          type="email"
        />
      </div>
      <Divider />
      {props.isEdit && (
        <>
          <div>
            <p className="text-base font-bold mb-[20px]">
              才能プロファイル診断
            </p>
            <p className="text-ui mb-[10px]">
              診断ステータス：
              {(() => {
                if (props.onsiteUser?.wealthProfileUnderDiagnosis) {
                  return '診断中';
                }

                if (!props.onsiteUser?.wealthProfileResult) {
                  return '未診断';
                }

                return '診断済';
              })()}
            </p>
            <p className="text-ui mb-[10px]">
              プロファイル名：
              {props.onsiteUser?.wealthProfileResult
                ? props.onsiteUser?.wealthProfileResult
                : 'プロファイル診断がありません'}
            </p>
            <p
              className="w-[52px] p-[12px] text-center text-main-blue text-meta border border-sub-blue rounded base-hover"
              onClick={() => {
                setIsShowModalProfileType(true);
              }}
            >
              診断
            </p>

            <ProfileTestModal
              isModalOpen={isShowModalProfileType}
              setIsModalOpen={setIsShowModalProfileType}
              onsiteUser={props.onsiteUser}
            />
          </div>
          <Divider />
        </>
      )}
      <div className="flex items-center gap-[40px]">
        <div>
          <p className="text-base font-bold pb-[20px]">入社日</p>
          <div className="flex items-center space-x-[5px]">
            <TextField
              useForm={props.useForm}
              name="yearOfjoinedAt"
              isErrorFiled={false}
              className="w-[60px]"
              maxLength={4}
            />
            <span>年</span>
            <TextField
              useForm={props.useForm}
              name="monthOfjoinedAt"
              isErrorFiled={false}
              className="w-[45px]"
              maxLength={2}
            />
            <span>月</span>
            <TextField
              useForm={props.useForm}
              name="dayOfjoinedAt"
              isErrorFiled={false}
              className="w-[45px]"
              maxLength={2}
            />
            <span>日</span>
          </div>

          <div>
            <ErrorMessage
              message={props.useForm.formState.errors.yearOfjoinedAt?.message!}
            />
            <ErrorMessage
              message={props.useForm.formState.errors.monthOfjoinedAt?.message!}
            />
            <ErrorMessage
              message={props.useForm.formState.errors.dayOfjoinedAt?.message!}
            />
          </div>
        </div>

        <div>
          <p className="text-base font-bold pb-[20px]">退社日</p>
          <div className="flex items-center space-x-[5px]">
            <TextField
              useForm={props.useForm}
              name="yearOfunsubscribeAt"
              isErrorFiled={false}
              className="w-[60px]"
              maxLength={4}
            />
            <span>年</span>
            <TextField
              useForm={props.useForm}
              name="monthOfunsubscribeAt"
              isErrorFiled={false}
              className="w-[45px]"
              maxLength={2}
            />
            <span>月</span>
            <TextField
              useForm={props.useForm}
              name="dayOfunsubscribeAt"
              isErrorFiled={false}
              className="w-[45px]"
              maxLength={2}
            />
            <span>日</span>
          </div>

          <div>
            <ErrorMessage
              message={
                props.useForm.formState.errors.yearOfunsubscribeAt?.message!
              }
            />
            <ErrorMessage
              message={
                props.useForm.formState.errors.monthOfunsubscribeAt?.message!
              }
            />
            <ErrorMessage
              message={
                props.useForm.formState.errors.dayOfunsubscribeAt?.message!
              }
            />
          </div>
        </div>
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">所属店舗</p>
        <Select
          useForm={props.useForm}
          name="shopId"
          menuItems={shops?.results.map(({ id, name }) => {
            return {
              label: name,
              value: id,
            };
          })}
          isErrorFiled={!!props.useForm.formState.errors.shopId}
          order="labelAsc"
        />
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">雇用形態</p>
        <Select
          useForm={props.useForm}
          name="employmentStatus"
          menuItems={uniquePostionList.map(({ id, name }) => {
            return {
              label: name,
              value: id,
            };
          })}
          isErrorFiled={!!props.useForm.formState.errors.employmentStatus}
        />
      </div>
      <div className="flex items-center gap-[40px]">
        <div>
          <p className="text-base font-bold pb-[20px]">職種</p>
          <Select
            useForm={props.useForm}
            name="occupationId"
            menuItems={corporation?.occupations.map(({ id, name }) => {
              return {
                label: name,
                value: id,
              };
            })}
            isErrorFiled={!!props.useForm.formState.errors.occupationId}
            order="labelAsc"
          />
        </div>

        <div>
          <p className="text-base font-bold pb-[20px]">サブ職種</p>
          <Select
            useForm={props.useForm}
            name="subOccupationId"
            menuItems={subOccupations?.map(({ id, name }) => {
              return {
                label: name,
                value: id,
              };
            })}
            isErrorFiled={!!props.useForm.formState.errors.subOccupationId}
            order="labelAsc"
          />
        </div>
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">等級</p>
        <Select
          useForm={props.useForm}
          name="gradeId"
          menuItems={corporation?.positions
            ?.flatMap((p) => p.grades)
            .map((g) => {
              return {
                label: g.name,
                value: g.id,
              };
            })}
          isErrorFiled={!!props.useForm.formState.errors.gradeId}
        />
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">基本給</p>
        <div className="flex items-center">
          <TextField
            useForm={props.useForm}
            name="salary"
            isErrorFiled={!!props.useForm.formState.errors.salary}
            className="w-[320px]"
            asNumeric
          />
          <span className="pl-[10px]">円</span>
        </div>
      </div>
      <div>
        <p className="text-base font-bold pb-[20px]">手当</p>
        <p className="text-meta text-main-red pb-[10px]">
          {props.useForm.formState?.errors?.allowances?.message}
        </p>
        {allowancesField.fields.map((filed, index) => {
          return (
            <div className="flex mb-[10px] items-center gap-[40px]">
              <div>
                <TextField
                  // key={filed.id}
                  useForm={props.useForm}
                  name={`allowances.${index}.name`}
                  isErrorFiled={
                    !!props.useForm.formState.errors.allowances?.[index]?.name
                  }
                  className="w-[320px]"
                  placeholder="手当名を入力してください"
                />
              </div>
              <div>
                <div className="flex items-center">
                  <TextField
                    // key={filed.id}
                    useForm={props.useForm}
                    name={`allowances.${index}.amount`}
                    isErrorFiled={
                      !!props.useForm.formState.errors?.allowances?.[index]
                        ?.amount
                    }
                    className="w-[320px]"
                    asNumeric
                    placeholder="手当名を入力してください"
                  />
                  <span className="ml-[10px]">円</span>
                  {index !== 0 && (
                    <div className="ml-[20px] leading-none base-hover">
                      <FieldDeleteIcon
                        style={{ width: '20px', height: '20px' }}
                        onClick={() => allowancesField.remove(index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div className="mt-[20px]">
          <FieldAddButton
            label="手当を追加"
            onClickHandler={() =>
              allowancesField.append({ name: '', amount: 0 })
            }
          />
        </div>
      </div>
      <Divider />
      <div>
        <p className="text-base font-bold pb-[20px]">権限</p>
        <div className="flex items-center gap-[40px]">
          <p className="text-base text-grayscale-700">マネジャー（評価者）</p>
          <Switch name="isManager" useForm={props.useForm} />
        </div>

        {isManager && (
          <div>
            <div className="flex items-center mt-[20px] mb-[20px]">
              <Checkbox
                name="isEvaluableShops"
                useForm={props.useForm}
                className="p-[0px]"
                id="multipleManager"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className="text-ui pl-[30px] no-selection-bg"
                htmlFor="multipleManager"
              >
                所属店舗以外の店舗でも評価者として設定する
              </label>
            </div>

            {isEvaluableShops && (
              <>
                <p className="text-base font-bold pb-[20px] mt-[30px]">
                  所属店舗以外の評価店舗
                </p>
                <div>
                  {evaluableShopsField.fields.map((filed, index) => {
                    return (
                      <div className="flex items-center mb-[15px]">
                        <BaseSelectField
                          size="small"
                          // error={
                          //   props.useForm.formState?.errors?.evaluableShops?.[index]
                          //     ?.name !== undefined
                          // }
                          // helperText={
                          //   props.useForm.formState?.errors?.evaluableShops?.[index]?.name
                          //     ?.message
                          // }
                          defaultValue={filed.shopId}
                          {...props.useForm.register(
                            `evaluableShops.${index}.shopId`
                          )}
                          onChange={(e) => {
                            setEvaluableShopsSelect({
                              ...evaluableShopsSelect,
                              [e.target.name]: e.target.value,
                            });
                          }}
                          menuItems={evaluableShopsFormItemList
                            ?.filter((shop) => {
                              if (shopId === shop.id) {
                                return false;
                              }

                              if (
                                shop.id ===
                                evaluableShopsSelect[
                                  `evaluableShops.${index}.shopId`
                                ]
                              ) {
                                return true;
                              }

                              const values =
                                Object.values(evaluableShopsSelect);
                              for (let i = 0; i < values.length; i += 1) {
                                if (shop.id === values[i]) {
                                  return false;
                                }
                              }

                              return true;
                            })
                            .map((shop: ResponseShop) => {
                              return (
                                <MenuItem key={shop.id} value={shop.id}>
                                  {shop.name}
                                </MenuItem>
                              );
                            })}
                        />
                        {index !== 0 && (
                          <div className="ml-[20px] leading-none">
                            <FieldDeleteIcon
                              onClick={() => evaluableShopsField.remove(index)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="mt-[20px]">
                  <FieldAddButton
                    label="店舗を追加"
                    onClickHandler={() =>
                      evaluableShopsField.append({ shopId: '' })
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Divider />
      <div className="pb-[20px]">
        <p className="text-base font-bold pb-[20px]">評価者</p>

        <SelectSearch
          useForm={props.useForm}
          name="bossId"
          menuItems={[
            ...staffs
              .filter((s) => s.isManager)
              .filter((s) => s.id !== props.onsiteUser?.id)
              .map(({ id, lastName, firstName }) => {
                return {
                  label: lastName! + firstName!,
                  value: JSON.stringify({ id, type: 'manager' }),
                };
              }),
            ...outsideManages.map(({ id, lastName, firstName }) => {
              return {
                label: lastName! + firstName!,
                value: JSON.stringify({ id, type: 'outside_manager' }),
              };
            }),
          ]}
          isErrorFiled={!!props.useForm.formState.errors.bossId}
          order="labelAsc"
        />
      </div>

      <Divider className="mt-[40px]" />
      {DeleteOrSuspendModalState.global.isShow(props.onsiteUser!) && (
        <>
          <div className="flex items-center gap-[40px]">
            <p className="text-base text-grayscale-700">アカウントの一時停止</p>
            <div>
              <Switch
                useForm={props.useForm}
                name="isSuspended"
                onClick={() => {
                  deleteOrSuspendModalState.showModal(
                    deleteOrSuspendModalState.isSuspendExecuteCompleted
                      ? 'reactivate'
                      : 'suspend'
                  );
                }}
              />
              {/* checked={
                    deleteOrSuspendModalState.isSuspendExecuteCompleted
                  } */}
            </div>
          </div>

          <DeleteOrSuspendModal
            _state={deleteOrSuspendModalState}
            onsiteUser={props.onsiteUser}
          />
        </>
      )}
      <div className="relative flex items-center justify-center gap-[40px] pt-[40px]">
        {DeleteOrSuspendModalState.global.isShow(props.onsiteUser!) && (
          <BaseButton
            className="
              absolute left-0
              p-button-small w-btn-medium
              text-ui font-normal border border-main-red bg-grayscale-100 text-main-red
            "
            onClick={() => {
              deleteOrSuspendModalState.showModal('delete');
            }}
          >
            アカウントの削除
          </BaseButton>
        )}

        <Button
          className="w-btn-small text-grayscale-700 base-hover"
          onClick={() => navigate(-1)}
        >
          キャンセル
        </Button>

        <BaseButton
          className="w-btn-small text-btn-confirm p-button-small"
          onClick={props.onSubmit}
        >
          {props.actionLabel}
        </BaseButton>
      </div>
    </div>
  );
};
