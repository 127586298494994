import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { RequestCareerPath } from './types';

export const createCareerPath = async (careerPath: RequestCareerPath) => {
  const response = await apiClient.post(
    MY_TENANT_URL('corporation/career_paths/'),
    careerPath
  );
  return response;
};
