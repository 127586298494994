import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import 'index.css';
// import 'config/yup';
import 'infra/config/zod';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { initFirebaseApp } from 'infra/components/Firebase';

// NOTE: react v18から以下のシンタックス
const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// =============================================
initFirebaseApp();
// =============================================
