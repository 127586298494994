import { useState, createElement, useEffect } from 'react';
import { FilterListIcon } from 'infra/components/UI/Icons/FilterListIcon';
import { KeywordInputFilter } from 'infra/components/UI/Table/Pagination/Filter/KeywordInputFilter';
import { SelectBoxFilter } from 'infra/components/UI/Table/Pagination/Filter/SelectBoxFilter';
import { FilterCategorySelector } from 'infra/components/UI/Table/Pagination/Filter/FilterCategorySelector';
import { Link } from 'react-router-dom';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { useFilterAreaContext } from 'infra/components/UI/Table/Pagination/Pagination';
import { getBusinessTypeLabel } from 'context/users/onsite_users/_infra/state/const/businessType';

type BusinessTypeOption = {
  id: string;
  name: string;
};

export const ShopListFilterArea = () => {
  const pagerState = useFilterAreaContext();
  const [{ data: corporation, loading }] = useFetchCorporation();

  const businessTypeOptions = corporation?.brands.reduce<BusinessTypeOption[]>(
    (acc, brand) => {
      const { businessType } = brand;
      const businessTypeLabel = getBusinessTypeLabel(businessType);
      if (!acc.some((item) => item.id === businessType)) {
        acc.push({ id: businessType, name: businessTypeLabel });
      }
      return acc;
    },
    []
  );

  const FormSelectBoxSettings = [
    {
      name: 'ブランド',
      component: SelectBoxFilter,
      params: () => {
        return {
          searchKey: 'brand__id',
          data: corporation?.brands,
          pagerState,
        };
      },
    },
    {
      name: '店舗名',
      component: KeywordInputFilter,
      params: () => {
        return {
          searchKey: 'name__contains',
          data: {},
          pagerState,
        };
      },
    },
    {
      name: '業態',
      component: SelectBoxFilter,
      params: () => {
        return {
          searchKey: 'brand__business_type',
          data: businessTypeOptions,
          pagerState,
        };
      },
    },
  ];

  const [selectedForm, setSelectedForm] = useState(FormSelectBoxSettings[0]);
  const switchSelectedFilterType = (selectedItem: string) => {
    const activeFormType =
      FormSelectBoxSettings.find((item) => item.name === selectedItem) ||
      FormSelectBoxSettings[0];
    setSelectedForm(activeFormType);
  };

  useEffect(() => {
    if (!loading) {
      switchSelectedFilterType(FormSelectBoxSettings[0].name);
    }
  }, [loading]);

  return (
    <div className="pt-[50px] flex justify-between items-center">
      <p className="text-base text-grayscale-700">
        全{pagerState.pager.totalCount}
        件中 {pagerState.pager.start_num}〜{pagerState.pager.end_num}
        件を表示
      </p>
      <div className="flex items-center gap-[14px]">
        <Link to="/shops/create">
          <BaseButton
            className="w-btn-small p-button-small tracking-short-text"
            startIcon={<AddIcon />}
          >
            登録
          </BaseButton>
        </Link>

        <div className="flex items-center">
          <FilterListIcon />
          <p className="text-ui text-grayscale-700 pl-[10px]">絞り込み</p>
        </div>

        <button
          type="button"
          className="text-meta text-main-blue px-[6px] py-[10px]"
          onClick={() => {
            pagerState.resetSearchParamsCondition();
            switchSelectedFilterType(FormSelectBoxSettings[0].name);
          }}
        >
          検索条件のクリア
        </button>

        <div>
          <div className="flex items-center gap-[10px]">
            <FilterCategorySelector
              filterCategory={FormSelectBoxSettings}
              onFilterChange={(selectedItem) => {
                pagerState.resetSearchParamsCondition();
                switchSelectedFilterType(selectedItem);
              }}
              selectedFormName={selectedForm.name}
            />
            {createElement(selectedForm.component, selectedForm.params())}
          </div>
        </div>
      </div>
    </div>
  );
};
