import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  MandalatSquareSettingRules,
  ResponseMandalatMaster,
} from 'context/mandalat/api/typeResponse';
import { PropsWithChildren } from 'react';
import { IndexBox } from 'context/mandalat/components/ImportMandalatMasterCsv/ThemeSetting/IndexBox';
import { EditSubTypeSelect } from 'context/mandalat/components/Modal/EditRuleList/SubTypeSelect';
import { EditBasicThinkingMasterIdSelect } from 'context/mandalat/components/Modal/EditRuleList/BasicThinkingMasterIdSelect';

export const EditRuleList = (props: {
  defaultValue: MandalatSquareSettingRules;
  formValue: MandalatSquareSettingRules;
  handleUpdateFormValue: (newValue: MandalatSquareSettingRules) => void;
  latestMandalatmaster: ResponseMandalatMaster | undefined;
  currentGradeId: number;
}) => {
  const {
    defaultValue,
    formValue,
    handleUpdateFormValue,
    latestMandalatmaster,
    currentGradeId,
  } = props;

  const getFilteredSelectableItemsByCurrentGradeAndIsMust = (index: number) => {
    // 必須で選択できる基礎思考マスターの一覧
    const selectalbleCurrentGradeItemsWithIsMust =
      latestMandalatmaster?.basicThinkingMasters.filter(
        (basicThinkingMaster) => {
          return (
            basicThinkingMaster.selectableBasicThinkingMasters.filter(
              (selectableBasicThinkingMaster) =>
                selectableBasicThinkingMaster.grade.id === currentGradeId &&
                selectableBasicThinkingMaster.isMust
            ).length > 0
          );
        }
      );

    const basicThinkingMasterIdsSelectedInOtherBoxes = formValue
      .filter((_, _index) => _index !== index)
      .filter((rule) => rule.basicThinkingMasterId !== 0) // 任意を除外している
      .map((rule) => rule.basicThinkingMasterId);

    // 選択可能等級の必須テーマから、自身が選択しているものを除外
    const selectableItemsWhichCurrentGradeAndIsMust =
      selectalbleCurrentGradeItemsWithIsMust?.filter(
        (basicThinkingMaster) =>
          !basicThinkingMasterIdsSelectedInOtherBoxes.includes(
            basicThinkingMaster.id
          )
      );

    // 表示用のデータを整形
    return selectableItemsWhichCurrentGradeAndIsMust?.map(
      (basicThinkingMaster) => ({
        type: '必須',
        key: basicThinkingMaster.id.toString(),
        value: basicThinkingMaster.id,
        label: basicThinkingMaster.basicThinkingLabel,
        group: `${basicThinkingMaster.category.categoryLarge}: ${basicThinkingMaster.category.categoryMiddle}`,
      })
    );
  };

  return (
    <TabPanel>
      {defaultValue.map((rule, index) => {
        return (
          <div className="flex items-center gap-[14px]" key={rule.index}>
            <IndexBox>{rule.index}</IndexBox>
            <Select
              sx={{ minWidth: 120 }}
              size="small"
              defaultValue={rule.type}
              value={formValue[index].type}
              displayEmpty
              onChange={(e: SelectChangeEvent) => {
                const newValue = [...formValue];
                newValue[index].type = e.target.value;
                if (formValue[index].type === '未指定') {
                  newValue[index].subType = '';
                  newValue[index].basicThinkingMasterId = null;
                }
                handleUpdateFormValue(newValue);
              }}
            >
              <MenuItem value="" disabled>
                種別を選択してください
              </MenuItem>
              <MenuItem value="未指定" key="未指定">
                未指定
              </MenuItem>
              <MenuItem value="必須" key="必須テーマ">
                必須
              </MenuItem>
              <MenuItem value="選択テーマ" key="選択テーマ">
                選択テーマ
              </MenuItem>
            </Select>
            <EditBasicThinkingMasterIdSelect
              sx={{
                minWidth: 120,
                display: formValue[index].type === '必須' ? 'block' : 'none',
              }}
              size="small"
              currentType={formValue[index].type}
              value={formValue[index].basicThinkingMasterId ?? ''}
              placeholder="テーマを選択してください"
              onChange={(e: SelectChangeEvent<unknown>) => {
                const newValue = [...formValue];
                newValue[index].subType = '';
                newValue[index].basicThinkingMasterId =
                  (e.target.value as number | null) || 0;
                handleUpdateFormValue(newValue);
              }}
              selectableItems={getFilteredSelectableItemsByCurrentGradeAndIsMust(
                index
              )}
            />
            <EditSubTypeSelect
              sx={{
                minWidth: 180,
                display:
                  formValue[index].type === '選択テーマ' ? 'block' : 'none',
              }}
              size="small"
              defaultValue={rule.subType}
              value={formValue[index].subType}
              placeholder="テーマを選択してください"
              onChange={(e: SelectChangeEvent<unknown>) => {
                const newValue = [...formValue];
                newValue[index].subType = e.target.value as string;
                newValue[index].basicThinkingMasterId = null;
                handleUpdateFormValue(newValue);
              }}
              currentType={formValue[index].type}
            />
          </div>
        );
      })}
    </TabPanel>
  );
};

const TabPanel = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return <div className="flex flex-col gap-[20px]">{children}</div>;
};
