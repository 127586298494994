/**
 * スタッフ関連のルーティング定義
 */
import { RouteType } from 'infra/config/routes.types';
import { StaffCreate } from 'context/users/onsite_users/Staff/StaffCreate';
import { StaffDetails } from 'context/users/onsite_users/_infra/components/StaffDetails';
import { StaffEdit } from 'context/users/onsite_users/_infra/components/StaffEdit';
import { StaffList } from 'context/users/onsite_users/Staff/StaffList';
import { UnsubscribedStaffList } from 'context/users/onsite_users/UnSubscribed/UnsubscribedStaffList';
import { Route } from 'react-router-dom';
import { StaffImport } from 'context/users/onsite_users/Staff/StaffImport';

export const StaffListRoute: RouteType = {
  path: '/staffs_manage/staffs/',
  element: <StaffList />,
  do_it: () => (
    <Route path={StaffListRoute.path} element={StaffListRoute.element} />
  ),
};

export const UnsubscribedStaffListRoute: RouteType = {
  path: '/staffs_manage/unsubscribed',
  element: <UnsubscribedStaffList />,
  do_it: () => (
    <Route
      path={UnsubscribedStaffListRoute.path}
      element={UnsubscribedStaffListRoute.element}
    />
  ),
};

export const StaffDetailsRoute: RouteType = {
  path: '/staffs_manage/staffs/:staffId',
  element: <StaffDetails />,
  do_it: () => (
    <Route path={StaffDetailsRoute.path} element={StaffDetailsRoute.element} />
  ),
};

export const StaffEditRoute: RouteType = {
  path: '/staffs_manage/staffs/:staffId/edit',
  element: <StaffEdit />,
  do_it: () => (
    <Route path={StaffEditRoute.path} element={StaffEditRoute.element} />
  ),
};

export const StaffGoalRoute: RouteType = {
  path: '/staffs_manage/staffs/:staffId/goal/:goalId',
  element: <StaffDetails />,
  do_it: () => (
    <Route path={StaffGoalRoute.path} element={StaffGoalRoute.element} />
  ),
};

export const StaffCreateRoute: RouteType = {
  path: '/staffs_manage/staffs/create',
  element: <StaffCreate />,
  do_it: () => (
    <Route path={StaffCreateRoute.path} element={StaffCreateRoute.element} />
  ),
};

export const StaffImportRoute: RouteType = {
  path: '/staffs_manage/staffs/import',
  element: <StaffImport />,
  do_it: () => (
    <Route path={StaffImportRoute.path} element={StaffImportRoute.element} />
  ),
};

export const routes = () => (
  <>
    {StaffListRoute.do_it()}
    {UnsubscribedStaffListRoute.do_it()}
    {StaffDetailsRoute.do_it()}
    {StaffEditRoute.do_it()}
    {StaffDetailsRoute.do_it()}
    {StaffCreateRoute.do_it()}
    {StaffImportRoute.do_it()}
  </>
);
