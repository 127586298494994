import { useState } from 'react';

export const FixMboInfoModalState = () => {
  const [isOpen, setIsOpen] = useState(false);

  const setModalClose = () => {
    setIsOpen(false);
  };

  const setModalOpen = () => {
    setIsOpen(true);
  };

  return {
    ui: {
      isOpen,
      setModalClose,
      setModalOpen,
    },
  };
};
