export const DragIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="11"
      viewBox="0 0 7 11"
    >
      <g
        id="グループ_1"
        data-name="グループ 1"
        transform="translate(-765 -443)"
      >
        <circle
          id="楕円形_1"
          data-name="楕円形 1"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(765 443)"
          fill="#757a80"
        />
        <circle
          id="楕円形_2"
          data-name="楕円形 2"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(765 447)"
          fill="#757a80"
        />
        <circle
          id="楕円形_3"
          data-name="楕円形 3"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(765 451)"
          fill="#757a80"
        />
        <circle
          id="楕円形_4"
          data-name="楕円形 4"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(769 443)"
          fill="#757a80"
        />
        <circle
          id="楕円形_5"
          data-name="楕円形 5"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(769 447)"
          fill="#757a80"
        />
        <circle
          id="楕円形_6"
          data-name="楕円形 6"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(769 451)"
          fill="#757a80"
        />
      </g>
    </svg>
  );
};
