import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/evaluation_periods/corporation/mbos/{id}/';
export type MboPatchRequestBody = Omit<
  SnakeObjToCamelObj<
    NonNullable<
      paths[typeof URL]['patch']['requestBody']
    >['content']['application/json']
  >,
  'id' | 'createdAt' | 'updatedAt'
>;

/**
 * MBOの変更
 * @param useCache
 * @returns
 */
export const patchMbo = (
  mboId: number,
  requestBody: {
    predictedValueSales: number | null;
    predictedValueProfit: number | null;
    predictedValueEs: number | null;
    predictedValueCs: number | null;
    actualValueSales: number | null;
    actualValueProfit: number | null;
    actualValueEs: number | null;
    actualValueCs: number | null;
  }
) => {
  const response = apiClient.patch<any, MboPatchRequestBody>(
    URL.replace('{id}', `${mboId}`),
    requestBody
  );
  return response;
};
