export const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.724"
      height="19.5"
      viewBox="0 0 18.724 19.5"
    >
      <g
        id="コンポーネント_49_3"
        data-name="コンポーネント 49 – 3"
        opacity="0.5"
      >
        <g
          id="_-Product-Icons"
          data-name="🔍-Product-Icons"
          transform="translate(-2.64 -2.25)"
        >
          <g id="ic_fluent_settings_24_regular">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M12.012,2.25a10.582,10.582,0,0,1,2.182.253.75.75,0,0,1,.582.649l.17,1.527a1.384,1.384,0,0,0,1.375,1.233,1.358,1.358,0,0,0,.553-.117l1.4-.615a.75.75,0,0,1,.849.174,9.793,9.793,0,0,1,2.2,3.792.75.75,0,0,1-.271.826l-1.241.915a1.381,1.381,0,0,0,0,2.226l1.243.915a.75.75,0,0,1,.271.826,9.8,9.8,0,0,1-2.2,3.792.75.75,0,0,1-.849.175L16.871,18.2a1.381,1.381,0,0,0-1.926,1.113l-.169,1.527a.75.75,0,0,1-.572.647,9.518,9.518,0,0,1-4.406,0,.75.75,0,0,1-.572-.647L9.058,19.32a1.382,1.382,0,0,0-1.925-1.111l-1.407.616a.75.75,0,0,1-.849-.175,9.8,9.8,0,0,1-2.2-3.8.75.75,0,0,1,.272-.825l1.243-.916a1.381,1.381,0,0,0,0-2.226L2.945,9.973a.75.75,0,0,1-.272-.826,9.793,9.793,0,0,1,2.2-3.792.75.75,0,0,1,.849-.174l1.4.615A1.388,1.388,0,0,0,9.058,4.678l.17-1.526A.75.75,0,0,1,9.811,2.5,10.665,10.665,0,0,1,12.012,2.25Zm0,1.5a9.136,9.136,0,0,0-1.355.117l-.109.977A2.888,2.888,0,0,1,6.525,7.169l-.9-.395A8.293,8.293,0,0,0,4.279,9.092l.8.587a2.881,2.881,0,0,1,0,4.643l-.8.589a8.3,8.3,0,0,0,1.348,2.321l.905-.4a2.882,2.882,0,0,1,4.017,2.318l.109.984a8.018,8.018,0,0,0,2.688,0l.109-.984a2.881,2.881,0,0,1,4.019-2.322l.9.4a8.3,8.3,0,0,0,1.347-2.318l-.8-.588a2.881,2.881,0,0,1,0-4.643l.8-.587a8.293,8.293,0,0,0-1.348-2.318l-.9.394a2.88,2.88,0,0,1-4.023-2.324l-.109-.977A8.99,8.99,0,0,0,12.013,3.75ZM12,8.25A3.75,3.75,0,1,1,8.25,12,3.75,3.75,0,0,1,12,8.25Zm0,1.5A2.25,2.25,0,1,0,14.25,12,2.25,2.25,0,0,0,12,9.75Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
