import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useNavigate } from 'react-router-dom';
import { BrandForm } from 'context/corporation/brand/components/BrandForm';
import { createBrand } from 'context/corporation/brand/api/createBrand';
import { AxiosError } from 'axios';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';

const schema = z.object({
  businessType: z.string().min(1),
  name: z.string().min(1),
  brandLogo: z.string().optional(),
});

export const BrandCreate = () => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const navigate = useNavigate();
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const useFormInstance = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      businessType: 'japaneseFood',
      name: '',
      brandLogo: undefined,
    } as z.infer<typeof schema>,
  });

  const onSubmit = useFormInstance.handleSubmit((value) => {
    startLoading('create_brand');

    createBrand(value)
      .then(() => {
        displayMessage({ text: '業態を登録しました', isInterruption: false });
        navigate('/brands');
      })
      .catch((error: AxiosError<any>) => {
        if (error.response?.data?.nonFieldErrors) {
          displayNonFieldApiError(error);
        }

        const errorDetailMap = error.response?.data as {
          [key: string]: string[];
        };
        if (errorDetailMap) {
          Object.keys(errorDetailMap).forEach((key) => {
            useFormInstance.setError(key as any, {
              type: 'custom',
              message: errorDetailMap[key][0],
            });
          });
        }
      })
      .finally(() => {
        stopLoading('create_brand');
      });
  });

  return (
    <div>
      <PageTitle
        title="業態登録"
        breadcrumbs={[
          ['ホーム', '/'],
          ['業態一覧', '/brands'],
        ]}
      />

      <div className="flex items-center mt-[50px] bg-grayscale-100 rounded">
        <BrandForm
          useForm={useFormInstance}
          onSubmit={onSubmit}
          actionLabel="登録"
        />
      </div>
    </div>
  );
};
