/**
 * 全社ランキング関連ルーティング
 */
import { CorporationRankings } from 'context/corporation/rankings';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const CorporationRankingsRoute: RouteType = {
  path: '/evaluation_info/rankings/',
  element: <CorporationRankings />,
  do_it: () => (
    <Route
      path={CorporationRankingsRoute.path}
      element={CorporationRankingsRoute.element}
    />
  ),
};

export const routes = () => <>{CorporationRankingsRoute.do_it()}</>;
