import { Dialog } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren, useEffect, useState } from 'react';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'react-router-dom';
import { OnsiteUserName } from 'context/objective/components/ObjectiveRecordList/OnsiteUserName';
import { OnsiteUserAffiliation } from 'context/objective/components/ObjectiveRecordList/OnsiteUserAffiliation';
import {
  SuccessResponse,
  getOutsideManagerById,
} from 'context/users/outside_managers/api/outsideManagers.get';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { CalendarInput } from 'infra/components/UI/Forms/CalendarInput';
import { schema, OutsideManagerFormWithPassword, passwordScheme } from '.';

export const OutsideManagerEditDialog = (
  props: PropsWithChildren<{
    editingOutsideManagerId: string | null;
    onClose: () => void;
    onSave: (value: OutsideManagerFormWithPassword) => void;
  }>
) => {
  const isCreate = !props.editingOutsideManagerId;
  const useFormInstance = useForm({
    resolver: zodResolver(isCreate ? schema.merge(passwordScheme) : schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      position: '',
      dateOfBirth: null,
    } as OutsideManagerFormWithPassword,
  });

  const [evaluatorList, setEvaluatorList] = useState<
    SuccessResponse['evaluatorList'] | null
  >(null);

  const onSubmit = useFormInstance.handleSubmit((value) => {
    props.onSave(value);
  });

  if (props.editingOutsideManagerId) {
    const [{ data, loading }] = getOutsideManagerById(
      props.editingOutsideManagerId
    );
    useEffect(() => {
      if (!loading) {
        useFormInstance.setValue('lastName', data?.lastName);
        useFormInstance.setValue('firstName', data?.firstName);
        useFormInstance.setValue('position', data?.position);
        useFormInstance.setValue('email', data?.email);
        useFormInstance.setValue('dateOfBirth', data?.dateOfBirth);

        setEvaluatorList(data?.evaluatorList || null);
      }
    }, [loading]);

    if (loading) {
      return <LoadingBackdrop isShow />;
    }
  }

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={onSubmit}
          >
            保存
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div className="pt-[10px] pb-[40px]">
          <h3 className="text-section-heading font-bold">
            マネジャー評価者設定
          </h3>

          <div className="mb-[30px]">
            <p className="text-base font-bold pt-[30px] pb-[16px]">姓</p>
            <TextField
              useForm={useFormInstance}
              name="lastName"
              isErrorFiled={!!useFormInstance.formState.errors.lastName}
              helperText={useFormInstance.formState.errors.lastName?.message}
              showHelperText={
                !!useFormInstance.formState.errors.lastName?.message
              }
              className="w-[320px]"
            />
          </div>

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">名</p>
            <TextField
              useForm={useFormInstance}
              name="firstName"
              isErrorFiled={!!useFormInstance.formState.errors.firstName}
              helperText={useFormInstance.formState.errors.firstName?.message}
              showHelperText={
                !!useFormInstance.formState.errors.firstName?.message
              }
              className="w-[320px]"
            />
          </div>

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">職位</p>
            <TextField
              useForm={useFormInstance}
              name="position"
              isErrorFiled={!!useFormInstance.formState.errors.position}
              helperText={useFormInstance.formState.errors.position?.message}
              showHelperText={
                !!useFormInstance.formState.errors.position?.message
              }
              className="w-[320px]"
            />
          </div>

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">メールアドレス</p>
            <TextField
              useForm={useFormInstance}
              name="email"
              isErrorFiled={!!useFormInstance.formState.errors.email}
              helperText={useFormInstance.formState.errors.email?.message}
              showHelperText={!!useFormInstance.formState.errors.email?.message}
              className="w-[320px]"
            />
          </div>

          {isCreate && (
            <div className="mb-[30px]">
              <p className="text-base font-bold pb-[16px]">パスワード</p>
              <TextField
                useForm={useFormInstance}
                name="password"
                isErrorFiled={!!useFormInstance.formState.errors.password}
                className="w-[320px]"
                helperText="パスワードは半角英数字8文字以上で入力ください。"
                showHelperText
              />
            </div>
          )}

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">お誕生日</p>
            <CalendarInput
              useForm={useFormInstance}
              name="dateOfBirth"
              isErrorFiled={!!useFormInstance.formState.errors.dateOfBirth}
              className="w-[320px]"
            />
          </div>

          {evaluatorList !== null && (
            <>
              <p className="text-base font-bold pb-[16px]">
                被評価者（マネジャー）
              </p>
              <div className="grid grid-cols-2 gap-[10px]">
                {evaluatorList?.map((item) => (
                  <div key={item.id}>
                    <Link to={`/staffs_manage/staffs/${item.id}`}>
                      <div className="px-[18px] py-[20px] border-1 border-grayscale-500 rounded base-hover">
                        <OnsiteUserName
                          image={item.avatar || ''}
                          fullName={`${item.lastName} ${item.firstName}`}
                        />
                        <OnsiteUserAffiliation
                          shopName={item.shop.name}
                          occupationName={item.occupation.name}
                          gradeName={item.grade.name}
                          positionName={item.grade.position.name}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </BaseModal>
    </Dialog>
  );
};
