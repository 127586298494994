import { useState } from 'react';
import { KeywordInputFilter } from 'infra/components/UI/Table/Pagination/Filter/KeywordInputFilter';
import { useNavigate } from 'react-router-dom';
import { useFetchCorporation } from 'context/corporation/corporation/api/fetchCorporation';
import { useFetchShops } from 'context/corporation/shop/api/fetchShops';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { AddIcon } from 'infra/components/UI/Icons/AddIcon';
import { useFilterAreaContext } from 'infra/components/UI/Table/Pagination/Pagination';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { NumPerPageSelect } from 'infra/components/UI/Table/Pagination/_NumPerPageSelect';
import { Dialog } from '@mui/material';
import { RadioButtonGroup } from 'infra/components/UI/Table/Pagination/Filter/RadioButtonGroup';
import { SearchIcon } from 'infra/components/UI/Icons/SearchIcon';
import { CheckBoxFilter } from 'infra/components/UI/Table/Pagination/Filter/CheckBoxFilter';
import { CsvDownloader } from './CsvDownloader';

const DivideLine = () => {
  return (
    <div
      className="w-full h-[1px] my-[30px]"
      style={{
        backgroundColor: '#DEDEDE',
      }}
    />
  );
};

const FilterAreaModal = (props: any) => {
  const [isModal, setIsModal] = props.modalState;
  const { corporation } = props;
  const { grades } = props;
  const { shops } = props;
  const pagerState = useFilterAreaContext();

  return (
    <Dialog
      open={isModal}
      onClose={() => {
        setIsModal(false);
      }}
      maxWidth={false}
    >
      <div>
        <div className="px-[112px] pt-[60px] max-h-[600px] overflow-y-auto w-[800px]">
          <h1 className="text-section-heading font-bold mb-[40px]">詳細検索</h1>
          <div className="flex items-center">
            <p className="min-w-[110px] text-base font-bold">氏名</p>
            <KeywordInputFilter
              searchKey="user_name"
              pagerState={pagerState}
              placeholder="スタッフ名、マネジャー名を入力"
              className="w-[320px]"
            />
          </div>

          <DivideLine />

          <div className="flex">
            <p className="min-w-[110px] text-base font-bold">職種</p>
            <CheckBoxFilter
              data={corporation?.occupations.map((occupation: any) => {
                return {
                  label: occupation.name,
                  value: occupation.id,
                };
              })}
              searchKey="occupation_id__in"
              pagerState={pagerState}
            />
          </div>

          <DivideLine />

          <div className="flex">
            <p className="min-w-[110px] text-base font-bold">職位・等級</p>
            <CheckBoxFilter
              data={corporation?.positions.map((position: any) => {
                return {
                  label: position.name,
                  value: position.id,
                };
              })}
              searchKey="grade__position__id__in"
              pagerState={pagerState}
            />
          </div>
          {/*
          <DivideLine />

          <div className="flex">
            <p className="min-w-[110px] text-base font-bold">等級</p>
            <CheckBoxFilter
              data={grades?.map((grade: any) => {
                return {
                  label: grade.name,
                  value: grade.id,
                };
              })}
              searchKey="grade_id__in"
              pagerState={pagerState}
            />
          </div> */}

          <DivideLine />

          <div className="flex">
            <p className="min-w-[110px] text-base font-bold">所属店舗</p>
            <CheckBoxFilter
              data={shops?.results.map((shop: any) => {
                return {
                  label: shop.name,
                  value: shop.id,
                };
              })}
              searchKey="shop_id__in"
              pagerState={pagerState}
            />
          </div>

          <DivideLine />

          <div className="flex">
            <p className="min-w-[110px] text-base font-bold">プロファイル</p>
            <CheckBoxFilter
              data={[
                {
                  label: 'クリエイター',
                  value: 'Creator',
                },
                {
                  label: 'スター',
                  value: 'Star',
                },
                {
                  label: 'サポーター',
                  value: 'Supporter',
                },
                {
                  label: 'ディールメーカー',
                  value: 'Deal Maker',
                },
                {
                  label: 'トレーダー',
                  value: 'Trader',
                },
                {
                  label: 'アキュムレーター',
                  value: 'Accumulator',
                },
                {
                  label: 'ロード',
                  value: 'Lord',
                },
                {
                  label: 'メカニック',
                  value: 'Mechanic',
                },
              ]}
              searchKey="wealth_profile"
              pagerState={pagerState}
            />
          </div>

          <DivideLine />

          <div className="flex items-center mb-[30px]">
            <p className="min-w-[110px] text-base font-bold">ステータス</p>
            <RadioButtonGroup
              searchKey="is_suspended"
              data={[
                { id: 'false', name: 'すべて' },
                { id: 'true', name: '一時停止' },
              ]}
              pagerState={pagerState}
            />
          </div>
        </div>

        <div className="shadow-main">
          <div className="py-[30px] gap-[60px] flex justify-center ">
            <button type="button" onClick={() => setIsModal(false)}>
              <p className="text-grayscale-700 font-normal">キャンセル</p>
            </button>

            <BaseButton
              className="w-btn-small tracking-short-text text-btn-large p-button-medium"
              onClick={() => {
                pagerState.setSearchParams((prev: any) => {
                  prev.delete('page');
                  return prev;
                });
                pagerState.doitPaging(1);
                setIsModal(false);
              }}
            >
              検索
            </BaseButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const StaffFilterArea = () => {
  const navigate = useNavigate();
  const pagerState = useFilterAreaContext();
  const [{ data: corporation }] = useFetchCorporation();
  const [{ data: shops }] = useFetchShops();

  const modalState = useState(false);

  const gradesData = corporation?.positions
    .flatMap((position) => position.grades)
    .map((grade) => ({
      id: grade.id,
      name: grade.name,
    }));

  return (
    <div className="pt-[50px] flex justify-between items-center">
      <div className="flex items-center gap-[14px]">
        <p className="text-base text-grayscale-700">
          全{pagerState.pager.totalCount}
          件中 {pagerState.pager.start_num}〜{pagerState.pager.end_num}
          件を表示
        </p>
        <NumPerPageSelect pagerState={pagerState} />
      </div>
      <div className="flex items-center gap-[14px]">
        <MoreMenu
          menuCategoryLabel={
            <BaseButton
              className="w-btn-small p-button-small tracking-short-text text-base"
              startIcon={<AddIcon />}
            >
              登録
            </BaseButton>
          }
          menuItemBoxStyle="w-[168px] left-0 top-[50px]"
          render={[
            {
              label: <span>新規登録</span>,
              onClick: () => {
                navigate('/staffs_manage/staffs/create');
              },
              className: 'text-left pl-[24px] py-[16px]',
            },
            {
              label: <span>一括登録（CSV）</span>,
              onClick: () => {
                navigate('/staffs_manage/staffs/import');
              },
              className: 'text-left pl-[24px] py-[16px]',
            },
          ]}
        />

        <CsvDownloader />

        <BaseButton
          className="
          w-btn-small p-button-small text-ui
          text-main-blue bg-grayscale-100
          border border-main-blue
          min-w-[78px]
          max-w-[78px]
        "
          onClick={() => {
            modalState[1](true);
          }}
        >
          詳細検索
        </BaseButton>

        <button
          type="button"
          className="text-meta text-main-blue px-[6px] py-[10px]"
          onClick={() => {
            pagerState.resetSearchParamsCondition();
          }}
        >
          検索条件のクリア
        </button>

        <KeywordInputFilter
          searchKey="keyword"
          pagerState={pagerState}
          placeholder="氏名、職種、職位、等級、店舗名で検索"
          className="w-[320px]"
          icon={<SearchIcon />}
        />

        <FilterAreaModal
          modalState={modalState}
          corporation={corporation}
          shops={shops}
          grades={gradesData}
        />
      </div>
    </div>
  );
};
