import { useState } from 'react';

export const usePagination = <T>() => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [items, setItems] = useState<T[] | null>(null);

  return [
    { page, totalCount, items },
    {
      movePage: (newPage: number) => {
        setPage(newPage);
        setItems(null);
      },
      reset: () => {
        setPage(1);
        setTotalCount(0);
        setItems(null);
      },
      setItems: (newItems: T[], _totalCount: number) => {
        setTotalCount(_totalCount);
        setItems(newItems);
      },
    },
  ] as const;
};
