export const WarningIcon = () => (
  <svg
    id="icon_alert"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 20.003 20.003"
  >
    <g id="warning" transform="translate(-1.992 -1.5)">
      <g id="ic_fluent_warning_24_regular" transform="translate(1.992 2.5)">
        <path
          id="_-Color"
          data-name="Color"
          d={
            'M10.9,2.774a2.285,2.285,0,0,1,2.973.72l.083.134,7.753,13.623a2.143,2.143,0,0,1,.282,1.06A2.214,2.214,0,0,1,19.9,20.495l-.154.005H4.241a2.3,2.3,0,' +
            '0,1-1.089-.274A2.16,2.16,0,0,1,2.2,17.391l.073-.139L10.025,3.629A2.219,2.219,0,0,1,10.9,2.774Zm9.5,15.184L12.647,4.335a.76.76,0,0,0-1.019-.285.7' +
            '44.744,0,0,0-.235.2l-.057.086L3.585,17.959a.719.719,0,0,0,.293.991.764.764,0,0,0,.268.085l.095.006h15.5a.74.74,0,0,0,.749-.729.712.712,0,0,0-.05' +
            '3-.27l-.041-.083L12.647,4.335Zm-8.407-2.328a.972.972,0,1,1-1,.971A.985.985,0,0,1,11.992,15.631Zm0-7.3a.743.743,0,0,1,.743.63l.007.1,0,4.378a.751' +
            '.751,0,0,1-1.492.1l-.007-.1,0-4.378A.74.74,0,0,1,11.988,8.335Z'
          }
          transform="translate(-1.992 -2.5)"
          fill="#f8bc2c"
        />
      </g>
    </g>
  </svg>
);
