import axios from 'axios';
import { configure } from 'axios-hooks';

export const MY_TENANT_URL = (path: string) => `/tenant/my/${path}`;

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ORIGIN,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000,
  withCredentials: true,
});

configure({ axios: apiClient });

/** @deprecated */
export const createOption = (token: string | null) =>
  ({
    headers: {
      Authorization: token !== null ? `Bearer ${token}` : undefined,
    },
  }) as { headers: { [key: string]: string } };
