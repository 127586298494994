import { useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { Types } from 'infra/components/UI/Table/Pagination/Pagination';
import { HackCloseDropDown } from 'infra/components/UI/Forms/utils/HackCloseDropDown';

type BaseSelectProps = {
  pagerState: Types.PagerState;
};

// １ページ毎に表示する件数
export const DISPLAY_COUNTS = ['10', '20', '50', '100'];

export const NumPerPageSelect = ({ pagerState }: BaseSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex items-center gap-[6px] bg-grayscale-100">
      <div className="relative">
        <button
          type="button"
          className="
            relative w-[80px] py-[6px] pl-[12px] pr-[20px]
            border-1 border-grayscale-600 rounded text-ui text-left
            select-hover
          "
          onClick={() => {
            toggleDropdown();
          }}
        >
          {pagerState.pager.numPerPage}件
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
            <ArrowDownIcon />
          </div>
        </button>
        {isOpen && (
          <div
            className="
              absolute left-0 top-[36px] w-full overflow-y-auto max-h-[200px] z-10 py-[6px]
              flex flex-col border-1 border-grayscale-500 rounded text-base hidden-scrollbar
              shadow-main bg-grayscale-100
            "
          >
            {DISPLAY_COUNTS.map((item) => (
              <button
                type="button"
                key={item}
                className="p-[10px] pulldown-hover bg-selectbox"
                onMouseDown={(event) => {
                  const selectedValue = parseInt(
                    event.currentTarget.textContent!,
                    10
                  );
                  pagerState.updateNumPerPage(selectedValue);
                  toggleDropdown();
                }}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
      <HackCloseDropDown isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
