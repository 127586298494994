export const StarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.997"
      height="19.15"
      viewBox="0 0 19.997 19.15"
    >
      <g
        id="コンポーネント_101_4"
        data-name="コンポーネント 101 – 4"
        opacity="0.5"
      >
        <path
          id="star.1"
          d="M10.788,3.1a1.35,1.35,0,0,1,2.421,0L15.567,7.88l5.273.766a1.35,1.35,0,0,1,.748,2.3l-3.815,3.719.9,5.251a1.35,1.35,0,0,1-1.959,1.423L12,18.863,7.283,21.342a1.35,1.35,0,0,1-1.959-1.423l.9-5.251L2.409,10.949a1.35,1.35,0,0,1,.748-2.3L8.43,7.88ZM12,4.039,9.74,8.615a1.35,1.35,0,0,1-1.016.738l-5.05.734,3.654,3.562a1.35,1.35,0,0,1,.388,1.195l-.863,5.029L11.37,17.5a1.35,1.35,0,0,1,1.256,0l4.517,2.375-.863-5.029a1.35,1.35,0,0,1,.388-1.195l3.654-3.562-5.05-.734a1.35,1.35,0,0,1-1.016-.738Z"
          transform="translate(-2 -2.35)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
