import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { colorToRgba } from 'context/mvps/types/mvpCategory';
import { TwemojiIconFromCodePoint } from 'context/mvps/components/TwemojiIconFromCodePoint';
import { getMvpCategories } from 'context/mvps/api/get.mvpCategory';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import Avatar from '@mui/material/Avatar';
import { EditMvpCategoryModal } from './EditMvpCategoryModal/Index';

const MVP_CATEGORY_GROUP_PHILOSOPHY = '__企業カスタマイズ__';

export const MvpCategorySetting = () => {
  const [{ data, loading }, refresh] = getMvpCategories();

  if (loading) {
    return <LoadingBackdrop isShow />;
  }

  return (
    <div>
      <PageTitle title="MVPカテゴリ設定" breadcrumbs={[['ホーム', '/']]} />
      <div className="mt-[50px] p-main-section bg-grayscale-100">
        <div className="grid gap-[20px]">
          {data?.results
            ?.filter((mvpCategory) => mvpCategory.name !== 'マイナスポイント')
            .map((mvpCategory) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <MvpCategoryItem
                  key={mvpCategory.id}
                  mvpCategory={mvpCategory}
                  onComplated={() => {
                    refresh();
                  }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const MvpCategoryItem = (props: {
  mvpCategory: any;
  onComplated: () => void;
}) => {
  return (
    <div
      className="flex justify-between items-center px-[30px] py-[40px] rounded"
      style={{ backgroundColor: colorToRgba(props.mvpCategory.color, 0.1) }}
    >
      <div
        className="border-r-1 border-grayscale-600"
        style={{ width: `calc(100% - ${30 * 2 + 40 + 1}px)` }}
      >
        <div className="flex items-center mb-[24px]">
          {props.mvpCategory.customIconImage === null ? (
            <div
              className="flex items-center justify-center bg-grayscale-100 w-[48px] h-[48px] rounded-full border-2"
              style={{ borderColor: props.mvpCategory.color }}
            >
              <TwemojiIconFromCodePoint codePoint={props.mvpCategory.icon} />
            </div>
          ) : (
            <div className="w-[48px] h-[48px]">
              <Avatar
                src={props.mvpCategory.customIconImage}
                alt=""
                sx={{ width: '100%', height: '100%' }}
              />
            </div>
          )}
          <p
            className="text-base font-bold ml-[10px]"
            style={{ color: props.mvpCategory.color }}
          >
            {props.mvpCategory.name}
          </p>
        </div>

        {/* 改行をそのまま表示する  */}
        <p className="pl-[4px] text-base break-all whitespace-pre-wrap">
          {props.mvpCategory.description}
        </p>
      </div>
      {props.mvpCategory.group === MVP_CATEGORY_GROUP_PHILOSOPHY && (
        <EditMvpCategoryModal
          mvpCategory={props.mvpCategory}
          onComplated={() => {
            props.onComplated();
          }}
          render={(openModal) => (
            <BaseButton
              className="w-btn-cell p-button-small font-normal"
              onClick={() => {
                openModal();
              }}
            >
              編集
            </BaseButton>
          )}
        />
      )}

      {props.mvpCategory.group !== MVP_CATEGORY_GROUP_PHILOSOPHY && (
        <div className="w-[56px] min-w-[56px] text-note self-center" />
      )}
    </div>
  );
};
