import { Button } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { ResponseBrandOfResponsePaginatedCorporation } from 'context/corporation/brand/api/types';
import { z } from 'zod';
import { Divider } from 'infra/components/UI/Elements/Divider';

import { TimeSelect } from 'context/corporation/shop/components/TimeSelect';
import { Select } from 'infra/components/UI/Forms/Select';
import { TextField } from 'infra/components/UI/Forms/TextField';

export const BusinessDaySchema = z.object({
  dayOfWeek: z.number().min(0).max(6),
  // 12:34 のようなフォーマット
  startedDatetime: z
    .string()
    .regex(
      /^\d{2}:\d{2}$/,
      '開始日時は09:30のような{2桁の時間}:{2桁の分}のフォーマットで入力してください'
    ),
  endedDatetime: z
    .string()
    .regex(
      /^\d{2}:\d{2}$/,
      '終了日時は17:30のような{2桁の時間}:{2桁の分}のフォーマットで入力してください'
    ),
});

export const GradedSalaryByStoresSchema = z.object({
  grade: z.object({
    id: z.number(),
    name: z.string(),
  }),
  salary: z.coerce
    .number({ invalid_type_error: '数値で入力してください。' })
    .min(0)
    .max(9999999),
});

export const ShopFormSchema = z.object({
  brandId: z.number(),
  name: z.string().min(1),
  zipCode: z.string().length(7),
  phoneNumber: z.string().min(10).max(11),
  businessHours: z.array(BusinessDaySchema),
  gradedSalaryByStores: z.array(GradedSalaryByStoresSchema),
  coinAvailabilityStartedDatetime: z
    .string()
    .regex(
      /^\d{2}:\d{2}$/,
      'MVPコイン取得開始時間は09:30のような{2桁の時間}:{2桁の分}のフォーマットで入力してください'
    ),
  coinAvailabilityEndedDatetime: z
    .string()
    .regex(
      /^\d{2}:\d{2}$/,
      'MVPコイン取得終了時間は09:30のような{2桁の時間}:{2桁の分}のフォーマットで入力してください'
    ),
});

type TypeShopForm = z.infer<typeof ShopFormSchema>;

export const ShopForm = (
  props: PropsWithChildren<{
    useForm: UseFormReturn<TypeShopForm>;
    onSubmit: () => void;
    actionLabel: string;
    brands: ResponseBrandOfResponsePaginatedCorporation[];
  }>
) => {
  const navigate = useNavigate();

  const gradedSalaryByStoresField = useFieldArray({
    name: 'gradedSalaryByStores',
    control: props.useForm.control,
  });

  return (
    <div className="p-[40px] w-full">
      <div className="mb-[30px]">
        <h2 className="pb-[16px] font-bold text-base">ブランド</h2>
        <Select
          useForm={props.useForm}
          name="brandId"
          menuItems={props.brands.map((brand) => {
            return {
              label: brand.name,
              value: brand.id,
            };
          })}
          isErrorFiled={!!props.useForm.formState.errors.brandId}
          order="labelAsc"
        />
      </div>

      <div className="mb-[30px]">
        <h2 className="pb-[16px] font-bold text-base">店舗名</h2>
        <TextField
          useForm={props.useForm}
          name="name"
          isErrorFiled={!!props.useForm.formState.errors.name}
          className="w-[320px]"
          placeholder="入力してください"
        />
      </div>

      <div className="mb-[30px]">
        <h2 className="pb-[16px] font-bold text-base">住所</h2>
        <TextField
          useForm={props.useForm}
          name="zipCode"
          isErrorFiled={!!props.useForm.formState.errors.zipCode}
          showHelperText
          helperText="ハイフンなしの半角数字7桁で入力して下さい"
          label="郵便番号"
          className="w-[320px]"
        />
      </div>

      <div className="mb-[30px]">
        <h2 className="pb-[16px] font-bold text-base">電話番号</h2>
        <TextField
          useForm={props.useForm}
          name="phoneNumber"
          isErrorFiled={!!props.useForm.formState.errors.phoneNumber}
          showHelperText
          helperText="ハイフンなしの半角数字10桁もしくは11桁で入力してください"
          className="w-[320px]"
        />
      </div>

      <div>
        <h2 className="pb-[16px] font-bold text-base">MVPコイン取得時間</h2>
        <div className="flex items-center pb-[5px]">
          <TimeSelect
            value={props.useForm.watch('coinAvailabilityStartedDatetime')}
            onChange={(newValue) => {
              props.useForm.setValue(
                'coinAvailabilityStartedDatetime',
                newValue
              );
            }}
          />

          <span className="text-base px-[20px]">-</span>

          <TimeSelect
            value={props.useForm.watch('coinAvailabilityEndedDatetime')}
            onChange={(newValue) => {
              props.useForm.setValue('coinAvailabilityEndedDatetime', newValue);
            }}
          />
        </div>
        <p className="text-ui text-grayscale-700 pt-[10px]">
          設定した時刻の+-5時間が取得可能な有効時間となります。
        </p>
      </div>

      {gradedSalaryByStoresField.fields.length > 0 && (
        <>
          <Divider className="my-[40px]" />

          <div>
            <h2 className="pb-[16px] font-bold text-base">
              パート・アルバイト時給
            </h2>

            {gradedSalaryByStoresField.fields.map((filed, index) => {
              return (
                <div className="flex items-center pb-[20px]">
                  <p className="text-base pr-[40px]">{filed.grade.name}</p>
                  <p className="text-base pr-[20px]">時給</p>

                  <TextField
                    useForm={props.useForm}
                    name={`gradedSalaryByStores.${index}.salary`}
                    isErrorFiled={
                      !!props.useForm.formState?.errors?.gradedSalaryByStores?.[
                        index
                      ]
                    }
                    showHelperText={
                      !!props.useForm.formState?.errors?.gradedSalaryByStores?.[
                        index
                      ]
                    }
                    helperText={
                      props.useForm.formState?.errors?.gradedSalaryByStores?.[
                        index
                      ]?.salary?.message
                    }
                    className="px-[10px] w-[320px]"
                  />

                  <p className="text-base">円</p>
                </div>
              );
            })}
          </div>
        </>
      )}

      <div className="flex justify-center pt-[40px] gap-[40px]">
        <Button
          size="large"
          sx={{ pr: 2, pl: 2, width: '120px' }}
          onClick={() => navigate(-1)}
        >
          <p className="text-grayscale-700 font-normal">キャンセル</p>
        </Button>

        <BaseButton
          className="w-btn-small text-btn-confirm p-button-small"
          onClick={props.onSubmit}
        >
          {props.actionLabel}
        </BaseButton>
      </div>
    </div>
  );
};
