import { apiClient } from 'infra/api/config';

export const exportStaffInfoCsv = () => {
  apiClient
    .get('/tenant/my/user/onsite_users/export/', {})
    .then(async (response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'スタッフ登録情報.csv');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    });
};
