export const WealthLabel = (props: { label?: string }) => {
  const defaultClass =
    'inline-block text-graysclae-800 text-label font-bold rounded bg-opacity-20 p-[4px] text-center';

  const gradientVariants: { [key: string]: string } = {
    サポーター: 'bg-gradient-サポーター',
    ディールメーカー: 'bg-gradient-ディールメーカー',
    トレーダー: 'bg-gradient-トレーダー',
    アキュムレーター: 'bg-gradient-アキュムレーター',
    ロード: 'bg-gradient-ロード',
    メカニック: 'bg-gradient-メカニック',
    スター: 'bg-gradient-スター',
    クリエイター: 'bg-gradient-クリエイター',
  };

  if (!props?.label) {
    return null;
  }

  if (props.label in gradientVariants) {
    return (
      <p className={`${defaultClass} ${gradientVariants[props.label]}`}>
        {props.label}
      </p>
    );
  }

  return (
    <p className={`${defaultClass} ${gradientVariants['サポーター']}`}>
      {props.label}
    </p>
  );
};
