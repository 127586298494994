export const SettingsIcon = () => (
  <svg
    id="コンポーネント_49_8"
    data-name="コンポーネント 49 – 8"
    xmlns="http://www.w3.org/2000/svg"
    width="30.726"
    height="32"
    viewBox="0 0 30.726 32"
  >
    <g id="_-Product-Icons" data-name="🔍-Product-Icons">
      <g id="ic_fluent_settings_24_regular">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M18.021,2.25a17.366,17.366,0,0,1,3.58.415,1.231,1.231,0,0,1,.955,1.065l.279,2.506a2.271,2.271,0,0,0,2.256,2.023A2.229,2.229,0,0,0,26,8.067l2.3-1.01a1.231,1.231,0,0,1,1.393.286,16.071,16.071,0,0,1,3.618,6.223,1.231,1.231,0,0,1-.445,1.355l-2.037,1.5a2.266,2.266,0,0,0,0,3.653l2.039,1.5a1.231,1.231,0,0,1,.446,1.355,16.079,16.079,0,0,1-3.616,6.222,1.231,1.231,0,0,1-1.393.286l-2.308-1.011a2.266,2.266,0,0,0-3.161,1.827l-.277,2.505a1.231,1.231,0,0,1-.938,1.062,15.619,15.619,0,0,1-7.23,0,1.231,1.231,0,0,1-.938-1.062l-.277-2.5a2.268,2.268,0,0,0-3.159-1.823L7.7,29.451a1.231,1.231,0,0,1-1.393-.287A16.079,16.079,0,0,1,2.7,22.935a1.231,1.231,0,0,1,.446-1.355l2.04-1.5a2.266,2.266,0,0,0,0-3.653l-2.039-1.5A1.231,1.231,0,0,1,2.7,13.568,16.071,16.071,0,0,1,6.313,7.345a1.231,1.231,0,0,1,1.393-.286L10,8.069a2.277,2.277,0,0,0,3.168-1.835l.279-2.5a1.231,1.231,0,0,1,.956-1.065A17.5,17.5,0,0,1,18.021,2.25Zm0,2.461A14.993,14.993,0,0,0,15.8,4.9l-.179,1.6a4.738,4.738,0,0,1-6.6,3.815L7.541,9.675a13.609,13.609,0,0,0-2.212,3.8l1.309.963a4.728,4.728,0,0,1,0,7.619l-1.311.966a13.618,13.618,0,0,0,2.212,3.809l1.485-.651a4.729,4.729,0,0,1,6.592,3.8L15.8,31.6a13.158,13.158,0,0,0,4.411,0l.179-1.614a4.728,4.728,0,0,1,6.595-3.811l1.484.65a13.618,13.618,0,0,0,2.211-3.8l-1.309-.965a4.728,4.728,0,0,1,0-7.619l1.308-.964a13.609,13.609,0,0,0-2.212-3.8l-1.471.646a4.725,4.725,0,0,1-6.6-3.813L20.21,4.9A14.752,14.752,0,0,0,18.021,4.711ZM18,12.1a6.154,6.154,0,1,1-6.154,6.154A6.154,6.154,0,0,1,18,12.1Zm0,2.462a3.692,3.692,0,1,0,3.692,3.692A3.692,3.692,0,0,0,18,14.558Z"
          transform="translate(-2.64 -2.25)"
          fill="#048cf2"
        />
      </g>
    </g>
  </svg>
);
