export type EvaluationType = 'middle' | 'final';
export type FromUserType = 'boss' | 'myself';

/**
 * 複数のEvaluationResultから指定の評価を取得するする
 */
export const getScore = (
  evaluationResults: {
    periodType: EvaluationType;
    fromUserType: FromUserType;
    score: number;
  }[],
  evaluationType: EvaluationType,
  fromUserType: FromUserType
) => {
  return (
    evaluationResults.find(
      (e) => e.periodType === evaluationType && e.fromUserType === fromUserType
    )?.score || 0
  );
};
