import twemoji from 'twemoji';
import { CodePoint as MvpCategoryCodePoint } from 'context/mvps/types/mvpCategory';
import { getStamp } from 'context/mvps/types/stamp';

export const TwemojiIconFromCodePoint = (props: {
  codePoint: MvpCategoryCodePoint | ReturnType<typeof getStamp>;
  size?: number;
}) => {
  const { codePoint, size } = props;
  const element = document.createElement('div');
  element.textContent = twemoji.convert.fromCodePoint(codePoint);
  twemoji.parse(element, {
    folder: 'svg',
    ext: '.svg',
    base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/',
  });
  const img = element.querySelector<HTMLImageElement>('img');
  return (
    <img
      src={img?.src}
      alt={img?.alt}
      style={{ width: size ?? 22, height: size ?? 22 }}
    />
  );
};
