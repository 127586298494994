/**
 * カスタムライゾボタングループです。
 */
import { Types } from 'infra/components/UI/Table/Pagination/Pagination';

export const RadioButtonGroup = ({
  data,
  searchKey,
  pagerState,
}: {
  data: {
    id: string;
    name: string;
  }[];
  searchKey: string;
  pagerState: Types.PagerState;
}) => {
  const radioStyle = `
    relative border-grayscale-600 h-[26px] w-[26px] rounded-full cursor-pointer
    before:content-[''] before:absolute before:w-[24px] before:h-[24px] before:rounded-full before:border-grayscale-600
    before:top-1/2 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2
    checked:before:border-main-blue
    after:content-[''] after:absolute after:w-[14px] after:w-[14px] after:bg-main-blue after:rounded-full
    after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:opacity-0
    checked:after:opacity-100
  `;

  return (
    <div className="flex items-center gap-[40px]">
      {data.map((item) => {
        return (
          <div className="flex items-center gap-[10px]">
            <input
              type="radio"
              id={item.id}
              name={searchKey}
              value={item.id}
              onChange={() => {
                pagerState.setSearchParams((prev: any) => {
                  prev.set(searchKey, item.id);
                  return prev;
                });
              }}
              checked={pagerState.searchParams.get(searchKey) === item.id}
              className={radioStyle}
            />
            <label
              htmlFor={item.id}
              className="text-base text-grayscale-700 cursor-pointer"
            >
              {item.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};
