import { createSvgIcon } from '@mui/material/utils';

const d =
  'M22,2A20,20,0,1,1,2,22,20,20,0,0,1,22,2Zm6.439,13.939L19.5,24.879l-3.939-3.939a1.5,1.5,0,1,0-2.121,2.121l5,5a1.5,1.5,0,0,0,2.121,0l10-10a1.5,1.5,0,0' +
  ',0-2.121-2.121Z';

export const ProgressIcon = createSvgIcon(
  <svg
    id="チェック"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 40 40"
  >
    <g id="ic_fluent_checkmark_circle_24_filled">
      <path
        id="_-Color"
        data-name="-Color"
        d={d}
        transform="translate(-2 -2)"
        fill="#e3e3e3"
      />
    </g>
  </svg>,
  'ProgressIcon'
);
