export const CheckBoxOutlineIcon = () => {
  return (
    <svg
      id="コンポーネント_46_4"
      data-name="コンポーネント 46 – 4"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="長方形_747"
        data-name="長方形 747"
        fill="#fff"
        stroke="#b9bcbf"
        strokeWidth="1"
      >
        <rect width="16" height="16" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
      </g>
    </svg>
  );
};
