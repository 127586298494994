import { BoxProps } from '@mui/material';
import { GraphIcon } from './GraphIcon';
import { DownGraphIcon } from './DownGraphIcon';

export const ScoreDisplay = (
  props: BoxProps & {
    score: number | null;
    prevScore: number | null;
  }
) => {
  return (
    <div className="flex items-end">
      <div className="rounded border-1 border-main-blue px-[6px] py-[4px] mr-[8px]">
        <p className="text-label text-main-blue font-bold">評価スコア</p>
      </div>

      <div className="mr-[8px] mb-[4px]">
        {(() => {
          if (props.score! > props.prevScore!) {
            return <GraphIcon />;
          }
          if (props.score! < props.prevScore!) {
            return <DownGraphIcon />;
          }
          return null;
        })()}
      </div>

      {props.score ? (
        <>
          <p className="text-result font-bold leading-none">{props.score}</p>
          <p className="text-label mb-[2px] ml-[4px]">点</p>
        </>
      ) : (
        <p className="text-meta">---</p>
      )}

      <div className="flex items-center rounded bg-grayscale-400 px-[8px] py-[4px] ml-[14px]">
        <p className="text-micro text-grayscale-700">前回評価スコア</p>
        {props.prevScore ? (
          <>
            <p className="text-base font-bold text-grayscale-700 mx-[4px]">
              {props.prevScore}
            </p>
            <p className="text-micro text-grayscale-700">点</p>
          </>
        ) : (
          <p className="text-micro text-grayscale-700">なし</p>
        )}
      </div>
    </div>
  );
};
