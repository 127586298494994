import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/user/onsite_users/';
export type Request = SnakeObjToCamelObj<
  paths[typeof URL]['post']['requestBody']['content']['application/json']
>;

export const postOnsiteuser = (request: Request) => {
  return apiClient.post(URL, request, {});
};
