import { atom } from 'jotai';

const loadingProcessIdListAtom = atom<string[]>([]);

export const isLoadingAtom = atom(
  (get) => get(loadingProcessIdListAtom).length !== 0
);

export const startLoadingAtom = atom(null, (get, set, id: string) => {
  if (
    get(loadingProcessIdListAtom).some(
      (loadingProcessId) => loadingProcessId === id
    )
  ) {
    console.warn(
      `重複したローディングが開始しています。確認して下さい。argument id:${id}, store ids: ${get(
        loadingProcessIdListAtom
      ).join(',')}`
    );
    return;
  }
  set(loadingProcessIdListAtom, (prev) => prev.concat([id]));
});

export const stopLoadingAtom = atom(null, (get, set, id: string) => {
  set(loadingProcessIdListAtom, (prev) =>
    prev.filter((loadingProcessId) => loadingProcessId !== id)
  );
});
