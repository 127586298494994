import { TableCell, TableCellProps } from '@mui/material';
import { COLORS } from 'tailwind.config';

export const BaseTableBorderCell = (
  props: TableCellProps & {
    backGroundColor?: string;
  }
) => {
  const { backGroundColor, ...tableCellProps } = props;

  return (
    <TableCell
      {...tableCellProps}
      sx={{
        ...tableCellProps.sx,
        border: `1px solid ${COLORS.GRAY_500}`,
        backgroundColor: backGroundColor,
      }}
    >
      {props.children}
    </TableCell>
  );
};
