import { useId } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  useForm: UseFormReturn<any>;
  name: string;
  onClick?: () => void;
};

export const Switch = (props: Props) => {
  const id = useId();
  return (
    <div className="leading-none">
      <label
        className="relative inline-flex items-center cursor-pointer"
        htmlFor={id}
      >
        <input
          type="checkbox"
          {...props.useForm.register(props.name)}
          onClick={props.onClick}
          className="sr-only peer"
          id={id}
        />
        <div
          className="
          w-[50px] h-[30px] bg-grayscale-500 rounded-full peer
          peer-focus:outline-none
          rtl:peer-checked:after:-translate-x-full peer-checked:after:border-grayscale-100
          after:content-[''] after:absolute after:top-[3px] after:start-[4px] after:bg-grayscale-100
          after:rounded-full after:h-[24px] after:w-[24px] after:transition-all
          peer-checked:bg-main-blue peer-checked:after:translate-x-[19px]
        "
        />
      </label>

      {!!props.useForm.formState.errors[props.name]?.message && (
        <ErrorMessage
          message={props.useForm.formState.errors[
            props.name
          ]?.message?.toString()}
        />
      )}
    </div>
  );
};
