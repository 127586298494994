export const CircleTrophy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="グループ_602"
      data-name="グループ 602"
      transform="translate(-173 -378)"
    >
      <circle
        id="楕円形_58"
        data-name="楕円形 58"
        cx="12"
        cy="12"
        r="12"
        transform="translate(173 378)"
        fill="#158cf2"
      />
      <g id="trophy" transform="translate(180 384)">
        <g
          id="_-Product-Icons"
          data-name="🔍-Product-Icons"
          transform="translate(0)"
        >
          <g id="ic_fluent_trophy_24_filled">
            <path
              id="_-Color"
              data-name="🎨-Color"
              d="M10.135,2a1.326,1.326,0,0,1,1.3,1.2h.738a1.032,1.032,0,0,1,1.016.964l0,.086v1.8A1.926,1.926,0,0,1,11.41,8l-.122,0a3.382,3.382,0,0,1-2.756,2.371V11.3H9.553a1.918,1.918,0,0,1,1.89,1.839l0,.111v.3a.446.446,0,0,1-.378.446l-.059,0H5.182a.441.441,0,0,1-.433-.389l0-.061v-.3A1.926,1.926,0,0,1,6.531,11.3l.107,0H7.658v-.929A3.382,3.382,0,0,1,4.9,8H4.889A1.922,1.922,0,0,1,3,6.05V4.25A1.035,1.035,0,0,1,4.015,3.2h.739A1.326,1.326,0,0,1,6.056,2Zm2.041,2.1h-.73V7.09a1.038,1.038,0,0,0,.872-.947l0-.092V4.25A.149.149,0,0,0,12.21,4.1Zm-7.431,0H4.015a.148.148,0,0,0-.146.15v1.8a1.041,1.041,0,0,0,.876,1.04Z"
              transform="translate(-2.996 -2)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
