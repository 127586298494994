import { ReactNode, useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';

type Props = {
  menuItems: { label: ReactNode; value: string | number }[] | undefined;
  className?: string;
  onSelectYear: (year: string) => void;
};

export const MandalatSelect = ({
  menuItems,
  className = 'w-[200px]',
  onSelectYear,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedName, setSelectedName] = useState<string>('すべて');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={`relative ${className}`}>
        <button
          type="button"
          className="
            relative w-full py-[8px] pl-[12px] pr-[20px]
            border-1 border-grayscale-500 rounded text-base text-left
            select-hover
          "
          onClick={() => {
            toggleDropdown();
          }}
        >
          {selectedName}
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
            <ArrowDownIcon />
          </div>
        </button>
        {isOpen && (
          <div
            className="
              absolute left-0 top-[41px] w-full overflow-y-auto max-h-[300px] z-10 py-[6px]
              flex flex-col border-1 border-grayscale-500 rounded text-base hidden-scrollbar
              shadow-main bg-grayscale-100
            "
          >
            {[
              {
                label: 'すべて',
                value: '',
              },
              ...menuItems!,
            ].map((item) => (
              <button
                type="button"
                key={item.value}
                className="py-[13px] px-[20px] text-left pulldown-hover bg-selectbox"
                onMouseDown={() => {
                  onSelectYear(item.value!.toString());
                  setSelectedName(item.label!.toString());
                  toggleDropdown();
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* 表示されているときのみ有効: 別に独立したchecknoxのon offで処理が走ってしまうため */}
      {isOpen && (
        <input
          type="text"
          className="absolute w-0 h-0 opacity-0"
          ref={(element) => {
            element?.focus();
          }}
          onBlur={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};
