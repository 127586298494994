import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePaginatedCorporation } from 'context/corporation/corporation/api/types';

export const useFetchCorporation = (useCache = false) => {
  // キャッシュを持たせると複雑になるのでコメントアウト
  // 将来的に導入する可能性は高いためおいておく
  // const [corporation, setCorporation] = useAtom(corporationAtom);
  // if (corporation !== null) return [corporation] as const;

  const [response, refetch] = useAxios<ResponsePaginatedCorporation>(
    {
      url: MY_TENANT_URL('corporation/'),
    },
    { useCache }
  );

  useNonFieldApiErrorCallback(response.error);

  // キャッシュを持たせると複雑になるのでコメントアウト
  // 将来的に導入する可能性は高いためおいておく
  // useEffect(() => {
  //   const fetchedCorporation = response.data?.results[0];
  //   if (fetchedCorporation !== undefined && fetchedCorporation !== null) {
  //     setCorporation(fetchedCorporation);
  //   }
  // }, [response]);

  return [
    { ...response, data: response.data?.results?.[0] ?? null },
    refetch,
  ] as const;
};
