import { Box, BoxProps } from '@mui/material';

export const ShopTitle = (props: {
  boxProps?: BoxProps;
  borderColor: string;
  shopName: string;
  brandName: string;
}) => (
  <Box
    {...props.boxProps}
    sx={{ borderLeft: `solid 5px ${props.borderColor}` }}
    padding={1}
  >
    <p className="pb-[8px] font-bold">{props.shopName}</p>
    <p className="text-grayscale-700 text-meta font-medium">
      {props.brandName}
    </p>
  </Box>
);
