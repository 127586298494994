import useAxios from 'axios-hooks';
import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';

import { useAtom } from 'jotai';

import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

export const URL = '/tenant/my/contracts/contract/';

export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/contracts/contract/']['get']['responses'][200]['content']['application/json']
>;

export type ErrorResponse = null;

/**
 * 契約情報の取得
 */
export default (useCache = false) => {
  const response = useAxios<SuccessResponse>(
    {
      url: URL,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};
