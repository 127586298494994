import useAxios from 'axios-hooks';

import { useAtom } from 'jotai';

import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePagination } from 'infra/api/type';

import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { paths } from 'infra/api/schema';

export const URL = '/tenant/my/mvps/rankings/';
export type ResponseMvpRankings = SnakeObjToCamelObj<
  Required<
    paths[typeof URL]['get']['responses'][200]['content']['application/json']
  >
>['results'][number] & {
  onsiteUser: {
    wealthProfileResult?: string;
  };
};

export const queryParameter: { [k in string]: any } = {
  limit: 9999,
  mvp_coin_assignment_log__to_shop_id: null,
  mvp_coin_assignment_log__to_shop_brand_business_type: null,
  mvp_coin_assignment_log__to_grade_id: null,
  created_at__gte: null,
  created_at__lte: null,
  mvp_category_id: null,
};

export const getMvpRankings = (params: any) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === 'すべて') {
      // eslint-disable-next-line no-param-reassign
      delete params[key];
    }
  });

  const response = useAxios<ResponsePagination<ResponseMvpRankings>>({
    url: URL,
    params,
  });
  useNonFieldApiErrorCallback(response[0].error);
  return response;
};
