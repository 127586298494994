import { PageTitle } from 'infra/components/UI/Headings/PageTitle';

import { StaffForm } from 'context/users/onsite_users/_infra/components/StaffForm';
import { State as StaffCreateState } from 'context/users/onsite_users/Staff/StaffCreate/state';

export const StaffCreate = () => {
  const staffCreateState = StaffCreateState();

  return (
    <div>
      <PageTitle
        title="スタッフ新規登録"
        breadcrumbs={[
          ['ホーム', '/'],
          ['スタッフ一覧', '/staffs_manage/staffs/'],
        ]}
      />
      <div className="mt-[50px] rounded bg-grayscale-100">
        <StaffForm
          useForm={staffCreateState.useForm}
          actionLabel="登録"
          onSubmit={staffCreateState.onCreate}
        />
      </div>
    </div>
  );
};
