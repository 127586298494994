import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { useAtom } from 'jotai';
import { Dialog } from '@mui/material';
import { useState } from 'react';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';

import { SelectSearch } from 'infra/components/UI/Table/Pagination/Filter/SelectSearch';
import { useGetOnsiteUsers } from '../../_infra/api/onsiteUsers.get';
import { bulkUpdateBoss } from '../../_infra/api/bulkUpdateBoss';

const Modal = (props: {
  onClose: () => void;
  staffIdList: string[];
  onUpdated: () => void;
}) => {
  const [{ data: staffResponse }] = useGetOnsiteUsers(true, true);
  const [bossId, setBossId] = useState<null | string>(null);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <div className="px-[130px] pt-[60px] pb-[60px] min-w-[750px] ">
        <h1 className="text-section-heading font-bold mb-[35px]">
          評価者の一括変更
        </h1>

        <div className="flex items-center mb-[30px] text-base leading-relaxed">
          選択したスタッフの評価者を一括で変更できます。
          <br />
          変更する評価者を選択してください。
        </div>

        {/* SelectSearchがすべて表示されるように、高さを351px確保している */}
        <div className="h-[351px]">
          <div className="flex items-center gap-[40px]">
            <p className="font-bold text-base ">評価者</p>
            {staffResponse !== undefined && (
              <SelectSearch
                form={{
                  inputValue: bossId || '',
                  onChangeValue: (_bossId) => {
                    setBossId(String(_bossId));
                  },
                }}
                menuItems={staffResponse?.results
                  .filter((s) => s.isManager)
                  .map(({ id, lastName, firstName }) => {
                    return {
                      label: lastName! + firstName!,
                      value: id,
                    };
                  })}
                order="labelAsc"
                isErrorFiled={false}
              />
            )}
          </div>
        </div>

        <div className="mt-[10px] gap-[60px] flex justify-center">
          <button type="button" onClick={props.onClose}>
            <p className="text-grayscale-700 font-normal">キャンセル</p>
          </button>

          <BaseButton
            className="!w-[108px] text-btn-confirm p-button-small"
            disabled={bossId === null}
            onClick={() => {
              bulkUpdateBoss(props.staffIdList, bossId || '')
                .then(() => {
                  displayMessage({
                    text: '評価者の一括変更が完了しました',
                    isInterruption: false,
                  });
                  props.onClose();
                  props.onUpdated();
                })
                .catch((error) => {
                  displayNonFieldApiError(error);
                });
            }}
          >
            変更
          </BaseButton>
        </div>
      </div>
    </Dialog>
  );
};

export const BulkUpdateBossButton = (props: {
  selectedStaffMap: Map<string, { id: string; isManager: boolean }>;
  onUpdated: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, displayMessage] = useAtom(displayMessageAtom);

  const onClick = () => {
    const isIncludeManager = [...props.selectedStaffMap.values()].some(
      ({ isManager }) => isManager
    );
    if (isIncludeManager) {
      displayMessage({
        text: '選択されたユーザーの中にマネージャーが含まれています。\nマネージャーは評価者を変更できないため、選択を外してください',
        isInterruption: true,
      });
      return;
    }
    setIsModalOpen(true);
  };
  return (
    <div>
      <BaseButton
        onClick={onClick}
        className="!w-[130px] p-button-small text-main-blue !text-ui bg-grayscale-100 border border-main-blue"
      >
        評価者の一括変更
      </BaseButton>

      {isModalOpen && (
        <Modal
          staffIdList={[...props.selectedStaffMap.keys()]}
          onClose={() => setIsModalOpen(false)}
          onUpdated={props.onUpdated}
        />
      )}
    </div>
  );
};
