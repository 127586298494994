import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';
import { RequestCareerPath } from './types';

export const updateCareerPath = async (
  id: number,
  careerPath: RequestCareerPath
) => {
  const response = await apiClient.patch(
    MY_TENANT_URL(`corporation/career_paths/${id}/`),
    careerPath
  );
  return response;
};
