/**
 * 運用設定 評価スケジュール設定関連ルーティング
 */
import { EvaluationPeriodSetting } from 'context/evaluation_period/components/EvaluationPeriodSetting';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const EvaluationPeriodSettingRoute: RouteType = {
  path: '/operation-setting/evaluation-period-setting',
  element: <EvaluationPeriodSetting />,
  do_it: () => (
    <Route
      path={EvaluationPeriodSettingRoute.path}
      element={EvaluationPeriodSettingRoute.element}
    />
  ),
};

export const routes = () => <>{EvaluationPeriodSettingRoute.do_it()}</>;
