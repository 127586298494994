import { Button } from '@mui/base/Button';

/**
 * Modalのコンテンツ部分のみを定義する
 * Modalの実態は、このコンポーネントとButtonを含めてページごとにorganismsで定義
 * organismsで定義したものをPageで利用する
 */
export const BaseModal = (props: {
  children: JSX.Element;
  height?: string;
  activeButton: JSX.Element;
  closeHandler: () => void;
}) => {
  return (
    <div className="w-[800px]">
      <div
        className="px-[40px] pt-[50px] overflow-y-auto"
        style={{ height: props.height ?? '600px' }}
      >
        {props.children}
      </div>
      <div className="flex justify-center gap-12 py-[30px] px-8 shadow-modal">
        <Button
          className="w-[120px]"
          onClick={() => {
            props.closeHandler();
          }}
        >
          <p className="text-grayscale-700">キャンセル</p>
        </Button>
        {props.activeButton}
      </div>
    </div>
  );
};
