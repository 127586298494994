export const SortDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="9"
      height="5"
      viewBox="0 0 9 5"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="9" height="5" fill="none" />
        </clipPath>
      </defs>
      <g id="chevron-down" clipPath="url(#clip-path)">
        <path
          id="chevron-down-2"
          data-name="chevron-down"
          d="M6,9l3.5,3L13,9"
          transform="translate(-5 -8)"
          fill="none"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
