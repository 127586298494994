import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { MenuItem, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { TimeIcon } from 'infra/components/UI/Icons/TimeIcon';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { BaseTableBorderCell } from 'infra/components/UI/Table/BaseTableBorderCell';
import { useFetchShop } from 'context/corporation/shop/api/fetchShop';
import { useParams } from 'react-router-dom';
import { getMbos, MboListResponse } from 'context/corporation/mbo/api/get.mbos';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { ErrorMessageView } from 'pages/ErrorMessageView';
import { useFetchEvaluationPeriods } from 'context/evaluation_period/api/useFetchEvaluationPeriods';
import { eachMonthOfInterval, format, startOfMonth } from 'date-fns';
import { ResponseShop } from 'context/corporation/shop/api/typeResponse';
import { confirmMbo } from 'context/corporation/mbo/api/confirm.mbo';

import { useAtom } from 'jotai';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { getSelectableYearDurationList } from 'context/corporation/mbo/mbo';
import { BaseSelectField } from 'infra/components/UI/Forms/BaseSelectField';
import { COLORS } from 'tailwind.config';
import { FixMboInfoModal } from 'context/corporation/mbo/components/ShopMboDetail/FixMboInfoModal';
import { FixMboInfoModalState } from 'context/corporation/mbo/components/ShopMboDetail/FixMboInfoModalState';
import { EditShopMboModal } from './EditShopMboModal';
import { ShopTitle } from './ShopTitle';

type Mbo = NonNullable<MboListResponse['results']>[number];
export type Shop = ResponseShop;
export type MonthWithMbo = {
  month: Date;
  mbo: Mbo | null;
};

/**
 * 表示するサイクルや画面上選択可能な期間のリストやを作成する
 * 表示をする基準となる月は、現在の評価期間の開始月とする。
 * そこから12ヶ月区切りで表示を行う
 */
const useDisplaySelectableDurationList = () => {
  const [{ data: currentEvaluationApiResponse, loading: loadEvaluation }] =
    useFetchEvaluationPeriods(true);

  if (loadEvaluation || currentEvaluationApiResponse === undefined) {
    return {
      loadEvaluation: true,
    } as const;
  }

  // 現在の評価期間が存在しない場合は、12ヶ月まとめて表示&現在日を基準として表示
  let monthCountPerSection = 12;
  let startedAt = startOfMonth(new Date());
  if (currentEvaluationApiResponse.results.length !== 0) {
    const currentEvaluation = currentEvaluationApiResponse.results[0];
    monthCountPerSection =
      // @ts-ignore
      currentEvaluation?.evaluationPeriodGroup?.evaluationCycle || 12;
    startedAt = startOfMonth(new Date(currentEvaluation?.startedAt));
  }
  const selectableYearDurationList = getSelectableYearDurationList(startedAt);

  return {
    loadEvaluation: false,
    monthCountPerSection,
    selectableYearDurationList,
  } as const;
};

export const ShopMboDetails = () => {
  const { shopId } = useParams();

  // 画面で選択されている年度の開始月
  // DateがISOでフォーマットされた文字列が入る
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(
    null
  );

  const [{ data: shop, loading: loadingShop }] = useFetchShop(shopId!);
  const [{ data: mbos }, reload] = getMbos([shopId!]);
  const { loadEvaluation, monthCountPerSection, selectableYearDurationList } =
    useDisplaySelectableDurationList();

  // 現在選択されている年度
  const currentDuration = selectableYearDurationList?.find(
    (duration) => selectedStartDate === duration.startDate.toISOString()
  );

  const onSaved = () => {
    reload();
  };

  // 初回のみ、選択年度を自動で設定する
  useEffect(() => {
    // 初回以外は無視
    if (selectedStartDate !== null) return;
    if (selectableYearDurationList === undefined) {
      return;
    }

    setSelectedStartDate(selectableYearDurationList[0].startDate.toISOString());
  }, [selectedStartDate, selectableYearDurationList]);

  if (
    loadingShop ||
    loadEvaluation ||
    mbos === undefined ||
    mbos.results === undefined ||
    selectableYearDurationList === undefined ||
    currentDuration === undefined
  ) {
    return <LoadingBackdrop isShow />;
  }
  if (shop === undefined) {
    return (
      <ErrorMessageView errorMessage="不正なデータが含まれています。時間をあけて再度お試しください" />
    );
  }

  const targetMboList =
    mbos?.results?.filter((mbo) => {
      const startYYYYMM = format(currentDuration.startDate, 'yyyyMM');
      const endYYYYMM = format(currentDuration.endDate, 'yyyyMM');
      return startYYYYMM <= mbo.yearMonth && mbo.yearMonth <= endYYYYMM;
    }) || [];

  const sortedMboList = targetMboList.sort((a, b) => {
    return a.yearMonth < b.yearMonth ? 1 : -1;
  });

  // 画面に表示する年月リスト
  const monthList = eachMonthOfInterval({
    start: currentDuration.startDate,
    end: currentDuration.endDate,
  });
  const monthListWithMbo: MonthWithMbo[] = monthList.map((month) => {
    return {
      month,
      mbo:
        sortedMboList.find(
          (mbo) => format(month, 'yyyyMM') === mbo.yearMonth
        ) || null,
    };
  });

  return (
    <div>
      <PageTitle
        title="店舗MBO管理"
        breadcrumbs={[
          ['ホーム', '/'],
          ['店舗MBO管理', '/shop-mbo'],
        ]}
      />

      <div className="flex justify-between items-center pt-[30px]">
        <ShopTitle
          borderColor={COLORS.MAIN_BLUE}
          shopName={shop.name}
          brandName={shop.brand.name}
        />
        <div className="flex items-center gap-[10px]">
          <TimeIcon />
          <p className="text-meta text-grayscale-700 mr-[8px]">表示期間</p>
          <BaseSelectField
            value={selectedStartDate}
            sx={{
              width: '200px',
              '.MuiSelect-select': {
                backgroundColor: COLORS.GRAY_100,
              },
            }}
            onChange={(e) => {
              setSelectedStartDate(e.target.value as string);
            }}
            menuItems={selectableYearDurationList.map((displayDate) => {
              return (
                <MenuItem
                  key={displayDate.label}
                  value={displayDate.startDate.toISOString()}
                >
                  <p className="text-meta text-grayscale-700 pt-[3px]">
                    {displayDate.label}
                  </p>
                </MenuItem>
              );
            })}
          />
        </div>
      </div>

      <div className="mt-[50px] p-main-section bg-grayscale-100">
        <p className="mb-[30px] text-base">
          目標と実績を入力してください。
          <br />
          データ取得のない月は空欄で入力してください。
        </p>

        {Array.from({ length: 12 / monthCountPerSection }).map((_v, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {index !== 0 && <div className="mb-[50px]" />}
              <BaseTable
                shop={shop}
                monthListWithMbo={monthListWithMbo.slice(
                  index * monthCountPerSection,
                  (1 + index) * monthCountPerSection
                )}
                onSaved={onSaved}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const BaseTable = (props: {
  shop: Shop;
  monthListWithMbo: MonthWithMbo[];
  onSaved: () => void;
}) => {
  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);
  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();
  const fixMboInfoModalState = FixMboInfoModalState();

  const notInputedMonthList = props.monthListWithMbo.filter(
    (m) => m.mbo === null
  );

  const targetMbos = props.monthListWithMbo.filter(
    (m) => m.mbo?.isFixed === false
  );

  const fixMboData = () => {
    if (notInputedMonthList.length > 0) {
      displayMessage({ text: '未入力の月があります', isInterruption: true });
      fixMboInfoModalState.ui.setModalClose();
      return;
    }

    startLoading('fix_mbo');
    confirmMbo(targetMbos.map((m) => m.mbo!.id))
      .then(() => {
        displayMessage({ text: '確定しました', isInterruption: false });
        props.onSaved();
      })
      .catch((error) => {
        displayNonFieldApiError(error);
      })
      .finally(() => {
        stopLoading('fix_mbo');
        fixMboInfoModalState.ui.setModalClose();
      });
  };

  const openConfirmationModal = () => {
    fixMboInfoModalState.ui.setModalOpen();
  };

  return (
    <div>
      <p className="font-bold mb-[20px]">
        {format(props.monthListWithMbo[0].month, 'yyyy年MM月')}~
        {format(
          props.monthListWithMbo[props.monthListWithMbo.length - 1].month,
          'yyyy年MM月'
        )}
      </p>
      <div className="overflow-x-auto mb-[30px]">
        <Table
          style={{
            tableLayout: 'fixed',
            minWidth: '1020px',
          }}
        >
          <TableHead>
            <TableRow>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                rowSpan={2}
                style={{ padding: '8px' }}
              />
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_300}
                align="center"
                colSpan={2}
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">売上</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_300}
                align="center"
                colSpan={2}
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">利益</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_300}
                align="center"
                colSpan={2}
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">ES</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_300}
                align="center"
                colSpan={2}
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">CS</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                rowSpan={2}
                style={{ padding: '8px' }}
              />
            </TableRow>
            <TableRow>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">目標</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">実績</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">目標</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">実績</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">目標</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">実績</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">目標</p>
              </BaseTableBorderCell>
              <BaseTableBorderCell
                backGroundColor={COLORS.GRAY_200}
                align="center"
                style={{ padding: '8px' }}
              >
                <p className="text-base font-bold">実績</p>
              </BaseTableBorderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.monthListWithMbo.map((monthWithMbo, index) => {
              return (
                <BaseRow
                  shop={props.shop}
                  key={monthWithMbo.month.toISOString()}
                  monthWithMbo={monthWithMbo}
                  onSaved={props.onSaved}
                  bgClass={index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      {/* <div className="flex justify-center"> */}
      {/* 未入力の月がある */}
      {/* {notInputedMonthList.length > 0 && (
          <BaseButton className="w-btn-base p-button-small h-[46px]" disabled>
            全て入力してください
          </BaseButton>
        )} */}
      {/* 未入力の月がなくてかつ確定していない月もない */}
      {/* {notInputedMonthList.length === 0 && targetMbos.length === 0 && (
          <BaseButton
            className="w-btn-confirm p-button-small h-[46px]"
            disabled
          >
            既にこの期間のMBOは確定しました
          </BaseButton>
        )} */}
      {/* 未入力の月がないが、確定していない月がある（=確定が必要） */}
      {/* {notInputedMonthList.length === 0 && targetMbos.length > 0 && (
          <BaseButton
            className="w-btn-base p-button-small h-[46px]"
            onClick={openConfirmationModal}
          >
            確定する
          </BaseButton>
        )} */}
      {/* </div> */}
      {/* <FixMboInfoModal state={fixMboInfoModalState} onConfirm={fixMboData} /> */}
    </div>
  );
};

const BaseRow = (props: {
  shop: Shop;
  monthWithMbo: MonthWithMbo;
  onSaved: () => void;
  bgClass?: string;
}) => {
  const NO_DATA = <span className="text-grayscale-700">データなし</span>;

  return (
    <TableRow style={{ backgroundColor: `${props.bgClass}` }}>
      <BaseTableBorderCell
        align="center"
        style={{ paddingLeft: '8px', paddingRight: '8px' }}
      >
        <p className="text-base font-bold">
          {format(props.monthWithMbo.month, 'yyyy年MM月')}
        </p>
      </BaseTableBorderCell>
      {props.monthWithMbo.mbo === null ? (
        // ユーザーが一度も入力していない場合は空欄を表示
        // eslint-disable-next-line react/no-array-index-key
        Array.from({ length: 8 }).map((_, index) => (
          <BaseTableBorderCell key={props.monthWithMbo.mbo?.id} align="right" />
        ))
      ) : (
        <>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.predictedValueSales?.toLocaleString() ||
              NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.actualValueSales?.toLocaleString() ||
              NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.predictedValueProfit?.toLocaleString() ||
              NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.actualValueProfit?.toLocaleString() ||
              NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.predictedValueEs?.toLocaleString() ||
              NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.actualValueEs?.toLocaleString() || NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.predictedValueCs?.toLocaleString() ||
              NO_DATA}
          </BaseTableBorderCell>
          <BaseTableBorderCell align="right">
            {props.monthWithMbo.mbo.actualValueCs?.toLocaleString() || NO_DATA}
          </BaseTableBorderCell>
        </>
      )}

      <BaseTableBorderCell
        align="center"
        className="fixed-column"
        style={{ backgroundColor: `${props.bgClass}`, height: '72.52px' }}
      >
        <EditShopMboModal
          monthWithMbo={props.monthWithMbo}
          shop={props.shop}
          key="1"
          onSaved={props.onSaved}
          readonly={false}
          render={(openModal) => (
            <BaseButton
              className="w-btn-cell p-button-small"
              onClick={() => {
                openModal();
              }}
            >
              編集
            </BaseButton>
          )}
        />
      </BaseTableBorderCell>
    </TableRow>
  );
};
