import { useState, ReactNode } from 'react';
import { CircleDotMenuIcon } from 'infra/components/UI/Menus/MoreMenuCircleDotIcon';
import { twMerge } from 'tailwind-merge';
import { HackCloseDropDown } from '../Forms/utils/HackCloseDropDown';

type MenuListItem = {
  label: ReactNode;
  onClick: () => void;
  className?: string;
} | null;

export const MoreMenu = (props: {
  render: MenuListItem[];
  menuCategoryLabel?: ReactNode;
  menuItemBoxStyle?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="relative p-[6px]"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {props?.menuCategoryLabel && (
        <button type="button">{props.menuCategoryLabel}</button>
      )}

      {!props?.menuCategoryLabel && (
        <button
          type="button"
          aria-label="メニューボタン"
          className="
          rounded-full border-1 border-grayscale-100 padding-[10px]
          duration-base ease-out-expo
        hover:border-main-blue
        "
        >
          <CircleDotMenuIcon />
        </button>
      )}
      {isOpen && (
        <>
          <ul
            className={twMerge(
              `absolute right-0 top-[36px] min-w-[80px] z-10
              w-full overflow-y-auto max-h-[200px] py-[6px]
              flex flex-col bg-grayscale-100
              border-1 border-grayscale-500 rounded
              hidden-scrollbar shadow-main`,
              props.menuItemBoxStyle
            )}
          >
            {props.render
              .filter((item) => item != null)
              .map((item, index) => {
                if (!item) return null;

                const stripedBackgroundColor =
                  index % 2 === 0 ? 'bg-white' : 'bg-soft-blue';

                return (
                  <li key={item!.label?.toString()}>
                    <button
                      type="button"
                      className={`
                        block w-full py-[10px] px-[10px] text-ui text-grayscale-800
                        pulldown-hover
                        ${stripedBackgroundColor}
                        ${item!.className || ''}
                      `}
                      onMouseDown={() => {
                        item!.onClick();
                        setIsOpen(false);
                      }}
                    >
                      {item!.label}
                    </button>
                  </li>
                );
              })}
          </ul>
          <HackCloseDropDown isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
      )}
    </div>
  );
};
