// eslint-disable-next-line import/no-extraneous-dependencies
import { getAnalytics, logEvent } from 'firebase/analytics';
// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp } from 'firebase/app';

export const CONFIG = {
  apiKey: 'AIzaSyCdgGtAwCfnzMTFBzLhxojPo0REhqZokV8',
  authDomain: 'canayell-app.firebaseapp.com',
  databaseURL:
    'https://canayell-app-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'canayell-app',
  storageBucket: 'canayell-app.appspot.com',
  messagingSenderId: '115440057244',
  appId: '1:115440057244:web:96f180fd3702f580742659',
  measurementId: 'G-T0LQ7F612E',
};

export const initFirebaseApp = () => {
  return initializeApp(CONFIG);
};

export const initFirebaseAnalytics = () => {
  const app = initFirebaseApp();
  return getAnalytics(app);
};

export const initFirebaseAnalyticsAndSetting = () => {
  const analytics = initFirebaseAnalytics();

  logEvent(analytics, 'set_stream_name', {
    stream_name: 'corp_admin',
  });
};
