export const StoreIcon = () => (
  <svg
    id="コンポーネント_45_318"
    data-name="コンポーネント 45 – 318"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="31.999"
    viewBox="0 0 32 31.999"
  >
    <path
      id="building-shop.1"
      d="M27.6,2a1.2,1.2,0,0,1,.759.27l.121.113,5.235,5.645.049.062A1.166,1.166,0,0,1,34,8.8l0,3.07A6.11,6.11,0,0,1,32.4,16V32.8a1.2,1.2,0,0,1-1.037,1.189L31.2,34H4.8a1.2,1.2,0,0,1-1.189-1.037L3.6,32.8V16a6.108,6.108,0,0,1-1.588-3.765L2,11.866v-3a1.165,1.165,0,0,1,.153-.653l.1-.153.063-.073,5.2-5.6a1.2,1.2,0,0,1,.714-.372L8.4,2ZM22.93,15.507l-.019.028a6.133,6.133,0,0,1-9.846-.025A6.138,6.138,0,0,1,6,17.617L6,31.6H8.4V20.8a1.2,1.2,0,0,1,1.037-1.189L9.6,19.6h7.193a1.2,1.2,0,0,1,1.189,1.037l.011.163V31.6H30V17.618a6.14,6.14,0,0,1-7.066-2.111ZM15.592,22H10.8v9.6h4.792Zm10.815-2.4A1.2,1.2,0,0,1,27.6,20.638l.011.163v5.6a1.2,1.2,0,0,1-1.037,1.189l-.163.011H20.8a1.2,1.2,0,0,1-1.189-1.037L19.6,26.4V20.8a1.2,1.2,0,0,1,1.037-1.189L20.8,19.6Zm-1.2,2.4H22v3.2h3.2ZM11.865,10H4.4v1.864l.01.274.045.37.052.25.079.275.087.237.065.15q.075.163.164.317L5.07,14l.087.12.206.249.227.231.154.136.067.055a3.715,3.715,0,0,0,1.779.772l.289.031.256.009a3.732,3.732,0,0,0,3.723-3.476l.009-.256Zm9.864,0H14.265v1.864a3.733,3.733,0,0,0,3.226,3.7l.251.025L18,15.6a3.732,3.732,0,0,0,3.723-3.476l.009-.256ZM31.6,10H24.132v1.864a3.733,3.733,0,0,0,3.226,3.7l.251.025.256.009a3.717,3.717,0,0,0,2.376-.854l.144-.125.185-.182.234-.271a3.746,3.746,0,0,0,.241-.345l.166-.3.1-.221.093-.248.048-.153.061-.243.045-.252.029-.267.01-.272ZM13.3,4.4H8.923L5.95,7.6h6.362Zm6.891,0H15.809l-.986,3.2h6.352Zm6.885,0H22.7l.986,3.2h6.362Z"
      transform="translate(-2 -2)"
      fill="#048cf2"
    />
  </svg>
);
