import { atom } from 'jotai';

export type Message = {
  text: string;
  isInterruption: boolean;
  onClose?: () => void;
};

const messagesAtom = atom<Message[]>([]);

export const latestMessageAtom = atom(
  (get) => (get(messagesAtom).length !== 0 ? get(messagesAtom)[0] : null),
  /* eslint-disable @typescript-eslint/no-unused-vars */
  (get, set, newValue) => {
    set(messagesAtom, (prev) => prev.slice(1));
  }
);

export const displayMessageAtom = atom(null, (get, set, message: Message) => {
  set(messagesAtom, (prev) => prev.concat([message]));
});
