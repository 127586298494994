import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { COLORS } from 'tailwind.config';

export const BaseDateInput = forwardRef<
  HTMLDivElement | null,
  TextFieldProps & { errormessage?: string }
>((props, ref) => {
  return (
    <div>
      <TextField
        {...props}
        error={props.errormessage !== undefined}
        ref={ref}
        size="small"
        type="date"
        inputProps={{
          style: {
            width: 180,
            fontSize: 13,
            color: COLORS.GRAY_700,
            padding: '11.38px 14px',
          },
        }}
        onBlur={() => {}}
      />
      {props.errormessage && (
        <div>
          <p className="text-main-red text-meta mt-[10px]">
            {props.errormessage}
          </p>
        </div>
      )}
    </div>
  );
});
