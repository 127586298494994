/**
 * 運用設定 MVPカテゴリ設定関連ルーティング
 */
import { MvpCategorySetting } from 'context/mvps/components/Index';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const MvpCategorySettingListRoute: RouteType = {
  path: '/operation-setting/mvp-category-setting',
  element: <MvpCategorySetting />,
  do_it: () => (
    <Route
      path={MvpCategorySettingListRoute.path}
      element={MvpCategorySettingListRoute.element}
    />
  ),
};

export const routes = () => <>{MvpCategorySettingListRoute.do_it()}</>;
