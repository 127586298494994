import { useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { HackCloseDropDown } from 'infra/components/UI/Forms/utils/HackCloseDropDown';

type FilterCategorySelectorProps = {
  filterCategory: { name: string; component: any; params: () => any }[];
  onFilterChange: (selectedItem: string) => void;
  selectedFormName: string;
};

export const FilterCategorySelector = ({
  filterCategory,
  onFilterChange,
  selectedFormName,
}: FilterCategorySelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const setSelectedFilterType = (selectedItem: string) => {
    onFilterChange(selectedItem);
  };

  return (
    <div className="relative">
      <button
        type="button"
        className="
          relative w-[200px] py-[9px] pl-[12px] pr-[20px]
          border-1 border-grayscale-600 rounded text-ui text-left bg-grayscale-100
          select-hover
        "
        onClick={() => {
          toggleDropdown();
        }}
      >
        {selectedFormName || '未選択'}
        <div className="absolute right-[20px] top-1/2 -translate-y-1/2">
          <ArrowDownIcon />
        </div>
      </button>
      {isOpen && (
        <div
          className="
            absolute left-0 top-[42px] w-full overflow-y-auto max-h-[400px] z-10 py-[6px]
            flex flex-col border-1 border-grayscale-500 rounded text-ui hidden-scrollbar
            shadow-main bg-grayscale-100
          "
        >
          {filterCategory.map((item) => (
            <button
              type="button"
              key={item.name}
              className="p-[14px] pl-[24px] text-left pulldown-hover bg-selectbox"
              onMouseDown={() => {
                setSelectedFilterType(item.name);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
      )}

      <HackCloseDropDown isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
