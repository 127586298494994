import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';

export const patchPromotionLogId = (
  promotionLogId: string,
  promotionLog: { is_promoted: boolean; salary: number }
) => {
  const response = apiClient.patch(
    MY_TENANT_URL(`promotion/promotion_logs/${promotionLogId}/`),
    promotionLog
  );
  return response;
};
