import { LinearProgress } from '@mui/material';
import { STYLES, COLORS } from 'tailwind.config';

export const ProggressBar = ({
  allCount,
  completedCount,
  showPercentageOnTop = true,
}: {
  showPercentageOnTop?: boolean;
  allCount: number;
  completedCount: number;
}) => {
  return (
    <div className="relative">
      {showPercentageOnTop && (
        <p className="text-base text-grayscale-700 absolute top-[-18px]">
          {allCount === 0 ? 0 : Math.round((completedCount / allCount) * 100)}%
        </p>
      )}
      <LinearProgress
        // color="primary"
        variant="determinate"
        value={
          allCount === 0 ? 0 : Math.round((completedCount / allCount) * 100)
        }
        style={{
          height: '10px',
          width: '100px',
          borderRadius: STYLES.BORDER_RADIUS.DEFAULT,
          backgroundColor: COLORS.LIGHT_BLUE,
        }}
      />
    </div>
  );
};
