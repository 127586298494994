import { Dialog } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { CalendarMonthIcon } from 'infra/components/UI/Icons/CalendarMonthIcon';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';

import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { formatDate } from 'infra/utils/date-fns';
import { Divider } from 'infra/components/UI/Elements/Divider';
import { STYLES, COLORS } from 'tailwind.config';
import { Tooltip } from 'context/objective/components/ObjectiveRecordList/_components/Tooltip';
import { EvaluationWeightsChartsItem } from 'context/objective/components/ObjectiveRecordList/_components/EvaluationWeightsCharts';
import { format } from 'date-fns';
import { LABEL_MAP, ObjectiveRecord, PromotionLog, UserType } from '.';
import { PromitionSwitch } from './PromotionSwitch';
import { EvaluationCard } from './EvaluationCard';
import { OnsiteUserAffiliation } from './OnsiteUserAffiliation';
import { OnsiteUserName } from './OnsiteUserName';

const getCappedScore = (score?: number | null) => {
  if (score === undefined || score === null) {
    return '-';
  }

  return score > 100 ? 100 : score.toFixed(1);
};

type Grade = {
  currentGradeName: string;
  nextGradeName: string;
};

export type EditingDataType = {
  salary: number | null;
  isPromoted: boolean;
  isTemporaryFixed: boolean;
};

const dateFormat = (dateString: string) => {
  return format(new Date(dateString), 'yyyy.MM.dd');
};

const BasicThinkingArea = (props: { objectiveRecord: ObjectiveRecord }) => {
  const { objectiveRecord } = props;

  const [isActive, setIsActive] = useState(0);
  const [currentBasicThinkings, setCurrentBasicThinkings] = useState(
    objectiveRecord.basicThinkings
  );

  return (
    <div>
      <div>
        <ul className="flex items-center space-x-[40px] mt-[30px]">
          <li className="base-hover w-[240px]">
            <div
              onClick={() => {
                setIsActive(0);
                setCurrentBasicThinkings(objectiveRecord.basicThinkings || []);
              }}
              tabIndex={0}
              role="button"
              aria-label="表示"
              className={`flex items-center relative ${
                isActive === 0 ? 'font-bold' : ''
              }`}
            >
              <CalendarMonthIcon />
              <span className="pl-[10px]">
                {dateFormat(objectiveRecord.evaluationPeriod.startedAt)}~
                {dateFormat(objectiveRecord.evaluationPeriod.endedAt)}
              </span>
              {isActive === 0 && (
                <span className="absolute left-0 bottom-[-10px] w-full h-[3px] bg-main-blue" />
              )}
            </div>
          </li>
          {objectiveRecord?.previousObjectiveRecord?.basicThinkings &&
            objectiveRecord?.previousObjectiveRecord?.basicThinkings?.length >=
              1 && (
              <li className="base-hover w-[240px]">
                <div
                  onClick={() => {
                    setIsActive(1);
                    setCurrentBasicThinkings(
                      objectiveRecord.previousObjectiveRecord?.basicThinkings ||
                        []
                    );
                  }}
                  tabIndex={0}
                  role="button"
                  aria-label="表示"
                  className={`flex items-center relative ${
                    isActive === 1 ? 'font-bold' : ''
                  }`}
                >
                  <CalendarMonthIcon />
                  <span className="pl-[10px]">
                    {dateFormat(
                      objectiveRecord.previousObjectiveRecord.evaluationPeriod
                        .startedAt
                    )}
                    ~
                    {dateFormat(
                      objectiveRecord.previousObjectiveRecord.evaluationPeriod
                        .endedAt
                    )}
                  </span>
                  {isActive === 1 && (
                    <span className="absolute left-0 bottom-[-10px] w-full h-[3px] bg-main-blue" />
                  )}
                </div>
              </li>
            )}
        </ul>
      </div>

      <div className="grid grid-cols-2 pt-[40px] gap-[36px]">
        {}
        {currentBasicThinkings.map((basicThinking) => {
          return (
            <div>
              <EvaluationCard
                color={basicThinking.basicThinkingMaster.categoryColor}
                basicThinkingLabel={
                  basicThinking.basicThinkingMaster.basicThinkingLabel
                }
                finalMyselfEvaluation={
                  basicThinking.finalEvaluationMyScore ?? undefined
                }
                finalBossEvaluation={
                  basicThinking.finalEvaluationBossScore ?? undefined
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ScoreArea = (props: { objectiveRecord: ObjectiveRecord }) => {
  const { objectiveRecord } = props;

  return (
    <div>
      <div className="flex items-center gap-[10px] mt-[30px]">
        <CalendarMonthIcon />
        <p className="text-ui">
          {formatDate(new Date(objectiveRecord.evaluationPeriod.startedAt))}~
          {formatDate(new Date(objectiveRecord.evaluationPeriod.endedAt))}
        </p>
      </div>

      <div className="flex mt-[11px]">
        <div className="flex items-center">
          <div className="rounded border-1 border-main-blue px-[6px] py-[4px] mr-[20px]">
            <p className="text-main-blue text-micro font-bold">評価スコア</p>
          </div>
          <p className="text-result mr-[6px]">
            {getCappedScore(objectiveRecord.score)}
          </p>
        </div>
        <p className="text-ui text-label self-end pb-[5px]">点</p>
      </div>

      <div className="py-[10px] min-w-[316px] rounded">
        <p className="text-label mb-[5px]">スコア内訳</p>
        <ul className="flex items-center">
          <EvaluationWeightsChartsItem
            name="マンダラ"
            score={objectiveRecord.scoreCommitment ?? 0}
            evaluateWeight={
              objectiveRecord.currentGrade?.weightQualitativeAsCommitment ?? 0
            }
            backgroundColor={COLORS.MAIN_BLUE}
            className="pr-[14px]"
          />
          <EvaluationWeightsChartsItem
            name="MVPコイン"
            score={objectiveRecord.scoreMvp ?? 0}
            evaluateWeight={
              objectiveRecord.currentGrade?.weightQualitativeAsMvp ?? 0
            }
            backgroundColor={COLORS.SUB_GREEN}
            className="pr-[14px]"
          />
          <EvaluationWeightsChartsItem
            name="MBO"
            score={objectiveRecord.scoreMbo ?? 0}
            evaluateWeight={
              objectiveRecord.currentGrade?.weightQuantitative ?? 0
            }
            backgroundColor={COLORS.SUB_PINK}
          />
        </ul>
      </div>
    </div>
  );
};

export const PromotionDetailModal = (
  props: PropsWithChildren<{
    promotionLog: PromotionLog;
    objectiveRecord: ObjectiveRecord;
    grade: Grade;
    userType: UserType;
    onClose: () => void;
    onSave: (editedData: EditingDataType) => void;
  }>
) => {
  const { objectiveRecord, promotionLog } = props;

  const [editingPromotionLog, setEditingPromotionLog] =
    useState<EditingDataType>({
      salary: props.promotionLog?.salary || null,
      isPromoted: props.promotionLog?.isPromoted || false,
      isTemporaryFixed: props.promotionLog?.isTemporaryFixed || false,
    });

  useEffect(() => {
    if (
      editingPromotionLog.isPromoted &&
      (editingPromotionLog.salary === 0 || editingPromotionLog.salary === null)
    ) {
      setEditingPromotionLog({
        ...editingPromotionLog,
        salary: objectiveRecord.nextGradeSalaryIncludeingAllowances,
      });
    }
  }, [editingPromotionLog.isPromoted]);

  // 不明な場合は読み取り専用
  const isReadonly = promotionLog === undefined ? true : promotionLog.isFixed;
  const isWithdrawal =
    objectiveRecord.onsiteUser.appUsageState === 'withdrawal';

  let buttonText = '退職者のため保存ができません';
  if (!isWithdrawal) {
    buttonText = isReadonly ? '既に確定しているため保存できません' : '保存';
  }

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            disabled={isWithdrawal || isReadonly}
            className="w-btn-small text-btn-confirm p-button-small whitespace-nowrap"
            style={{
              backgtoundColor:
                isWithdrawal || isReadonly ? COLORS.GRAY_700 : '',
              width: isWithdrawal || isReadonly ? STYLES.WIDTH.BTN_CONFIRM : '',
              fontSize:
                isWithdrawal || isReadonly ? STYLES.FONT_SIZE.BTN_LARGE : '',
            }}
            onClick={() => props.onSave(editingPromotionLog)}
          >
            {buttonText}
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div>
          <h3 className="text-section-heading font-bold">
            {LABEL_MAP[props.userType]}
            最終評価
          </h3>
          <div className="grid grid-cols-2 pt-[40px]">
            <div>
              <OnsiteUserName
                image={objectiveRecord.onsiteUser.avatar || ''}
                fullName={`${objectiveRecord.onsiteUser.lastName} ${objectiveRecord.onsiteUser.firstName}`}
              />
              <OnsiteUserAffiliation
                shopName={objectiveRecord.onsiteUser.shopName}
                occupationName={objectiveRecord.onsiteUser.occupationName}
                positionName={objectiveRecord.onsiteUser.gradePositionName}
                gradeName={objectiveRecord.onsiteUser.gradeName}
                wealthProfileResult={
                  objectiveRecord.onsiteUser.wealthResultProfile
                }
                isTextCenter={false}
              />
            </div>
            <div>
              <div className="flex flex-col items-start pt-[14px] max-w-[240px]">
                {promotionLog !== undefined ? (
                  <PromitionSwitch
                    readonly={isWithdrawal || isReadonly}
                    isPromoted={editingPromotionLog.isPromoted}
                    setIsPromoted={(value) => {
                      setEditingPromotionLog({
                        ...editingPromotionLog,
                        isPromoted: value,
                      });
                    }}
                    salary={editingPromotionLog.salary}
                    setSalary={(value) => {
                      setEditingPromotionLog({
                        ...editingPromotionLog,
                        salary: value,
                      });
                    }}
                    nextGradeWageType={objectiveRecord.nextGradeWageType}
                  />
                ) : (
                  <p className="text-base">
                    評価スコアが決まっていないため昇給記録をつけることができません
                  </p>
                )}
                {editingPromotionLog.isPromoted && (
                  <div className="ml-auto mt-[10px]">
                    <div className="flex items-center gap-[4px]">
                      {objectiveRecord.onsiteUser.allowances.length > 0 && (
                        <>
                          <Tooltip
                            content={objectiveRecord.onsiteUser.allowances.map(
                              (allowance) => {
                                return {
                                  ...allowance,
                                  amount: allowance.amount.toLocaleString(),
                                };
                              }
                            )}
                          />
                          <span className="text-label text-main-blue font-bold">
                            手当{' '}
                            {objectiveRecord.onsiteUser.allowances
                              .reduce(
                                (total, allowance) => total + allowance.amount,
                                0
                              )
                              .toLocaleString()}
                            円を含む
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center pt-[12px]">
                <p className="text-label mr-[6px]">現在の等級</p>
                <p className="text-meta mr-[16px]">
                  {props.grade.currentGradeName}
                </p>
                <p className="text-meta font-medium mr-[16px]">→</p>
                <p className="text-label text-main-blue mr-[6px]">
                  昇給後の等級
                </p>
                <p className="text-meta text-main-blue font-bold">
                  {props.grade.nextGradeName}
                </p>
              </div>
            </div>
          </div>

          <Divider className="mt-[30px]" />

          <div className="flex items-center">
            <ScoreArea objectiveRecord={objectiveRecord} />
            {objectiveRecord?.previousObjectiveRecord?.score !== undefined &&
              objectiveRecord?.previousObjectiveRecord?.score !== null && (
                <ScoreArea
                  objectiveRecord={objectiveRecord.previousObjectiveRecord}
                />
              )}
          </div>

          <Divider className="mt-[30px]" />

          <BasicThinkingArea objectiveRecord={objectiveRecord} />
        </div>
      </BaseModal>
    </Dialog>
  );
};
