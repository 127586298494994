import { TableRow, TableRowProps, TableRowTypeMap } from '@mui/material';
import {
  EvaluationMeasure,
  EvaluationMeasureType,
  getName,
  getTypeName,
  getTypeUnit,
} from 'context/corporation/evaluation_measures/types/evaluationMeasure';
import { BaseTableBorderCell } from 'infra/components/UI/Table/BaseTableBorderCell';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { EditEvaluationMeasureModal } from 'context/corporation/evaluation_measures/components/EditEvaluationMeasureModal';
import { PropsWithChildren } from 'react';
import { COLORS } from 'tailwind.config';

export const MeasureTableRow = (
  props: TableRowProps<
    TableRowTypeMap['defaultComponent'],
    {
      evaluationMeasureId: number;
      slug: keyof Omit<EvaluationMeasure, 'id'>;
      measure: number[];
      type: EvaluationMeasureType;
      onChanged: () => void;
      bgClass?: string;
    }
  >
) => {
  const name = getName(props.slug);
  const typeName = getTypeName(props.type);
  const typeUnit = getTypeUnit(props.type);

  return (
    <TableRow style={{ backgroundColor: `${props.bgClass}` }}>
      <BaseTableBorderCell align="center">
        <p className="text-meta font-bold">{name}</p>
      </BaseTableBorderCell>
      <LocalCell>
        {typeName}が<b>{props.measure[0] + typeUnit}</b>
        未満
      </LocalCell>
      {props.measure.map((value, index) => {
        if (index > 0) {
          return (
            <LocalCell
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              backGroundColor={
                props.slug === 'finalEvaluation' && index > 2
                  ? COLORS.SOFT_BLUE
                  : undefined
              }
              textColor={
                props.slug === 'finalEvaluation' && index > 2
                  ? COLORS.GRAY_800
                  : undefined
              }
            >
              {`${typeName}が`}
              {props.measure[index - 1] + typeUnit}
              以上
              <br />
              {value + typeUnit}
              未満
            </LocalCell>
          );
        }
        return null;
      })}
      <LocalCell
        backGroundColor={
          props.slug === 'finalEvaluation' ? COLORS.SOFT_BLUE : undefined
        }
        textColor={
          props.slug === 'finalEvaluation' ? COLORS.GRAY_800 : undefined
        }
      >
        {`${typeName}が`}
        {props.measure[props.measure.length - 1] + typeUnit}
        以上
      </LocalCell>
      <BaseTableBorderCell
        align="center"
        sx={{ paddingX: '13px', paddingY: '9px' }}
        style={{ backgroundColor: `${props.bgClass}` }}
        className="fixed-column"
      >
        <EditEvaluationMeasureModal
          evaluationMeasureId={props.evaluationMeasureId}
          slug={props.slug}
          measure={props.measure}
          type={props.type}
          onChanged={props.onChanged}
          render={(openModal) => (
            <BaseButton
              className="w-btn-cell p-button-small font-normal"
              onClick={() => {
                openModal();
              }}
            >
              編集
            </BaseButton>
          )}
        />
      </BaseTableBorderCell>
    </TableRow>
  );
};

const LocalCell = (
  props: PropsWithChildren<{
    backGroundColor?: string;
    textAlign?: 'right' | 'left';
    textColor?: string;
  }>
) => {
  return (
    <BaseTableBorderCell
      sx={{
        verticalAlign: 'top',
        padding: '10px',
        textAlign: props.textAlign,
      }}
      backGroundColor={props.backGroundColor}
    >
      <p
        className="text-grayscale-700 text-meta"
        style={{ color: props.textColor }}
      >
        {props.children}
      </p>
    </BaseTableBorderCell>
  );
};
