import { Button } from '@mui/base/Button';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import { Divider } from 'infra/components/UI/Elements/Divider';
import ReplaceImg from 'context/corporation/brand/assets/replace_img01.png';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { Select } from 'infra/components/UI/Forms/Select';
import { FileField } from 'infra/components/UI/Forms/FileField';

type FormType = {
  businessType: string;
  name: string;
  brandLogo?: string;
};

interface BusinessTypeHint {
  [key: string]: string;
}
export const BUSINESS_TYPE_LIST: BusinessTypeHint = {
  japaneseFood: '和食',
  italian: 'イタリアン',
  french: 'フレンチ',
  japanesePub: '居酒屋',
  cafe: 'カフェ',
  chaKaiseki: '日本料理・懐石',
  sushi: '寿司',
  patissier: 'パティスリー・製菓',
  bakery: 'ベーカリー・製パン',
  westernFood: '洋食・西洋料理',
  chineseFood: '中華料理',
  chicken: '焼き鳥・鳥料理',
  koreanBarbecue: '焼肉・肉料理',
  stake: 'ステーキ・鉄板焼',
  ramen: 'ラーメン',
  noodle: 'うどん・蕎麦',
  pub: 'バル',
  spanishFood: 'スペイン料理',
  asianFood: 'アジアン・エスニック',
  bar: 'BAR',
  hotel: 'ホテル・旅館',
  wedding: 'ウェディング',
  specialty: '専門店（各国料理）',
  meatAndFish: '精肉・鮮魚',
  deli: '惣菜・DELI・仕出し・弁当',
  cafeteria: '給食・社員食堂・集団料理',
  other: 'その他',
};

export const getBrandName = (key: any) => {
  if (Object.prototype.hasOwnProperty.call(BUSINESS_TYPE_LIST, key)) {
    return BUSINESS_TYPE_LIST[key];
  }

  return '';
};

export const BrandForm = (
  props: PropsWithChildren<{
    useForm: UseFormReturn<FormType>;
    onSubmit: () => void;
    actionLabel: string;
  }>
) => {
  const navigate = useNavigate();

  return (
    <div className="p-[40px] w-full">
      <h2 className="pb-[16px] font-bold text-base">業態</h2>

      <div className="mb-[30px]">
        <Select
          name="businessType"
          useForm={props.useForm}
          menuItems={Object.entries(BUSINESS_TYPE_LIST).map((businessType) => {
            return {
              label: businessType[1],
              value: businessType[0],
            };
          })}
          isErrorFiled={!!props.useForm.formState.errors.businessType}
        />
      </div>

      <h2 className="pb-[16px] font-bold text-base">ブランド名</h2>
      <div className="pb-[30px]">
        <TextField
          useForm={props.useForm}
          name="name"
          isErrorFiled={!!props.useForm.formState.errors.name}
          className="w-[320px]"
        />
      </div>

      <h2 className="pb-[6px] font-bold text-base">ブランドロゴ</h2>

      <p className="text-grayscale-700 text-meta mb-[20px]">
        500KB 以下のファイルを選択してください。
      </p>
      <FileField
        useForm={props.useForm}
        name="brandLogo"
        replaceImg={ReplaceImg}
      >
        画像を選択
      </FileField>

      <Divider className="mt-[60px]" />

      <div className="pt-[40px] gap-[40px] flex justify-center">
        <Button onClick={() => navigate(-1)}>
          <p className="text-grayscale-700 font-normal">キャンセル</p>
        </Button>

        <BaseButton
          className="w-btn-small tracking-short-text text-btn-large p-button-small"
          onClick={props.onSubmit}
        >
          {props.actionLabel}
        </BaseButton>
      </div>
    </div>
  );
};
