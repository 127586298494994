import { z } from 'zod';

/**
 * zodのエラーのカスタマイズ
 * NOTE: https://qiita.com/kjkj_ongr/items/0eff5173b6e4fce7fbe8#%E3%82%A8%E3%83%A9%E3%83%BC%E3%81%AE%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA
 * NOTE: https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
 */
const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === 'string') {
      return { message: '入力してください' };
    }
    if (issue.expected === 'number') {
      return { message: '入力してください' };
    }

    if (issue.expected === 'boolean') {
      return { message: '入力してください' };
    }
  }

  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (issue.validation === 'email') {
      return { message: 'メールアドレスの形式で入力してください' };
    }
  }

  if (issue.code === z.ZodIssueCode.invalid_date) {
    return { message: '日付を入力してください' };
  }

  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === 'string') {
      return { message: `${issue.minimum}文字以上で入力してください` };
    }
    if (issue.type === 'number') {
      return {
        message: `${issue.minimum}${
          issue.inclusive ? '以上' : 'より上'
        }の数値にしてください`,
      };
    }
  }
  if (issue.code === z.ZodIssueCode.too_big) {
    if (issue.type === 'string') {
      return { message: `${issue.maximum}文字以内で入力してください` };
    }
    if (issue.type === 'array') {
      return {
        message: `最大でも${issue.maximum}つ${
          issue.inclusive ? '以下' : '未満'
        }にしてください`,
      };
    }
    if (issue.type === 'number') {
      return {
        message: `${issue.maximum}${
          issue.inclusive ? '以下' : '未満'
        }の数値にしてください`,
      };
    }
  }

  // 上記以外はデフォルトで定義されたエラーを返す
  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
