/**
 * 運用設定 評価尺度設定関連ルーティング
 */
import { EvaluationMeasureSetting } from 'context/corporation/evaluation_measures/components';
import { RouteType } from 'infra/config/routes.types';
import { Route } from 'react-router-dom';

export const EvaluationMeasureSettingRoute: RouteType = {
  path: '/operation-setting/evaluation-measure-setting',
  element: <EvaluationMeasureSetting />,
  do_it: () => (
    <Route
      path={EvaluationMeasureSettingRoute.path}
      element={EvaluationMeasureSettingRoute.element}
    />
  ),
};

export const routes = () => <>{EvaluationMeasureSettingRoute.do_it()}</>;
