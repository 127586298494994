import { apiClient, createOption, MY_TENANT_URL } from 'infra/api/config';

export const importMandalatMaster = async (csvFile: File) => {
  const form = new FormData();

  form.append('file', csvFile);

  const response = await apiClient.post(
    MY_TENANT_URL('mandalat/mandalat_master/import/'),
    form,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  return response;
};
