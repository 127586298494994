import { Dialog } from '@mui/material';
import { OnsiteUserListResponse } from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { OnsiteUserAffiliation } from 'context/objective/components/ObjectiveRecordList/OnsiteUserAffiliation';
import { OnsiteUserName } from 'context/objective/components/ObjectiveRecordList/OnsiteUserName';
import { Link } from 'react-router-dom';

export const EvaluateeListModal = (props: {
  onClose: () => void;
  evaluateeList: OnsiteUserListResponse['evaluateeOnsiteUsers'];
}) => {
  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <div className="w-[820px] py-[58px] px-[40px]">
        <h3 className="text-section-heading font-bold pb-[30px]">
          被評価者一覧
        </h3>
        {props.evaluateeList.length === 0 && (
          <p className="text-base font-bold">被評価者はいません</p>
        )}

        <div className="grid grid-cols-3 gap-[16px]">
          {props.evaluateeList
            .filter((evaluatee) => evaluatee.appUsageState !== 'withdrawal')
            .map((evaluatee) => {
              return (
                <div
                  key={evaluatee.id}
                  className={evaluatee.isSuspended ? 'opacity-suspend' : ''}
                >
                  <Link to={`/staffs_manage/staffs/${evaluatee.id}`}>
                    <div className="px-[18px] py-[20px] border-1 border-grayscale-500 rounded">
                      <OnsiteUserName
                        image={evaluatee.avatar || ''}
                        fullName={`${evaluatee.lastName} ${evaluatee.firstName}`}
                      />
                      <OnsiteUserAffiliation
                        shopName={evaluatee.shop?.name}
                        occupationName={evaluatee.occupation?.name}
                        gradeName={evaluatee.grade?.name}
                        positionName={evaluatee.grade?.position.name}
                      />
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </Dialog>
  );
};
