import useAxios from 'axios-hooks';
import { createOption, MY_TENANT_URL } from 'infra/api/config';
import { hasUserOutsideManagerAtom } from 'context/auth/store/auth';
import { useAtom } from 'jotai';
import { getCoporationAdminWithStoreUserInfo } from 'context/users/corporation_admin/api/corporationAdmin.get';
import { getOutsideManagerWithStoreUserInfo } from 'context/users/outside_managers/api/outsideManagers.get';

type ResponseLogin = {
  accessToken: string;
  refreshToken: string;
  user: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    isOutsideManager: boolean;
    isCorporationAdmin: boolean;
  };
};

export const useLogin = () => {
  const [data, execute] = useAxios<ResponseLogin>(
    {
      url: MY_TENANT_URL('users/auth/login'),
      method: 'post',
      ...createOption(null),
    },
    { manual: true }
  );

  return [
    data,
    (requestData: { email: string; password: string }) => {
      return execute({ data: requestData });
    },
  ] as const;
};

export const getLoginInfo = () => {
  const [hasUserOutsideManager] = useAtom(hasUserOutsideManagerAtom);
  if (hasUserOutsideManager) {
    return getOutsideManagerWithStoreUserInfo(true);
  }

  return getCoporationAdminWithStoreUserInfo(true);
};
