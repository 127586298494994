import { AppBar, Chip, Toolbar } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useAtom } from 'jotai';
import {
  inputedScoreListAtom,
  ValidatedScore,
} from 'context/objective/store/evaluationByOutsideManager';
import { Link } from 'react-router-dom';
import { ProggressBar } from 'infra/components/UI/Steps/ProggressBar';
import LogoImage from 'context/objective/components/evaluate/EvaluationByOutsideManager/Layout/assets/logo_transparent.png';
import { COLORS, STYLES } from 'tailwind.config';
import { EvaluationLayoutFooter } from './EvaluationLayoutFooter';

const BOTH_SIDES_WIDTH = 180;

/** 入力しなければならないスコアの数 */
const ALL_SCORE_COUNT = 8;

/** 評価画面専用レイアウト */
export const EvaluationLayout = (
  props: PropsWithChildren<{
    evaluationType: 'middle' | 'final';
    displayFixButton: boolean;
    displayProggress: boolean;
    onBack: () => void;
    onFixEvaluation: (scoreList: ValidatedScore[]) => void;
  }>
) => {
  const [scoreList] = useAtom(inputedScoreListAtom);

  return (
    // 横幅が大きくなりすぎると各アイテムの縦幅も大きくなりすぎるため
    <div className="flex min-h-[100vh] flex-col relative bg-grayscale-400">
      <AppBar
        elevation={0}
        style={{
          boxShadow: STYLES.BOX_SHADOW.MAIN,
          backgroundColor: COLORS.GRAY_100,
          color: COLORS.GRAY_800,
        }}
      >
        <Toolbar variant="dense">
          <div
            className="flex items-center pl-[16px] h-[50px]"
            style={{ width: `${BOTH_SIDES_WIDTH}px` }}
          >
            <Link to="/">
              <img src={LogoImage} className="h-[26px]" alt="ロゴ" />
            </Link>
          </div>

          <div className="flex flex-1 items-center justify-center gap-[8px]">
            {props.displayProggress && (
              <>
                <p className="text-ui font-bold text-main-blue pr-[8px]">
                  {scoreList.length}/{ALL_SCORE_COUNT}
                </p>

                <ProggressBar
                  showPercentageOnTop={false}
                  allCount={ALL_SCORE_COUNT}
                  completedCount={scoreList.length}
                />
                <Chip
                  sx={{
                    backgroundColor: '#000',
                    color: '#FFF',
                    height: 24,
                    width: 50,
                    fontSize: 13,
                    '& .MuiChip-label': {
                      paddingRight: '4px',
                      paddingLeft: '4px',
                    },
                  }}
                  label={`${Math.round(
                    (scoreList.length / ALL_SCORE_COUNT) * 100
                  )}%`}
                />
              </>
            )}
          </div>

          <p
            className="text-base text-[16px]"
            style={{ width: `${BOTH_SIDES_WIDTH}px` }}
          >
            {props.evaluationType === 'middle'
              ? 'マネジャー中間評価'
              : 'マネジャー最終評価'}
          </p>

          {/* アバターを持っていないので非表示 */}
        </Toolbar>
      </AppBar>
      <Toolbar variant="dense" />
      <main className="flex bg-grayscale-400 flex-grow">
        <div className="flex-grow min-w-0">{props.children}</div>
      </main>

      <div className="fixed bottom-0 w-full p-0">
        <EvaluationLayoutFooter
          displayFixButton={props.displayFixButton}
          onConfirm={() => {
            // eslint-disable-next-line no-alert
            if (!window.confirm('本当に確定してよろしいですか？')) return false;
            props.onFixEvaluation(
              scoreList.map((s) => ({ ...s, score: s.score! }))
            );
            return true;
          }}
          onBack={props.onBack}
          evaluationType={props.evaluationType}
        />
      </div>
    </div>
  );
};
