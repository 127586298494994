/* eslint-disable react/no-unused-prop-types */
import { useFieldArray } from 'react-hook-form';
import { FieldDeleteIcon } from 'infra/components/UI/Icons/FieldDeleteIcon';
import { FieldAddButton } from 'infra/components/UI/Buttons/FieldAddButton';
import { useAtom } from 'jotai';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import { useDisplayNonFieldApiError } from 'infra/components/UI/Overlays/useDisplayNonFieldApiError';
import { SubOccupationEditForm } from 'context/corporation/occupation/components/Occupation/EditForm/SubOccupation/SubOccupationEditForm';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { deleteOccupation } from 'context/corporation/occupation/api/deleteOccupation';

export const OccupationEditForm = (props: {
  onSaved: () => void;
  form: any;
}) => {
  const { control } = props.form;

  const { fields, append, remove } = useFieldArray({
    keyName: 'localId',
    name: 'occupations',
    control,
  });

  const addField = () => {
    append({
      id: null,
      name: '',
      subOccupations: [],
    });
  };

  const [, displayMessage] = useAtom(displayMessageAtom);
  const displayNonFieldApiError = useDisplayNonFieldApiError();

  const deleteField = async (field: any, index: number) => {
    if (!field.id) {
      props.onSaved();
      remove(index);
      return;
    }

    // eslint-disable-next-line no-alert
    if (!window.confirm('本当に削除してもよろしいですか？')) {
      return;
    }
    await deleteOccupation(field.id)
      .then(() => {
        displayMessage({ text: '職種を削除しました。', isInterruption: false });
        remove(index);
        props.onSaved();
      })
      .catch((error) => {
        displayNonFieldApiError(error);
      });
  };

  if (fields.length === 0) {
    addField();
  }

  return (
    <form>
      <p className="text-base font-bold mb-[24px]">職種</p>
      <div className="grid grid-flow-row mb-[30px] gap-[20px]">
        {fields?.map((field: any, index: number) => {
          return (
            <div className="grid grid-flow-row gap-[20px] items-center grid-cols-[320px,auto]">
              <TextField
                name={`occupations.${index}.name`}
                useForm={props.form}
                isErrorFiled={
                  !!props.form.formState.errors?.occupations?.[index]?.name
                }
                className="w-[320px]"
                placeholder="職種名を入力してください"
                key={field.localId}
              />
              <FieldDeleteIcon
                onClick={() => {
                  deleteField(field, index);
                }}
              />

              {field.subOccupations && (
                <div className="flex w-full justify-start ml-[40px]">
                  <SubOccupationEditForm
                    onSaved={props.onSaved}
                    outerIndex={index}
                    form={props.form}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <FieldAddButton
        label="職種を追加"
        onClickHandler={() => {
          addField();
        }}
      />
    </form>
  );
};
