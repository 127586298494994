export const StarIcon = () => (
  <svg
    id="コンポーネント_101_5"
    data-name="コンポーネント 101 – 5"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30.646"
    viewBox="0 0 32 30.646"
  >
    <path
      id="star.1"
      d="M16.063,3.554a2.16,2.16,0,0,1,3.875,0L23.711,11.2l8.437,1.226a2.16,2.16,0,0,1,1.2,3.685L27.24,22.062l1.441,8.4a2.16,2.16,0,0,1-3.135,2.277L18,28.775l-7.547,3.968a2.16,2.16,0,0,1-3.135-2.277l1.441-8.4L2.655,16.111a2.16,2.16,0,0,1,1.2-3.685L12.289,11.2ZM18,5.053l-3.614,7.323a2.16,2.16,0,0,1-1.627,1.182L4.679,14.731l5.847,5.7a2.161,2.161,0,0,1,.621,1.912l-1.38,8.048,7.228-3.8a2.16,2.16,0,0,1,2.011,0l7.228,3.8-1.38-8.048a2.161,2.161,0,0,1,.621-1.912l5.847-5.7L23.24,13.557a2.16,2.16,0,0,1-1.626-1.182Z"
      transform="translate(-2 -2.35)"
      fill="#048cf2"
    />
  </svg>
);
