import React, { ComponentProps, FC } from 'react';
import { Button } from '@mui/base/Button';
import { twMerge } from 'tailwind-merge';

type BaseButtonProps = ComponentProps<typeof Button> & {
  className?: string;
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  startIconMargin?: string;
  endIcon?: React.ReactNode;
  endIconMargin?: string;
  disabled?: boolean;
};

export const BaseButton: FC<BaseButtonProps> = ({
  children,
  className,
  startIcon,
  startIconMargin = 'mr-[10px]',
  endIcon,
  endIconMargin = 'ml-3',
  disabled = false,
  ...otherProps
}) => {
  const defaultClasses = `
    w-btn-base p-button max-w-full
    bg-main-blue rounded font-bold text-center text-base text-grayscale-100
    base-hover
  `;

  const disabledClasses =
    'w-btn-base p-button !bg-grayscale-500 !text-grayscale-100 font-bold text-base rounded !cursor-default';
  const baseClasses = disabled ? disabledClasses : defaultClasses;
  const mergedClasses = twMerge(baseClasses, className);

  return (
    <Button
      className={`text-grayscale-100 ${mergedClasses}`}
      disabled={disabled}
      {...otherProps}
    >
      <div className="flex justify-center items-center">
        {startIcon && <div className={startIconMargin}>{startIcon}</div>}
        {children}
        {endIcon && <div className={endIconMargin}>{endIcon}</div>}
      </div>
    </Button>
  );
};
