import { apiClient } from 'infra/api/config';

export const bulkUpdateBoss = (staffIdList: string[], bossId: string) => {
  return apiClient.patch(
    '/tenant/my/user/outside_managers/onsite_users/bulk_update_boss/',
    {
      staffs: staffIdList,
      boss: bossId,
    }
  );
};
