import useAxios from 'axios-hooks';
import { useAtom } from 'jotai';
import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';
import { ResponsePagination } from 'infra/api/type';

import { userIdAtom } from 'context/auth/store/auth';
import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';

export const URL = '/tenant/my/user/corporation_admin/';
export type PagedSuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/corporation_admin/']['get']['responses'][200]['content']['application/json']
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/corporation_admin/{id}/']['get']['responses'][200]['content']['application/json'] & {
    dateOfBirth?: string;
  }
>;

/**
 * 企業担当者情報一覧の取得
 * @param useCache
 * @returns
 */
export const getCoporationAdmins = (useCache = false) => {
  const response = useAxios<PagedSuccessResponse>(
    {
      url: URL,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};

/**
 * 企業担当者情報詳細の取得
 * @param useCache
 * @returns
 */
export const getCoporationAdminById = (userId: string, useCache = false) => {
  const response = useAxios<SuccessResponse>(
    {
      url: `${URL}${userId}/`,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};

/**
 * store情報から担当者情報の取得
 * @param useCache
 * @returns
 */
export const getCoporationAdminWithStoreUserInfo = (useCache = false) => {
  const [userId] = useAtom(userIdAtom);
  const response = useAxios<SuccessResponse>(
    {
      url: `${URL}${userId}/`,
    },
    { useCache }
  );

  return response;
};

export const fetchCorporationAdmin = (
  limit: number,
  offset: number,
  searchParams: URLSearchParams
) => {
  const params = Object.fromEntries(searchParams.entries());
  const response = apiClient.get<ResponsePagination<PagedSuccessResponse>>(
    URL,
    {
      params: {
        limit,
        offset,
        ...params,
      },
    }
  );

  return response;
};
