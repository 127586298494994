export const BasicThinkingTitle = (props: {
  color: string;
  basicThinkingLabel: string;
}) => {
  return (
    <div className="flex items-center gap-[12px]">
      <div
        className="h-[24px] w-[8px]"
        style={{ backgroundColor: props.color }}
      />
      <p className="font-bold text-item-heading">{props.basicThinkingLabel}</p>
    </div>
  );
};
