import { apiClient, MY_TENANT_URL } from 'infra/api/config';
import { ResponsePagination } from 'infra/api/type';
import { ResponsePaginatedCorporation } from 'context/corporation/corporation/api/types';

export const fetchCorporationBrands = (
  limit: number,
  offset: number,
  searchParams: URLSearchParams
) => {
  const params = Object.fromEntries(searchParams.entries());
  const response = apiClient.get<
    ResponsePagination<ResponsePaginatedCorporation>
  >(MY_TENANT_URL('corporation/brand/'), {
    params: { limit, offset, ...params },
  });
  return response;
};
