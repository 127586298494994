/**
 * カスタムセレクトボックスです。
 * このセレクトボックスは、指定されたデータと検索キーを使用して、選択可能なオプションを表示します。
 *  - `data`のデータ型はこのようなものです。例）業態一覧 -> [{ id: 1, name: 'イタリアン' }, { id: 2, name: 'アジアン' }]
 * ドロップダウン機能を持ち、ドロップダウン外のクリックで自動的に閉じる機能も備えています。
 *
 * Props:
 * - `data`: セレクトボックスに表示するオプションのリスト。例）業態一覧 -> [{ id: 1, name: 'イタリアン' }, { id: 2, name: 'アジアン' }]など
 * - `searchKey`: 検索時に使用するキー。このキーに基づいて選択されたオプションがフィルタリングされます。
 */

import { useState } from 'react';
import { ArrowDownIcon } from 'infra/components/UI/Icons/ArrowDownIcon';
import { HackCloseDropDown } from 'infra/components/UI/Forms/utils/HackCloseDropDown';
import { Types } from 'infra/components/UI/Table/Pagination/Pagination';

export const SelectBoxFilter = ({
  data,
  searchKey,
  pagerState,
  order,
  className,
  dropboxClassName,
}: {
  data?: any;
  searchKey: string;
  pagerState: Types.PagerState;
  order?: 'labelAsc';
  className?: string;
  dropboxClassName?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function sortMenuItems(a: any, b: any) {
    if (order === 'labelAsc') {
      return a?.name?.toString()?.localeCompare(b?.name?.toString()!);
    }

    return true;
  }

  const mapNameById: { [key: string]: string } =
    data?.reduce((acc: any, item: any) => {
      acc[item.id.toString()] = item.name;
      return acc;
    }, {}) || {};

  const searchValue = pagerState.searchParams.get(searchKey) || 'デフォルト値';
  const selectedName = mapNameById[searchValue] || 'すべて';

  return (
    <div className="relative">
      <button
        type="button"
        className={`
            relative w-[200px] py-[8px] pl-[12px] pr-[20px]
            border-1 border-grayscale-600 rounded text-ui text-left bg-grayscale-100
            select-hover
            ${className || ''}
          `}
        onClick={() => {
          toggleDropdown();
        }}
      >
        {selectedName}
        <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
          <ArrowDownIcon />
        </div>
      </button>
      {isOpen && (
        <div
          className={`
              absolute left-0 top-[42px] w-full overflow-y-auto max-h-[308px] z-10 py-[6px]
              flex flex-col border-1 border-grayscale-500 rounded text-ui hidden-scrollbar
              shadow-main bg-grayscale-100
              ${dropboxClassName}
            `}
        >
          <button
            type="button"
            key="0"
            className="p-[14px] pl-[24px] text-left pulldown-hover bg-selectbox"
            onMouseDown={() => {
              pagerState.setSearchParams((prev: any) => {
                prev.set(searchKey, '');
                return prev;
              });
              toggleDropdown();
            }}
          >
            すべて
          </button>
          {data?.sort(sortMenuItems).map((item: any) => (
            <button
              type="button"
              key={item.id}
              className="p-[14px] pl-[24px] text-left pulldown-hover bg-selectbox"
              onMouseDown={() => {
                pagerState.setSearchParams((prev: any) => {
                  prev.set(searchKey, item.id);
                  return prev;
                });
                toggleDropdown();
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
      )}

      <HackCloseDropDown isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
