import useAxios from 'axios-hooks';
import { useAtom } from 'jotai';

import { apiClient } from 'infra/api/config';
import { paths } from 'infra/api/schema';
import { SnakeObjToCamelObj } from 'infra/api/snakeToCamel';

import { userIdAtom } from 'context/auth/store/auth';
import { useNonFieldApiErrorCallback } from 'infra/components/UI/Overlays/useNonFieldApiErrorCallback';
import { ResponsePagination } from 'infra/api/type';

export const URL = '/tenant/my/user/outside_managers/';
export type PagedSuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/outside_managers/']['get']['responses'][200]['content']['application/json']
>;
export type SuccessResponse = SnakeObjToCamelObj<
  paths['/tenant/my/user/outside_managers/{id}/']['get']['responses'][200]['content']['application/json'] & {
    dateOfBirth?: string;
  }
>;
export type EvaluatorListType =
  paths['/tenant/my/user/outside_managers/{id}/']['get']['responses'][200]['content']['application/json']['evaluator_list'];

/**
 * 自企業のマネジャー評価者一覧の取得
 * @param useCache
 * @returns
 */
export const getOutsideManagers = (useCache = false) => {
  const response = useAxios<PagedSuccessResponse>(
    {
      url: URL,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};

/**
 * 自企業のマネジャー評価者の詳細取得
 * @param useCache
 * @returns
 */
export const getOutsideManagerById = (userId: string, useCache = false) => {
  const response = useAxios<SuccessResponse>(
    {
      url: `${URL}${userId}/`,
    },
    { useCache }
  );
  useNonFieldApiErrorCallback(response[0].error);

  return response;
};

/**
 * store情報からマネージャ評価者情報の取得
 * @param useCache
 * @returns
 */
export const getOutsideManagerWithStoreUserInfo = (useCache = false) => {
  const [userId] = useAtom(userIdAtom);
  const response = useAxios<SuccessResponse>(
    {
      url: `${URL}${userId}/`,
    },
    { useCache }
  );

  return response;
};

/**
 * マネジャー評価者一覧の取得
 */
export const fetchOutsideManagers = (
  limit: number,
  offset: number,
  searchParams: URLSearchParams
) => {
  const params = Object.fromEntries(searchParams.entries());
  const response = apiClient.get<ResponsePagination<EvaluatorListType>>(URL, {
    params: {
      limit,
      offset,
      ...params,
    },
  });

  return response;
};
