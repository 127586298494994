import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  AvatarGroup,
} from '@mui/material';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { PropsWithChildren, useState, useEffect } from 'react';
import { MoreMenu } from 'infra/components/UI/Menus/MoreMenu';
import { z } from 'zod';
import { useAtom } from 'jotai';

import {
  Pagination,
  usePagerState,
  useFilterAreaContext,
  FilterAreaContext,
} from 'infra/components/UI/Table/Pagination/Pagination';
import {
  startLoadingAtom,
  stopLoadingAtom,
} from 'infra/components/UI/Overlays/atomLoading';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';
import {
  getOutsideManagers,
  SuccessResponse,
  fetchOutsideManagers,
} from 'context/users/outside_managers/api/outsideManagers.get';
import { postOutsideManager } from 'context/users/outside_managers/api/outsideManagers.post';
import { patchOutsideManager } from 'context/users/outside_managers/api/outsideManagers.patch';
import { BaseTableBodyCell } from 'infra/components/UI/Table/BaseTableBodyCell';
import { COLORS } from 'tailwind.config';
import { OutsideManagerFilterArea } from 'context/users/outside_managers/components/OutsideManagerList/OutsideManagerFilterArea';
import { Contents } from 'infra/components/UI/Overlays/Contents';
import { TableHeaderCell } from 'infra/components/UI/Table/TableHeaderCell';
import { OutsideManagerEditDialog } from './OutsideManagerEditDialog';

export const OutsideManagerListContext = () => {
  const pagerState = useFilterAreaContext();
  useEffect(() => {
    pagerState.doitPaging(1);
  }, [pagerState.searchParams]);

  // 使用 getOutsideManagers() フックから refetch 関数のみを取得。
  // 最初の2つの要素 (通常は data と loading) は無視。
  const [, , refetch] = getOutsideManagers();

  const [editingOutsideManagerId, setEditingOutsideManagerId] = useState<
    string | null
  >(null);

  const [, startLoading] = useAtom(startLoadingAtom);
  const [, stopLoading] = useAtom(stopLoadingAtom);

  const [, displayMessage] = useAtom(displayMessageAtom);

  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);

  const onSave = async (outsideManagerForm: OutsideManagerFormWithPassword) => {
    startLoading('update_outside_manager');

    try {
      if (editingOutsideManagerId) {
        await patchOutsideManager({
          id: editingOutsideManagerId,
          email: outsideManagerForm?.email ?? '',
          firstName: outsideManagerForm?.firstName ?? '',
          lastName: outsideManagerForm?.lastName ?? '',
          position: outsideManagerForm?.position ?? '',
          dateOfBirth: outsideManagerForm?.dateOfBirth ?? '',
        });
        pagerState.doitPaging(pagerState.pager.page);
        displayMessage({
          text: 'マネジャー評価者情報を更新しました',
          isInterruption: false,
        });
      } else {
        outsideManagerForm as OutsideManagerFormWithPassword;
        await postOutsideManager({
          id: '',
          email: outsideManagerForm?.email ?? '',
          firstName: outsideManagerForm?.firstName ?? '',
          lastName: outsideManagerForm?.lastName ?? '',
          position: outsideManagerForm?.position ?? '',
          password: outsideManagerForm?.password ?? '',
          dateOfBirth: outsideManagerForm?.dateOfBirth ?? '',
        });

        pagerState.doitPaging(pagerState.pager.page);
        displayMessage({
          text: 'マネジャー評価者情報を追加しました',
          isInterruption: false,
        });
      }
    } finally {
      stopLoading('update_outside_manager');
      setIsOpenSaveModal(false);
    }
  };

  return (
    <div>
      <PageTitle title="マネジャー評価者設定" breadcrumbs={[['ホーム', '/']]} />

      <OutsideManagerFilterArea
        setIsOpenSaveModal={setIsOpenSaveModal}
        setEditingOutsideManagerId={setEditingOutsideManagerId}
      />

      <Contents isLoading={pagerState.pager.isLoading}>
        <div className="mt-[20px] flex justify-between items-center">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-grayscale-500">
              <TableRow>
                <TableHeaderCell orderValue="last_name" pagerState={pagerState}>
                  マネジャー評価者
                </TableHeaderCell>
                <TableHeaderCell orderValue="position" pagerState={pagerState}>
                  職位
                </TableHeaderCell>
                <TableHeaderCell orderValue="email" pagerState={pagerState}>
                  メールアドレス
                </TableHeaderCell>
                <TableHeaderCell>被評価者</TableHeaderCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {pagerState?.pager.items.map(
                (outsideManager: SuccessResponse, index: number) => {
                  return (
                    <Row
                      key={outsideManager.id}
                      onClick={() => {
                        setEditingOutsideManagerId(outsideManager.id);
                        setIsOpenSaveModal(true);
                      }}
                      outsideManager={outsideManager}
                      onEdit={() => {
                        setEditingOutsideManagerId(outsideManager.id);
                        setIsOpenSaveModal(true);
                      }}
                      index={index}
                      isStripedBackgroundColor
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Contents>

      {isOpenSaveModal && (
        <OutsideManagerEditDialog
          editingOutsideManagerId={editingOutsideManagerId}
          onClose={() => setIsOpenSaveModal(false)}
          onSave={onSave}
        />
      )}

      <div className="pt-[40px]">
        <Pagination pagerState={pagerState} />
      </div>
    </div>
  );
};

const Row = (
  props: PropsWithChildren<{
    onClick: () => void;
    outsideManager: any;
    onEdit: () => void;
    index: number;
    isStripedBackgroundColor: boolean;
  }>
) => {
  const stripedBackgroundColor =
    props.index % 2 === 0 ? COLORS.GRAY_100 : COLORS.GRAY_200;
  return (
    <TableRow
      style={{
        backgroundColor: props.isStripedBackgroundColor
          ? `${stripedBackgroundColor}`
          : COLORS.GRAY_100,
      }}
    >
      <BaseTableBodyCell
        className="base-hover"
        onClick={props.onClick}
        component="th"
        scope="row"
      >
        <div className="flex gap-[12px] items-center text-ui base-hover">
          <span>{props.outsideManager.lastName}</span>
          <span>{props.outsideManager.firstName}</span>
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={props.onClick}>
        <span className="text-ui">{props.outsideManager.position}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={props.onClick}>
        <span className="text-ui">{props.outsideManager.email}</span>
      </BaseTableBodyCell>
      <BaseTableBodyCell className="base-hover" onClick={props.onClick}>
        <div className="flex items-center relative gap-[12px]">
          <AvatarGroup
            max={5}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                  fontSize: '12px',
                  backgroundColor: COLORS.GRAY_800,
                },
              },
            }}
          >
            {props.outsideManager.evaluatorList.map((evaluator: any) => (
              <Avatar
                key={evaluator.id}
                sx={{
                  width: 32,
                  height: 32,
                  border: '2px solid #FFF',
                }}
                alt="被評価者アイコン"
                src={evaluator.avatar}
              />
            ))}
          </AvatarGroup>
        </div>
      </BaseTableBodyCell>
      <BaseTableBodyCell>
        <div className="flex justify-end">
          <MoreMenu
            render={[
              {
                label: <span>編集</span>,
                onClick: () => {
                  props.onEdit();
                },
              },
            ]}
          />
        </div>
      </BaseTableBodyCell>
    </TableRow>
  );
};

export const schema = z.object({
  firstName: z.string().min(1, '名を入力してください。').optional(),
  lastName: z.string().min(1, '性を入力してください').optional(),
  position: z.string().min(1).optional().nullable(),
  email: z.string().email().optional().nullable(),
  dateOfBirth: z.string().optional().nullable(),
});

export const passwordScheme = z.object({
  password: z
    .string({ required_error: 'パスワードを入力してください。' })
    .min(8, 'パスワードは8文字以上で入力してください')
    .regex(
      /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/i,
      'パスワードは半角英数字混合で入力してください'
    ),
});

export type OutsideManagerForm = z.infer<typeof schema>;
export type OutsideManagerFormWithPassword = OutsideManagerForm &
  z.infer<typeof passwordScheme>;

export const OutsideManagerList = () => {
  const pagerState = usePagerState<SuccessResponse>(
    (limit: number, offset: number, searchParams: URLSearchParams) => {
      return fetchOutsideManagers(limit, offset, searchParams);
    },
    10
  );

  return (
    <FilterAreaContext.Provider value={pagerState}>
      <Contents isLoading={pagerState.pager.isLoading}>
        <OutsideManagerListContext />
      </Contents>
    </FilterAreaContext.Provider>
  );
};
