import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

export const GradeEditDialogState = () => {
  // ドーナッツチャートの中央の合計値をリアルタイムで更新する
  const updateTotalPercentage = (fields: string[], control: any) => {
    const watchedValues = useWatch({ control, name: fields });
    const [totalPercentage, setTotalPercentage] = useState<number>(0);

    useEffect(() => {
      const total = Object.values(watchedValues).reduce((acc, value) => {
        const numValue = typeof value === 'number' ? value : 0;
        return acc + numValue;
      }, 0);
      setTotalPercentage(total);
    }, [watchedValues]);

    return totalPercentage;
  };

  // ドーナッツチャートの面積をリアルタイムで更新する
  const updateChartArea = (fields: string[], control: any) => {
    const watchedValues = useWatch({ control, name: fields });
    const [chartData, setChartData] = useState<number[]>([]);

    useEffect(() => {
      const newData = watchedValues.map((value: number) => value ?? 0);
      if (!Helper.isEqual(newData, chartData)) {
        setChartData(newData);
      }
    }, [watchedValues, fields, control, chartData]);

    return chartData;
  };

  return {
    reactive: {
      updateTotalPercentage,
      updateChartArea,
    },
  };
};

namespace Helper {
  // 配列の中身が同じかどうかを判定するヘルパー関数
  export const isEqual = (array1: any[], array2: any[]): boolean => {
    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  };
}
