import { createSvgIcon } from '@mui/material/utils';

export const CheckBoxOn = createSvgIcon(
  <svg
    id="チェック"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="ic_fluent_checkmark_circle_24_filled">
      <path
        id="_-Color"
        data-name="🎨-Color"
        d="M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2Zm3.22,6.97-4.47,4.47L8.78,11.47A.75.75,0,0,0,7.72,12.53l2.5,2.5a.75.75,0,0,0,1.061,0l5-5A.75.75,0,1,0,15.22,8.97Z"
        transform="translate(-2 -2)"
        fill="#048cf2"
      />
    </g>
  </svg>,
  'CheckBoxOn'
);
