import { UseFormReturn } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import { ErrorMessage } from './ErrorMessage';

type Props = PropsWithChildren<{
  useForm: UseFormReturn<any>;
  name: string;
  value: string | number;
  onClick?: (e: any) => void;
  isAsCheckbox?: boolean;
}>;

export const Radio = ({
  useForm,
  name,
  value,
  children,
  onClick,
  isAsCheckbox,
}: Props) => {
  const uniqueRadioId = `${name}-${value}`;

  return (
    <div>
      {!isAsCheckbox ? (
        <div className="relative inline-flex items-center base-hover">
          <input
            type="radio"
            value={value}
            defaultValue={useForm.getValues(name)}
            checked={String(useForm.watch(name)) === String(value)}
            {...useForm.register(name)}
            onClick={onClick}
            id={uniqueRadioId}
            className="
            absolute whitespace-nowrap w-[1px] h-[1px] border-0 p-0 -m-[1px] cursor-pointer appearance-none
            before:content-[''] before:absolute before:w-[24px] before:h-[24px] before:rounded-full before:border-1
            before:border-grayscale-600 before:top-[50%] -translate-y-[12px] before:left-0 before:transform
            checked:before:border-main-blue
            after:content-[''] after:absolute after:w-[14px] after:h-[14px] after:rounded-full after:bg-main-blue
            after:top-[6px] after:left-[5px] after:opacity-0
            checked:after:opacity-100
          "
          />
          <label
            htmlFor={uniqueRadioId}
            className="pl-[34px] text-base text-grayscale-700 cursor-pointer no-selection-bg"
            aria-label="ラジオボタンの識別子"
          >
            {children}
          </label>
        </div>
      ) : (
        <div className="relative inline-flex items-center base-hover">
          <input
            type="radio"
            value={value}
            defaultValue={useForm.getValues(name)}
            checked={String(useForm.watch(name)) === String(value)}
            {...useForm.register(name)}
            onClick={onClick}
            id={uniqueRadioId}
            className="
              absolute whitespace-nowrap w-[1px] h-[1px] border-0 p-0 -m-[1px] cursor-pointer appearance-none
              before:content-[''] before:w-[18px] before:h-[18px] before:rounded before:border-1 before:border-grayscale-600 before:block
              before:absolute before:top-[50%] before:-translate-y-[10px] before:left-0 before:transform
              checked:before:bg-main-blue checked:before:border-main-blue
              after:content-[''] after:absolute after:border-b-2 after:border-l-2 after:opacity-0
              after:h-[6px] after:w-[10px] after:transform after:border-grayscale-100
              after:-rotate-45 after:-top-[4px] after:left-[4px] after:block
              checked:after:opacity-100
            "
          />
          <label
            htmlFor={uniqueRadioId}
            className="pl-[28px] text-base text-grayscale-700 cursor-pointer no-selection-bg"
            aria-label="ラジオボタンの識別子"
          >
            {children}
          </label>
        </div>
      )}

      {!!useForm.formState.errors[name]?.message && (
        <ErrorMessage
          message={useForm.formState.errors[name]?.message?.toString()}
        />
      )}
    </div>
  );
};
