export const QuestionCircle = () => (
  <svg
    id="help"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      id="question-circle"
      d="M9,2A7,7,0,1,1,2,9,7,7,0,0,1,9,2Zm0,9.45a.7.7,0,1,0,.7.7A.7.7,0,0,0,9,11.45ZM9,5.325A1.925,1.925,0,0,0,7.075,7.25a.525.525,0,0,0,1.045.071l0-.071a.875.875,0,1,1,1.75,0,1.151,1.151,0,0,1-.451.933l-.095.1a2.2,2.2,0,0,0-.854,1.771.525.525,0,1,0,1.05,0,1.151,1.151,0,0,1,.451-.933l.095-.1a2.2,2.2,0,0,0,.854-1.771A1.925,1.925,0,0,0,9,5.325Z"
      transform="translate(-2 -2)"
      fill="#b9bcbf"
    />
  </svg>
);
