import { Dialog } from '@mui/material';
import { BaseButton } from 'infra/components/UI/Buttons/BaseButton';
import { PropsWithChildren, useEffect } from 'react';
import { BaseModal } from 'infra/components/UI/Overlays/BaseModal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCoporationAdminById } from 'context/users/corporation_admin/api/corporationAdmin.get';
import { LoadingBackdrop } from 'infra/components/UI/Overlays/LoadingBackdrop';
import { TextField } from 'infra/components/UI/Forms/TextField';
import { CalendarInput } from 'infra/components/UI/Forms/CalendarInput';
import { CorporationAdminFormWithPassword, schema, passwordScheme } from '.';

export const CorporationAdminEditDialog = (
  props: PropsWithChildren<{
    // nullの場合は新規登録
    editingCorporationAdminId: string | null;
    onClose: () => void;
    onSave: (value: CorporationAdminFormWithPassword) => void;
  }>
) => {
  const isCreate = !props.editingCorporationAdminId;
  const useFormInstance = useForm({
    resolver: zodResolver(isCreate ? schema.merge(passwordScheme) : schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      departmentName: '',
      dateOfBirth: null,
    } as CorporationAdminFormWithPassword,
  });

  const onSubmit = useFormInstance.handleSubmit((value) => {
    props.onSave(value);
  });

  if (props.editingCorporationAdminId) {
    const [{ data, loading }] = getCoporationAdminById(
      props.editingCorporationAdminId
    );
    useEffect(() => {
      if (!loading) {
        useFormInstance.setValue('lastName', data?.lastName);
        useFormInstance.setValue('firstName', data?.firstName);
        useFormInstance.setValue('departmentName', data?.departmentName);
        useFormInstance.setValue('email', data?.email);
        useFormInstance.setValue('dateOfBirth', data?.dateOfBirth);
      }
    }, [loading]);

    if (loading) {
      return <LoadingBackdrop isShow />;
    }
  }

  return (
    <Dialog open onClose={props.onClose} maxWidth={false}>
      <BaseModal
        activeButton={
          <BaseButton
            type="submit"
            className="w-btn-small text-btn-confirm p-button-small"
            onClick={onSubmit}
          >
            保存
          </BaseButton>
        }
        closeHandler={props.onClose}
      >
        <div className="pt-[10px] pb-[30px]">
          <h3 className="text-section-heading font-bold">担当者設定</h3>

          <div className="mb-[30px]">
            <p className="text-base font-bold pt-[30px] pb-[16px]">姓</p>
            <TextField
              useForm={useFormInstance}
              name="lastName"
              isErrorFiled={!!useFormInstance.formState.errors.lastName}
              className="w-[320px]"
            />
          </div>

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">名</p>
            <TextField
              useForm={useFormInstance}
              name="firstName"
              isErrorFiled={!!useFormInstance.formState.errors.firstName}
              className="w-[320px]"
            />
          </div>

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">メールアドレス</p>
            <TextField
              useForm={useFormInstance}
              name="email"
              isErrorFiled={!!useFormInstance.formState.errors.email}
              className="w-[320px]"
            />
          </div>

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">部署名</p>
            <TextField
              useForm={useFormInstance}
              name="departmentName"
              isErrorFiled={!!useFormInstance.formState.errors.departmentName}
              className="w-[320px]"
            />
          </div>

          {isCreate && (
            <div className="mb-[30px]">
              <p className="text-base font-bold pb-[16px]">パスワード</p>
              <TextField
                useForm={useFormInstance}
                name="password"
                isErrorFiled={!!useFormInstance.formState.errors.password}
                className="w-[320px]"
                helperText="パスワードは半角英数字8文字以上で入力ください。"
                showHelperText
              />
            </div>
          )}

          <div className="mb-[30px]">
            <p className="text-base font-bold pb-[16px]">お誕生日</p>
            <CalendarInput
              useForm={useFormInstance}
              name="dateOfBirth"
              isErrorFiled={!!useFormInstance.formState.errors.dateOfBirth}
              className="w-[320px]"
            />
          </div>
        </div>
      </BaseModal>
    </Dialog>
  );
};
