import { Grid } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { StoreIcon } from 'pages/00_home/icons/StoreIcon';
import { FlagIcon } from 'pages/00_home/icons/FlagIcon';
import { PeopleIcon } from 'pages/00_home/icons/PeopleIcon';
import { MandalatIcon } from 'pages/00_home/icons/MandalatIcon';
import { SettingsIcon } from 'pages/00_home/icons/SettingsIcon';
import { StarIcon } from 'pages/00_home/icons/StarIcon';
import { BusinessIcon } from 'pages/00_home/icons/BusinessIcon';
import { Link } from 'react-router-dom';
import { PageTitle } from 'infra/components/UI/Headings/PageTitle';
import { getLoginInfo } from 'context/auth/api/login';
import { EvaluationAlertView } from '../../context/promotion/components/EvaluationAlertView';

const Tile = (
  props: PropsWithChildren<{
    title: string;
    description: string;
    icon: ReactNode;
  }>
) => (
  <div
    className="
      flex h-full p-box gap-[20px]
    bg-grayscale-100 rounded
      duration-base ease-out-expo transition-opacity
      hover:opacity-base"
  >
    {props.icon}
    <div>
      <h2 className="text-grayscale-800 font-bold mb-[10px]">{props.title}</h2>
      <div className="min-h-[32px]">
        <p className="text-meta text-grayscale-800 leading-readable">
          {props.description}
        </p>
      </div>
    </div>
  </div>
);

export const Home = () => {
  const [{ data: me }] = getLoginInfo();

  return (
    <div>
      <PageTitle title="ホーム" breadcrumbs={[]} />

      <div>{me && <EvaluationAlertView myId={me.id} />}</div>
      <Grid container spacing="20px" sx={{ paddingTop: '30px' }}>
        <Grid item xs={4}>
          <Link to="/brands">
            <Tile
              title="店舗管理"
              description="店舗情報の登録や編集などはこちら"
              icon={<StoreIcon />}
            />
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link to="/shop-mbo">
            <Tile
              title="店舗MBO管理"
              description="各店舗の売上・利益やES・CS情報の管理などはこちら"
              icon={<FlagIcon />}
            />
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link to="/staffs_manage/staffs/">
            <Tile
              title="スタッフ管理"
              description="スタッフ情報の登録や管理などはこちら"
              icon={<PeopleIcon />}
            />
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link to="/mandalat/import-mandalat-master-csv">
            <Tile
              title="マンダラ設定"
              description="マンダラの設定はこちら"
              icon={<MandalatIcon />}
            />
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link to="/operation-setting/occupation-position-setting">
            <Tile
              title="運用設定"
              description="職種・職位・等級や評価設定などはこちら"
              icon={<SettingsIcon />}
            />
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link to="/evaluation_info/rankings/">
            <Tile
              title="評価情報"
              description="最終評価完了一覧はこちら"
              icon={<StarIcon />}
            />
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Link to="/operation-setting/contract-setting">
            <Tile
              title="企業設定"
              description="契約情報などはこちら"
              icon={<BusinessIcon />}
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
