import { useAtom } from 'jotai';
import { displayMessageAtom } from 'infra/components/UI/Overlays/globalmessage';

export const hasNonFieldError = (error: any) => {
  return (
    isNonFieldErrorObject(error.response?.data?.nonFieldErrors) ||
    isNonFieldErrorObject(error.response?.data)
  );
};

const isNonFieldErrorObject = (errorObject: any) => {
  if (errorObject === undefined) return false;
  return Array.isArray(errorObject) && typeof errorObject[0] === 'string';
};

/**
 * nonFieldなAPIエラーを画面に表示する関数を提供するカスタムフック
 */
export const useDisplayNonFieldApiError = () => {
  const [, displayMessage] = useAtom(displayMessageAtom);
  return (error: any) => {
    if (error.response && error.response.status === 500) {
      displayMessage({
        text: '不明なエラーが発生しました。しばらくしてからもう一度お試しください。',
        isInterruption: true,
      });
      return;
    }
    const nonFieldErrors = [];
    if (isNonFieldErrorObject(error.response?.data?.nonFieldErrors)) {
      nonFieldErrors.push(...(error.response?.data?.nonFieldErrors || []));
    }

    if (isNonFieldErrorObject(error.response?.data)) {
      nonFieldErrors.push(...(error.response?.data || []));
    }

    const text =
      nonFieldErrors.length !== 0
        ? nonFieldErrors.map((message) => `・${message}`).join('\n')
        : '不明なエラーが発生しました。しばらくしてから再度お試しください';
    displayMessage({ text, isInterruption: true });
  };
};
