import { useState } from 'react';
import { CommitmentCard } from 'context/users/onsite_users/_infra/components/StaffDetails/_components/CommitmentCard';
import { ResponseObjectiveRecord } from 'context/objective/api/useFetchObjectiveRecords';
import { Divider } from 'infra/components/UI/Elements/Divider';
import {
  useFetchEvaluationPeriodGroups,
  ResponseEvaluationPeriodGroups,
} from 'context/evaluation_period/api/useFetchEvaluationPeriodGroups';
import { MandalatSelect } from './MandalatSelect';

export const GoalTab = (props: {
  objectiveRecordList: ResponseObjectiveRecord[];
  onSelectObjectiveRecord: (id: number) => void;
}) => {
  const [{ data: evaluationPeriodGroups }] = useFetchEvaluationPeriodGroups();
  const results = evaluationPeriodGroups?.results;
  const [selectedYear, setSelectedYear] = useState<string | null>(null);

  const setCurrentSelectYear = (year: string) => {
    setSelectedYear(year);
  };

  const filteredObjectiveRecordListByYear = props.objectiveRecordList.filter(
    (objectiveRecord) => {
      if (!selectedYear) {
        return true;
      }
      const year = new Date(objectiveRecord.evaluationPeriod.startedAt)
        .getFullYear()
        .toString();
      return year === selectedYear;
    }
  );

  const getYearsFromEvaluationPeriods = (
    evaluationPeriodGroupsData: ResponseEvaluationPeriodGroups[]
  ) => {
    let yearsArray: any = [];
    evaluationPeriodGroupsData.map((group) => {
      return group.evaluationPeriods.map((period) => {
        return yearsArray.push(period.startedAt);
      });
    });

    yearsArray = yearsArray.map((year: string) => {
      return new Date(year).getFullYear().toString();
    });

    yearsArray = new Set(
      yearsArray.sort((a: string, b: string) => Number(b) - Number(a))
    );

    return [...yearsArray].map((year: string) => ({
      label: year.toString(),
      value: year,
    }));
  };

  const uniqueYearsFromEvaluationPeriods = results
    ? getYearsFromEvaluationPeriods(results)
    : [];

  return (
    <div className="py-[40px] px-[24px]">
      <div className="flex justify-between">
        <p className="font-bold">マンダラ</p>
        <MandalatSelect
          menuItems={uniqueYearsFromEvaluationPeriods}
          onSelectYear={setCurrentSelectYear}
        />
      </div>
      <Divider className="mt-[30px] mb-[40px] border-b-2" />
      <div className="flex flex-col gap-[30px]">
        {filteredObjectiveRecordListByYear.map((objectiveRecord) => (
          <div className="base-hover">
            <CommitmentCard
              key={objectiveRecord.id}
              cardProps={{
                onClick: () =>
                  props.onSelectObjectiveRecord(objectiveRecord.id),
              }}
              objectiveRecord={objectiveRecord}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
