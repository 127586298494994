import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { PropsWithChildren } from 'react';

export const NotificationDialog = ({
  open,
  onClose,
  title,
  children,
}: PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title: string;
}>) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>
        {children}
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ padding: '20px', paddingTop: 0 }}>
      <Button onClick={onClose}>閉じる</Button>
    </DialogActions>
  </Dialog>
);
