import {
  ResponseMandalatMaster,
  MandalatSquareSettingRules,
} from 'context/mandalat/api/typeResponse';

export const convertSubTypeAndBasicThinkingMasterId2Label = (
  rule: MandalatSquareSettingRules[number],
  latestMandalatmaster: ResponseMandalatMaster
) => {
  const { type, subType, basicThinkingMasterId } = rule;
  if (type === '選択テーマ') {
    if (subType === '心') {
      return '心 Spirit';
    }
    if (subType === '技') {
      return '技 Skill';
    }
    if (subType === '体') {
      return '体 Stance';
    }
    if (subType === '生活') {
      return '生活 Style';
    }
  }
  if (type === '必須') {
    if (basicThinkingMasterId === 0) {
      return '任意';
    }
    return latestMandalatmaster?.basicThinkingMasters.filter(
      (basicThinkingMaster) => basicThinkingMaster.id === basicThinkingMasterId
    )[0]?.basicThinkingLabel;
  }
  return '未指定';
};
