export const ArrowUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.001"
      height="18.001"
      viewBox="0 0 16.001 18.001"
    >
      <g
        id="_-System-Icons"
        data-name="🔍-System-Icons"
        transform="translate(-3.996 -2.999)"
      >
        <g id="ic_fluent_arrow_up_24_filled">
          <path
            id="_-Color"
            data-name="🎨-Color"
            d="M4.284,10.3a1,1,0,1,0,1.425,1.4L11,6.329V20a1,1,0,0,0,2,0V6.335L18.285,11.7a1,1,0,0,0,1.425-1.4L12.887,3.371a1.25,1.25,0,0,0-1.781,0Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
