/* eslint-disable max-classes-per-file */
import {
  OnsiteUserDetailResponse,
  OnsiteUserListResponse,
} from 'context/users/onsite_users/_infra/api/onsiteUsers.get';
import { atom } from 'jotai';

export interface UserListStateInterface {
  results: OnsiteUserListResponse[];
  paging: () => void;
  search: () => void;
}

export interface UserDetailType {
  readonly onsiteUser?: OnsiteUserDetailResponse;
  readonly appUserState: AppUserStateInterface;
}

export type AppUserStateInterface = {
  isCurrentState: () => 'suspended' | 'withdrawal' | 'active';
};

const ActiveUserState: AppUserStateInterface = {
  isCurrentState: () => 'active',
};

const SuspendedUserState: AppUserStateInterface = {
  isCurrentState: () => 'suspended',
};

const WithdrawalUserState: AppUserStateInterface = {
  isCurrentState: () => 'withdrawal',
};

export class UserDetail implements UserDetailType {
  public readonly onsiteUser?: OnsiteUserDetailResponse;

  public readonly appUserState: AppUserStateInterface;

  // TODO 型を正確に
  constructor(onsiteUser?: OnsiteUserDetailResponse) {
    this.onsiteUser = onsiteUser;
    this.appUserState = (() => {
      if (this.onsiteUser?.isSuspended === true) {
        return SuspendedUserState;
      }

      if (this.onsiteUser?.appUsageState === 'withdrawal') {
        return WithdrawalUserState;
      }

      return ActiveUserState;
    })();
  }
}

export const OnsiteUserStateAtom = atom<UserDetailType>(new UserDetail());
