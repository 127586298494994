import { Link } from 'react-router-dom';
import { useFetchObjectiveRecordsRequiredForOutsideManager } from 'context/objective/api/useFetchObjectiveRecords';
import { useState } from 'react';
import { formatDate } from 'infra/utils/date-fns';
import { colorToRgba } from 'context/mvps/types/mvpCategory';
import { CheckIcon } from 'context/promotion/components/CheckIcon';
import { COLORS } from 'tailwind.config';

export const EvaluationAlertView = ({ myId }: { myId: string }) => {
  const [now] = useState(new Date());
  const {
    final: [
      { data: requiredFinalEvaluations, loading: loadingFinalEvaluations },
    ],
    middle: [
      { data: requiredMiddleEvaluations, loading: loadingMiddleEvaluations },
    ],
  } = useFetchObjectiveRecordsRequiredForOutsideManager(myId, now);

  if (
    loadingFinalEvaluations ||
    loadingMiddleEvaluations ||
    requiredMiddleEvaluations === undefined ||
    requiredFinalEvaluations === undefined
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-[20px]">
      {requiredMiddleEvaluations.count !== 0 && (
        <div
          className="p-[30px] rounded mt-[50px]"
          style={{
            backgroundColor: colorToRgba(COLORS.MAIN_GREEN, 0.1),
          }}
        >
          <div className="flex items-center mb-[20px]">
            <div className="w-[24px] h-[24px]">
              <CheckIcon />
            </div>
            <p className="font-bold ml-[10px]">
              以下のユーザーの中間上司評価を行ってください
            </p>
          </div>
          {requiredMiddleEvaluations.results.map((e) => {
            return (
              <p className="text-base text-main-blue font-medium mt-[8px]">
                <Link to={`/evaluate/${e.id}/middle`}>
                  ・{`${e.onsiteUser.lastName} ${e.onsiteUser.firstName}`}（
                  {formatDate(new Date(e.evaluationPeriod.startedAt))}
                  から
                  {formatDate(new Date(e.evaluationPeriod.endedAt))}
                  までの評価期間 ）
                </Link>
              </p>
            );
          })}
        </div>
      )}
      {requiredFinalEvaluations.count !== 0 && (
        <div
          className="p-[30px] rounded mt-[50px]"
          style={{
            backgroundColor: colorToRgba(COLORS.MAIN_GREEN, 0.1),
          }}
        >
          <div className="flex items-center mb-[20px]">
            <div className="w-[24px] h-[24px]">
              <CheckIcon />
            </div>
            <p className="font-bold ml-[10px]">
              以下のユーザーの最終上司評価を行ってください
            </p>
          </div>
          {requiredFinalEvaluations.results.map((e) => {
            return (
              <p className="text-base text-main-blue font-medium mt-[8px]">
                <Link to={`/evaluate/${e.id}/final`}>
                  ・{`${e.onsiteUser.lastName} ${e.onsiteUser.firstName}`}（
                  {formatDate(new Date(e.evaluationPeriod.startedAt))}
                  から
                  {formatDate(new Date(e.evaluationPeriod.endedAt))}
                  までの評価期間 ）
                </Link>
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};
