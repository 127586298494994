import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { PeriodCircleIcon } from 'context/evaluation_period/components/EvaluationPeriodSetting/_components/PeriodCircleIcon';
import { PeriodCircleActiveIcon } from 'context/evaluation_period/components/EvaluationPeriodSetting/_components/PeriodCircleActiveIcon';
import { format } from 'date-fns';
import { ResponseEvaluationPeriod } from 'context/evaluation_period/api/typeResponse';
import { twMerge } from 'tailwind-merge';
import { TildeIcon } from 'infra/components/UI/Icons/TildeIcon';
import { Tooltip } from 'infra/components/UI/Helps/Tooltip';
import { useAtom } from 'jotai';
import {
  convertDateToString,
  isBetweenDate,
  selectedEvaluationPeriodSettingAtom,
} from '../state';

export const EvaluationPeriodList3Month = () => {
  const [selectedEvaluationPeriodSetting, _] = useAtom(
    selectedEvaluationPeriodSettingAtom
  );

  return (
    <>
      <>
        <h2 className="font-bold text-item-heading mb-[25px]">
          評価スケジュール（第1四半期）
        </h2>
        <EvaluationPeriodList
          ep={selectedEvaluationPeriodSetting.evaluationPeriods[0]}
          additionalClass=""
        />
      </>

      <>
        <h2 className="font-bold text-item-heading mb-[25px]">
          評価スケジュール（第2四半期）
        </h2>

        <EvaluationPeriodList
          ep={selectedEvaluationPeriodSetting.evaluationPeriods[1]}
          additionalClass="mt-5"
        />
      </>

      <>
        <h2 className="font-bold text-item-heading mb-[25px]">
          評価スケジュール（第3四半期）
        </h2>

        <EvaluationPeriodList
          ep={selectedEvaluationPeriodSetting.evaluationPeriods[2]}
          additionalClass="mt-5"
        />
      </>

      <>
        <h2 className="font-bold text-item-heading mb-[25px]">
          評価スケジュール（第4四半期）
        </h2>

        <EvaluationPeriodList
          ep={selectedEvaluationPeriodSetting.evaluationPeriods[3]}
          additionalClass="mt-5"
        />
      </>
    </>
  );
};

export const EvaluationPeriodList6Month = () => {
  const [selectedEvaluationPeriodSetting, _] = useAtom(
    selectedEvaluationPeriodSettingAtom
  );

  return (
    <>
      <>
        <h2 className="font-bold text-item-heading mb-[25px]">
          評価スケジュール（上期）
        </h2>
        <EvaluationPeriodList
          ep={selectedEvaluationPeriodSetting.evaluationPeriods[0]}
          additionalClass=""
        />
      </>

      <>
        <h2 className="font-bold text-item-heading mb-[25px]">
          評価スケジュール（下期）
        </h2>

        <EvaluationPeriodList
          ep={selectedEvaluationPeriodSetting.evaluationPeriods[1]}
          additionalClass="mt-5"
        />
      </>
    </>
  );
};

export const EvaluationPeriodList1Year = () => {
  const [selectedEvaluationPeriodSetting, _] = useAtom(
    selectedEvaluationPeriodSettingAtom
  );

  return (
    <>
      <h2 className="font-bold text-item-heading mb-[25px]">
        評価スケジュール（年間）
      </h2>
      <EvaluationPeriodList
        ep={selectedEvaluationPeriodSetting.evaluationPeriods[0]}
        additionalClass=""
      />
    </>
  );
};

export const EvaluationPeriodList = (
  props: BoxProps & {
    ep: ResponseEvaluationPeriod;
    additionalClass: string;
  }
) => (
  <Box {...props} className={`${props.additionalClass}`}>
    <EvaluationPeriodListItem
      title="目標設定期間"
      isCurrentEvaluation={isBetweenDate(
        props.ep.objectiveCreationStartedAt,
        props.ep.objectiveCreationEndedAt
      )}
    >
      <PeriodContent
        start={props.ep.objectiveCreationStartedAt}
        end={props.ep.objectiveCreationEndedAt}
      />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="中間評価実施期間"
      isCurrentEvaluation={
        isBetweenDate(
          props.ep.intermediateMyselfEvaluationStartedAt,
          props.ep.intermediateMyselfEvaluationEndedAt
        ) ||
        isBetweenDate(
          props.ep.intermediateBossEvaluationStartedAt,
          props.ep.intermediateBossEvaluationEndedAt
        )
      }
    >
      <PeriodContentMyselfAndBoss
        myself={{
          start: props.ep.intermediateMyselfEvaluationStartedAt,
          end: props.ep.intermediateMyselfEvaluationEndedAt,
        }}
        boss={{
          start: props.ep.intermediateBossEvaluationStartedAt,
          end: props.ep.intermediateBossEvaluationEndedAt,
        }}
      />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="中間面談実施期間（任意）"
      tooltip={{
        text: '中間上司評価の際、必要に応じて被評価者と面談を設定する期間です。',
      }}
      isCurrentEvaluation={isBetweenDate(
        props.ep.intermediateInterviewStartedAt,
        props.ep.intermediateInterviewEndedAt
      )}
    >
      <PeriodContent
        start={props.ep.intermediateInterviewStartedAt}
        end={props.ep.intermediateInterviewEndedAt}
      />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="最終評価実施期間"
      isCurrentEvaluation={
        isBetweenDate(
          props.ep.finalMyselfEvaluationStartedAt,
          props.ep.finalMyselfEvaluationEndedAt
        ) ||
        isBetweenDate(
          props.ep.finalBossEvaluationStartedAt,
          props.ep.finalBossEvaluationEndedAt
        )
      }
    >
      <PeriodContentMyselfAndBoss
        myself={{
          start: props.ep.finalMyselfEvaluationStartedAt,
          end: props.ep.finalMyselfEvaluationEndedAt,
        }}
        boss={{
          start: props.ep.finalBossEvaluationStartedAt,
          end: props.ep.finalBossEvaluationEndedAt,
        }}
      />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="最終評価前面談実施期間（任意）"
      tooltip={{
        text: '最終上司評価の際、必要に応じて被評価者と面談を設定する期間です。',
      }}
      isCurrentEvaluation={isBetweenDate(
        props.ep.finalInterviewStartedAt,
        props.ep.finalInterviewEndedAt
      )}
    >
      <PeriodContent
        start={props.ep.finalInterviewStartedAt}
        end={props.ep.finalInterviewEndedAt}
      />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="最終評価検討期間"
      tooltip={{
        text: '現場の評価完了後、評価会議等で最終評価を確定するまでの期間です。',
      }}
      isCurrentEvaluation={isBetweenDate(
        props.ep.headquartersEvaluationConsiderationStartedAt,
        props.ep.headquartersEvaluationConsiderationEndedAt
      )}
    >
      <PeriodContent
        start={props.ep.headquartersEvaluationConsiderationStartedAt}
        end={props.ep.headquartersEvaluationConsiderationEndedAt}
      />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="最終評価確定日"
      // 同じ日付であればactive
      isCurrentEvaluation={
        format(new Date(), 'yyyy-MM-dd') ===
        format(new Date(props.ep.headquartersEvaluationFixedDate), 'yyyy-MM-dd')
      }
      tooltip={{
        text: '全員の最終評価を確定する期日です。確定後の評価変更はできません。',
      }}
    >
      <PeriodContent start={props.ep.headquartersEvaluationFixedDate} />
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="最終評価スタッフ通知日時"
      // 同じ日付であればactive
      isCurrentEvaluation={
        format(new Date(), 'yyyy-MM-dd') ===
        format(
          new Date(props.ep.headquartersEvaluationNotifiedAt),
          'yyyy-MM-dd'
        )
      }
      tooltip={{
        text: '設定した日時に各スタッフのアプリ画面に評価結果が配信されます。',
      }}
    >
      <p className="flex items-center text-ui text-grayscale-700">
        {format(
          new Date(props.ep.headquartersEvaluationNotifiedAt),
          'yyyy/MM/dd HH:00'
        )}
      </p>
    </EvaluationPeriodListItem>
    <EvaluationPeriodListItem
      title="次期目標設定面談実施期間"
      isLast
      isCurrentEvaluation={isBetweenDate(
        props.ep.interviewAfterHeadquartersEvaluationStartedAt,
        props.ep.interviewAfterHeadquartersEvaluationEndedAt
      )}
      tooltip={{ text: '前期の振り返りと次期目標設定に関する面談期間です。' }}
    >
      <PeriodContent
        start={props.ep.interviewAfterHeadquartersEvaluationStartedAt}
        end={props.ep.interviewAfterHeadquartersEvaluationEndedAt}
      />
    </EvaluationPeriodListItem>
    {/* <EvaluationPeriodListItem
      isLast
      title="最終評価確定後＆目標設定面談実施期間"
      isCurrentEvaluation={isBetweenDate(props.ep.interviewAfterHeadquartersEvaluationStartedAt, props.ep.interviewAfterHeadquartersEvaluationEndedAt)}
    >
      <PeriodContent start={props.ep.interviewAfterHeadquartersEvaluationStartedAt} end={props.ep.interviewAfterHeadquartersEvaluationEndedAt} />
    </EvaluationPeriodListItem> */}
  </Box>
);

const EvaluationPeriodListItem = (
  props: PropsWithChildren<{
    title: string;
    isLast?: boolean;
    isCurrentEvaluation: boolean;
    tooltip?: {
      text: string;
    };
  }>
) => {
  const borderLeftStyle =
    props.isLast === undefined || !props.isLast
      ? 'border-0 border-l border-grayscale-600 border-dashed'
      : '';

  const periodListItemStyles = twMerge(
    'pt-[10px] pb-[30px] pl-[18px] ml-[7px] mb-[14px]',
    borderLeftStyle
  );

  return (
    <div>
      <div className="flex items-center mb-3">
        {props.isCurrentEvaluation ? (
          <PeriodCircleActiveIcon />
        ) : (
          <PeriodCircleIcon />
        )}
        <h3 className="ml-2 text-base font-bold">{props.title}</h3>
        {props.tooltip && <Tooltip tooltip={props.tooltip} />}
      </div>
      <div className={periodListItemStyles}>{props.children}</div>
    </div>
  );
};

const PeriodContentMyselfAndBoss = (props: {
  myself: {
    start: string;
    end: string;
  };
  boss: {
    start: string;
    end: string;
  };
}) => {
  return (
    <div className="flex">
      <div className="mr-[60px]">
        <p className="text-ui text-grayscale-800 mb-1">自己評価</p>
        <PeriodContent start={props.myself.start} end={props.myself.end} />
      </div>
      <div>
        <p className="text-ui text-grayscale-800 mb-1">上司評価</p>
        <PeriodContent start={props.boss.start} end={props.boss.end} />
      </div>
    </div>
  );
};

const PeriodContent = (props: { start: string; end?: string }) => (
  <p className="flex items-center text-ui text-grayscale-700">
    {convertDateToString(props.start)}
    {props.end !== undefined ? (
      <>
        <div className="mx-[10px]">
          <TildeIcon />
        </div>
        {convertDateToString(props.end)}
      </>
    ) : null}
  </p>
);
