/**
 * スタッフ詳細のタブコンポーネント関連のステート
 */

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { state as DeleteOrSuspendModalState } from 'context/users/onsite_users/_infra/components/Modal/DeleteOrSuspendModal';

/**
 * Types
 */
export namespace Types {
  export type State = {
    activeTab: number;
    setActiveTab: (index: number) => void;
    generateTabProp: any;
  };
}

/**
 * `state` にはスタッフ詳細のタブコンポーネントに関する状態があります。
 * このスタッフ詳細のタブコンポーネントに関連する状態管理はここを参照してください。
 * このスタッフ詳細のタブコンポーネントに状態を追加する場合は、ここ以外に記述しないでください。
 *
 * @returns {object} 状態管理に必要な変数と関数を含むオブジェクト
 */
export const state = (): Types.State => {
  const [activeTab, setActiveTab] = useState(0);

  return {
    activeTab,
    setActiveTab,
    generateTabProp: (index: number) => ({
      id: `staff-tab-${index}`,
      'aria-controls': `staff-tabpanel-${index}`,
    }),
  };
};

export namespace reactive {
  export const NavigateToStaffsWhenDeleteCompleted = () => {
    const deleteOrSuspendModalState = DeleteOrSuspendModalState.local();
    const navigate = useNavigate();

    useEffect(() => {
      if (deleteOrSuspendModalState.isDeletedCompleted) {
        navigate('/staffs_manage/staffs/');
      }
    }, [deleteOrSuspendModalState.isDeletedCompleted]);
  };
}
